import { Form } from "react-bootstrap";
import tableIcon from "../assets/images/table-icon.svg";

export default function DeactivatedFleet() {
  return (
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            <th style={{width:"30px"}}>
              <Form.Check aria-label="option 1" />
            </th>
            <th style={{ width: "140px" }}>
              <div className="stop-item">
                <strong>Type</strong>
                <img src={tableIcon} alt="icon" />
              </div>
            </th>
            <th>
              <div>
                <strong>Number</strong>
                <img src={tableIcon} alt="icon" />
              </div>
            </th>
            <th>
              <div>
                <strong>YEAR</strong>
                <img src={tableIcon} alt="icon" />
              </div>
            </th>
            <th>
              <strong>Make</strong>
            </th>
            <th>
              <div>
                <strong>Model</strong>
                <img src={tableIcon} alt="icon" />
              </div>
            </th>
            <th>
              <div>
                <strong>license number</strong>
                <img src={tableIcon} alt="icon" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{width:"30px"}}>
              <Form.Check aria-label="option 1" />
            </td>
            <td>
              <div>
                <span>Truck</span>
              </div>
            </td>
            <td>
              <div>
                <span>53737373</span>
              </div>
            </td>
            <td>
              <div>
                <span>Freight linter</span>
              </div>
            </td>
            <td>
              <div>
                <span>S256565</span>
              </div>
            </td>
            <td>
              <div>
                <span>2018</span>
              </div>
            </td>
            <td>
              <div>
                <span>S256565</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
