import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Spinner } from "react-bootstrap";

AOS.init();
export default function Loadingdata({ title }) {
  return (
    <div className="no-data-outer" style={{height:'75vh'}}>
      {/* <div className="loader">
        <img src={require("../images/loader.gif")} />
        <h3 style={{textAlign:"center"}}>{title ?? "Loading Data..."}</h3>
      </div> */}
      <Spinner animation="border" role="status" size="lg" />
    </div>
  );
}
