import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import addDocument from "../../assets/images/add-document.svg";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { sendInvitationAsyncThunk } from "../../redux/pagesSlices/workspaceSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFleetsAsyncThunk } from "../../redux/pagesSlices/fleetSlice";
import {
  createDriverAsyncThunk,
  getDriversAsyncThunk,
} from "../../redux/pagesSlices/driverSlice";
import { State } from "country-state-city"; // Import the State module
import { Link } from "react-router-dom";

const initialData = {
  firstName: "",
  lastName: "",
  city: "",
  state: "",
  phone: "",
  zipCode: "",
  email: "",
};

const AddDriverModal = () => {
  const { loadings } = useSelector((state) => state.workspace);
  const [driverData, setDriverData] = useState(initialData);
  const [show, setShow] = useState(false);
  const [states, setStates] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.fleet?.fleet);
  console.log("🚀 ~ AddDriverModal ~ data:", data);

  useEffect(() => {
    const usStates = State.getStatesOfCountry("US");
    setStates(usStates);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getFleetsAsyncThunk({
          fleetType: "TRUCK",
        })
      );
    };
    fetchData();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    city: Yup.string().required("City is required"),
    email: Yup.string().required("Email is required"),
    state: Yup.string().required("State is required"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("values", values);

    const data = {
      email: values.email,
      // role: "driver",
      // metadata: {
      firstName: values.firstName,
      lastName: values.lastName,
      city: values.city,
      state: values.state,
      phone: values.phone,
      zipCode: values.zipCode,
      fleetId: values.fleetId,
      // },
    };

    dispatch(
      // createDriverAsyncThunk({
      createDriverAsyncThunk({
        data,
        callBack: () => {
          resetForm();
          handleClose();
        },
      })
    );

    setSubmitting(false);
  };

  return (
    <>
      {/* <button className="add-btn" variant="primary" onClick={handleShow}>
        <div className="icon">
          <img src={addDocument} alt="icon" />
        </div>

        <span>Add Driver</span>
      </button> */}
      <Link to={`/add-driver`} className="add-btn ms-3">
        <div className="icon">
          <img src={addDocument} alt="icon" />
        </div>
        <span>Add Driver</span>
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="add-truck-btn"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialData}
            validationSchema={validationSchema}
            // onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="form-wrapper">
                <div className="row">
                  <div className="col-md-6">
                    <div className="modal-field">
                      <label>First Name</label>
                      <Field type="text" placeholder="Max" name="firstName" />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-field">
                      <label>Last Name</label>
                      <Field type="text" placeholder="Max" name="lastName" />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="modal-field">
                      <label>City</label>
                      <Field type="text" placeholder="City" name="city" />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-field">
                      <label>State</label>
                      <Field
                        as="select"
                        placeholder="Select"
                        name="state"
                        className="bg-white"
                      >
                        <option value="">Select</option>
                        {Object.entries(states).length > 0 ? (
                          Object.entries(states)?.map(([key, value]) => (
                            <option key={key} value={key}>
                              {value.name}
                            </option>
                          ))
                        ) : (
                          <option value="">No states available</option>
                        )}
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="modal-field">
                      <label>Phone Number</label>
                      <Field
                        type="text"
                        placeholder="Enter phone number"
                        name="phone"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-field">
                      <label>Zip Code</label>
                      <Field
                        type="text"
                        placeholder="Enter zip code"
                        name="zipCode"
                      />
                      <ErrorMessage
                        name="zipCode"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="co">
                    <div className="modal-field">
                      <label>Email</label>
                      <Field
                        type="email"
                        placeholder="example@reenvoy.com"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="modal-field">
                      <label>assign Truck</label>
                      <Dropdown className="dropdown-1">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          Select
                        </Dropdown.Toggle>
                        {data?.results?.length > 0 ? (
                          <Dropdown.Menu>
                            {data?.results?.map((item) => (
                              <Dropdown.Item
                                key={item?.id}
                                onClick={() => {
                                  setFieldValue("fleetId", item?.id);
                                }}
                              >
                                {item?.fleetMake}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        ) : null}
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <Button
                  className="btn-style"
                  disabled={isSubmitting || loadings.sendInvitationAsyncThunk}
                  type="submit"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                  }}
                >
                  {isSubmitting || loadings.sendInvitationAsyncThunk ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    "Add"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDriverModal;
