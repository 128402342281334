import arrowBack from "../assets/images/arrow-back.svg";
import trash from "../assets/images/trash.svg";
import cancel from "../assets/images/cancel-right.svg";
import edit from "../assets/images/edit-icon.svg";
import DeleteLoadModal from "./Modals/DeleteLoadModal";
import TruckModal from "./Modals/MarkTruckModal";
import EditLoadModal from "./Modals/EditLoadModal";
import downloadGray from "../assets/images/download-gray.svg";
import addDocument from "../assets/images/add-document.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { createFleetAsyncThunk } from "../redux/pagesSlices/fleetSlice";
import { useDispatch } from "react-redux";
import AddDriverModal from "./Modals/AddDriverModal";

export default function DriversPageTopBar() {
  const [show, setShow] = useState(false);
  const [fleetData, setfleetData] = useState({
    fleetType: "TAILOR",
    modelYear: 0, //type Number
    fleetMake: "",
    fleetModel: "",
    odometer: 0, //type Number
    fleetNumber: 0,
    licenseNumber: "",
    status: "active", //active and deactive, fedault active
  });
  const [deleteModalShow, deleteSetModalShow] = useState(false);

  const handleDeleteModalOpen = () => {
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
  };

  const [editModalShow, editSetModalShow] = useState(false);

  const handleEditModalOpen = () => {
    editSetModalShow(true);
  };

  const handleEditModalClose = () => {
    editSetModalShow(false);
  };

  const dispatch = useDispatch();

  // Modal control
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setfleetData({
      ...fleetData,
      [name]: value,
    });
  };

  const handleDropdownChange = (name, value) => {
    setfleetData({
      ...fleetData,
      [name]: value,
    });
  };

  const handleCreateFleet = () => {
    dispatch(
      createFleetAsyncThunk({
        data: fleetData,
        activeTab: fleetData.fleetType,
      })
    );
    handleClose();
    setfleetData({
      fleetType: "TAILOR",
      modelYear: 0,
      fleetMake: "",
      fleetModel: "",
      odometer: 0,
      fleetNumber: 0,
      licenseNumber: "",
      status: "active", //active and deactive, fedault active
    });
  };

  return (
    <div className="load-top-bar-wrapper">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-4">
            <div className="bar-navigation">
              <div className="back-icon">
                <Link to="/home">
                  <img src={arrowBack} alt="arrow back" />
                </Link>
              </div>
              <div className="page-name">
                <strong>Drivers</strong>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="buttons-wrapper">
              <div className="single-button">
             
              <AddDriverModal />
              </div>

              <DeleteLoadModal
                handleDeleteModalClose={handleDeleteModalClose}
                deleteModalShow={deleteModalShow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
