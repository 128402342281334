export const defaultUserDocuments = [
  { documentType: "DOT Medical Card" },
  { documentType: "Insurance" },
  { documentType: "FMCSA Clearinghouse Driver Registration" },
  { documentType: "Complete Driver Qualification File" },
  { documentType: "MVR Record" },
  { documentType: "Road Test Certificate" },
];

export const defaultFleetDocuments = [
  { documentName: "DOT Medical Card", documentType: "Driver License" },
  { documentName: "Insurance", documentType: "Driver License" },
  {
    documentName: "FMCSA Clearinghouse Driver Registration",
    documentType: "Driver License",
  },
  {
    documentName: "Complete Driver Qualification File",
    documentType: "Driver License",
  },
  { documentName: "MVR Record", documentType: "Driver License" },
  { documentName: "Road Test Certificate", documentType: "Driver License" },
];

export const defaultDriverDocuments = [
  { documentName: "DOT Medical Card", documentType: "Driver License" },
  { documentName: "Insurance", documentType: "Driver License" },
  {
    documentName: "FMCSA Clearinghouse Driver Registration",
    documentType: "Driver License",
  },
  {
    documentName: "Complete Driver Qualification File",
    documentType: "Driver License",
  },
  { documentName: "MVR Record", documentType: "Driver License" },
  { documentName: "Road Test Certificate", documentType: "Driver License" },
];
