export default function Titlebar(){
    return(
        <div className="title-bar-wrapper">
            <div className="admin-title">
                <strong>
                    Admin
                </strong>
            </div>
            <div className="title-bar-inner-wrapper">
            <div className="title-wrapper" style={{maxWidth:"284px",width:"100%"}}>
                <strong>General Menu</strong>
            </div>
            <div className="title-wrapper">
                <strong>Company Profile</strong>
            </div>
            </div>
            
        </div>
    )
}