import React from "react";
import Navbar from "../components/Navbar";
import tableIcon from "../assets/images/table-icon.svg";
import stopIcon from "../assets/images/stop-icon.svg";
import boxIcon from "../assets/images/box.svg";
import tableEdit from "../assets/images/table-edit.svg";
import Form from "react-bootstrap/Form";
import trash from "../assets/images/forms-images/trash-03.svg";

const TailorExpenses = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="chart-section">
              <div className="summary-heading">
                <p>Expense Summary</p>
              </div>
              <div className="row">
                <div className="col-12 col-md-5"></div>

                <div className="col-12 col-md-7">
                  <div className="main-summary">
                    <div className="fule-summary">
                      <div className="dot-1"></div>
                      <div className="text">
                        <p>Fuel</p>
                      </div>
                    </div>
                    <div className="total-ammount">
                      <p>$3,256,23</p>
                    </div>
                  </div>
                  <div className="main-summary">
                    <div className="fule-summary">
                      <div className="dot-2"></div>
                      <div className="text">
                        <p>Insurance</p>
                      </div>
                    </div>
                    <div className="total-ammount">
                      <p>$1,256,23</p>
                    </div>
                  </div>
                  <div className="main-summary">
                    <div className="fule-summary">
                      <div className="dot-3"></div>
                      <div className="text">
                        <p>ELD</p>
                      </div>
                    </div>
                    <div className="total-ammount">
                      <p>$35,00</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="total-section">
                <p>$5,136,34</p>
                <span>Total</span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className="filter-result">
              <div className="row">
                <div className="col-12">
                  <div className="main-filter">
                    <div className="filter-fields">
                      <label>Appointment Window Start</label>
                      <br></br>
                      <input type="month" placeholder="mm/dd/yyyy" />
                    </div>

                    <div className="filter-fields">
                      <label>Appointment Window Start</label>
                      <br></br>
                      <input type="month" placeholder="mm/dd/yyyy" />
                    </div>
                    <div className="filter-btn">
                      <button className="btn-style">Filter Results</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="history-heading">
          <h2>Tailor Expense History</h2>
        </div>

        {/* table */}

        <div className="table-wrapper overflow-x-auto">
          <div className="container-fluid" style={{ padding: 0 }}>
            <table>
              <thead>
                <tr>
                  <th style={{width:"30px"}}>
                    <Form.Check aria-label="option 1" />
                  </th>
                  <th style={{ width: "212px" }}>
                    <div className="stop-item">
                      <strong>DATE</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>CATEGORY</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>DESCRIPTION/NOTES</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <strong>TOTAL </strong>
                    <img src={tableIcon} alt="icon" />
                  </th>
                  <th className="">
                    <div>
                      <strong>ACTION</strong>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>
                    <div className="stop-item">
                      <span>1/7/2024</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>Lorem Ipsum</span>
                    </div>
                  </td>
                  <td>
                    <div className="address-col">
                      <span>Lorem Ipsum</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>10</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className="trash-icon">
                      <button style={{boder:"none",background:"transparent"}}>
                        <img src={trash} alt="Trash" />

                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Check aria-label="option 1" />
                  </td>
                  <td>
                    <div className="stop-item">
                      <span>1/7/2024</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>Lorem Ipsum</span>
                    </div>
                  </td>
                  <td>
                    <div className="address-col">
                      <span>Lorem Ipsum</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>10</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div className="trash-icon">
                        <button style={{boder:"none",background:"transparent"}}>
                        <img src={trash} alt="Trash" />

                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TailorExpenses;
