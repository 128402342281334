import FleetSelectionTab from "../components/FleetSelectionTab";
import FleetDetailsTopBar from "../components/FleetDetailsTopBar";
import Navbar from "../components/Navbar";
import Expenses from "./Expenses";
import FleetTabSection from "../components/FleetTabSection";
import { useParams } from "react-router-dom";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFleetAsyncThunk } from "../redux/pagesSlices/fleetSlice";

export default function FleetDetails() {
  const data = useSelector((state) => state?.fleet);
  const { fleetId } = useParams();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getFleetAsyncThunk({
          id: fleetId,
          populate: true,
        })
      );
    };

    fetchData();
  }, [dispatch, fleetId]);

  return (
    <div className="truck-details-page-wrapper">
      <FleetDetailsTopBar fleetData={data?.fleetById} />
      <FleetTabSection fleetData={data?.fleetById} />
      <div className="container-fluid"></div>
    </div>
  );
}
