import React, { useState, useEffect } from "react";
import sortIcon from "../assets/images/table-icon.svg";
import userIcon from "../assets/images/user-avatar.svg";
import Dropdown from "react-bootstrap/Dropdown";
import dropdownToggle from "../assets/images/dropdown-icon.svg";
import roleCheck from "../assets/images/role-check.svg";
import TeamPageTopbar from "../components/TeamPageTopbar";
import LoadingData from "../components/loadingdata";
import NoDataFound from "../components/nodatafound";

import DeleteUserModal from "../components/Modals/DeleteUserModal.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersAsyncThunk,
  updateUserAsyncThunk,
} from "../redux/pagesSlices/userSlice";
import { ROLES } from "../constant/roles.js";
import { toast } from "react-toastify";
import { parseSortBy } from "../helpers/parse.js";
import { useUser } from "@clerk/clerk-react";
import useUserHasRole from "../hooks/useHasRole.js";
import UsePagination from "../components/UsePagination.jsx";

function ProfileDropdown({ role, onRoleChange }) {
  const roles = Object.values(ROLES);

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" variant="light">
        <div className="role-btn">
          <span>{role}</span>
          <img src={dropdownToggle} alt="icon" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="roles-list">
          <ul>
            {roles?.map((item) => (
              <li key={item} onClick={() => onRoleChange(item)}>
                {item}
                {item === role && <img src={roleCheck} alt="tick" />}
              </li>
            ))}
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
export default function TeamsPage() {
  const { user } = useUser();
  const { activeOrg } = useSelector((state) => state.workspace);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnSortOrder, setColumnSortOrder] = useState([
    { name: "Name", isSortable: true, order: "asc", key: "displayName" },
    { name: "Email", isSortable: true, key: "email" },
    { name: "Role", isSortable: true, key: "role" },
    { name: "Joined On", isSortable: true, key: "createdAt" },
    { name: "Action", isSortable: false, key: "" },
  ]);
  const isAdminOrOwner = useUserHasRole(user, [ROLES.ADMIN, ROLES.OWNER]);

  const dispatch = useDispatch();

  // Debounce mechanism to prevent excessive API calls
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const data = {
        page: currentPage,
        username: searchTerm.trim(),
        ...parseSortBy(columnSortOrder),
      };
      const params =
        searchTerm.trim() !== ""
          ? data
          : { ...parseSortBy(columnSortOrder), page: currentPage };
      console.log("🚀 ~ delayDebounceFn ~ params:", params);
      dispatch(getUsersAsyncThunk(params));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, dispatch, activeOrg, columnSortOrder, currentPage]);

  const handleDeleteModalOpen = (id) => {
    setDeleteId(id);
    setDeleteModalShow(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalShow(false);
    setDeleteId("");
    // Refetch users after deletion to refresh the list
    dispatch(getUsersAsyncThunk(searchTerm));
  };

  const {
    users: getAllUsers,
    loadings,
    isError,
    error,
  } = useSelector((state) => state.user);

  console.log("🚀 ~ TeamsPage ~ getAllUsers:", getAllUsers);

  const handleInviteMember = () => {
    setInviteModalShow(true);
  };

  const handleInviteModalClose = () => {
    setInviteModalShow(false);
  };
  const handleRoleChange = (userId, newRole) => {
    console.log("🚀 ~ handleRoleChange ~ userId:", userId);
    dispatch(updateUserAsyncThunk({ id: userId, data: { role: newRole } }));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="teams-page-wrapper">
      <TeamPageTopbar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onInviteMember={handleInviteMember}
      />

      <div className="table-wrapper">
        {loadings?.getUsersAsyncThunk && <LoadingData />}
        {isError && <div className="error-message">Error: {error}</div>}
        {!loadings?.getUsersAsyncThunk && !isError && (
          <>
            {getAllUsers &&
            getAllUsers.results &&
            getAllUsers.results.length > 0 ? (
              <>
                <table>
                  <thead>
                    <tr>
                      {columnSortOrder.map((item, index) => (
                        <th
                          onClick={() => {
                            if (!item.isSortable) return;
                            setColumnSortOrder((prev) => {
                              const newColumns = prev.map((col) => {
                                if (col.key === item.key) {
                                  return {
                                    ...col,
                                    order: col.order === "asc" ? "desc" : "asc",
                                  };
                                }
                                return {
                                  ...col,
                                  order: undefined,
                                };
                              });
                              return newColumns;
                            });
                          }}
                        >
                          <div>
                            <strong>{item.name}</strong>
                            {item.isSortable && (
                              <img src={sortIcon} alt="Sort Icon" />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {getAllUsers?.results?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="stop-item">
                            <img
                              src={
                                item.picture &&
                                !item.picture.startsWith(
                                  "https://img.clerk.com/"
                                )
                                  ? process.env.REACT_APP_CLOUDFLARE_URL +
                                    item.picture
                                  : item.picture
                              }
                              alt="User Icon"
                              className="user-avatar"
                            />
                            <span>
                              {item.name ||
                                `${item.firstName || ""} ${
                                  item.lastName || ""
                                }` ||
                                "N/A"}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>{item.email || "N/A"}</span>
                          </div>
                        </td>
                        <td>
                          {isAdminOrOwner ? (
                            <ProfileDropdown
                              role={item.projects[0]?.role}
                              onRoleChange={(newRole) => {
                                if (item?.projects[0]?.role !== "owner") {
                                  handleRoleChange(item.user_id, newRole);
                                } else {
                                  toast.error("Owner role cannot be changed");
                                }
                              }}
                            />
                          ) : (
                            <div>
                              <span>{item.projects[0]?.role}</span>
                            </div>
                          )}
                        </td>
                        {/* <td>
                        <ProfileDropdown
                          role={item.projects[0]?.role}
                          {...(item?.projects[0]?.role !== "owner" && {
                            onRoleChange: (newRole) =>
                              handleRoleChange(item.user_id, newRole),
                          })}
                        />
                      </td> */}

                        <td>
                          <div>
                            <span>
                              {item.createdAt
                                ? new Date(item.createdAt).toLocaleDateString()
                                : "N/A"}
                            </span>
                          </div>
                        </td>
                        {item?.projects[0]?.role !== "owner" && (
                          <td>
                            <div>
                              {isAdminOrOwner && (
                                <span
                                  className="remove-action"
                                  onClick={() =>
                                    handleDeleteModalOpen(item.user_id)
                                  }
                                >
                                  Remove
                                </span>
                              )}
                              {/* Render the DeleteUserModal only once, outside the loop */}
                              {deleteModalShow && (
                                <DeleteUserModal
                                  deleteModalShow={deleteModalShow}
                                  handleDeleteModalClose={
                                    handleDeleteModalClose
                                  }
                                  deleteId={deleteId}
                                />
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {getAllUsers?.totalPages > 1 && (
                  <UsePagination
                    currentPage={getAllUsers?.page}
                    count={getAllUsers?.totalPages}
                    onChange={handlePageChange}
                  />
                )}
              </>
            ) : (
              <NoDataFound nodataTitle="No members found." />
            )}
          </>
        )}
      </div>
    </div>
  );
}
