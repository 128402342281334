import { useEffect, useState } from "react";
import pencilIcon from "../assets/images/pencil-edit.svg";
import EditDriverEmploymentModal from "./Modals/EditDriverEmployment";
import { getDriverEmpAsyncThunk } from "../redux/pagesSlices/driverEmpSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loadingdata from "./loadingdata";

export default function DriverEmploymentContent({ user }) {
  const [showEditEmploymentModal, setShowEmploymentModal] = useState(false);
  const handleShowEditEmploymemntModal = () => setShowEmploymentModal(true);
  const handleHideEditEmploymentModal = () => setShowEmploymentModal(false);
  const { driverEmpById, loadings } = useSelector((state) => state?.driverEmp);

  const d = useDispatch();
  useEffect(() => {
    d(getDriverEmpAsyncThunk({ id: user?.user_id }));
  }, []);
  console.log("driverEmpById", driverEmpById);

  return (
    <div className="driver-employment-content">
      <div className="container">
        {loadings?.getDriverEmpAsyncThunk ? (
          <Loadingdata />
        ) : (
          <div className="driver-employment-tab-wrapper">
            <div className="tab-content">
              <div className="tab-title">
                <strong>Employment</strong>
                <button
                  className="icon"
                  onClick={handleShowEditEmploymemntModal}
                >
                  <img src={pencilIcon} alt="icon" />
                </button>
                {showEditEmploymentModal && (
                  <EditDriverEmploymentModal
                    showEditEmploymentModal={showEditEmploymentModal}
                    handleHideEditEmploymentModal={
                      handleHideEditEmploymentModal
                    }
                    driverEmpById={driverEmpById}
                    driverId={user?.user_id}
                  />
                )}
              </div>
              <div className="detail-items-wrapper">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="single-item">
                      <span>Type</span>
                      <strong>
                        {driverEmpById?.employementType &&
                        driverEmpById?.employementType == "driver"
                          ? "Driver"
                          : "Owner/Operator" || "N/A"}
                      </strong>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-item">
                      <span>Date of hire</span>
                      <strong>
                        {driverEmpById?.hireDate
                          ? moment(driverEmpById?.hireDate).format(
                              "DD MMM, YYYY"
                            )
                          : "Missing"}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-item">
                      <span>Compensation Type</span>
                      <strong>
                        {driverEmpById?.compensationType || "N/A"}
                      </strong>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-item">
                      <span>Compensation Percentage</span>
                      <strong>{driverEmpById?.compensation || "N/A"}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
