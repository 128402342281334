import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

// Start User Slices
///////////////////////////////////////////////////

export const getUsersAsyncThunk = createAsyncThunk(
  "user/getUsersAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getUsers(params);
    return response?.data;
  })
);

export const membersCheckUsernameAsyncThunk = createAsyncThunk(
  "user/membersCheckUsernameAsyncThunk",
  catchAsync(async ({ params, callBack }, { dispatch }) => {
    const response = await ApiRequests.membersCheckUsername(params);
    if (callBack) callBack(response?.data);
    return response?.data;
  })
);

export const filterClassBaseStudentsAsyncThunk = createAsyncThunk(
  "user/filterClassBaseStudentsAsyncThunk",
  catchAsync(async ({ params, callBack }, { dispatch }) => {
    const response = await ApiRequests.filterClassBaseStudents(params);
    if (callBack) callBack(response?.data);
    return response?.data;
  })
);

export const getUsersByIdsAsyncThunk = createAsyncThunk(
  "user/getUsersByIdsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getOwner(params);
    return response?.data;
  })
);

export const getUserAsyncThunk = createAsyncThunk(
  "user/getUserAsyncThunk",
  catchAsync(async ({ driverId, params }) => {
    const response = await ApiRequests.getUser(driverId, params);
    return response?.data;
  })
);

export const createUserAsyncThunk = createAsyncThunk(
  "user/createUserAsyncThunk",
  catchAsync(async ({ data, signUp, navigate, setActive, callBack }) => {
    try {
      // const signUpAttempt = await signUp.create({
      //   emailAddress: data.email,
      //   password: data.password,
      //   firstName: data.firstName,
      //   lastName: data.lastName,
      //   phoneNumber: data.phone,
      //   role: data?.role || "user",
      // });

      const token = JSON.parse(sessionStorage.getItem("__clerk_ticket"));
      const workspaceName = JSON.parse(sessionStorage.getItem("workspaceName"));
      const invitedBy = JSON.parse(sessionStorage.getItem("invitedBy"));

      const signupdata = {
        emailAddress: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phone,
        role: data?.role || "user",
      };
      const signUpAttempt = await signUp.create(signupdata);

      if (signUpAttempt.status === "complete") {
        toast.success("Sign-up successful!", { autoClose: true });
        localStorage.setItem("user-id", signUpAttempt.id);
        // setLocalValue("app-access-token", token);
        await setActive({ session: signUpAttempt.createdSessionId });

        navigate(`/welcome/${workspaceName}`);
        // Check if user is signed in before navigation
      } else if (signUpAttempt.status === "missing_requirements") {
        if (signUpAttempt.unverifiedFields.includes("email_address")) {
          let verifiedRedirectUrl = `${process.env.REACT_APP_FRONTEND_URL}/verified`;
          if (token) {
            verifiedRedirectUrl = `${process.env.REACT_APP_FRONTEND_URL}/workspace-invite/${workspaceName}/${invitedBy}?email=${data.email}&__clerk_status=sign_in&__clerk_ticket=${token}`;
          }

          await signUpAttempt.prepareEmailAddressVerification({
            strategy: "email_link",
            redirectUrl: verifiedRedirectUrl,
          });
          navigate(`/new-verify/${data.email}`);
        }
      } else {
        toast.error("An unknown error occurred. Please try again.");
      }
      if (callBack) callBack();
    } catch (error) {
      console.error(error.errors);

      if (error.message) {
        toast.error(error.message, {
          autoClose: true,
        });
        throw error.message;
      } else if (error.errors) {
        const messages = error.errors
          .map((error) => error.longMessage)
          .join(", ");
        toast.error(messages, {
          autoClose: true,
        });

        throw messages;
      } else {
        toast.error("An unexpected error occurred. Please try again.", {
          autoClose: true,
        });
      }
    }
  })
);

export const signInUserAsyncThunk = createAsyncThunk(
  "user/signInUserAsyncThunk",
  catchAsync(async ({ data, signIn, setActive, callBack }) => {
    try {
      const signInAttempt = await signIn.create({
        identifier: data.email,
        password: data.password,
      });
      localStorage.removeItem("loginEmail");

      console.log("🚀 ~ catchAsync ~ signInAttempt:", signInAttempt);
      if (signInAttempt.status === "complete") {
        toast.success("Sign-in successful!", { autoClose: true });
        localStorage.setItem("user-id", signInAttempt.id);
        localStorage.removeItem("loginEmail");
        await setActive({ session: signInAttempt.createdSessionId });
      }

      if (callBack) callBack();

      // return response?.data;
    } catch (error) {
      if (error.message) {
        toast.error(error.message, {
          autoClose: true,
        });
        throw error.message;
      } else if (error.errors) {
        const messages = error.errors.map((error) => error.message).join(", ");
        toast.error(messages, {
          autoClose: true,
        });

        throw messages;
      } else {
        toast.error("An unexpected error occurred. Please try again.", {
          autoClose: true,
        });
      }
    }
  })
);

export const signInWithGoogleAsyncThunk = createAsyncThunk(
  "user/signInWithGoogleAsyncThunk",
  catchAsync(async ({ signUp, signIn, userId, callBack }) => {
    try {
      // Attempt to sign in the user
      await signIn.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/home`,
      });
      if (userId) {
        console.log("User signed in:", userId);
        // Handle signed in user
        if (callBack) callBack();
      } else {
        await signUp.authenticateWithRedirect({
          strategy: "oauth_google",
          redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/home`,
        });

        if (callBack) callBack();
      }
    } catch (err) {
      console.error("Error during Google sign-in:", err);
      if (err.message) {
        toast.error(err.message);
      } else if (err.errors) {
        console.log(err.errors);
        const messages = err.errors.map((error) => error.message).join(", ");
        toast.error(messages);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  })
);

export const signInWithFacebookAsyncThunk = createAsyncThunk(
  "user/signInWithFacebookAsyncThunk",
  catchAsync(async ({ signUp, signIn, userId, callBack }) => {
    try {
      // Attempt to sign in the user
      await signIn.authenticateWithRedirect({
        strategy: "oauth_facebook",
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/load`,
      });

      if (userId) {
        console.log("User signed in:", userId);
        // Handle signed in user
        if (callBack) callBack();
      } else {
        await signUp.authenticateWithRedirect({
          strategy: "oauth_facebook",
          redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/load`,
        });

        console.log("New user created:");
        if (callBack) callBack();
      }
    } catch (err) {
      console.error("Error during Google sign-in:", err);
      if (err.message) {
        toast.error(err.message);
      } else if (err.errors) {
        console.log(err.errors);
        const messages = err.errors.map((error) => error.message).join(", ");
        toast.error(messages);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  })
);

export const updateUserAsyncThunk = createAsyncThunk(
  "user/updateUserAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    // console.log(state.users?.paramsForThunk)
    const response = await ApiRequests.updateUser({ id, data });
    if (response.status == 200) {
      toast.success("User Updated Successfully!", { autoClose: true });
      dispatch(getUsersAsyncThunk());
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteUserAsyncThunk = createAsyncThunk(
  "user/deleteUserAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    // const response = await ApiRequests.getAssets(filterparams);
    const response = await ApiRequests.deleteUser(id);
    toast.success("User Deleted Successfully.", { autoClose: true });
    if (response.status == 204) {
      // toast.success("User Deleted Successfully!");
      // let params = {};
      let state = getState().listings;
      // if (state.search) params.name = state.search;
      // if (state.order) params.sortBy = `name:${state.order}`;
      // dispatch(
      //   getUsersAsyncThunk({ ...params, populate: "user_id", role: "User" })
      // );

      let params = { role: "User", limit: 12, page: 0 };
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(getUsersAsyncThunk({ ...params }));
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

export const deleteMultipleUsersAsyncThunk = createAsyncThunk(
  "user/deleteMultipleUsersAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    // console.log("🚀 ~ file: userSlice.js:167 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.deleteMultipleUsers(data);
    if (response.status == 204) {
      toast.success("Multiple Students Deleted Successfully!", {
        autoClose: true,
      });
      let state = getState().listings;
      let params = { role: "User", limit: 12, page: 0 };
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(getUsersAsyncThunk({ ...params }));
    } else {
      toast.error(response.error);
    }
    return response?.data;
  })
);


///////////////////////////////////////////////////

const initialState = {
  //news states
  users: {
    page: 0,
    users: [],
    totalPages: 1,
  },
  usersCount: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  inviteUser: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  userExport: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  userRole: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  usersList: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  user: {},
  assets: null,
  asset: null,
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      .addCase(getUsersAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.users = {
            page: 0,
            results: [],
            totalPages: 1,
          };
        }
      })

      .addCase(getUsersAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.users = {
            ...action.payload,
            users: state?.users?.results.concat(action?.payload?.results),
          };
        } else {
          state.users = action.payload;
        }
      })

      .addCase(membersCheckUsernameAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.usersCount = {
            ...action.payload,
            results: state?.usersCount?.results.concat(
              action?.payload?.results
            ),
          };
        } else {
          state.usersCount = action.payload;
        }
      })

      .addCase(filterClassBaseStudentsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.users = {
            ...action.payload,
            results: state?.users?.results.concat(action?.payload?.results),
          };
        } else {
          state.users = action.payload;
        }
      })

      .addCase(getUserAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(deleteUserAsyncThunk.fulfilled, (state, action) => {
        state.users = {
          ...state.users,
          totalResults: state.users?.totalResults - 1,
          results: state.users?.results.filter((e) => e.id != action.payload),
        };
        state.usersCount = {
          ...state.usersCount,
          totalResults: state.usersCount?.totalResults - 1,
          results: state.usersCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })

      .addCase(deleteMultipleUsersAsyncThunk.fulfilled, (state, action) => {
        state.users = {
          ...state.users,
          totalResults: state.users?.totalResults - 1,
          results: state.users?.results.filter((e) => e.id != action.payload),
        };
        state.usersCount = {
          ...state.usersCount,
          totalResults: state.usersCount?.totalResults - 1,
          results: state.usersCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getUsersAsyncThunk,
            membersCheckUsernameAsyncThunk,
            filterClassBaseStudentsAsyncThunk,
            getUserAsyncThunk,
            getUsersByIdsAsyncThunk,
            deleteUserAsyncThunk,
            deleteMultipleUsersAsyncThunk,
            createUserAsyncThunk,
            signInUserAsyncThunk,
            signInWithGoogleAsyncThunk,
            signInWithFacebookAsyncThunk,
            updateUserAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
} = userSlice.actions;
