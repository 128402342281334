import { useState } from "react";
import DriversDetailPageTopBar from "../components/DriversDetailTopbar";
import AddDriverProfile from "../components/AddDriverProfile";
import AddDriverDocument from "../components/AddDriverDocument";
import AddDriverEmploy from "../components/AddDriverEmploy";
import activeStepIcon from "../assets/images/active-step.svg";
import completedStep from "../assets/images/completed-step.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  addDriverDocumentAsyncThunk,
  createDriverAsyncThunk,
} from "../redux/pagesSlices/driverSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addDriverEmpAsyncThunk } from "../redux/pagesSlices/driverEmpSlice";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const validatePhoneNumber = (phoneNumber) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  return parsedNumber ? parsedNumber.isValid() : false;
};

const stepOneValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required").max(32),
  lastName: Yup.string().required("Last Name is required").max(32),
  city: Yup.string().max(32),
  state: Yup.string().max(32),
  phone: Yup.string().matches(
    /^(\d{10})$/,
    "Phone number must be exactly 10 digits"
  ),

  zipCode: Yup.string().max(32),
  email: Yup.string().required("Email is required").max(32),
  fleetId: Yup.string(),
  dateOfBirth: Yup.date(),
  hireDate: Yup.date(),
  licenseNumber: Yup.string()
    .matches(/^[0-9]+$/, "License Number must be numeric")
    .max(32),
  issuingState: Yup.string().max(32),
});

const stepTwoValidationSchema = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      expirationDate: Yup.date()
        .nullable()
        .test(
          "expirationDate-required-if-file",
          "Expiration date is required if file is provided",
          function (value) {
            // Access `file` from the sibling fields using `this.parent`
            const { file } = this.parent;
            return file ? !!value : true; // If file exists, expirationDate must be filled
          }
        ),
      file: Yup.mixed()
        .nullable()
        .test(
          "file-required-if-expirationDate",
          "File is required if expiration date is provided",
          function (value) {
            const { expirationDate } = this.parent;
            return expirationDate ? !!value : true; // If expirationDate exists, file must be filled
          }
        ),
    })
  ),
});

const stepThreeValidationSchema = Yup.object().shape({
  employement: Yup.object().shape({
    employementType: Yup.string(),
    hireDate: Yup.date().test(
      "required-if-filled",
      "Hire Date is required",
      function (value) {
        const { employementType } = this.parent;
        return !value && employementType ? Boolean(value) : true;
      }
    ),
    compensationType: Yup.string(),
    compensation: Yup.string()
      .matches(/^[0-9]+$/, "Compensation must be numeric")
      .max(32)
      .test("required-if-filled", "Compensation is required", function (value) {
        const { compensationType } = this.parent;
        return !value && compensationType ? Boolean(value) : true;
      }),
  }),
});

export default function AddDriverPageStepper() {
  const [activeStep, setActiveStep] = useState(1); // Track the current step
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const steps = ["Profile", "Documents", "Employement"]; // Steps for the stepper

  // Function to handle moving to the next step
  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  // Function to handle moving to the previous step
  const handlePrevious = () => {
    if (activeStep > 1) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("values", values);
    const { documents, employement, ...otherValues } = values;

    const data = {
      email: otherValues.email,
      firstName: otherValues.firstName,
      lastName: otherValues.lastName,
      city: otherValues.city,
      state: otherValues.state,
      phone: otherValues.phone,
      zipCode: otherValues.zipCode,
      fleetId: otherValues.fleetId,
      dateOfBirth: otherValues.dateOfBirth,
      hireDate: otherValues.hireDate,
      licenseNumber: otherValues.licenseNumber,
      issuingState: otherValues.issuingState,
    };
    try {
      const { driver } = await dispatch(
        createDriverAsyncThunk({
          data,
        })
      ).unwrap();
      if (driver?.user_id) {
        const validDocuments = documents.filter(
          (doc) => doc && doc.expirationDate && doc.file
        );

        const promises = validDocuments.map(async (doc) => {
          const formData = new FormData();

          formData.append("documentName", doc.name);
          formData.append("documentType", "Driver Document");
          formData.append("expiryDate", doc.expirationDate);
          formData.append("referenceId", driver.user_id);
          formData.append("referenceType", "DRIVER");
          formData.append("file", doc.file);

          return dispatch(addDriverDocumentAsyncThunk({ data: formData }));
        });

        try {
          await Promise.all(promises);

          const validateEmployements = (employ) => {
            const emptyValues = Object.values(employ).every(
              (value) => value === "" || value === null
            );
            return emptyValues ? false : employ;
          };

          const validatedEmployements = validateEmployements(employement);
          if (validatedEmployements) {
            dispatch(
              addDriverEmpAsyncThunk({
                data: {
                  ...validatedEmployements,
                  userId: driver.user_id,
                },
              })
            );
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }

      toast.success("Driver added successfully");
      resetForm();
      setSubmitting(false);
      navigate("/drivers");
    } catch (error) {
      // Display error message to the user
      toast.error(error || "Failed to create driver");
    }
  };

  const handleSubmitNext = (values, { setSubmitting }) => {
    handleNext();
    setSubmitting(false);
  };

  return (
    <div className="add-driver-page-stepper">
      <DriversDetailPageTopBar />
      <div className="add-driver-stepper-content-wrapper">
        <div className="container-fluid p-0">
          <div className="add-driver-stepper-wrapper add-truck-stepper">
            <div className="stepper-header">
              <h2>Add Driver - {steps[activeStep - 1]}</h2>
              <div className="stepper">
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`step ${
                      activeStep > index + 1
                        ? "completed"
                        : activeStep === index
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="circle">
                      <img
                        src={
                          activeStep > index + 1
                            ? completedStep
                            : activeStep === index
                            ? activeStepIcon
                            : activeStepIcon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                city: "",
                state: "",
                phone: "",
                zipCode: "",
                email: "",
                fleetId: "",
                dateOfBirth: "",
                hireDate: "",
                licenseNumber: "",
                issuingState: "",
                documents: [],
                employement: {
                  employementType: "driver",
                  compensationType: "mile",
                  hireDate: "",
                  compensation: "",
                },
              }}
              validationSchema={
                activeStep === 1
                  ? stepOneValidationSchema
                  : activeStep === 2
                  ? stepTwoValidationSchema
                  : stepThreeValidationSchema
              }
              onSubmit={
                activeStep === steps.length ? handleSubmit : handleSubmitNext
              }
            >
              {({
                isSubmitting,
                setFieldValue,
                values,
                errors,
                validateForm,
                handleSubmit,
                submitForm,
              }) => {
                console.log("isSubmitting", isSubmitting);
                return (
                  <Form
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  >
                    {/* Render different forms based on the active step */}
                    {activeStep === 1 && (
                      <>
                        <AddDriverProfile
                          handleNext={handleNext}
                          setFieldValue={setFieldValue}
                          validateForm={validateForm}
                          values={values}
                          handleSubmit={handleSubmit}
                        />
                      </>
                    )}
                    {activeStep === 2 && (
                      <>
                        <AddDriverDocument
                          errors={errors}
                          handlePrevious={handlePrevious}
                          handleNext={handleNext}
                          setFieldValue={setFieldValue}
                          values={values}
                          handleSubmit={handleSubmit}
                        />
                      </>
                    )}
                    {activeStep === 3 && (
                      <AddDriverEmploy
                        handlePrevious={handlePrevious}
                        handleNext={handleNext}
                        isSubmitting={isSubmitting}
                        setFieldValue={setFieldValue}
                        values={values}
                        validateForm={validateForm}
                        submitForm={submitForm}
                        handleSubmit={handleSubmit}
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>

            {/* <div className="button-group">
          {activeStep > 1 && (
            <button
            type="button"
            className="cancel-button"
            onClick={handlePrevious}
            >
            Back
            </button>
            )}
            {activeStep < steps.length ? (
              <button
              type="button"
              className="next-button"
              onClick={handleNext}
              >
              Next
              </button>
              ) : (
                <button type="submit" className="next-button">
              Submit
            </button>
          )}
        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
