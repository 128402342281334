import LoadPageTopBar from "../components/FleetPageTopBar";
import Navbar from "../components/Navbar";
import TailorTabsSection from "../components/TailorTabs";

export default function TailorDetailsPage() {
  return (
    <div className="tailor-details-page-wrapper mb-5">
      <LoadPageTopBar />
      <TailorTabsSection />
    </div>
  );
}
