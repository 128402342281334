import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import fileImg from "../../assets/images/forms-images/material-symbols_upload.svg";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  getFleetDocsAsyncThunk,
  updateFleetDocAsyncThunk,
} from "../../redux/pagesSlices/fleetDocSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import moment from "moment";

export default function EditFleetDocumentModal({
  selectedDoc,
  fleetData,
  editTruckModalShow,
  handleTruckModalClose,
}) {
  const dispatch = useDispatch();
  const { activeOrg } = useSelector((state) => state.workspace);
  const { loadings } = useSelector((state) => state.fleetDoc);
  const fileInputRef = useRef(null); // Reference for the file input

  const [expiryDate, setExpiryDate] = useState("");

  useEffect(() => {
    if (selectedDoc) {
      let expiryDate = moment(selectedDoc?.expiryDate).format("YYYY-MM-DD");
      setExpiryDate(expiryDate);
    }
  }, [selectedDoc]);

  const validationSchema = Yup.object({
    expiryDate: Yup.string()
      .test(
        "is-valid-date",
        "Expiration date is invalid or cannot be in the past",
        (value) =>
          moment(value, "YYYY-MM-DD", true).isValid() &&
          moment(value).isSameOrAfter(moment())
      )
      .required("Expiration date is required"),
    file: Yup.mixed()
      .nullable()
      .test(
        "fileFormat",
        "Unsupported file format",
        (file) =>
          !file ||
          ["image/jpeg", "image/png", "application/pdf"].includes(file.type)
      ),
  });

  const formik = useFormik({
    initialValues: {
      documentName: selectedDoc?.documentName || "",
      documentType: selectedDoc?.documentType || "",
      expiryDate: expiryDate || "",
      file: null,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("documentName", values.documentName);
      formData.append("documentType", values.documentType);
      formData.append("expiryDate", values.expiryDate);
      formData.append("referenceId", fleetData?.id);
      formData.append("referenceType", "FLEET");

      if (values.file) {
        formData.append("file", values.file);
      }
      dispatch(
        updateFleetDocAsyncThunk({
          data: formData,
          callBack: () => {
            formik.resetForm();
            handleTruckModalClose();
            handleRemoveFile();
            const params = {
              referenceType: "FLEET",
              referenceId: fleetData?.id,
            };
            dispatch(getFleetDocsAsyncThunk({ params }));
          },
        })
      );
    },
  });

  console.log("formik.values", formik.values);
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("file", file);
  };

  const handleRemoveFile = () => {
    formik.setFieldValue("file", null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Modal
      show={editTruckModalShow}
      onHide={handleTruckModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-truck-btn"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-field">
            <label>Expiration Date</label>
            <input
              type="date"
              name="expiryDate"
              value={formik.values.expiryDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.expiryDate && formik.errors.expiryDate ? (
              <div className="error">{formik.errors.expiryDate}</div>
            ) : null}
          </div>

          <div className="attach-docs">
            <label>Attach Document</label>
            <div className="select-file">
              <div
                className="selectfile-border"
                onClick={() => fileInputRef.current.click()}
              >
                <img src={fileImg} alt="Upload Icon" />
                <p>Select new file</p>
              </div>
              <input
                type="file"
                id="attach-document"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                style={{ position: "absolute", left: "-9999px" }}
              />
              {formik.values.file && (
                <div className="remove-text" onClick={handleRemoveFile}>
                  <p>Remove</p>
                </div>
              )}
              {formik.touched.file && formik.errors.file ? (
                <div className="error">{formik.errors.file}</div>
              ) : null}
            </div>
          </div>

          <Button
            type="submit"
            disabled={
              loadings?.updateFleetDocAsyncThunk ||
              !formik.isValid ||
              !formik.dirty
            }
            className="btn-style"
          >
            {loadings?.updateFleetDocAsyncThunk ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              "Update"
            )}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
