import DriversPageTabs from "../components/DriversPageTabs";
import DriversPageTopBar from "../components/DriversPageTopbar";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDriversAsyncThunk } from "../redux/pagesSlices/driverSlice";
import DriverSelectionTab from "../components/DriverSelectionTab";

export default function DriversPage() {
  const { activeOrg } = useSelector((state) => state.workspace);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("Active Drivers");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // Fetching loading state and driver data
  const loading = useSelector(
    (state) => state.drivers?.loadings?.getDriversAsyncThunk
  );
  const getAllUsers = useSelector((state) => state.drivers?.drivers);

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm); 
    }, 500); 

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: 10,
      status: activeTab === "Active Drivers", 
      displayName: debouncedSearchTerm || null,
    };

    if (debouncedSearchTerm.trim() !== "") {
      params.displayName = debouncedSearchTerm.trim(); 
    }

    // console.log("🚀 ~ Sending API request with params:", params);

    dispatch(getDriversAsyncThunk(params)); 
  }, [debouncedSearchTerm, dispatch, activeOrg, currentPage, activeTab]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDelete = () => {
    console.log("Delete Rows: ", selectedRows);
    // Add your delete functionality here
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update current page when pagination is used
  };

  return (
    <>
      <DriversPageTopBar />
      <DriverSelectionTab
        selectedCount={selectedRows.length}
        onDelete={handleDelete}
        setSelectedRows={setSelectedRows}
      />
      <DriversPageTabs
        data={getAllUsers?.results}
        loading={loading}
        totalPages={getAllUsers?.totalPages}
        currentPage={getAllUsers?.page}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handlePageChange={handlePageChange}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
      />
    </>
  );
}
