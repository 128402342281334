import React, { useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";

import "./App.scss";
import "./assets/scss/Bootstrap-Customization.scss";

// Pages and Components
import RegistrationForm from "./Pages/RegistrationForm";
import LoadPage from "./Pages/LoadPage";
import LoadDetails from "./Pages/LoadDetails";
import HomeWrapper from "./Pages/HomeWrapper";
import LoginPage from "./components/LoginPage";
import ForgetPage from "./components/ForgetPage";
import VerifyEmailPage from "./components/VerifyEmailPage";
import AllDone from "./components/AllDone";
import NewPassword from "./components/NewPassword";
import CreateAccount from "./components/CreateAccount";
import NewVerifyEmail from "./components/NewVerifyEmail";
import EmailVerified from "./components/EmailVerified";
import WorkSpace from "./components/WorkSpace";
import InviteTeam from "./components/InviteTeam";
import ReEnovy from "./components/ReEnovy";
import WorkSpaceInvite from "./components/WorkSpaceInvite";
import Welcome from "./components/Welcome";
import CreateLoad from "./Pages/CreateLoad";
import FleetPage from "./Pages/FleetPage";
import Expenses from "./Pages/Expenses";
import FleetDetails from "./Pages/FleetDetails";
import TailorDetailsPage from "./Pages/TailorDetails";
import TailorPage from "./Pages/TailorPage";
import AdminPanel from "./Pages/AdminPanel";
import Navbar from "./components/Navbar";
import CompanyProfile from "./Pages/CompanyProfile";
import Integrations from "./Pages/Integrations";
import ConnectedTrucks from "./Pages/ConnectedTrucks";
import AdminHome from "./Pages/admin/AdminHome";
import Compliance from "./Pages/Compliance";
import ComplianceDashboard from "./Pages/ComplianceDashboard";
import CompanyDocuments from "./components/CompanyDocuments";
import Pricing from "./components/Pricing";
import CompanyExpiredSoon from "./Pages/CompanyExpiredSoon";
import CompanyMissingPage from "./Pages/CompanyMissingPage";
import ComplianceDriver from "./Pages/ComplianceDriver";
import BillingPage from "./Pages/Billing";
import HomeWelcome from "./components/HomeWelcome";
import ProfileSetting from "./Pages/ProfileSetting";
import TeamsPage from "./Pages/TeamsPage";
import Invitations from "./Pages/Invitations";
import EnviteTeamModal from "./components/Modals/InviteTeamModal";
import DriversPage from "./Pages/DriversPage";
import OAuthCallback from "./components/OAuthCallback";
import ActivityPage from "./Pages/ActivityPage";
import FinancePage from "./Pages/FinancePage";
import DriverLicenseform from "./Pages/DriverLicenseform";
import Driversdetail from "./Pages/Driversdetail";
import FleetStepper from "./Pages/FleetStepper";
import NotFound from "./Pages/NotFound";

// Clerk Authentication Hooks
import { useAuth, useUser } from "@clerk/clerk-react";

// Custom Route Components
import PublicRoute from "./components/PublicRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthRoute from "./components/AuthRoute";
import DriversDetailPage from "./Pages/DriversDetailPage";
import AddDriverPageStepper from "./Pages/AddDriverStepperPage";
import ClickHouse, { clickhouseHelper } from "./helpers/clickhouse";
import { useDispatch } from "react-redux";
import { switchWorkspaceAsyncThunk } from "./redux/pagesSlices/workspaceSlice";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import DeactivatedFleet from "./components/DeActivatedFleet";
import Unauthorized from "./components/Unauthorized";
import RestrictedRoute from "./components/RestrictedRoute";
import { ROLES } from "./constant/roles";

ChartJS.register(ArcElement, Tooltip, Legend);

function App() {
  const { getToken, isSignedIn, userId } = useAuth();
  const { user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const activeOrg = localStorage.getItem("active_org");

    if (!activeOrg && user && user.organizationMemberships?.length > 0) {
      const firstOrg = user.organizationMemberships[0].organization;
      dispatch(
        switchWorkspaceAsyncThunk({
          org: firstOrg.id,
        })
      );
    }
    dispatch(
      switchWorkspaceAsyncThunk({
        org: activeOrg,
      })
    );
  }, [user]);

  useLayoutEffect(() => {
    const refreshToken = async () => {
      if (isSignedIn && userId) {
        try {
          const token = await getToken({ template: "JWT" });
          localStorage.setItem("app-access-token", token);
          localStorage.setItem("user-id", userId);
          if (user) {
            localStorage.setItem("user", JSON.stringify(user));
          }
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      }
    };

    refreshToken();
    const tokenRefreshInterval = setInterval(refreshToken, 60 * 1000);

    return () => clearInterval(tokenRefreshInterval);
  }, [isSignedIn, userId, getToken]);

  // clickhouse call
  useEffect(() => {
    const token = localStorage.getItem("app-access-token");
    if (token) {
      clickhouseHelper({ type: "token", data: token });
    }
  }, []);
  return (
    <div className="App">
      <Navbar />
      <Routes>
        {/* Public routes (accessible without being logged in) */}
        <Route
          path="/login"
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />

        <Route
          path="/forget"
          element={
            <AuthRoute>
              <ForgetPage />
            </AuthRoute>
          }
        />
        <Route
          path="/reset-password"
          element={
            // <AuthRoute>
            <NewPassword />
            // </AuthRoute>
          }
        />
        <Route path="/oauth-callback" element={<OAuthCallback />} />
        <Route
          path="/sign-up"
          element={
            <AuthRoute>
              <CreateAccount />
            </AuthRoute>
          }
        />
        <Route
          path="/new-verify/:email"
          element={
            // <AuthRoute>
            <NewVerifyEmail />
            // </AuthRoute>
          }
        />
        <Route path="/verified" element={<EmailVerified />} />
        <Route path="/verify" element={<VerifyEmailPage />} />
        <Route
          path="/register"
          element={
            // <AuthRoute>
            <RegistrationForm />
            // </AuthRoute>
          }
        />

        {/* Driver-specific routes (only drivers can access) */}
        {/* <Route element={<DriverRoute />}>
          <Route path="/drivers2" element={<DriversPage />} />
        </Route> */}

        {/* Protected Routes (accessible only after login) */}
        <Route element={<ProtectedRoute />}>
          <Route path="/home" element={<HomeWelcome />} />
          <Route path="/alldone" element={<AllDone />} />
          <Route path="/load" element={<LoadPage />} />
          <Route path="/load-details/:id" element={<LoadDetails />} />
          <Route path="/create-load" element={<CreateLoad />} />
          <Route path="/fleet" element={<FleetPage />} />
          <Route path="/fleet-details/:fleetId" element={<FleetDetails />} />
          <Route path="/drivers" element={<DriversPage />} />
          <Route path="/inviteTeam" element={<InviteTeam />} />
          <Route path="/reenovy" element={<ReEnovy />} />
          <Route path="/welcome/:workspaceName" element={<Welcome />} />
          <Route path="/profile-setting" element={<ProfileSetting />} />
          <Route
            path="/admin"
            element={
              <RestrictedRoute allowedRoles={[ROLES.ADMIN, ROLES.OWNER]}>
                <AdminHome />
              </RestrictedRoute>
            }
          >
            <Route path="company-profile" element={<CompanyProfile />} />
            <Route path="integration" element={<Integrations />} />
            <Route path="compliance" element={<Compliance />} />
            <Route path="billing" element={<BillingPage />} />
            <Route path="teams" element={<TeamsPage />} />
            <Route path="invitations" element={<Invitations />} />
          </Route>
          <Route path="/activity" element={<ActivityPage />} />
          <Route path="/finance" element={<FinancePage />} />
          <Route
            path="/driversdetail/:driverId"
            element={<DriversDetailPage />}
          />
          <Route path="/add-fleet" element={<FleetStepper />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Route>
        <Route path="/workSpace" element={<WorkSpace />} />

        {/* Routes that don't require authentication */}
        <Route
          // path="/workspace-invite/:workspaceName/:invitedBy/:email"
          path="/workspace-invite/:workspaceName/:invitedBy"
          element={<WorkSpaceInvite />}
        />
        <Route path="/home-wrapper" element={<HomeWrapper />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/tailor-details" element={<TailorDetailsPage />} />
        <Route path="/tailor" element={<TailorPage />} />
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/connected-trucks" element={<ConnectedTrucks />} />
        <Route path="/dashboard" element={<ComplianceDashboard />} />
        <Route path="/CD" element={<CompanyDocuments />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/expired-soon" element={<CompanyExpiredSoon />} />
        <Route path="/missing" element={<CompanyMissingPage />} />
        <Route path="/driver" element={<ComplianceDriver />} />
        <Route path="/DriverLicenseform" element={<DriverLicenseform />} />
        <Route path="/add-driver" element={<AddDriverPageStepper />} />
        <Route path="/stepper" element={<AddDriverPageStepper />} />
        <Route path="/deactive" element={<DeactivatedFleet />} />
        <Route
          path="/"
          element={
            <AuthRoute>
              <RegistrationForm />
            </AuthRoute>
          }
        />
        {/* <Route path="/clickhouse" element={<ClickHouse />} /> */}
        {/* // 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;

// const DriverRoute = ({ children }) => {
//   const { user } = useUser();
//   const userRole = user?.organizationMemberships[0]?.role; // assuming single org for simplicity
//   console.log("🚀 ~ DriverRoute ~ userRole:", userRole)

//   if (userRole == "org:driver") {
//     return <Navigate to="/drivers2" replace />;  // Redirect if not a driver
//   } else {
//     return children;  // Render driver content
//   }
// };
