import React, { useState } from 'react'
import Titlebar from '../components/adminPanelComponents/Titlebar'
import Sidebar from '../components/adminPanelComponents/Sidebar'
import Form from "react-bootstrap/Form";
import tableIcon from "../assets/images/table-icon.svg";
import stopIcon from "../assets/images/stop-icon.svg";
import boxIcon from "../assets/images/box.svg";
import refresh from '../assets/images/forms-images/arrow-refresh-05.svg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Integrations = () => {

  const [activeTab, setActiveTab] = useState('driver');
  const activeHandler = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div className='integration-page'>
      <div className='integration-topbar'>
        <div className='integration-link'>
          <Link
            to={'#'}
            className={` ${activeTab === 'driver' ? 'link-color' : ''}`}
            onClick={() => activeHandler('driver')}
          >
            {' '}
            Connected Drivers
          </Link>
          <Link
            to={'#'}
            className={` ${activeTab === 'truck' ? 'link-color' : ''}`}
            onClick={() => activeHandler('truck')}
          >
            {' '}
            Connected Trucks
          </Link>
        </div>
        <div className='topbar-butn'>
          <Button>
            <img src={refresh} alt='Refresh-icon' /> Click Here To Link Your
            Motive Account
          </Button>
        </div>
      </div>
      {activeTab === 'driver' && (
        <div className='table-wrapper'>
          <table>
            <thead>
              <tr>
                <th>
                  <div>
                    <strong>First Name</strong>
                    <img src={tableIcon} alt='icon' />
                  </div>
                </th>

                <th>
                  <div>
                    <strong>Last Name</strong>
                    <img src={tableIcon} alt='icon' />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <span>Time</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>Jack</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div>
                    <span>Time</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>Jack</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div>
                    <span>Time</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>Jack</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {activeTab === 'truck' && (
        <div className='table-wrapper'>
          <table>
            <thead>
              <tr>
                <th>
                  <div>
                    <strong>Truck Number</strong>
                    <img src={tableIcon} alt='icon' />
                  </div>
                </th>
                <th>
                  <div>
                    <strong>Model Year</strong>
                    <img src={tableIcon} alt='icon' />
                  </div>
                </th>
                <th>
                  <div>
                    <strong>Make</strong>
                    <img src={tableIcon} alt='icon' />
                  </div>
                </th>
                <th>
                  <div>
                    <strong>Model</strong>
                    <img src={tableIcon} alt='icon' />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <span>T001</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2023</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T002</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2022</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T003</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2021</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T003</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2021</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T003</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2021</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T003</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2021</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T003</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2021</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T003</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2021</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <span>T003</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2021</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>2011</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>S256565</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default Integrations
