import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: "0 auto",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  border: "1px solid var(--Global-Neutral-Grey-500, #E6E6E6)",
  background: "var(--Grey-100, #FAFBFC)",
  "& Button:first-child": {
    borderLeft: "none",
  },
  "& Button:last-child": {
    borderRight: "none",
  },
});

const PaginationItem = styled("li")({
  padding: "10px 16px",
  cursor: "pointer",
  transition: "background-color 0.3s, color 0.3s",
  borderRight: "1px solid var(--Global-Neutral-Grey-500, #E6E6E6)",
  borderLeft: "none",
  borderTop: "none",
  borderBottom: "none",
  background: "var(--Grey-100, #FAFBFC)",
  color: "var(--Global-Neutral-Grey-1300, #0A090B)",
  fontFamily: "Inter",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "1.25rem",
  letterSpacing: "-0.00313rem",
  "&:hover": {
    background: "var(--Global-Neutral-Grey-300, #E0E0E0)",
  },
  '&[aria-current="true"]': {
    fontWeight: "bold",
    background: "var(--Global-Neutral-Grey-300, #E0E0E0)",
    color: "var(--Global-Neutral-Grey-900, #333333)",
  },
  "&[disabled]": {
    opacity: 0.5,
    cursor: "not-allowed",
  },
});

export default function UsePagination({
  count = 10,
  currentPage = 1,
  onChange,
}) {
  const { items } = usePagination({
    count,
    page: currentPage, // Set current page
    onChange,
  });

  return (
    <nav aria-label="Pagination">
      <List>
        {items?.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = page;
          } else {
            children = type === "previous" ? "< Prev" : "Next >";
          }

          return (
            <PaginationItem
              key={index}
              as="button"
              aria-current={selected ? "true" : undefined}
              {...item}
            >
              {children}
            </PaginationItem>
          );
        })}
      </List>
    </nav>
  );
}
