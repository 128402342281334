import LoadPageTopBar from "../components/FleetPageTopBar";
import Navbar from "../components/Navbar";
import TailorTabsSection from "../components/TailorTabsSection";

export default function TailorPage() {
  return (
    <div>
      <LoadPageTopBar />
      <TailorTabsSection />
    </div>
  );
}
