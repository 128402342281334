import React, { useState } from "react";
import refresh from "../assets/images/forms-images/arrow-refresh-05.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Titlebar from "../components/adminPanelComponents/Titlebar";
import Sidebar from "../components/adminPanelComponents/Sidebar";
import viewIcon from "../assets/images/view.svg";
import download from "../assets/images/download.svg";
import deleteIcon from "../assets/images/delete.svg";
import { IoEyeOutline } from "react-icons/io5";
import edit from "../assets/images/forms-images/Edit_Pencil_02.svg";
import tableIcon from "../assets/images/table-icon.svg";
import CompanyDocuments from "../components/CompanyDocuments";
import ComplianceDocuments from "../components/complianceDocuments.js";
import Modal from "react-bootstrap/Modal";
import EditLoadModal from "../components/Modals/EditLoadModal";
import EditDetailModal from "../components/Modals/EditDetailModal";
import DeleteLoadModal from "../components/Modals/DeleteLoadModal";
import TeamPageTopbar from "../components/TeamPageTopbar";
import CompanyDocumentsEditModel from "../components/CompanyDocumentsEditModel.js";
import DocumentPageTopbar from "../components/DocumentPageTopbar.jsx";
import NoRecord from "../components/NoRecord.jsx";
import DeleteUserModal from "../components/Modals/DeleteUserModal.js";
import ComingSoon from "../components/ComingSoon.jsx";

const Compliance = () => {
  const [activeTab, setActiveTab] = useState("company-documents");
  const [activeDashboardTab, setActiveDashboardTab] = useState("Company");
  const [subActiveab, setSubActiveab] = useState("Company");

  const [editModalShow, editSetModalShow] = useState(false);
  const [deleteModalShow, deleteSetModalShow] = useState(false);

  const handleDeleteModalOpen = () => {
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
  };

  const handleEditModalOpen = () => {
    editSetModalShow(true);
  };

  const handleEditModalClose = () => {
    editSetModalShow(false);
  };

  const activeHandler = (tab) => {
    setActiveTab(tab);
  };
  const activeDashboardHandler = (tab) => {
    setActiveDashboardTab(tab);
  };
  const subActiveabHandler = (tab) => {
    setSubActiveab(tab);
  };


  return (
    <>
      {/* <TeamPageTopbar /> */}
      <DocumentPageTopbar />
      {activeTab === "company-documents" && (
        <div className="teams-page-wrapper">
          <CompanyDocuments
            handleEditModalOpen={handleEditModalOpen}
            editModalShow={editModalShow}
            handleEditModalClose={handleEditModalClose}
            handleDeleteModalOpen={handleDeleteModalOpen}
            deleteModalShow={deleteModalShow}
            handleDeleteModalClose={handleDeleteModalClose}
          />
        </div>
      )}
      {activeTab === "compliance-documents" && (
        <ComplianceDocuments
          activeDashboardHandler={activeDashboardHandler}
          activeDashboardTab={activeDashboardTab}
          subActiveab={subActiveab}
          subActiveabHandler={subActiveabHandler}
        />
      )}
    </>
  );
};

export default Compliance;
