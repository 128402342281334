import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
import { getUserAsyncThunk } from "./userSlice";

// Start Driver Slices
///////////////////////////////////////////////////

// export const createDriverAsyncThunk = createAsyncThunk(
//   "user/createDriverAsyncThunk",
//   catchAsync(async ({ data, callBack }, { dispatch }) => {
//     const response = await ApiRequests.createDriver(data);
//     // if (response.status == 201) {
//     //   toast.success("Driver Created Successfully!");
//     // }
//     dispatch(getDriversAsyncThunk());
//     if (callBack) callBack();
//     return response?.data;
//   })
// );

export const createDriverAsyncThunk = createAsyncThunk(
  "user/createDriverAsyncThunk",
  async ({ data, callBack }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiRequests.createDriver(data);
      dispatch(getDriversAsyncThunk());
      if (callBack) callBack();
      return response?.data;
    } catch (error) {
      // Log error for debugging purposes
      console.error("Error creating driver:", error);

      // Check if the error response contains a message
      const errorMessage =
        error?.response?.data?.message || "An unexpected error occurred";

      // Use `rejectWithValue` to return the error message
      return rejectWithValue(errorMessage);
    }
  }
);

export const getDriversAsyncThunk = createAsyncThunk(
  "driver/getDriversAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getDrivers(params);
    return response?.data;
  })
);

export const updateDriverStatusAsyncThunk = createAsyncThunk(
  "driver/updateDriverStatusAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const response = await ApiRequests.updateDriverStatus({ id, data });
    if (response.status === 200) {
      toast.success("Driver Status Updated Successfully!", { autoClose: true });
      dispatch(getDriversAsyncThunk());
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const getDriverAsyncThunk = createAsyncThunk(
  "driver/getDriverAsyncThunk",
  catchAsync(async (id, _) => {
    const response = await ApiRequests.getDriver(id);
    return response?.data;
  })
);

export const updateDriverAsyncThunk = createAsyncThunk(
  "driver/updateDriverAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch, getState }) => {
    const state = getState();
    console.log("state.drivers?.paramsForThunk");
    const response = await ApiRequests.updateDriver({ id, data });
    if (response.status === 200) {
      toast.success("Driver Updated Successfully!", { autoClose: true });
      const params = { include: ["documents", "fleets"] };
      dispatch(getUserAsyncThunk({ driverId: id, params }));
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteDriverAsyncThunk = createAsyncThunk(
  "driver/deleteDriverAsyncThunk",
  catchAsync(async (id, { dispatch, getState }) => {
    const response = await ApiRequests.deleteDriver(id);
    toast.success("Driver Deleted Successfully.", { autoClose: true });
    if (response.status === 204) {
      let state = getState().listings;

      let params = { role: "Driver", limit: 12, page: 0 };
      if (state.order) params.sortBy = `name:${state.order}`;
      dispatch(getDriversAsyncThunk({ ...params }));
    } else {
      toast.error(response.error);
    }
    return id;
  })
);

export const updateDriverLinceseAsyncThunk = createAsyncThunk(
  "driver/updateDriverLinceseAsyncThunk",
  catchAsync(async ({ id, data, callBack }) => {
    const response = await ApiRequests.updateDriverLicense({ data, id });
    if (response.status == 201) {
      toast.success("Driver License Updated!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const getDriverLinceseAsyncThunk = createAsyncThunk(
  "driver/getDriverLinceseAsyncThunk",
  catchAsync(async ({ driverId, callBack }) => {
    const response = await ApiRequests.getDriverLicense(driverId);
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteDriverLinceseAsyncThunk = createAsyncThunk(
  "driver/deleteDriverLinceseAsyncThunk",
  catchAsync(async ({ driverId, callBack }) => {
    const response = await ApiRequests.deleteDriverLicense(driverId);
    if (callBack) callBack();
    return response?.data;
  })
);

///////////////////////////////////////////////////
// driver documents

export const addDriverDocumentAsyncThunk = createAsyncThunk(
  "driver/addDriverDocumentAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    const response = await ApiRequests.addDriverDocument(data);
    // if (response.status == 201) {
    //   toast.success("Document Created Successfully!");
    // }
    if (callBack) callBack();
    return response?.data;
  })
);

export const getDriverDocsAsyncThunk = createAsyncThunk(
  "driver/getDriverDocsAsyncThunk",
  catchAsync(async ({ params }) => {
    const response = await ApiRequests.getDriverDocuments(params);
    return response?.data;
  })
);

export const getDriverDocAsyncThunk = createAsyncThunk(
  "driver/getDriverDocAsyncThunk",
  catchAsync(async (id) => {
    const response = await ApiRequests.getDriverDocument(id);
    return response?.data;
  })
);

export const updateDriverDocAsyncThunk = createAsyncThunk(
  "driver/updateDriverDocAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    // Log FormData entries
    for (let [key, value] of data.entries()) {
      console.log(`${key}:`, value);
    }
    const response = await ApiRequests.updateDriverDocument(data);
    if (response.status == 200) {
      toast.success("Document Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);
export const deleteDriverDocAsyncThunk = createAsyncThunk(
  "driver/deleteDriverDocAsyncThunk",
  catchAsync(async ({ id, callBack }) => {
    const response = await ApiRequests.deleteDriverDocument(id);
    if (response.status == 200) {
      toast.success("Document Deleted Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

const initialState = {
  //news states
  drivers: {
    page: 0,
    drivers: [],
    totalPages: 1,
  },
  driversCount: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  inviteDriver: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  driverExport: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  driverRole: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  driversList: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  story: null,
  assets: null,
  asset: null,
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  driverDocuments: [],
  // manager states
  errors: {},
  driverDoc: {},
  driverLicense: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  driverIds: [],
};

const driverSlice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeDriverIds: (state, action) => {
      state.driverIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //
      // .addCase(getDriversAsyncThunk.pending, (state, action) => {
      //   if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
      //     state.drivers = {
      //       page: 0,
      //       results: [],
      //       totalPages: 1,
      //     };
      //   }
      // })

      .addCase(getDriversAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.drivers = {
            ...action.payload,
            drivers: state?.drivers?.results.concat(action?.payload?.results),
          };
        } else {
          state.drivers = action.payload;
        }
      })
      .addCase(getDriverDocsAsyncThunk.pending, (state, action) => {
        state.driverDocuments = [];
      })
      // .addCase(getDriverDocsAsyncThunk.fulfilled, (state, action) => {
      //   if (action.payload?.page > 1) {
      //     state.driverDocuments = {
      //       ...action.payload,
      //       driverDocuments: state?.driverDocuments?.results.concat(
      //         action?.payload?.results
      //       ),
      //     };
      //   } else {
      //     state.driverDocuments = action.payload;
      //   }
      // })
      .addCase(getDriverDocsAsyncThunk.fulfilled, (state, action) => {
        state.driverDocuments = action.payload;
      })
      .addCase(getDriverDocAsyncThunk.fulfilled, (state, action) => {
        state.driverDoc = action.payload;
      })
      .addCase(getDriverLinceseAsyncThunk.pending, (state, action) => {
        state.driverLicense = {};
      })
      .addCase(getDriverLinceseAsyncThunk.fulfilled, (state, action) => {
        state.driverLicense = action.payload;
      })
      .addCase(getDriverAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.story = {
            ...action.payload,
            results: state?.story?.results.concat(action?.payload?.results),
          };
        } else {
          state.story = action.payload;
        }
      })
      .addCase(deleteDriverAsyncThunk.fulfilled, (state, action) => {
        state.drivers = {
          ...state.drivers,
          totalResults: state.drivers?.totalResults - 1,
          results: state.drivers?.results.filter((e) => e.id != action.payload),
        };
        state.driversCount = {
          ...state.driversCount,
          totalResults: state.driversCount?.totalResults - 1,
          results: state.driversCount?.results.filter(
            (e) => e.id != action.payload
          ),
        };
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            getDriversAsyncThunk,
            getDriverAsyncThunk,
            deleteDriverAsyncThunk,
            updateDriverAsyncThunk,
            createDriverAsyncThunk,
            addDriverDocumentAsyncThunk,
            updateDriverStatusAsyncThunk,
            getDriverDocsAsyncThunk,
            getDriverDocAsyncThunk,
            updateDriverDocAsyncThunk,
            deleteDriverDocAsyncThunk,
            updateDriverLinceseAsyncThunk,
            deleteDriverLinceseAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default driverSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeDriverIds,
} = driverSlice.actions;
