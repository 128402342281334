import React from "react";
import tabs from "../assets/images/forms-images/tabs.png";
import DriversPageTopBar from "../components/DriversPageTopbar";
import Button from "react-bootstrap/Button";
import StepperComponents from "../components/StepperComponents";
import { Spinner } from "react-bootstrap";
import { ErrorMessage, Field } from "formik";

const AddDriverEmploy = ({
  isSubmitting,
  handlePrevious,
  setFieldValue,
  validateForm,
  values,
  submitForm,
  handleSubmit,
}) => {
  const handleSkip = () => {
    setFieldValue(
      "employement",
      {
        employementType: "",
        compensationType: "",
        hireDate: "",
        compensation: "",
      },
      false
    );

    // Call submitForm after setFieldValue completes
    setTimeout(() => {
      submitForm();
    }, 0);
  };

  const handleAdd = async () => {
    const errors = await validateForm();
    const hasOtherErrors = Object.keys(errors).some(
      (key) => key !== "employement"
    );

    if (hasOtherErrors) {
      console.error("Other validation errors:", errors);
      return;
    }
    submitForm();
  };

  return (
    <>
      {/* <DriversPageTopBar /> */}

      <div className="main-driver">
        <div className="container">
          {/* <div className="driver-title">
                        <strong>Add Driver - Documents</strong>
                    </div> */}
          {/* <div className="profile-tabs">
                        <img src={tabs} alt='Links Tabs' />
                        <StepperComponents/>
                    </div> */}

          <div className="employment-type">
            <p>Employment Type</p>
            <div className="radio-box">
              <div className="row">
                <div className="col-md-6">
                  <div className="radio-flex">
                    <label>
                      <Field
                        type="radio"
                        name="employement.employementType"
                        value="driver"
                      />{" "}
                      Driver
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="radio-flex">
                    <label>
                      <Field
                        type="radio"
                        name="employement.employementType"
                        value="owner"
                      />{" "}
                      Owner/Operator
                    </label>
                  </div>
                </div>
              </div>
              <ErrorMessage
                name="employement.employementType"
                component="div"
                className="error"
              />
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="modal-field">
                  <label>Hire date</label>
                  <Field
                    type="date"
                    name="employement.hireDate"
                    placeholder="mm/dd/yyyy"
                  />
                  <ErrorMessage
                    name="employement.hireDate"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="employment-type">
            <p>Compensation Type</p>
            <div className="radio-box">
              <div className="row">
                <div className="col-md-4">
                  <div className="radio-flex">
                    <label>
                      <Field
                        type="radio"
                        name="employement.compensationType"
                        value="mile"
                      />{" "}
                      Per mile
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="radio-flex">
                    <label>
                      <Field
                        type="radio"
                        name="employement.compensationType"
                        value="percentage"
                      />{" "}
                      Percentage
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="radio-flex">
                    <label>
                      <Field
                        type="radio"
                        name="employement.compensationType"
                        value="flatRate"
                      />{" "}
                      Flat Rate
                    </label>
                  </div>
                </div>
              </div>
              <ErrorMessage
                name="employement.compensationType"
                component="div"
                className="error"
              />
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="modal-field mb-0">
                  <label>
                    {values?.employement?.compensationType === "percentage"
                      ? "Percentage"
                      : values?.employement?.compensationType === "mile"
                      ? "Per mile"
                      : "Flat Rate"}
                  </label>
                  <Field
                    type="text"
                    name="employement.compensation"
                    placeholder={
                      values?.employement?.compensationType === "percentage"
                        ? "%  Enter percentage"
                        : values?.employement?.compensationType === "mile"
                        ? "Enter per mile"
                        : "Enter flat rate"
                    }
                  />
                  <ErrorMessage
                    name="employement.compensation"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="document-btn">
            <div className="skip-btn">
              <button
                className="btn btn-link p-0 text-decoration-none text-secondary text-xs"
                disabled={isSubmitting}
                onClick={handleSkip}
              >
                Skip for now
              </button>
            </div>
            <div className="driver-profile-btn">
              <Button
                type="button"
                disabled={isSubmitting}
                onClick={handlePrevious}
                className="link-btn light-bg"
              >
                Back
              </Button>

              <Button
                type="button"
                onClick={handleAdd}
                disabled={isSubmitting}
                className="add-btn"
              >
                {isSubmitting ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Add"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDriverEmploy;
