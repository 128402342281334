import React from "react";
import { RiFilter3Line } from "react-icons/ri";
import { FiPlusCircle } from "react-icons/fi";
import { Dropdown } from "react-bootstrap";
import plusIcon from "../assets/images/plus-gray.svg";
import { MdOutlineSplitscreen } from "react-icons/md";
import { MdOutlineWindow } from "react-icons/md";

import addDocument from "../assets/images/add-document.svg";
export default function TopTabsComponent({customername,setCustomerName,handleFilterClick,status,setStatus,location,setLocation,driver,setDriverName,activeViewHandler, activeView }) {
  return (
    <div className="custom-tabs-header container-fluid">
      <div className="custom-tabs">
        <div className={`custom-tab-item  `}>Activity</div>
        <div className={`custom-tab-item custom-active-tab`}>History</div>
      </div>
      <div className="add-filters-section">
        <button className="filter-btn">
          <RiFilter3Line />
          <span>Filter</span>
        </button>
        <div className="icons_flex">
                <button
                  onClick={() => activeViewHandler("list")}
                  className={`${activeView === "list" ? "active" : ""}`}
                >
                  <MdOutlineSplitscreen />
                </button>
                <div className="line">|</div>
                <button
                  onClick={() => activeViewHandler("grid")}
                  className={`${activeView === "grid" ? "active" : ""}`}
                >
                  <MdOutlineWindow />
                </button>
              </div>
        <div className="add-buttons">
          {/* <badge className="badge_filters">Date<FiPlusCircle/></badge> */}
          <Dropdown id="dropdown-basic">
            <Dropdown.Toggle>
              <div className="single-add-btn">
                <strong>Customer</strong>
                <div className="icon">
                  <img src={plusIcon} alt="icon" />
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form onSubmit={handleFilterClick} className="dropdown-content">
                  <div className="input-section">
                    <label htmlFor="">
                      Customer Name
                    </label>
                    <input
                      type="text"
                      name="customername"
                      placeholder="username"
                      value={customername}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </div>
                  <div className="done-button">
                    <button>Done</button>
                  </div>
                </form>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown id="dropdown-basic">
            <Dropdown.Toggle>
              <div className="single-add-btn">
                <strong>Driver</strong>
                <div className="icon">
                  <img src={plusIcon} alt="icon" />
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form onSubmit={handleFilterClick} className="dropdown-content">
                  <div className="input-section">
                    <label htmlFor="">
                      Driver Name
                    </label>
                    <input
                      type="text"
                      name="drivername"
                      placeholder="driver name"
                      value={driver}
                      onChange={(e) => setDriverName(e.target.value)}
                    />
                  </div>
                  <div className="done-button">
                    <button>Done</button>
                  </div>
                </form>
            </Dropdown.Menu>
          </Dropdown>
          {/* <badge className="badge_filters">Truck<FiPlusCircle/></badge> */}
          <Dropdown id="dropdown-basic">
            <Dropdown.Toggle>
              <div className="single-add-btn">
                <strong>Status</strong>
                <div className="icon">
                  <img src={plusIcon} alt="icon" />
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form onSubmit={handleFilterClick} className="dropdown-content">
                  <div className="input-section">
                    <label htmlFor="">
                      Status
                    </label>
                    <input
                      type="text"
                      name="status"
                      placeholder="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                  </div>
                  <div className="done-button">
                    <button>Done</button>
                  </div>
                </form>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown id="dropdown-basic">
            <Dropdown.Toggle>
              <div className="single-add-btn">
                <strong>Location</strong>
                <div className="icon">
                  <img src={plusIcon} alt="icon" />
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <form onSubmit={handleFilterClick} className="dropdown-content">
                  <div className="input-section">
                    <label htmlFor="">
                      Location
                    </label>
                    <input
                      type="text"
                      name="location"
                      placeholder="Location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <div className="done-button">
                    <button>Done</button>
                  </div>
                </form>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
