import { useState } from "react";
import { Link } from "react-router-dom";
import Pricing from "../components/Pricing";

export default function BillingPage() {
  const [activeTab, setActiveTab] = useState("plans");
  const activeHandler = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="billing-tabs">
      <div className="integration-topbar">
        <div className="integration-link">
          <Link
            to={"#"}
            className={` ${activeTab === "plans" ? "link-color" : ""}`}
            onClick={() => activeHandler("plans")}
          >
            {" "}
            Plans
          </Link>
          <Link
            to={"#"}
            className={` ${activeTab === "invoices" ? "link-color" : ""}`}
            onClick={() => activeHandler("invoices")}
          >
            {" "}
            Invoices
          </Link>
        </div>
      </div>
      {activeTab === "plans" && (
        <div className="tab-content">
          <Pricing />
        </div>
      )}
      {activeTab === "invoices" && (
        <div className="tab-content">Hello from pricing tabs</div>
      )}
    </div>
  );
}
