import { useEffect, useState } from "react";

function useUserHasRole(user, rolesToCheck = []) {
  const organizationId = localStorage.getItem("active_org");

  if (
    organizationId &&
    user?.organizationMemberships &&
    user?.organizationMemberships.length > 0 &&
    rolesToCheck.length > 0
  ) {
    const currentOrganization = user.organizationMemberships.find(
      (org) => org.organization.id === organizationId
    );

    if (
      currentOrganization &&
      rolesToCheck.includes(currentOrganization.role.replace("org:", ""))
    ) {
      return true;
    }
  }
  return false;
}

export default useUserHasRole;
