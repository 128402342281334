export default function TailorDetailsForm() {
  return (
    <div className="truck-details-form">
      <div className="container-fluid">
        <div className="truck-details-form-inner-wrapper">
          <div className="form-wrapper">
            <form>
              <div className="form-fiels-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    <div className="single-field">
                      <label htmlFor="">Model Year</label>
                      <input type="text" placeholder="Enter Model Year" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-field">
                      <label htmlFor="">Tailor Make</label>
                      <input type="text" placeholder="Enter Truck Make" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-field">
                      <label htmlFor="">Tailor Model</label>
                      <input type="text" placeholder="Enter Truck Model" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-field">
                      <label htmlFor="">Odometer</label>
                      <input type="text" placeholder="Enter Odometer" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-field">
                      <label htmlFor="">Tailor Number</label>
                      <input type="text" placeholder="Enter Truck Number" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="save-button">
                <button>Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
