import React, { useState, useEffect } from "react";
import TableComponent from "./TableComponent";
import addDocument from "../assets/images/add-document.svg";
import DocumentsContent from "./DocumentsContent";
import DetailsCard from "./DetailsCard";
import tableIcon from "../assets/images/table-icon.svg";
import tripSummaryIcon from "../assets/images/trip-summary.svg";
import { Modal, Box, TextField, Button } from "@mui/material";
import Logs from "./Logs";
import HomeWrapper from "../Pages/HomeWrapper";
import filterIcon from "../assets/images/filter-icon.svg";
import plusIcon from "../assets/images/plus-gray.svg";
import { Form } from "react-bootstrap";
import actionBtn from "../assets/images/action-btn.svg";
import UsePagination from "./UsePagination";
import UsePaginationv2 from "./UsePaginationv2";
import { Link, useNavigate } from "react-router-dom";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import sortIcon from "../assets/images/table-icon.svg";
import userIcon from "../assets/images/user-avatar.svg";
import NoDataFound from "./nodatafound";
import Loadingdata from "./loadingdata";
import UpdateStatus from "./Modals/UpdateStatus";
import { useDispatch } from "react-redux";
import { getDriversAsyncThunk } from "../redux/pagesSlices/driverSlice";

const DriversPageTabs = ({
  data,
  totalPages,
  currentPage,
  handlePageChange,
  selectedRows,
  setSelectedRows,
  activeTab,
  setActiveTab,
  loading,
  searchTerm,
  onSearchChange, // Pass this prop from parent component
}) => {
  const navigate = useNavigate();
  console.log("🚀 ~ FleetTabs ~ data:", data);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handle checkbox selection
  const handleSelect = (itemId) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId));
    } else {
      setSelectedRows([...selectedRows, itemId]);
    }
  };

  const isSelected = (itemId) => selectedRows.includes(itemId);

  return (
    <div className="custom-tabs-container">
      {/* Tabs Header */}
      <div className="custom-tabs-header">
        <div className="custom-tabs">
          <div
            className={`custom-tab-item ${
              activeTab === "Active Drivers" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Active Drivers")}
          >
            Active Drivers
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Deactivated Drivers" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Deactivated Drivers")}
          >
            Deactivated Drivers
          </div>
        </div>
        <div className="tab-buttons width-auto">
          <div className="filter-btn">
            <button>
              <div className="icon">
                <img src={filterIcon} alt="filter-icon" />
              </div>
              <strong>Filter</strong>
            </button>
            <input
              type="text"
              value={searchTerm}
              onChange={onSearchChange}
              placeholder="Search by Full Name"
            />
          </div>
        </div>
      </div>

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {loading ? (
          <Loadingdata /> // Show loading spinner while loading
        ) : data?.length === 0 ? (
          <NoDataFound nodataTitle="No Data Found" /> // Show NoDataFound component if no data is available
        ) : (
          <>
            <div className="custom-tab-pane">
              <div
                className="table-wrapper"
                style={{ marginTop: "32px", overflowX: "auto" }}
              >
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "30px" }}>
                        <Form.Check aria-label="option 1" />
                      </th>
                      <th>
                        <div>
                          <strong>FULL NAME</strong>
                          <img src={sortIcon} alt="Sort Icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>EMAIL</strong>
                          <img src={sortIcon} alt="Sort Icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>PHONE NUMBER</strong>
                          <img src={sortIcon} alt="Sort Icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>TYPE</strong>
                          <img src={sortIcon} alt="Sort Icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>Assigned truck</strong>
                          <img src={sortIcon} alt="Sort Icon" />
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, i) => (
                      <tr
                        key={item?.id}
                        onClick={() =>
                          navigate(`/driversdetail/${item?.user_id}`)
                        }
                      >
                        <td
                          style={{ width: "30px" }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Form.Check
                            aria-label={`Select truck ${item.user_id}`}
                            checked={isSelected(item.user_id)}
                            onChange={() => handleSelect(item.user_id)}
                          />
                        </td>
                        <td>
                          <div className="stop-item">
                            {/* <img
                                src={
                                  item.picture &&
                                  !item.picture.startsWith(
                                    "https://img.clerk.com/"
                                  )
                                    ? process.env.REACT_APP_CLOUDFLARE_URL +
                                      item.picture
                                    : item.picture
                                }
                                alt="User Icon"
                                className="user-avatar"
                              /> */}
                            {/* <span>{item?.displayName || "N/A"}</span> */}
                            <span style={{ cursor: "pointer" }}>
                              {item?.displayName || "N/A"}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>{item?.email || "N/A"}</span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>{item?.phone || "N/A"}</span>
                          </div>
                        </td>
                        <td>
                          <div className="address-col">
                            <span>
                              {item?.employement?.employementType || "N/A"}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>
                              {(item?.fleets?.length > 0 &&
                                item?.fleets[0]?.fleetNumber) ||
                                "Not Assigned"}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                            <div>
                              <span>
                                {(item?.projects[0] &&
                                  item?.projects[0]?.user?.username) ||
                                  "Not Assigned"}
                              </span>
                            </div>
                          </td> */}

                        {/* <td>
                            <div>
                              <UpdateStatus
                                id={item?.user_id}
                                status={
                                  activeTab === "Active Drivers" ? false : true
                                }
                              />
                            </div>
                          </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && (
                <UsePagination
                  currentPage={currentPage}
                  count={totalPages}
                  onChange={handlePageChange}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DriversPageTabs;
