import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  createLoadStopAsyncThunk,
  updateLoadStopAsyncThunk,
} from "../../redux/pagesSlices/loadStopSlice";
import { Spinner } from "react-bootstrap";
import moment from "moment/moment";

const initialState = {
  type: "PICKUP",
  location: "",
  address: "",
  pickupNumber: "",
  phone: "",
  notes: "",
};

export default function EditLoadModal({
  params,
  editModalShow,
  handleEditModalClose,
}) {
  const { loadings } = useSelector((state) => state.loadStop);

  const [loadStop, setLoadStop] = useState(initialState);
  const [appointmentWindow, setAppointmentWindow] = useState({
    start: "",
    end: "",
  });
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoadStop((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      ...loadStop,
      Load: params.id,
      appointmentWindow,
    };
    dispatch(
      createLoadStopAsyncThunk({
        data,
        callBack: () => {
          setLoadStop(initialState);
          setAppointmentWindow({
            start: "",
            end: "",
          });
          handleEditModalClose();
        },
      })
    );
  };

  return (
    <Modal
      show={editModalShow}
      onHide={handleEditModalClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="edit-load-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Location</Modal.Title>
      </Modal.Header>
      <div className="edit-load-modal-body">
        <div className="container">
          <form onSubmit={handleSubmit}>
            <div className="radio-items">
              <div className="single-item">
                <input
                  type="radio"
                  value="PICKUP"
                  checked={loadStop.type === "PICKUP"}
                  name="type"
                  onChange={handleInputChange}
                  id="pickup"
                />
                <label htmlFor="pickup">Pick Up</label>
              </div>
              <div className="single-item">
                <input
                  type="radio"
                  value="DELIVERY"
                  checked={loadStop.type === "DELIVERY"}
                  name="type"
                  onChange={handleInputChange}
                  id="dropoff"
                />
                <label htmlFor="dropoff">Drop Off</label>
              </div>
            </div>
            <div className="form-section">
              <div className="row">
                <div className="col">
                  <div className="single-field">
                    <label htmlFor="">Location Name</label>
                    <select
                      id=""
                      name="location"
                      value={loadStop.location}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="Hasimara">Hasimara</option>
                      <option value="Princess_Anne">Princess Anne</option>
                      <option value="Baao">Baao</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="single-field address-field">
                    <label htmlFor="address">Search An Address</label>
                    <select
                      id="address"
                      name="address"
                      value={loadStop.address}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="8282 West South Carolina Avenue">
                        8282 West South Carolina Avenue
                      </option>
                      <option value="4892 West Kellogg Boulevard">
                        4892 West Kellogg Boulevard
                      </option>
                      <option value="Saint Paul, Minnesota">
                        Saint Paul, Minnesota
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="stop-details-section">
                <div className="title">
                  <strong>Stop Details</strong>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="single-detail-field">
                      <label htmlFor="">Pickup Number</label>
                      <input
                        type="number"
                        placeholder="124"
                        name="pickupNumber"
                        value={loadStop.pickupNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="single-detail-field">
                      <label htmlFor="">Contact Phone</label>
                      <input
                        type="tel"
                        placeholder="02-94020024"
                        name="phone"
                        value={loadStop.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="appointment-items">
                  <div className="row">
                    <div className="col-6">
                      <div className="single-detail-field">
                        <label htmlFor="">Appointment Window Start</label>
                        <input
                          type="date"
                          required
                          value={appointmentWindow.start}
                          onChange={(e) =>
                            setAppointmentWindow({
                              ...appointmentWindow,
                              start: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="single-detail-field">
                        <label htmlFor="">Appointment Window End</label>
                        <input
                          type="date"
                          required
                          value={appointmentWindow.end}
                          onChange={(e) =>
                            setAppointmentWindow({
                              ...appointmentWindow,
                              end: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="notes-field">
                  <div className="row">
                    <div className="col-12">
                      <div className="single-detail-field">
                        <label htmlFor="notes">Notes</label>
                        <input
                          id="notes"
                          type="text"
                          placeholder="Type here"
                          name="notes"
                          value={loadStop.notes}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="update-btn" type="submit">
                  <button disabled={loadings?.createLoadStopAsyncThunk}>
                    {loadings?.createLoadStopAsyncThunk ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

// export default function EditLoadModal({ src, text }) {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <button onClick={() => setModalShow(true)}>
//         <img src={edit} alt="icon" />
//         <span>Edit Load</span>
//       </button>
//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }
