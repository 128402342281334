import { APIurls } from "../constants.js";
import apiService from ".";
import multipart from "./multipart.js";
import axios from "axios";

export const ApiRequests = {
  //user Authentication
  login: async (data) => await apiService.post(APIurls.login, data),
  logout: async (data) => await apiService.post(APIurls.logout, data),
  register: async (data) => await apiService.post(APIurls.register, data),
  authenticate: async () => await apiService.get(APIurls.authenticate),
  refreshTokens: async (data) =>
    await apiService.post(APIurls.refreshTokens, data),

  revokeToken: async (data) => await apiService.post(APIurls.revokeToken, data),

  getProfile: async (id) => await apiService.get(APIurls.profile),

  // workspace
  createWorkSpace: async (data) =>
    await apiService.post(APIurls.workSpaces, data),
  inviteMember: async (data) => await apiService.post(APIurls.invite, data),
  rejectInvitation: async (data) =>
    await apiService.post(APIurls.invite + "/reject", data),
  getInvitations: async (params) =>
    await apiService.get(APIurls.invite, { params }),

  deleteWorkSpace: async (id) =>
    await apiService.delete(APIurls.workSpaces + "/" + id),
  // deleteWorkSpace: async ({ id, data }) => await apiService.patch(APIurls.workSpaces + "/" + id, data),
  getWorkSpaces: async (params) =>
    await apiService.get(APIurls.workSpaces, { params }),
  getWorkSpace: async (id) =>
    await apiService.get(APIurls.workSpaces + "/" + id),
  updateWorkspace: async ({ data, id }) =>
    await apiService.patch(APIurls.workSpaces + "/" + id, data),
  getWorkSpacesByMail: async (params) =>
    await apiService.get(APIurls.bymail, { params }),
  getWorkSpacesMembership: async (params) =>
    await apiService.get(APIurls.membership, { params }),
  deleteWorkSpaceUser: async (id) =>
    await apiService.delete(APIurls.workSpaces + "/user/" + id),

  createUser: async (data) => await apiService.post(APIurls.users, data),
  getUsers: async (params) => await apiService.get(APIurls.users, { params }),
  getUser: async (id, params) =>
    await apiService.get(APIurls.users + "/" + id, { params }),
  updateUser: async ({ data, id }) =>
    await apiService.patch(APIurls.users + "/" + id, data),
  deleteUser: async (id) => await apiService.delete(APIurls.users + "/" + id),

  createLoad: async (data) => await apiService.post(APIurls.loads, data),
  getLoads: async (params) => await apiService.get(APIurls.loads, { params }),
  getLoad: async (id) => await apiService.get(APIurls.loads + "/" + id),
  updateLoad: async ({ data, id }) =>
    await apiService.patch(APIurls.loads + "/" + id, data),
  deleteLoad: async (id) => await apiService.delete(APIurls.loads + "/" + id),

  createLoadStop: async (data) =>
    await apiService.post(APIurls.loadStops, data),
  getLoadStops: async (params) =>
    await apiService.get(APIurls.loadStops, { params }),
  getLoadStop: async (id) => await apiService.get(APIurls.loadStops + "/" + id),
  updateLoadStop: async ({ data, id }) =>
    await apiService.patch(APIurls.loadStops + "/" + id, data),
  deleteLoadStop: async (id) =>
    await apiService.delete(APIurls.loadStops + "/" + id),

  // fleets
  createFleet: async (data) => await apiService.post(APIurls.fleets, data),
  getFleets: async (params) => await apiService.get(APIurls.fleets, { params }),
  getFleet: async (id) => await apiService.get(APIurls.fleets + "/" + id),
  updateFleet: async ({ data, id }) =>
    await apiService.patch(APIurls.fleets + "/" + id, data),
  deleteFleet: async (id) => await apiService.delete(APIurls.fleets + "/" + id),
  deleteMultipleFleets: async (fleetIds) =>
    await apiService.post(APIurls.deleteMultipleFleets, fleetIds),

  // fleet documents
  addFleetDocuments: async (data) =>
    await apiService.post(APIurls.fleetDoc, data),
  getFleetDocs: async (params) =>
    await apiService.get(APIurls.fleetDoc, { params }),
  getFleetDoc: async (id) => await apiService.get(APIurls.fleetDoc + "/" + id),
  updateFleetDoc: async (data) =>
    await apiService.patch(APIurls.fleetDoc, data),
  deleteFleetDoc: async (id) =>
    await apiService.delete(APIurls.fleetDoc + "/" + id),

  // fleet documents
  addFleetExpense: async (data) => await apiService.post(APIurls.expense, data),
  getFleetExpenses: async (params) =>
    await apiService.get(APIurls.expense, { params }),
  // getFleetDoc: async (id) => await apiService.get(APIurls.fleetDoc + "/" + id),
  updateFleetExpense: async (id, data) =>
    await apiService.patch(APIurls.expense + "/" + id, data),
  deleteFleetExpense: async (id) =>
    await apiService.delete(APIurls.expense + "/" + id),
  deleteMultipleFleetExpenses: async (expenseIds) =>
    await apiService.post(
      APIurls.expense + "/deleteMultipleExpenses",
      expenseIds
    ),

  // user documents
  createUserDocument: async (data) =>
    await apiService.post(APIurls.userDoc, data),
  getUserDocuments: async (params) =>
    await apiService.get(APIurls.userDoc, { params }),
  getUserDocument: async (id) =>
    await apiService.get(APIurls.userDoc + "/" + id),
  updateUserDocument: async (data) =>
    await apiService.patch(APIurls.userDoc, data),
  deleteUserDocument: async (id) =>
    await apiService.delete(APIurls.userDoc + "/" + id),

  // Driver
  createDriver: async (data) => await apiService.post(APIurls.drivers, data),
  getDrivers: async (params) =>
    await apiService.get(APIurls.drivers, { params }),
  getDriver: async (id) => await apiService.get(APIurls.drivers + "/" + id),
  updateDriverStatus: async ({ data, id }) =>
    await apiService.patch(APIurls.drivers + "/status/" + id, data),
  updateDriver: async ({ data, id }) =>
    await apiService.patch(APIurls.drivers + "/" + id, data),
  deleteDriver: async (id) =>
    await apiService.delete(APIurls.drivers + "/" + id),
  updateDriverLicense: async ({ data, id }) =>
    await apiService.post(APIurls.drivers + "/license/" + id, data),
  getDriverLicense: async (id) =>
    await apiService.get(APIurls.drivers + "/license/" + id),
  deleteDriverLicense: async (id) =>
    await apiService.delete(APIurls.drivers + "/license/" + id),

  // Driver document
  addDriverDocument: async (data) =>
    await apiService.post(APIurls.driverDoc, data),
  getDriverDocuments: async (params) =>
    await apiService.get(APIurls.driverDoc, { params }),
  getDriverDocument: async (id) =>
    await apiService.get(APIurls.driverDoc + "/" + id),
  updateDriverDocument: async (data) =>
    await apiService.patch(APIurls.driverDoc, data),
  deleteDriverDocument: async (id) =>
    await apiService.delete(APIurls.driverDoc + "/" + id),

  // driver employement
  addDriverEmp: async (data) => await apiService.post(APIurls.driverEmp, data),
  getDriverEmps: async (params) =>
    await apiService.get(APIurls.driverEmp, { params }),
  getDriverEmp: async (id) =>
    await apiService.get(APIurls.driverEmp + "/" + id),
  updateDriverEmp: async (id, data) =>
    await apiService.patch(APIurls.driverEmp + "/emp/" + id, data),
  deleteDriverEmp: async (id) =>
    await apiService.delete(APIurls.driverEmp + "/" + id),
};
