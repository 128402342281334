import {
  useAuth,
  useOrganization,
  useSession,
  useSignIn,
} from "@clerk/clerk-react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setLocalValue } from "../helpers/localStorageHelpers";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { rejectInvitationAsyncThunk } from "../redux/pagesSlices/workspaceSlice";

const WorkSpaceInvite = () => {
  const { loadings } = useSelector((state) => state.workspace);
  const location = useLocation();
  const { session } = useSession();
  const { getToken, userId } = useAuth();
  const { signIn, setActive: setActiveSignIn } = useSignIn();
  const { organization } = useOrganization();
  const navigate = useNavigate();
  const { workspaceName, invitedBy } = useParams();
  // const decodedEmail = decodeURIComponent(email);

  // useEffect(() => {
  //   console.log("Decoded email:", decodedEmail); // Should print the correct email
  // }, [decodedEmail]);

  const [acceptLoading, setAcceptLoading] = useState(false);
  const dispatch = useDispatch();

  // Function to get query parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      email: params.get("email"), // Extract the email value
      token: params.get("__clerk_ticket"), // Extract the __clerk_ticket value
      accountStatus: params.get("__clerk_status"), // You can extract other params similarly
    };
  };

  // Call the function to get the values
  const { token, email, accountStatus } = getQueryParams();

  useEffect(() => {
    if (accountStatus === "sign_up") {
      sessionStorage.setItem("__clerk_ticket", JSON.stringify(token));
      sessionStorage.setItem("accountStatus", JSON.stringify(accountStatus));
      sessionStorage.setItem("email", JSON.stringify(email));
      sessionStorage.setItem("workspaceName", JSON.stringify(workspaceName));
      sessionStorage.setItem("invitedBy", JSON.stringify(invitedBy));
      // sessionStorage.setItem("email", JSON.stringify(decodedEmail));
      navigate(`/sign-up`);
    }
  }, [accountStatus, token]);

  const handleSignOut = async () => {
    try {
      await session.end();
      localStorage.removeItem("active_org");
      localStorage.removeItem("clerk_active_org");
      localStorage.removeItem("app-access-token");
      localStorage.removeItem("user-id");
      localStorage.removeItem("user");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleAcceptInvite = async () => {
    if (!signIn || !setActiveSignIn || !token) {
      return;
    }
    setAcceptLoading(true);
    try {
      await acceptInvitation();
    } catch (error) {
      if (error.errors) {
        await handleSignOut();

        try {
          await acceptInvitation();
        } catch (retryError) {
          console.error("Error retrying invitation acceptance:", retryError);
          handleError(retryError);
        }
      } else {
        handleError(error);
      }
    } finally {
      setAcceptLoading(false);
    }
  };

  // Function to encapsulate the invitation acceptance logic
  const acceptInvitation = async () => {
    if (accountStatus === "sign_in") {
      const signInAttempt = await signIn.create({
        strategy: "ticket",
        ticket: token,
      });
      if (signInAttempt.status === "complete") {
        await setActiveSignIn({
          session: signInAttempt.createdSessionId,
        });
        let token = await getToken();
        setLocalValue("app-access-token", token);
        setLocalValue("user-id", userId);
        window.location.href = `/welcome/${workspaceName}`;
      }
    }
  };

  // Function to handle error messages
  const handleError = (error) => {
    if (error.message) {
      toast.error(error.message);
    } else if (error.errors) {
      console.log("error.errors", error.errors);
      const messages = error.errors
        .map((err) => {
          if (err.code === "session_exists") {
            return "You can only be signed into one account at a time. Please sign out and try again.";
          }
          return err.longMessage;
        })
        .join(", ");

      toast.error(messages);
    } else {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleDeclineInvite = () => {
    dispatch(
      rejectInvitationAsyncThunk({
        data: { token },
        callBack: async () => {
          navigate("/");
        },
      })
    );
  };

  return (
    <>
      <div className="bg-image fixed-page">
        <div className="container">
          <div className="form-wrapper">
            <div className="heading-wrapper">
              <h2 style={{ fontWeight: 500 }}>Workspace Invite </h2>
            </div>

            <div className="verify-title">
              <p>
                You are invited to join the workspace <br></br>{" "}
                <span> {workspaceName} </span>
                {/* by {invitedBy}. */}
              </p>
            </div>

            <div className="decline-btn">
              <button
                onClick={handleDeclineInvite}
                disabled={loadings.rejectInvitationAsyncThunk}
                className="danger"
              >
                {loadings.rejectInvitationAsyncThunk ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Decline"
                )}
              </button>
              <button
                className="primary"
                disabled={acceptLoading}
                onClick={handleAcceptInvite}
              >
                {acceptLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Join Workspace"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkSpaceInvite;
