import { Close } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Button as ButtonMui } from '@mui/material';


import { Button } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";

export default function AddDocumentModal({
  openAddDocumentModal,
  handleCloseAddDocumentModal,
}) {
  return (
    <Modal
      open={openAddDocumentModal}
      onClose={handleCloseAddDocumentModal}
      aria-labelledby="add-document-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "90%",
            sm: "80%",
            md: 537,
          },
          maxWidth: 537,
          bgcolor: "#fff",
          p: 3,
          borderRadius: "16px",
          border: "1px solid #dfdfe2",
          boxShadow: "0px 15px 32px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography variant="modalTitleClass" id="simple-modal-title">
            Add Documents
          </Typography>

          <IconButton
            aria-label="close"
            onClick={handleCloseAddDocumentModal}
            sx={{
              "&.MuiIconButton-root": {
                padding: "3px",
                borderRadius: "0%",
                fill: "#68687A",
              },
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormLabel htmlFor="document-name">Document Name</FormLabel>
            <TextField
              required
              id="document-name"
              fullWidth
              placeholder="Type here ..."
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <InputLabel id="document-type-label">Document Type</InputLabel>
            <FormControl fullWidth>
              <Select
                labelId="document-type-label"
                id="document-type-select"
                placeholder="Select Document Type"
                // value={documentType}
                // onChange={handleDocumentTypeChange}
              >
                <MenuItem value="invoice">Invoice</MenuItem>
                <MenuItem value="receipt">Receipt</MenuItem>
                <MenuItem value="contract">Contract</MenuItem>
                {/* Add more document types as needed */}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <FormLabel htmlFor="attach-document">Attach Document</FormLabel>
        <Box sx={{ mt: 2, mb: 4 }}>
          <ButtonMui
            variant="outlined"
            component="label"
            startIcon={<FiUpload />}
            sx={{
              "&.MuiButton-root": {
                padding: "10px 20px",
                color: "var(--Primary-P300, #0065EC)",
                textAlign: "center",
                fontFamily: "var(--Font-Poppins, Poppins)",
                fontSize: "0.75rem",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              },
            }}
          >
            Upload
            <input type="file" hidden />
          </ButtonMui>
          <ButtonMui variant="text" disabled sx={{ ml: 2 }}>
            Remove
          </ButtonMui>
        </Box>
        <Button variant="contained">Add</Button>
      </Box>
    </Modal>
  );
}
