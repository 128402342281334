import React, { useState, useEffect } from "react";
import { getFirstTwoUpperCase } from "../helpers/parse";
import {
  Modal,
  Box,
  TextField,
  Button,
  FormLabel,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Button as ButtonMui } from "@mui/material";
import actionBtn from "../assets/images/action-btn.svg";
import UsePagination from "./UsePagination";
import UsePaginationv2 from "./UsePaginationv2";
import { Link, useNavigate } from "react-router-dom";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { FiEdit, FiUpload } from "react-icons/fi";
import sortIcon from "../assets/images/table-icon.svg";
import userIcon from "../assets/images/user-avatar.svg";
import EditDriverDocumentModal from "./Modals/EditDriverDocumentModal";
import DriverLicenseform from "../Pages/DriverLicenseform";
import { IoEyeOutline } from "react-icons/io5";
import editIcon from "../assets/images/pencil.svg";
import download from "../assets/images/download.svg";
import deleteIcon from "../assets/images/delete.svg";
import { ErrorMessage, Form, Field, Formik } from "formik";
import NoDsataFound from "./nodatafound";
import Loadingdata from "./loadingdata";
import * as Yup from "yup";
import {
  addDriverDocumentAsyncThunk,
  getDriverDocsAsyncThunk,
} from "../redux/pagesSlices/driverSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DeleteDriverDocModal from "./Modals/DeleteDriverDocModal";
import DriverEmploymentContent from "./DriverEmployementContent";
import { defaultDriverDocuments } from "../constant/documents";

const initialDocumentData = {
  documentName: "",
  documentType: "",
  expiryDate: "",
  file: null,
};

const DocumentSchema = Yup.object().shape({
  documentName: Yup.string().required("Document Name is required"),
  documentType: Yup.string().required("Document Type is required"),
  expiryDate: Yup.date().nullable(),
  file: Yup.mixed().nullable(),
});

export default function DriversDetailTabs({ user, getUserLoading }) {
  console.log("🚀 ~ DriversDetailTabs ~ user:", user);
  const { loadings, driverDocuments } = useSelector((state) => state.drivers);
  const [activeTab, setActiveTab] = useState("Profile");
  const [selectedDoc, setSelectedDoc] = useState("");
  const [openAddDocumentModal, setAddDocumentModalShow] = useState(false);
  const [editDriverDocModalShow, setEditDriverDocModalShow] = useState(false);
  const [deleteModalShow, deleteSetModalShow] = useState(false);
  const [documents, setDocuments] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTab === "Documents")
      dispatch(
        getDriverDocsAsyncThunk({
          params: { referenceType: "DRIVER", referenceId: user.user_id },
        })
      );
  }, [activeTab, openAddDocumentModal]);

  useEffect(() => {
    if (driverDocuments?.length > 0 || defaultDriverDocuments?.length > 0) {
      const docsWithData = defaultDriverDocuments?.map((doc) => {
        const docData = driverDocuments?.find(
          (data) => data.documentName === doc.documentName
        );
        return {
          ...doc,
          createdAt: docData?.createdAt || null,
          updatedAt: docData?.updatedAt || null,
          expiryDate: docData?.expiryDate || null,
          file: docData?.file || null,
          id: docData?.id || null,
        };
      });
      setDocuments(docsWithData);
    }
  }, [driverDocuments, defaultDriverDocuments]);

  const handleTabActive = (tabname) => {
    setActiveTab(tabname);
  };

  const handleDriverDocModalOpen = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    setEditDriverDocModalShow(true);
  };

  const handleDriverDocModalClose = () => {
    setSelectedDoc("");
    setEditDriverDocModalShow(false);
  };

  const handleDeleteModalOpen = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedDoc("");
    deleteSetModalShow(false);
  };

  return (
    <div className="custom-tabs-container">
      {/* Tabs Header */}
      <div className="custom-tabs-header">
        <div className="custom-tabs">
          <div
            className={`custom-tab-item ${
              activeTab === "Profile" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Profile")}
          >
            Profile
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Documents" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Documents")}
          >
            Documents
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Employment " ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Employment ")}
          >
            Employment 
          </div>
        </div>
      </div>

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {activeTab === "Profile" && (
          <>
            {getUserLoading ? (
              <Loadingdata />
            ) : (
              <div className="custom-tab-pane">
                <DriverLicenseform user={user} />
              </div>
            )}
          </>
        )}
        {activeTab === "Documents" && (
          <div className="custom-tab-pane">
            <div className="truck-docs-section">
              <div className="container-fluid">
                <div className="documents-table" style={{ overflowX: "auto" }}>
                  {loadings?.getDriverDocsAsyncThunk ? (
                    <Loadingdata />
                  ) : documents?.length === 0 ? (
                    <NoDsataFound nodataTitle="No Document Found" />
                  ) : (
                    <table className="truck-documents-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {documents?.map((document) => {
                          return (
                            <tr>
                              <td style={{ minWidth: "300px" }}>
                                <div className="single-list-item">
                                  <div className="item-info">
                                    <span>
                                      {getFirstTwoUpperCase(
                                        document.documentName
                                      )}
                                    </span>
                                    <div className="info">
                                      <strong>{document.documentName}</strong>
                                      <p>{document.documentType}</p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ minWidth: "250px" }}>
                                <div className="content">
                                  <strong>Updated</strong>
                                  <strong>
                                    {document.updatedAt ? (
                                      moment(document.updatedAt).format(
                                        "MMMM Do YYYY, h:mm A"
                                      )
                                    ) : (
                                      <span className="yellow-text">
                                        Missing
                                      </span>
                                    )}
                                  </strong>
                                </div>
                              </td>
                              <td style={{ minWidth: "120px" }}>
                                <div className="content">
                                  <strong>Expiration</strong>
                                  <strong>
                                    {document.expiryDate ? (
                                      moment(document.expiryDate).format(
                                        "MMMM Do YYYY, h:mm A"
                                      )
                                    ) : (
                                      <span className="yellow-text">
                                        Missing
                                      </span>
                                    )}
                                  </strong>
                                </div>
                              </td>
                              <td style={{ minWidth: "250px" }}>
                                <div className="item-actions">
                                  <div className="single-action">
                                    <button
                                      onClick={() =>
                                        handleDriverDocModalOpen(document)
                                      }
                                    >
                                      <img src={editIcon} alt="icon" />
                                    </button>
                                    {selectedDoc && editDriverDocModalShow && (
                                      <EditDriverDocumentModal
                                        selectedDoc={selectedDoc}
                                        referenceId={user.user_id}
                                        editDriverDocModalShow={
                                          editDriverDocModalShow
                                        }
                                        handleDriverDocModalClose={
                                          handleDriverDocModalClose
                                        }
                                      />
                                    )}
                                  </div>

                                  {document.file && (
                                    <div className="single-action">
                                      <a
                                        download
                                        href={
                                          process.env.REACT_APP_CLOUDFLARE_URL +
                                          document.file
                                        }
                                      >
                                        <img src={download} alt="icon" />
                                      </a>
                                    </div>
                                  )}

                                  <div className="single-action">
                                    <button
                                      onClick={() =>
                                        handleDeleteModalOpen(document)
                                      }
                                    >
                                      <img src={deleteIcon} alt="icon" />
                                    </button>
                                    {selectedDoc && deleteModalShow && (
                                      <DeleteDriverDocModal
                                        selectedDoc={selectedDoc}
                                        referenceId={user.user_id}
                                        handleDeleteModalClose={
                                          handleDeleteModalClose
                                        }
                                        deleteModalShow={deleteModalShow}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "Employment " && (
          <div className="custom-tab-pane">
            <DriverEmploymentContent user={user} />
          </div>
        )}
      </div>
    </div>
  );
}
