import React, { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import tick from "../assets/images/forms-images/Check circle.png";
import dark from "../assets/images/forms-images/Check circle (1).png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSignIn } from "@clerk/clerk-react";
import { Spinner } from "react-bootstrap";

const initialState = {
  password: "",
  confirmPassword: "",
};
const NewPassword = () => {
  const { signIn } = useSignIn();
  const [formData, setFormData] = useState(initialState);
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
  const [isMinLength, setIsMinLength] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      code: params.get("otp"),
    };
  };

  // Call the function to get the values
  const { code } = getQueryParams();

  useEffect(() => {
    setIsMinLength(formData.password.length >= 8);
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    setHasSpecialChar(specialCharRegex.test(formData.password));
  }, [formData.password]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      return toast.error("Passwords do not match");
    }
    if (isMinLength && hasSpecialChar) {
      setLoading(true);
      try {
        let resetPassAttempt = await signIn?.attemptFirstFactor({
          strategy: "reset_password_email_code",
          code,
          password: formData.password,
        });

        if (resetPassAttempt.status == "complete") {
          window.location.href = "/alldone";
        }
      } catch (error) {
        console.error(error.errors);

        if (error.message) {
          toast.error(error.message, {
            autoClose: true,
          });
        } else if (error.errors) {
          const messages = error.errors
            .map((error) => error.longMessage)
            .join(", ");
          toast.error(messages, {
            autoClose: true,
          });
        } else {
          toast.error("An unexpected error occurred. Please try again.", {
            autoClose: true,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <div className="bg-image fixed-page">
        <div className="container">
          <form className="form-wrapper" onSubmit={handleSubmit}>
            <div className="heading-wrapper">
              <h2 style={{ fontWeight: "500" }}>Set new password</h2>
              <p>
                Your new password must be different from the previously used
                password.
              </p>
            </div>
            <div className="login-fields">
              <div className="input-fields with-icon">
                <label>Password</label>
                <div className="input-wrapper">
                  <input
                    type={isShowPass ? "text" : "password"}
                    placeholder=".........."
                    name="password"
                    required
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <button
                    className="btn btn-link p-0"
                    type="button"
                    onClick={() => setIsShowPass(!isShowPass)}
                  >
                    {isShowPass ? (
                      <FiEye className="input-icon" />
                    ) : (
                      <FiEyeOff className="input-icon" />
                    )}
                  </button>
                </div>
              </div>
              <div className="input-fields with-icon">
                <label>Confirm Password</label>
                <div className="input-wrapper">
                  <input
                    type={isShowConfirmPass ? "text" : "password"}
                    placeholder=".........."
                    name="confirmPassword"
                    required
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  <button
                    className="btn btn-link p-0"
                    type="button"
                    onClick={() => setIsShowConfirmPass(!isShowConfirmPass)}
                  >
                    {isShowConfirmPass ? (
                      <FiEye className="input-icon" />
                    ) : (
                      <FiEyeOff className="input-icon" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="chracter">
              <img src={isMinLength ? tick : dark} alt="Tick" />
              <p>Must be at least 8 characters</p>
            </div>

            <div className="chracter">
              <img src={hasSpecialChar ? tick : dark} alt="Tick" />
              <p>Must contain 1 special character</p>
            </div>

            <div className="login-btn text-end justify-content-end align-items-end">
              <button className="btn-style border-0" disabled={loading}>
                {loading ? (
                  <Spinner size="sm" className="ms-2" />
                ) : (
                  "Reset Password"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
