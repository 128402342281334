import React, { useEffect, useRef, useState } from "react";
import TruckDetailsForm from "./TruckDetailsForm";
import download from "../assets/images/download.svg";
import deleteIcon from "../assets/images/delete.svg";
import { IoEyeOutline } from "react-icons/io5";
import editIcon from "../assets/images/pencil.svg";
import Expenses from "../Pages/Expenses";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Button as ButtonMui } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Close } from "@mui/icons-material";
import { Button, Spinner } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import addDocument from "../assets/images/add-document.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loadingdata from "./loadingdata";
import Nodatafound from "./nodatafound";
import DeleteFleetDocModal from "./Modals/DeleteFleetDocModal";
import EditFleetDocumentModal from "./Modals/EditFleetDocumentModal";
import moment from "moment";
import { getFirstTwoUpperCase } from "../helpers/parse";
import TruckDetailsSection from "./TruckDetailsSection";
import AddTruckExpenseModal from "./Modals/AddTruckExpenseModal";
import FleetExpense from "./FleetExpense";
import { getFleetDocsAsyncThunk } from "../redux/pagesSlices/fleetDocSlice";
import { defaultFleetDocuments } from "../constant/documents";

const TrucksTabsSection = ({ fleetData }) => {
  console.log("🚀 ~ TrucksTabsSection ~ fleetData:", fleetData);
  // State to manage active tab
  const { fleetDocuments, loadings } = useSelector((state) => state.fleetDoc);
  const { activeOrg } = useSelector((state) => state.workspace);
  const [activeTab, setActiveTab] = useState("Details");
  const [selectedDoc, setSelectedDoc] = useState("");
  const [documents, setDocuments] = useState(null);
  const [deleteModalShow, deleteSetModalShow] = useState(false);
  const [editTruckModalShow, setTruckModalShow] = useState(false);
  const fileInputRef = useRef(null); // Reference for the file input
  const dispatch = useDispatch();

  const fetchDocuments = async () => {
    const params = {
      referenceType: "FLEET",
      referenceId: fleetData?.id,
    };
    dispatch(getFleetDocsAsyncThunk({ params }));
  };

  useEffect(() => {
    if (activeTab === "Documents") {
      fetchDocuments();
    }
  }, [dispatch, activeTab, activeOrg]);

  useEffect(() => {
    if (fleetDocuments?.length > 0 || defaultFleetDocuments?.length > 0) {
      const docsWithData = defaultFleetDocuments?.map((doc) => {
        const docData = fleetDocuments?.find(
          (data) => data.documentName === doc.documentName
        );
        return {
          ...doc,
          createdAt: docData?.createdAt || null,
          updatedAt: docData?.updatedAt || null,
          expiryDate: docData?.expiryDate || null,
          file: docData?.file || null,
          id: docData?.id || null,
        };
      });
      setDocuments(docsWithData);
    }
  }, [fleetDocuments, defaultFleetDocuments]);

  const handleTruckModaOpen = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    setTruckModalShow(true);
  };

  const handleTruckModalClose = () => {
    setTruckModalShow(false);
  };

  const handleDeleteModalOpen = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
    setSelectedDoc("");
  };

  const [addtruckExpenseShow, setAddTruckExpenseShow] = useState(false);

  const handleAddTruckModalShow = () => setAddTruckExpenseShow(true);

  const handleAddTruckModalClose = () => setAddTruckExpenseShow(false);

  return (
    <div className="custom-tabs-container">
      {/* Tabs Header */}
      <div className="custom-tabs-header">
        <div className="custom-tabs">
          <div
            className={`custom-tab-item ${
              activeTab === "Details" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Details")}
          >
            Details
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Documents" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Documents")}
          >
            Documents
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Expenses" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Expenses")}
          >
            Expenses
          </div>
        </div>

        <div
          className={`${
            activeTab === "Expenses" ? "btn-show" : "btn-hide"
          } add-document-btn`}
        >
          <button onClick={handleAddTruckModalShow}>
            <img src={addDocument} alt="icon" />
            <span>
              Add {fleetData?.fleetType === "TRUCK" ? "Truck" : "Trailer"}{" "}
              Expense
            </span>
          </button>
          <AddTruckExpenseModal
            fleetData={fleetData}
            addtruckExpenseShow={addtruckExpenseShow}
            handleAddTruckModalClose={handleAddTruckModalClose}
          />
        </div>
      </div>

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {activeTab === "Details" && (
          <div className="custom-tab-pane">
            {/* <TruckDetailsForm fleetData={fleetData} />
             */}
            <TruckDetailsSection fleetData={fleetData} />
          </div>
        )}

        {activeTab === "Documents" && (
          <>
            {loadings?.getFleetDocsAsyncThunk ? (
              <Loadingdata />
            ) : !documents?.length && !loadings?.getFleetDocsAsyncThunk ? (
              <Nodatafound nodataTitle="No Documents Found" />
            ) : (
              <div className="custom-tab-pane">
                <div className="truck-docs-section">
                  <div className="container-fluid">
                    <div
                      className="documents-table"
                      style={{ overflowX: "auto" }}
                    >
                      <table className="truck-documents-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td style={{ minWidth: "300px" }}>
                                  <div className="single-list-item">
                                    <div className="item-info">
                                      <span>
                                        {getFirstTwoUpperCase(
                                          item.documentName
                                        )}
                                      </span>
                                      <div className="info">
                                        <strong>{item.documentName}</strong>
                                        <p>{item.documentType}</p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td style={{ minWidth: "250px" }}>
                                  <div className="content">
                                    <strong>Updated</strong>
                                    <strong>
                                      {item.updatedAt ? (
                                        moment(item.updatedAt).format(
                                          "MMMM Do YYYY, h:mm A"
                                        )
                                      ) : (
                                        <span className="yellow-text">
                                          Missing
                                        </span>
                                      )}
                                    </strong>
                                  </div>
                                </td>
                                <td style={{ minWidth: "120px" }}>
                                  <div className="content">
                                    <strong>Expiration</strong>
                                    <strong>
                                      {item.expiryDate ? (
                                        moment(item.expiryDate).format(
                                          "MMMM Do YYYY, h:mm A"
                                        )
                                      ) : (
                                        <span className="yellow-text">
                                          Missing
                                        </span>
                                      )}
                                    </strong>
                                  </div>
                                </td>

                                <td style={{ minWidth: "250px" }}>
                                  <div className="item-actions">
                                    <div className="single-action">
                                      <button
                                        onClick={() =>
                                          handleTruckModaOpen(item)
                                        }
                                      >
                                        <img src={editIcon} alt="icon" />
                                      </button>
                                      {selectedDoc && editTruckModalShow && (
                                        <EditFleetDocumentModal
                                          selectedDoc={selectedDoc}
                                          fleetData={fleetData}
                                          editTruckModalShow={
                                            editTruckModalShow
                                          }
                                          handleTruckModalClose={() => {
                                            handleTruckModalClose();
                                            setSelectedDoc("");
                                          }}
                                        />
                                      )}
                                    </div>
                                    {item.file && (
                                      <div className="single-action">
                                        <a
                                          download
                                          href={
                                            process.env
                                              .REACT_APP_CLOUDFLARE_URL +
                                            item.file
                                          }
                                        >
                                          <img src={download} alt="icon" />
                                        </a>
                                      </div>
                                    )}
                                    <div className="single-action">
                                      <button
                                        onClick={() =>
                                          handleDeleteModalOpen(item)
                                        }
                                      >
                                        <img src={deleteIcon} alt="icon" />
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {activeTab === "Expenses" && (
          <div className="custom-tab-pane">
            <FleetExpense activeTab={activeTab} fleetData={fleetData} />
          </div>
        )}
      </div>
      <DeleteFleetDocModal
        selectedDoc={selectedDoc}
        fleetData={fleetData}
        handleDeleteModalClose={handleDeleteModalClose}
        deleteModalShow={deleteModalShow}
      />
    </div>
  );
};

export default TrucksTabsSection;
