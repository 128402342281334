import React from "react";
import Modal from "react-bootstrap/Modal";
import trash from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteFleetDocAsyncThunk, getFleetDocsAsyncThunk } from "../../redux/pagesSlices/fleetDocSlice";
import { Spinner } from "react-bootstrap";

function DeleteFleetDocModal({
  deleteModalShow,
  handleDeleteModalClose,
  selectedDoc,
  fleetData,
}) {
  const dispatch = useDispatch();
  const { loadings } = useSelector((state) => state.fleetDoc);

  const handleDeleteFleetDoc = () => {
    if (selectedDoc?.id == null) {
      handleDeleteModalClose();
      return;
    }
    dispatch(
      deleteFleetDocAsyncThunk({
        id: selectedDoc?.id,
        callBack: () => {
          handleDeleteModalClose();
          const params = {
            referenceType: "FLEET",
            referenceId: fleetData?.id,
          };
          dispatch(getFleetDocsAsyncThunk({ params }));
        },
      })
    );
  };

  return (
    <Modal
      show={deleteModalShow}
      onHide={handleDeleteModalClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-load-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="delete-modal-body">
        <div className="title">
          <strong>Are you sure you want to archive this Document?</strong>
        </div>
        <div className="delete-btn">
          <button
            disabled={loadings.deleteFleetDocAsyncThunk}
            onClick={handleDeleteFleetDoc}
          >
            {loadings.deleteFleetDocAsyncThunk ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              <>
                <img src={trash} alt="icon" />
                <span>Archive document</span>
              </>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteFleetDocModal;
