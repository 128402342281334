import React from "react";
import Modal from "react-bootstrap/Modal";
import trash from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { deleteFleetExpenseAsyncThunk } from "../../redux/pagesSlices/fleetExpenseSlice";

function DeleteFleetExpenseModal({
  deleteModalShow,
  handleDeleteModalClose,
  delId,
}) {
  const { loadings } = useSelector((state) => state?.fleetExpense);
  const dispatch = useDispatch();

  const handleDeleteDriverDoc = () => {
    dispatch(
      deleteFleetExpenseAsyncThunk({
        id: delId,
        callBack: () => {
          handleDeleteModalClose();
        },
      })
    );
  };

  return (
    <Modal
      show={deleteModalShow}
      onHide={handleDeleteModalClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-load-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="delete-modal-body">
        <div className="title">
          <strong>Are you sure you want to archive this expense?</strong>
        </div>
        <div className="delete-btn">
          <button
            disabled={loadings.deleteFleetExpenseAsyncThunk}
            onClick={handleDeleteDriverDoc}
          >
            {loadings.deleteFleetExpenseAsyncThunk ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              <>
                <img src={trash} alt="icon" />
                <span>Archive expense</span>
              </>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteFleetExpenseModal;
