import { Modal, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  addDriverEmpAsyncThunk,
  getDriverEmpAsyncThunk,
  updateDriverEmpAsyncThunk,
} from "../../redux/pagesSlices/driverEmpSlice";
import moment from "moment";
import { toast } from "react-toastify";

export default function EditDriverEmploymentModal({
  showEditEmploymentModal,
  handleHideEditEmploymentModal,
  driverEmpById,
  driverId,
}) {
  console.log("🚀 ~ driverEmpById:", driverEmpById);
  const dispatch = useDispatch();
  const { loadings } = useSelector((state) => state?.driverEmp);

  const validationSchema = Yup.object().shape({
    employementType: Yup.string().required("Employment Type is required"),
    hireDate: Yup.date().required("Hire date is required"),
    compensationType: Yup.string().required("Compensation Type is required"),
    compensation: Yup.number()
      .typeError("Compensation must be a number")
      .required("Compensation is required"),
  });

  const formik = useFormik({
    initialValues: {
      employementType: driverEmpById?.employementType || "driver",
      hireDate: moment(driverEmpById?.hireDate).format("YYYY-MM-DD") || "",
      compensationType: driverEmpById?.compensationType || "mile",
      compensation: driverEmpById?.compensation || "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (driverEmpById) {
        dispatch(
          updateDriverEmpAsyncThunk({
            id: driverEmpById?.id,
            data: values,
            driverId: driverId,
            callBack: () => {
              handleHideEditEmploymentModal();
            },
          })
        );
      } else {
        dispatch(
          addDriverEmpAsyncThunk({
            data: {
              ...values,
              userId: driverId,
            },
          })
        ).then(() => {
          toast.success("Employemnt Created Successfully!");
          handleHideEditEmploymentModal();
          dispatch(getDriverEmpAsyncThunk({ id: driverId }));
        });
      }
    },
    enableReinitialize: true,
  });

  return (
    <Modal
      show={showEditEmploymentModal}
      onHide={handleHideEditEmploymentModal}
      size="lg"
      centered
      className="edit-driver-employment-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Driver Employment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="main-driver">
            <div className="container">
              <div className="employment-type">
                <p>Employment Type</p>
                <div className="radio-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="radio-flex">
                        <label>
                          <input
                            type="radio"
                            name="employementType"
                            value="driver"
                            checked={formik.values.employementType === "driver"}
                            onChange={formik.handleChange}
                          />{" "}
                          Driver
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="radio-flex">
                        <label>
                          <input
                            type="radio"
                            name="employementType"
                            value="owner"
                            checked={formik.values.employementType === "owner"}
                            onChange={formik.handleChange}
                          />{" "}
                          Owner/Operator
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {formik.errors.employementType &&
                  formik.touched.employementType && (
                    <div className="error">{formik.errors.employementType}</div>
                  )}

                <div className="row">
                  <div className="col-md-12">
                    <div className="modal-field">
                      <label>Hire date</label>
                      <input
                        type="date"
                        name="hireDate"
                        value={formik.values.hireDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.errors.hireDate && formik.touched.hireDate && (
                        <div className="error">{formik.errors.hireDate}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="employment-type">
                <p>Compensation Type</p>
                <div className="radio-box">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="radio-flex">
                        <label>
                          <input
                            type="radio"
                            name="compensationType"
                            value="mile"
                            checked={formik.values.compensationType === "mile"}
                            onChange={formik.handleChange}
                          />{" "}
                          Per mile
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="radio-flex">
                        <label>
                          <input
                            type="radio"
                            name="compensationType"
                            value="percentage"
                            checked={
                              formik.values.compensationType === "percentage"
                            }
                            onChange={formik.handleChange}
                          />{" "}
                          Percentage
                        </label>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="radio-flex">
                        <label>
                          <input
                            type="radio"
                            name="compensationType"
                            value="flatRate"
                            checked={
                              formik.values.compensationType === "flatRate"
                            }
                            onChange={formik.handleChange}
                          />{" "}
                          Flat Rate
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {formik.errors.compensationType &&
                  formik.touched.compensationType && (
                    <div className="error">
                      {formik.errors.compensationType}
                    </div>
                  )}

                <div className="row">
                  <div className="col-md-12">
                    <div className="modal-field mb-0">
                      <label>Compensation</label>
                      <input
                        type="text"
                        name="compensation"
                        value={formik.values.compensation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="%  Enter percentage or flat rate"
                      />
                      {formik.errors.compensation &&
                        formik.touched.compensation && (
                          <div className="error">
                            {formik.errors.compensation}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="document-btn">
                <div className="driver-profile-btn">
                  <Button
                    className="add-btn"
                    type="submit"
                    disabled={
                      loadings?.updateDriverEmpAsyncThunk ||
                      loadings?.addDriverEmpAsyncThunk ||
                      (driverEmpById ? !formik.dirty : false)
                    }
                  >
                    {loadings?.updateDriverEmpAsyncThunk ||
                    loadings?.addDriverEmpAsyncThunk ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
