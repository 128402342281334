import ControlPointIcon from '@mui/icons-material/ControlPoint'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import UsePagination from './UsePagination'
import AddExpensesModal from './AddExpenseModal'
import CreateInvoiceModal from './CreateInvoiceModal'

const itemsPerPage = 9

export default function Loods() {
  const [currentPage, setCurrentPage] = useState(1)
  // Expense modal
  const [newExpense, setNewExpense] = useState(false)
  const handleOpenNewExpense = () => setNewExpense(true)
  const handleCloseNewExpense = () => setNewExpense(false)

  // Create invoice modal
  const [createInvoiceModalOpen, setCreateInvoiceModalOpen] = useState(false)
  const handleOpenCreateInvoiceModal = () => setCreateInvoiceModalOpen(true)
  const handleCloseCreateInvoiceModal = () => setCreateInvoiceModalOpen(false)

  const expensesData = [
    { type: 'Labor', description: 'Additional work hours', amount: '$250.00' },
    {
      type: 'Material',
      description: 'Extra paint supplies',
      amount: '$120.50',
    },
    {
      type: 'Equipment',
      description: 'Rental of power washer',
      amount: '$75.00',
    },
    {
      type: 'Labor',
      description: 'Overtime for skilled workers',
      amount: '$350.00',
    },
    { type: 'Material', description: 'Specialized sealant', amount: '$180.75' },
    { type: 'Equipment', description: 'Scaffolding rental', amount: '$200.00' },
    { type: 'Labor', description: 'Subcontractor fees', amount: '$500.00' },
    { type: 'Material', description: 'Replacement parts', amount: '$95.25' },
    {
      type: 'Equipment',
      description: 'Heavy machinery rental',
      amount: '$450.00',
    },
    { type: 'Labor', description: 'Emergency repairs', amount: '$300.00' },
    {
      type: 'Material',
      description: 'Insulation materials',
      amount: '$150.25',
    },
    {
      type: 'Equipment',
      description: 'Concrete mixer rental',
      amount: '$175.00',
    },
    { type: 'Labor', description: 'Specialized technician', amount: '$400.00' },
    {
      type: 'Material',
      description: 'Electrical components',
      amount: '$225.50',
    },
    {
      type: 'Equipment',
      description: 'Safety equipment rental',
      amount: '$80.00',
    },
    {
      type: 'Labor',
      description: 'Project management hours',
      amount: '$550.00',
    },
    { type: 'Material', description: 'Plumbing supplies', amount: '$135.75' },
    { type: 'Equipment', description: 'Excavator rental', amount: '$375.00' },
    {
      type: 'Labor',
      description: 'Quality control inspection',
      amount: '$275.00',
    },
    { type: 'Labor', description: 'Additional work hours', amount: '$250.00' },
    {
      type: 'Material',
      description: 'Extra paint supplies',
      amount: '$120.50',
    },
    {
      type: 'Equipment',
      description: 'Rental of power washer',
      amount: '$75.00',
    },
    {
      type: 'Labor',
      description: 'Overtime for skilled workers',
      amount: '$350.00',
    },
    { type: 'Material', description: 'Specialized sealant', amount: '$180.75' },
    { type: 'Equipment', description: 'Scaffolding rental', amount: '$200.00' },
    { type: 'Labor', description: 'Subcontractor fees', amount: '$500.00' },
    { type: 'Material', description: 'Replacement parts', amount: '$95.25' },
    {
      type: 'Equipment',
      description: 'Heavy machinery rental',
      amount: '$450.00',
    },
    { type: 'Labor', description: 'Emergency repairs', amount: '$300.00' },
    {
      type: 'Material',
      description: 'Insulation materials',
      amount: '$150.25',
    },
    {
      type: 'Equipment',
      description: 'Concrete mixer rental',
      amount: '$175.00',
    },
    { type: 'Labor', description: 'Specialized technician', amount: '$400.00' },
    {
      type: 'Material',
      description: 'Electrical components',
      amount: '$225.50',
    },
    {
      type: 'Equipment',
      description: 'Safety equipment rental',
      amount: '$80.00',
    },
    {
      type: 'Labor',
      description: 'Project management hours',
      amount: '$550.00',
    },
    { type: 'Material', description: 'Plumbing supplies', amount: '$135.75' },
    { type: 'Equipment', description: 'Excavator rental', amount: '$375.00' },
    {
      type: 'Labor',
      description: 'Quality control inspection',
      amount: '$275.00',
    },
    { type: 'Labor', description: 'Additional work hours', amount: '$250.00' },
    {
      type: 'Material',
      description: 'Extra paint supplies',
      amount: '$120.50',
    },
    {
      type: 'Equipment',
      description: 'Rental of power washer',
      amount: '$75.00',
    },
    {
      type: 'Labor',
      description: 'Overtime for skilled workers',
      amount: '$350.00',
    },
    { type: 'Material', description: 'Specialized sealant', amount: '$180.75' },
    { type: 'Equipment', description: 'Scaffolding rental', amount: '$200.00' },
    { type: 'Labor', description: 'Subcontractor fees', amount: '$500.00' },
    { type: 'Material', description: 'Replacement parts', amount: '$95.25' },
    {
      type: 'Equipment',
      description: 'Heavy machinery rental',
      amount: '$450.00',
    },
    { type: 'Labor', description: 'Emergency repairs', amount: '$300.00' },
    {
      type: 'Material',
      description: 'Insulation materials',
      amount: '$150.25',
    },
    {
      type: 'Equipment',
      description: 'Concrete mixer rental',
      amount: '$175.00',
    },
    { type: 'Labor', description: 'Specialized technician', amount: '$400.00' },
    {
      type: 'Material',
      description: 'Electrical components',
      amount: '$225.50',
    },
    {
      type: 'Equipment',
      description: 'Safety equipment rental',
      amount: '$80.00',
    },
    {
      type: 'Labor',
      description: 'Project management hours',
      amount: '$550.00',
    },
    { type: 'Material', description: 'Plumbing supplies', amount: '$135.75' },
    { type: 'Equipment', description: 'Excavator rental', amount: '$375.00' },
    {
      type: 'Labor',
      description: 'Quality control inspection',
      amount: '$275.00',
    },
    { type: 'Labor', description: 'Additional work hours', amount: '$250.00' },
    {
      type: 'Material',
      description: 'Extra paint supplies',
      amount: '$120.50',
    },
    {
      type: 'Equipment',
      description: 'Rental of power washer',
      amount: '$75.00',
    },
    {
      type: 'Labor',
      description: 'Overtime for skilled workers',
      amount: '$350.00',
    },
    { type: 'Material', description: 'Specialized sealant', amount: '$180.75' },
    { type: 'Equipment', description: 'Scaffolding rental', amount: '$200.00' },
    { type: 'Labor', description: 'Subcontractor fees', amount: '$500.00' },
    { type: 'Material', description: 'Replacement parts', amount: '$95.25' },
    {
      type: 'Equipment',
      description: 'Heavy machinery rental',
      amount: '$450.00',
    },
    { type: 'Labor', description: 'Emergency repairs', amount: '$300.00' },
    {
      type: 'Material',
      description: 'Insulation materials',
      amount: '$150.25',
    },
    {
      type: 'Equipment',
      description: 'Concrete mixer rental',
      amount: '$175.00',
    },
    { type: 'Labor', description: 'Specialized technician', amount: '$400.00' },
    {
      type: 'Material',
      description: 'Electrical components',
      amount: '$225.50',
    },
    {
      type: 'Equipment',
      description: 'Safety equipment rental',
      amount: '$80.00',
    },
    {
      type: 'Labor',
      description: 'Project management hours',
      amount: '$550.00',
    },
    { type: 'Material', description: 'Plumbing supplies', amount: '$135.75' },
    { type: 'Equipment', description: 'Excavator rental', amount: '$375.00' },
    {
      type: 'Labor',
      description: 'Quality control inspection',
      amount: '$275.00',
    },
  ]

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = expensesData.slice(indexOfFirstItem, indexOfLastItem)

  return (
    <div class='loods'>
      <div class='container-fluid'>
        <div class='row g-3'>
          <div class='col-lg-6'>
            <div class='invoice-card custom-card h-100'>
              <div class='card-header'>
                <span>Invoice info</span>
              </div>
              <div class='invoice-content'>
                <ul class='invoice-list'>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>Invoice Status</span>
                    </div>
                    <div class='col-4'>
                      <span class='invoice-badge'>Not Invoiced</span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>Invoice Number</span>
                    </div>
                    <div class='col-4'>
                      <span class=''></span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>Invoice Date</span>
                    </div>
                    <div class='col-4'>
                      <span class=''>N/A</span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>Billed To</span>
                    </div>
                    <div class='col-4'>
                      <span class=''></span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>Invoice Amount</span>
                    </div>
                    <div class='col-4'>
                      <span class=''>$0.00</span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>Payment Date</span>
                    </div>
                    <div class='col-4'>
                      <span class=''>$0.00</span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>Payment type</span>
                    </div>
                    <div class='col-4'>
                      <span class=''>N/A</span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>
                        Payment Reference
                      </span>
                    </div>
                    <div class='col-4'>
                      <span class=''>N/A</span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0'>
                    <div class='col-8'>
                      <span class='invoice-item px-2 mx-1'>
                        Payment Processing Fee
                      </span>
                    </div>
                    <div class='col-4'>
                      <span class=''>N/A</span>
                    </div>
                  </li>
                  <li class='row align-items-center g-0 border-0'>
                    <div class='col-8'>
                      <Link
                        to='/'
                        className='mx-1 px-2 text-decoration-none link'
                        onClick={e => {
                          e.preventDefault()
                          handleOpenCreateInvoiceModal()
                        }}
                      >
                        $ Create Invoice
                      </Link>
                      <CreateInvoiceModal
                        createInvoiceModalOpen={createInvoiceModalOpen}
                        handleCloseCreateInvoiceModal={
                          handleCloseCreateInvoiceModal
                        }
                      ></CreateInvoiceModal>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='expenses-card custom- h-100'>
              <div className='card-header d-flex align-items-center justify-content-between'>
                <span className='title'>Invoice Changes/Expenses</span>
                <Button
                  variant='contained'
                  startIcon={<ControlPointIcon />}
                  onClick={handleOpenNewExpense}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    px: 1.5,
                    '& .MuiButton-startIcon': {
                      marginRight: '8px',
                    },
                  }}
                >
                  Add
                </Button>

                <AddExpensesModal
                  newExpense={newExpense}
                  handleCloseNewExpense={handleCloseNewExpense}
                ></AddExpensesModal>
              </div>

              <TableContainer
                component={Paper}
                className='expenses-table-container'
                elevation={0}
                sx={{
                  background: 'var(--Grey-White, #FFF)',
                }}
              >
                <Table className='expenses-table'>
                  <TableHead
                    className='expenses-table-head'
                    sx={{ '& th': { border: 'none' } }}
                  >
                    <TableRow>
                      <TableCell
                        width='25%'
                        sx={{ height: '30px', padding: '0px 24px' }}
                      >
                        Type
                      </TableCell>
                      <TableCell
                        width='50%'
                        sx={{ height: '30px', padding: '0px 24px' }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        width='25%'
                        sx={{ height: '30px', padding: '0px 24px' }}
                      >
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className='table-body'>
                    {currentItems.map((expense, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: '1px solid var(--Cipta-30, #EEEEF0)',
                          '& td': {
                            border: 'none',
                          },
                        }}
                      >
                        <TableCell sx={{ padding: '24px' }}>
                          <span>{expense.type}</span>
                        </TableCell>
                        <TableCell sx={{ padding: '24px' }}>
                          <span>{expense.description}</span>
                        </TableCell>
                        <TableCell sx={{ padding: '24px' }}>
                          <span>{expense.amount}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <UsePagination
                count={Math.ceil(expensesData.length / itemsPerPage)}
                onChange={(event, page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
