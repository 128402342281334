import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDriversAsyncThunk } from "../redux/pagesSlices/driverSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function AddTruckDetailsForm({
  handleNext,
  validateForm,
  fleetType,
  values,
  setFieldValue,
  handleSubmit,
}) {
  const { activeOrg } = useSelector((state) => state.workspace);
  const { drivers } = useSelector((state) => state.drivers);
  const dispatch = useDispatch();
  const navigete = useNavigate();

  useEffect(() => {
    const params = {
      status: true,
    };
    dispatch(getDriversAsyncThunk(params));
  }, [dispatch, activeOrg]);

  return (
    <div className="add-truck-stepper-form-wrapper">
      <div className="container">
        <div className="form-wrapper">
          {fleetType === "TRUCK" && (
            <div className="form-header">
              <div className="toggle-btn">
                <span>Company Truck</span>
                <div
                  className={`toggle ${values.isOwner ? "active" : ""}`}
                  onClick={() => setFieldValue("isOwner", !values.isOwner)}
                >
                  <div className="toggle-circle"></div>
                </div>
                <span>Owner-opt truck</span>
              </div>
            </div>
          )}
          <div className="form-fields">
            <div className="row">
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="">
                    {fleetType === "TRUCK" ? "Truck" : "Trailer"} Number *
                  </label>
                  <Field
                    type="text"
                    name="fleetNumber"
                    placeholder="Enter Number"
                  />
                  <ErrorMessage
                    name="fleetNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="">Year *</label>
                  <Field
                    type="text"
                    name="modelYear"
                    placeholder="Enter Year"
                  />
                  <ErrorMessage
                    name="modelYear"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="">Make *</label>
                  <Field as="select" name="fleetMake">
                    <option value="">Select</option>
                    {["Freightliner", "Kenworth", "Volvo"].map((make) => (
                      <option
                        key={make}
                        onClick={() => setFieldValue("fleetMake", make)}
                      >
                        {make}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fleetMake"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="">Model *</label>
                  <Field
                    type="text"
                    name="fleetModel"
                    placeholder="Enter Model"
                  />

                  {/* <Field as="select" name="fleetModel">
                    <option value="">Select</option>
                    {["2023", "2000", "2013"].map((model) => (
                      <option
                        key={model}
                        onClick={() => setFieldValue("fleetModel", model)}
                      >
                        {model}
                      </option>
                    ))}
                  </Field> */}
                  <ErrorMessage
                    name="fleetModel"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="">License Number</label>
                  <Field
                    type="text"
                    name="licenseNumber"
                    placeholder="#########"
                  />
                  <ErrorMessage
                    name="licenseNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="">VIN</label>
                  <Field type="text" name="odometer" placeholder="#########" />
                  <ErrorMessage
                    name="odometer"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="single-field">
                  <label htmlFor="">Assign Driver</label>
                  <Field as="select" name="users">
                    <option value="">Select</option>
                    {drivers?.results?.map((item) => (
                      <option key={item.user_id} value={item.user_id}>
                        {item.email}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="users"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-buttons">
            <div className="single-btn">
              <button type="button" onClick={() => navigete("/fleet")}>
                Cancel
              </button>
            </div>
            <div className="single-btn">
              <button type="button" className="bg-blue" onClick={handleSubmit}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
