import React from "react";
import { Link } from "react-router-dom";
const VerifyEmailPage = () => {
  return (
    <>
      <div className="bg-image fixed-page">
        <div className="container">
          <div className="form-wrapper verify_email" style={{ maxWidth: 464 }}>
            <div className="heading-wrapper">
              <h2 style={{fontWeight:'400'}}>Verify Email</h2>
            </div>

            <div className="verify-title">
              <p>
                A Code has been sent to<span> ruttaba@reenvoy.com </span>
                <br></br>
                Please enter the code below to continue.
              </p>
            </div>

            <div className="nmb-box-flex">
              <div className="nmb-box">
                <div className="nmb">
                  <input type="text" />
                </div>
              </div>

              <div className="nmb-box">
                <div className="nmb">
                  <input type="text" />
                </div>
              </div>

              <div className="nmb-box">
                <div className="nmb">
                  <input type="text" />
                </div>
              </div>

              <div className="nmb-box">
                <div className="nmb">
                  <input type="text" />
                </div>
              </div>
            </div>

            <div className="login-btn flex-row align-items-center">
              <div className="new-signup">
                <p style={{ fontWeight: 500 }}>Sign in with different email</p>
              </div>
              <Link to="/password" className="btn-style">
                Next
              </Link>
            </div>

            <div className="signup-btn-1">
              <p>
                Don’t received the email? <Link to="/">Click to resend</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmailPage;
