import axios from 'axios';

export const clickhouseHelper = async (data) => {
    console.log("🚀 ~ clickhouseHelper ~ data:", data)
    try {
        const response = await axios.post(`${process.env.REACT_APP_BasePath_main}clickhouse`, data);
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw new Error('Failed to insert data');
    }
};

// import React, { useState } from 'react';
// import axios from 'axios';

// function ClickHouse() {
//     const [formData, setFormData] = useState({
//         type: '',
//         data: ''
//     });
//     const [responseMessage, setResponseMessage] = useState('');

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//             const response = await axios.post('http://localhost:3333/clickhouse', formData);
//             setResponseMessage(response.data.message);
//             setFormData({ type: '', data: '' }); // Clear form after submission
//         } catch (error) {
//             setResponseMessage('Failed to insert data');
//             console.error('Error:', error);
//         }
//     };

//     return (
//         <div>
//             <h2>Insert Data into ClickHouse</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>Column 1:(type)</label>
//                     <input
//                         type="text"
//                         name="type"
//                         value={formData.type}
//                         onChange={handleChange}
//                         required
//                     />
//                 </div>
//                 <div>
//                     <label>Column 2:(data)</label>
//                     <input
//                         type="text"
//                         name="data"
//                         value={formData.data}
//                         onChange={handleChange}
//                         required
//                     />
//                 </div>
//                 <button type="submit">Submit</button>
//             </form>
//             {responseMessage && <p>{responseMessage}</p>}
//         </div>
//     );
// }

// export default ClickHouse;
