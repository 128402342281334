import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
    createLoadStopAsyncThunk,
    updateLoadStopAsyncThunk,
} from "../../redux/pagesSlices/loadStopSlice";
import { Spinner } from "react-bootstrap";
import moment from "moment/moment";

const initialState = {
    type: "PICKUP",
    location: "",
    address: "",
    pickupNumber: "",
    phone: "",
    notes: "",
};

export default function EditNewLoadModal({
    params,
    editNewModalShow,
    handleNewEditModalClose,
}) {
    const { loadings } = useSelector((state) => state.loadStop);

    const [loadStop, setLoadStop] = useState(initialState);
    const [appointmentWindow, setAppointmentWindow] = useState({
        start: "",
        end: "",
    });
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoadStop((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            ...loadStop,
            Load: params.id,
            appointmentWindow,
        };
        dispatch(
            createLoadStopAsyncThunk({
                data,
                callBack: () => {
                    setLoadStop(initialState);
                    setAppointmentWindow({
                        start: "",
                        end: "",
                    });
                    handleNewEditModalClose();
                },
            })
        );
    };

    return (
        <Modal
            show={editNewModalShow}
            onHide={handleNewEditModalClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="edit-load-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Load</Modal.Title>
            </Modal.Header>
            <div className="edit-load-modal-body pt-2">
                <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                        <div className="form-section mt-0">
                            <div className="stop-details-section mt-0">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="single-detail-field">
                                            <label htmlFor="">Load #</label>
                                            <input
                                                type="number"
                                                placeholder="333123"
                                                name="pickupNumber"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="single-detail-field">
                                            <label htmlFor="">Rate $</label>
                                            <input
                                                type="number"
                                                placeholder="$15.00"
                                                name="pickupNumber"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="appointment-items">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="single-field">
                                                <label htmlFor="">Load Type</label>
                                                <select
                                                    id=""
                                                    name="location"
                                                >
                                                    <option value="">Dry Van</option>
                                                    <option value="Hasimara">Hasimara</option>
                                                    <option value="Princess_Anne">Princess Anne</option>
                                                    <option value="Baao">Baao</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="single-detail-field">
                                                <label htmlFor="">Weight(lbs)</label>
                                                <input
                                                    type="number"
                                                    placeholder="$15.00"
                                                    name="pickupNumber"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className="stop-details-section">
                                <div className="title">
                                    <strong>Driver & Truck </strong>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="single-field">
                                            <label htmlFor="">Driver</label>
                                            <select
                                                id=""
                                                name="location"
                                            >
                                                <option value="">Mohammed Uzair</option>
                                                <option value="Hasimara">Hasimara</option>
                                                <option value="Princess_Anne">Princess Anne</option>
                                                <option value="Baao">Baao</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="single-field">
                                            <label htmlFor="">Truck #</label>
                                            <select
                                                id=""
                                                name="location"
                                            >
                                                <option value="">032262</option>
                                                <option value="Hasimara">Hasimara</option>
                                                <option value="Princess_Anne">Princess Anne</option>
                                                <option value="Baao">Baao</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="stop-details-section">
                                <div className="title">
                                    <strong>Company</strong>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="single-detail-field">
                                            <label htmlFor="notes">Phone Number</label>
                                            <input
                                                id="notes"
                                                type="number"
                                                placeholder="+1 35375 54 474 "
                                                name="notes"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">

                                        <div className="single-detail-field">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                id="email"
                                                type="email"
                                                placeholder="mohammed@reenvoy.com"
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="appointment-items">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="single-detail-field">
                                                <label htmlFor="company">Company Name</label>
                                                <input
                                                    id="company"
                                                    type="text"
                                                    placeholder="Taylor BCN"
                                                    name="company"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="single-detail-field">
                                                <label htmlFor="notes">Contact Name</label>
                                                <input
                                                    id="notes"
                                                    type="text"
                                                    placeholder="Mohammed Uzair"
                                                    name="notes"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="update-btn" type="submit">
                                    <button disabled={loadings?.createLoadStopAsyncThunk}>
                                        {loadings?.createLoadStopAsyncThunk ? (
                                            <Spinner animation="border" role="status" size="sm" />
                                        ) : (
                                            "Update"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}