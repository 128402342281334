import { useSignIn } from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ForgetPage = () => {
  const { signIn } = useSignIn();
  const [email, setEmail] = useState("");
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signIn.create({
        identifier: email,
        strategy: "reset_password_email_code",
      });

      setIsLinkSent(true);
    } catch (error) {
      setIsLinkSent(false);
      console.error(error.errors);

      if (error.message) {
        toast.error(error.message, {
          autoClose: true,
        });
        throw error.message;
      } else if (error.errors) {
        const messages = error.errors
          .map((err) => {
            if (err.code === "form_conditional_param_value_disallowed") {
              return "The account was registered using Google, so it's not allowed to reset the password.";
            }
            return err.message;
          })
          .join(", ");

        toast.error(messages, {
          autoClose: true,
        });
      } else {
        toast.error("An unexpected error occurred. Please try again.", {
          autoClose: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-image fixed-page" style={{ minHeight: "95vh" }}>
        {!isLinkSent ? (
          <div className="container">
            <form onSubmit={handleSubmit} className="form-wrapper">
              <div className="heading-wrapper">
                <h2>Forget your Password</h2>
                <p>
                  Enter your email address and we send you instructions to reset
                  your password{" "}
                </p>
              </div>
              <div className="login-fields">
                <div className="input-fields">
                  <label htmlFor="email">Enter your email</label>
                  <div className="input-wrapper">
                    <input
                      type="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="login-btn">
                <div className="new-signup">
                  <p>
                    <Link to="/login" style={{ color: "#7F8493" }}>
                      Sign in with different email
                    </Link>
                  </p>
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn-style border-0"
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="form-wrapper" style={{ maxWidth: "500px" }}>
            <div className="heading-wrapper">
              <h2>Check your email</h2>
            </div>

            <div className="verify-title">
              <p>
                We sent a password reset link to <br></br>
                <a href={`mailto:${email}`}> {email} </a>
              </p>
            </div>

            <div className="verify-btn-1">
              <p
                className={`${
                  loading ? "d-flex" : "d-inline"
                }  align-items-center justify-content-center`}
              >
                Don’t received the email?{" "}
                {loading ? (
                  <Spinner size="sm" className="ms-2" />
                ) : (
                  <a className="btn btn-link" onClick={handleSubmit}>
                    Click to resend
                  </a>
                )}
              </p>
            </div>

            <div className="back-btn">
              <Link to="/login">
                {" "}
                <FaArrowLeft /> Back to Login
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ForgetPage;
