import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

export const addFleetExpenseAsyncThunk = createAsyncThunk(
  "fleetExpense/addFleetExpenseAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    console.log("data", data);
    const response = await ApiRequests.addFleetExpense(data);
    if (callBack) callBack(); // Call callback if provided
    return response?.data;
  })
);

export const getFleetExpensesAsyncThunk = createAsyncThunk(
  "fleetExpense/getFleetExpensesAsyncThunk",
  catchAsync(async ({ params, callBack }, { getState }) => {
    const response = await ApiRequests.getFleetExpenses(params);
    if (callBack) callBack();
    console.log("response?.data", response?.data);
    return response?.data;
  })
);

export const getFleetDocAsyncThunk = createAsyncThunk(
  "fleetExpense/getFleetDocAsyncThunk",
  catchAsync(async ({ id }) => {
    const response = await ApiRequests.getFleetDoc(id);
    return response?.data;
  })
);

export const updateFleetExpenseAsyncThunk = createAsyncThunk(
  "fleetExpense/updateFleetExpenseAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch }) => {
    const response = await ApiRequests.updateFleetExpense(id, data);
    if (response.status == 200) {
      toast.success("Expense Updated Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteFleetExpenseAsyncThunk = createAsyncThunk(
  "fleetExpense/deleteFleetExpenseAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch }) => {
    const response = await ApiRequests.deleteFleetExpense(id);
    if (response.status == 204) {
      toast.success("Expense Deleted Successfully!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteMultipleExpensesAsyncThunk = createAsyncThunk(
  "fleet/deleteMultipleExpensesAsyncThunk",
  async ({ expenseIds, fleetId }, { dispatch }) => {
    try {
      const response = await ApiRequests.deleteMultipleFleetExpenses(
        expenseIds
      );
      console.log(
        ":rocket: ~ deleteMultipleExpensesAsyncThunk ~ response:",
        response
      );
      const params = {
        fleetId,
        page: 1,
      };
      await dispatch(getFleetExpensesAsyncThunk({ params }));
      console.log("Fleets successfully deleted and refreshed.");

      return response.data;
    } catch (error) {
      console.error("Error deleting multiple fleets:", error);
      throw error;
    }
  }
);

///////////////////////////////////////////////////

const initialState = {
  fleetExpenses: {
    results: [], // Ensure results array is correctly initialized
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
  fleetDocById: {},
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  categoriesCalculation: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const fleetExpenseSlice = createSlice({
  name: "fleetExpense",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling fulfilled state for getFleetExpensesAsyncThunk
      .addCase(getFleetExpensesAsyncThunk.fulfilled, (state, action) => {
        if (action.meta.arg?.params?.page > 1) {
          // For paginated results, concatenate with existing results
          state.fleetExpenses = {
            ...state.fleetExpenses,
            ...action.payload.expenses,
            results: state.fleetExpenses.results.concat(
              action.payload?.expenses?.results || []
            ),
          };
        } else {
          // For the first page, replace the current results
          state.fleetExpenses = action.payload.expenses;
        }
        state.categoriesCalculation = action.payload.categoriesCalculation;
      })
      .addCase(getFleetDocAsyncThunk.fulfilled, (state, action) => {
        state.fleetDocById = action.payload;
      })
      // Manage loading and error states using addMatcher
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([
            addFleetExpenseAsyncThunk,
            getFleetExpensesAsyncThunk,
            getFleetDocAsyncThunk,
            updateFleetExpenseAsyncThunk,
            deleteMultipleExpensesAsyncThunk,
            deleteFleetExpenseAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default fleetExpenseSlice.reducer;
