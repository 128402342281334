// ProfileDropdown.jsx
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import userProfile from "../assets/images/user-avatar.svg";
import { useAuth, useSession, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import ProfileSetting from "../Pages/ProfileSetting";
import setting from "../assets/images/forms-images/settings.svg";
import logout from "../assets/images/forms-images/log-out.svg";

export default function ProfileDropdown() {
  const { session } = useSession();
  const { signOut } = useAuth();
  const { user, isSignedIn, isLoaded } = useUser();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  // useEffect(() => {
  //   window.reload();
  // }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let loaggedinUserId = localStorage.getItem("user-id");

  const handleSignOut = async () => {
    try {
      // await signOut({ redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/login` });
      localStorage.removeItem("clerk_active_org");
      localStorage.removeItem("active_org");
      localStorage.removeItem("app-access-token");
      localStorage.removeItem("user-id");
      localStorage.removeItem("user");
      await session.end();
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleSignIn = () => {
    navigate("/");
  };
  // if (!isLoaded) {
  //   return null; // Alternatively, return a loading spinner: <div>Loading...</div>
  // }

  // if (!isSignedIn) {
  //   return null; // Alternatively, redirect to login: navigate("/login")
  // }

  const handleNavigate = () => {
    if (!user) navigate("/");
  };

  return (
    <div className="user-profile-dropdown">
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          <div className="user-avatar" onClick={handleNavigate}>
            <img
              src={user?.imageUrl || userProfile}
              alt="avatar"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = userProfile;
              }}
            />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className={`profile-dropdown-menu ${!user && "d-none"}`}>
          <Dropdown.Item
            href="#"
            onClick={handleShow}
            className="dropdown-mail"
          >
            <div className="user-avatar">
              <img
                src={user?.imageUrl || userProfile}
                alt="avatar"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = userProfile;
                }}
              />
            </div>
            <div className="mail-text">
              <span>{user?.fullName || null}</span>
              <p>{user?.primaryEmailAddress?.emailAddress || null}</p>
            </div>
          </Dropdown.Item>
          {user && (
            <Dropdown.Item href="#" onClick={handleShow} className="setting">
              <img src={setting} alt="settings" />
              Settings
            </Dropdown.Item>
          )}
          {user ? (
            <Dropdown.Item onClick={handleSignOut} className="setting">
              <img src={logout} alt="logout" />
              Sign Out
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={handleSignIn} className="setting">
              <img src={logout} alt="logout" />
              Sign in
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <ProfileSetting show={show} handleClose={handleClose} />
    </div>
  );
}
