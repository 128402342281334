import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import lodergif from '../images/nodata.gif'

AOS.init();
export default function NoDataFound({ nodataTitle }) {
  return (
    <div className='no-data-found'>
      <img style={{width:'200px',height:"200px",borderRadius:"50%"}} src={lodergif} alt="Loarding" />
      <h3>{nodataTitle}</h3>
    </div>
  )
}
