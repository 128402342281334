import React, { useState } from "react";
import inviteMember from "../assets/images/invite-member-icon.svg";
import CompanyDocumentsEditModel from "./CompanyDocumentsEditModel";

export default function DocumentPageTopbar({
  searchTerm,
  setSearchTerm,
  onInviteMember,
}) {
  return (
    <div className="teampage-topbar-wrapper">
      <div className="bar-heading mb-2">
        <strong>Documents</strong>
      </div>
      <div className="searchbar-btn">
        <div className="invite-member-btn">
          {/* Pass inviteModalShow and handleModalClose to control the modal */}
        </div>
      </div>
    </div>
  );
}
