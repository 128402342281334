import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FleetSelectionTab from "../components/FleetSelectionTab";
import FleetTabs from "../components/FleetTabs";
import FleetPageTopBar from "../components/FleetPageTopBar";
import Navbar from "../components/Navbar";
import TabsSection from "../components/TabsSection";
import TopTabsComponent from "../components/TopTabsComponent";
import {
  deleteMultipleFleetsAsyncThunk,
  getFleetsAsyncThunk,
} from "../redux/pagesSlices/fleetSlice";
import { parseSortBy } from "../helpers/parse";

export default function FleetPage() {
  const { activeOrg } = useSelector((state) => state.workspace);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fleetNumber, setFleetNumber] = useState("");
  const [fleetMake, setFleetMake] = useState("");
  const [filterOption, setFilterOption] = useState(false);
  const [activeTab, setActiveTab] = useState("TRUCK");
  const [columnSortOrder, setColumnSortOrder] = useState([
    { name: "TRUCK MAKE", isSortable: true, order: "asc", key: "fleetMake" },
    { name: "MODEL YEAR", isSortable: true, key: "modelYear" },
    { name: "TRUCK MODEL", isSortable: true, key: "fleetModel" },
    { name: "ODOMETER", isSortable: true, key: "odometer" },
    { name: "TRUCK NUMBER", isSortable: true, key: "fleetNumber" },
    { name: "LICENSE NUMBER", isSortable: true, key: "licenseNumber" },
    // { name: "STATUS", isSortable: true, key: "status" },
    { name: "Driver Assign", isSortable: false, key: "" },
    // { name: "Action", isSortable: false, key: "" },
  ]);

  useEffect(() => {
    setColumnSortOrder([
      {
        name: `${
          activeTab === "TRUCK"
            ? "TRUCK"
            : activeTab === "TAILOR"
            ? "Trailer"
            : "fleet"
        } NUMBER`,
        isSortable: true,
        key: "fleetNumber",
      },
      { name: "YEAR", isSortable: true, key: "modelYear" },
      {
        name: `MAKE`,
        isSortable: true,
        order: "asc",
        key: "fleetMake",
      },
      { name: `MODEL`, isSortable: true, key: "fleetModel" },
      // { name: "ODOMETER", isSortable: true, key: "odometer" },
      { name: "LICENSE NUMBER", isSortable: true, key: "licenseNumber" },
      // { name: "STATUS", isSortable: true, key: "status" },
      { name: "Driver Assign", isSortable: true, key: "" },
      // { name: "Action", isSortable: false, key: "" },
    ]);
  }, [activeTab]);

  const data = useSelector((state) => state?.fleet?.fleet);
  console.log("🚀 ~ FleetPage ~ data:", data);
  const dispatch = useDispatch();

  const fetchData = async (currentPage) => {
    setIsLoading(true);

    const data = {
      page: currentPage,
      fleetNumber: fleetNumber || null,
      fleetMake: fleetMake || null,
      ...parseSortBy(columnSortOrder),
    };

    if (activeTab == "Deactivated") {
      data.status = "DEACTIVE";
      delete data.fleetType;
    } else {
      data.status = "ACTIVE";
      data.fleetType = activeTab || null;
    }

    if (filterOption) {
      data.sortBy = "fleetMake";
    }

    await dispatch(getFleetsAsyncThunk(data));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [
    dispatch,
    currentPage,
    activeTab,
    activeOrg,
    filterOption,
    columnSortOrder,
  ]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDelete = () => {
    console.log("Delete Rows: ", selectedRows);
    if (selectedRows.length == 0) {
      console.warn("No fleets selected for deletion.");
      return;
    }
    dispatch(
      deleteMultipleFleetsAsyncThunk({ fleetIds: selectedRows, activeTab })
    )
      .unwrap()
      .then(() => {
        console.log("Delete operation successful.");
        setSelectedRows([]);
      })
      .catch((error) => {
        console.error("Delete operation failed:", error);
      });
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    fetchData(currentPage);
  };

  return (
    <>
      <FleetPageTopBar data={data?.results} activeTab={activeTab} />
      <FleetSelectionTab
        selectedCount={selectedRows.length}
        onDelete={handleDelete}
        setSelectedRows={setSelectedRows}
      />
      <FleetTabs
        data={data?.results}
        isLoading={isLoading}
        totalPages={data?.totalPages}
        currentPage={data?.page}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handlePageChange={handlePageChange}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setFleetNumber={setFleetNumber}
        fleetNumber={fleetNumber}
        setFleetMake={setFleetMake}
        fleetMake={fleetMake}
        handleFilterClick={handleFilterClick}
        columns={columnSortOrder}
        setColumns={setColumnSortOrder}
        setFilterOption={setFilterOption}
        filterOption={filterOption}
      />
    </>
  );
}
