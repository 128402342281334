import { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import Loadingdata from "./loadingdata";

const ProtectedRoute = () => {
  const { user, isLoaded, isSignedIn } = useUser();
  console.log("🚀 ~ ProtectedRoute ~ isSignedIn:", isSignedIn)
  const location = useLocation();
  const [hasOrg, setHasOrg] = useState(localStorage.getItem("active_org"));
  
  useEffect(() => {
    const activeOrg = localStorage.getItem("active_org");
    if (activeOrg) {
      if(user){

        if (user?.organizationMemberships?.length) {
          const haveAccess = user.organizationMemberships.find(
          (org) => org.organization.id === activeOrg
        );
        if (!haveAccess) {
          localStorage.setItem(
            "active_org",
            user.organizationMemberships[0].organization.id
          );
          setHasOrg(user.organizationMemberships[0].organization.id);
        }
      } else {
        setHasOrg(null);
      }
    }
    }
  }, [user]);
  
  console.log("🚀 ~ ProtectedRoute ~ hasOrg:", hasOrg)
  if (!isLoaded) {
    return <Loadingdata />;
  }

  if (!isSignedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!hasOrg) {
    return <Navigate to="/workSpace" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
