import { useState } from "react";
import DriversDetailPageTopBar from "../components/DriversDetailTopbar";
import AddDriverProfile from "../components/AddDriverProfile";
import AddDriverDocument from "../components/AddDriverDocument";
import AddDriverEmploy from "../components/AddDriverEmploy";
import activeStepIcon from "../assets/images/active-step.svg";
import completedStep from "../assets/images/completed-step.svg";
import TruckDetailsForm from "../components/TruckDetailsForm";
import AddTruckDetailsForm from "../components/AddTruckDetailsForm";
import AddTruckDocument from "../components/AddTruckDocument";
import AddTruckExpenseForm from "../components/AddTruckExpenseForm";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { createFleetAsyncThunk } from "../redux/pagesSlices/fleetSlice";
import { useDispatch } from "react-redux";
import { createFleetDocAsyncThunk } from "../redux/pagesSlices/fleetDocSlice";
import { toast } from "react-toastify";
import { addFleetExpenseAsyncThunk } from "../redux/pagesSlices/fleetExpenseSlice";
const currentYear = new Date().getFullYear();

// Validation schema for Step 1

const stepOneValidationSchema = Yup.object().shape({
  fleetNumber: Yup.string()
    .matches(/^[0-9]+$/, "Fleet Number must be numeric")
    .required("Fleet Number is required")
    .max(32),
  modelYear: Yup.number("Model Year must be a number")
    .min(1800, "Model Year must be at least 1800")
    .max(new Date().getFullYear(), "Model Year cannot be in the future")
    .required("Model Year is required"),
  fleetMake: Yup.string()
    .required("Fleet Make is required")
    .min(1, "Fleet Make cannot be empty")
    .max(32, "Fleet Make cannot exceed 32 characters"),
  fleetModel: Yup.string()
    .matches(/^[0-9]+$/, "Fleet Model must be numeric")
    .required("Fleet Model is required")
    .max(32),
});

const stepTwoValidationSchema = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      expirationDate: Yup.date()
        .nullable()
        .test(
          "expirationDate-required-if-file",
          "Expiration date is required if file is provided",
          function (value) {
            // Access `file` from the sibling fields using `this.parent`
            const { file } = this.parent;
            return file ? !!value : true; // If file exists, expirationDate must be filled
          }
        ),
      file: Yup.mixed()
        .nullable()
        .test(
          "file-required-if-expirationDate",
          "File is required if expiration date is provided",
          function (value) {
            const { expirationDate } = this.parent;
            return expirationDate ? !!value : true; // If expirationDate exists, file must be filled
          }
        ),
    })
  ),
});

const stepThreeValidationSchema = Yup.object().shape({
  expenses: Yup.object().shape({
    category: Yup.string().required("Category is required"),
    amount: Yup.number()
      .required("Monthly Rate is required")
      .positive("Amount must be positive"),
    description: Yup.string().required("Registration is required"),
    variableExpense: Yup.number().positive("Variable Expense must be positive"),
  }),
});

const skipSchema = Yup.object().shape({
  // No validation for `expenses`
});

export default function FleetStepper() {
  const [activeStep, setActiveStep] = useState(1); // Track the current step
  const [isExpenseActive, setIsExpenseActive] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [expenseValidationSchema, setExpenseValidationSchema] = useState(
    stepThreeValidationSchema
  );

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      fleetType: params.get("tab"),
    };
  };

  // Call the function to get the values
  const { fleetType } = getQueryParams();

  const steps = ["Details", "Documents", "Expenses"]; // Steps for the stepper

  // Function to handle moving to the next step
  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  // Function to handle moving to the previous step
  const handlePrevious = () => {
    if (activeStep > 1) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleExpenseToggle = () => {
    setIsExpenseActive(!isExpenseActive);
  };

  const handleAddFleet = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const { documents, expenses, ...otherValues } = values;

    // Step 1: Create Fleet API Call
    const fleetBody = { ...otherValues, fleetType };
    if (otherValues.users) {
      fleetBody.users = [otherValues.users];
    } else {
      fleetBody.users = [];
    }

    try {
      const fleetResult = await dispatch(
        createFleetAsyncThunk({
          data: fleetBody,
          activeTab: fleetBody.fleetType,
        })
      ).unwrap();

      // Step 2: Create Documents
      if (fleetResult.id) {
        const validDocuments = documents.filter(
          (doc) => doc && doc.expirationDate && doc.file
        );
        const promises = validDocuments.map(async (doc) => {
          const formData = new FormData();

          formData.append("documentName", doc.name);
          formData.append("documentType", "Driver Document");
          formData.append("expiryDate", doc.expirationDate);
          formData.append("referenceId", fleetResult.id);
          formData.append("referenceType", "FLEET");
          formData.append("file", doc.file);

          return dispatch(createFleetDocAsyncThunk({ data: formData }));
        });

        await Promise.all(promises);

        // Step 3: Add Expenses
        const validateExpenses = (expense) =>
          Object.entries(expense).some(
            ([key, value]) =>
              key !== "category" && value !== "" && value !== null
          );

        if (validateExpenses(expenses)) {
          await dispatch(
            addFleetExpenseAsyncThunk({
              data: {
                ...expenses,
                fleetId: fleetResult.id,
              },
            })
          );
        }
      }
    } catch (error) {
      toast.error("Failed to add fleet: " + error.message);
    }
    toast.success("Fleet added successfully");
    resetForm();
    setSubmitting(false);
    navigate("/fleet");
  };

  const handleSubmitNext = (values, { setSubmitting }) => {
    handleNext();
    setSubmitting(false);
  };

  return (
    <div className="add-driver-page-stepper">
      <DriversDetailPageTopBar fleetType={fleetType} />
      <div className="add-driver-stepper-content-wrapper">
        <div className="container">
          <div className="add-driver-stepper-wrapper add-truck-stepper">
            <div className="stepper-header">
              <h2>
                Add {fleetType === "TRUCK" ? "Truck" : "Trailer"} -
                {steps[activeStep - 1]}
              </h2>
              <div className="stepper">
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`step ${
                      activeStep > index + 1
                        ? "completed"
                        : activeStep === index
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="circle">
                      <img
                        src={
                          activeStep > index + 1
                            ? completedStep
                            : activeStep === index
                            ? activeStepIcon
                            : activeStepIcon
                        }
                        alt="icon"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Formik
              initialValues={{
                fleetNumber: "",
                modelYear: "",
                fleetMake: "",
                fleetModel: "",
                licenseNumber: "",
                odometer: "",
                users: "",
                documents: [],
                expenses: {
                  category: isExpenseActive ? "Own" : "Lease",
                  amount: "",
                  description: "",
                },
              }}
              validationSchema={
                activeStep === 1
                  ? stepOneValidationSchema
                  : activeStep === 2
                  ? stepTwoValidationSchema
                  : expenseValidationSchema
              }
              onSubmit={
                activeStep === steps.length ? handleAddFleet : handleSubmitNext
              }
            >
              {({
                isSubmitting,
                setFieldValue,
                values,
                errors,
                validateForm,
                handleSubmit,
                submitForm,
              }) => {
                console.log("🚀 ~ FleetStepper ~ values:", values);
                console.log("🚀 ~ FleetStepper ~ errors:", errors);

                return (
                  <Form
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                  >
                    {activeStep === 1 && (
                      <>
                        <AddTruckDetailsForm
                          handleNext={handleNext}
                          fleetType={fleetType}
                          setFieldValue={setFieldValue}
                          validateForm={validateForm}
                          values={values}
                          handleSubmit={handleSubmit}
                        />
                      </>
                    )}
                    {activeStep === 2 && (
                      <>
                        <AddTruckDocument
                          errors={errors}
                          handlePrevious={handlePrevious}
                          fleetType={fleetType}
                          handleNext={handleNext}
                          setFieldValue={setFieldValue}
                          values={values}
                          handleSubmit={handleSubmit}
                        />
                      </>
                    )}
                    {activeStep === 3 && (
                      <>
                        <AddTruckExpenseForm
                          handlePrevious={handlePrevious}
                          handleNext={handleNext}
                          isSubmitting={isSubmitting}
                          setFieldValue={setFieldValue}
                          values={values}
                          validateForm={validateForm}
                          submitForm={submitForm}
                          handleSubmit={handleSubmit}
                          setExpenseValidationSchema={
                            setExpenseValidationSchema
                          }
                          skipSchema={skipSchema}
                          stepThreeValidationSchema={stepThreeValidationSchema}
                          handleExpenseToggle={handleExpenseToggle}
                          isExpenseActive={isExpenseActive}
                        />
                      </>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
