import React, { useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import tableIcon from "../assets/images/table-icon.svg";
import edit from "../assets/images/table-edit.svg";
import assign from "../assets/images/assignments.svg";
import deleteicon from "../assets/images/table-delete.svg";
import truck from "../assets/images/truck-return.svg";
import { GoPackage } from "react-icons/go";
import NoDataFound from "./nodatafound";
import Loadingdata from "./loadingdata";

export default function LoadListView({ data }) {
  const navigate = useNavigate();
  useEffect(() => {
    console.log("🚀 ~ LoadListView ~ data:", data);
  }, [data]);

  const statusClasses = {
    Schedule: "pink",
    "In Progress": "warning",
    Completed: "success",
  };

  return (
    <div>
      <div className="table_box">
        <div className="Form-table">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th className="flex-box-table">
                    <Form.Check aria-label="option 1" /> <strong> LOAD</strong>
                    <img src={tableIcon} alt="icon" />
                  </th>
                  <th>
                    <div>
                      <strong>CUSTOMER</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>DRIVER</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>PICK UP</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>DROP OFF</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>RATE</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>DOCUMENTS</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div>
                      <strong>STATUS</strong>
                      <img src={tableIcon} alt="icon" />
                    </div>
                  </th>
                  <th>
                    <div className="justify-content-end">
                      <strong>ACTION</strong>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0
                  ? data?.map((item, i) => (
                      <tr
                        key={i}
                        onClick={() => navigate(`/load-details/${item?.id}`)}
                      >
                        <td onClick={(e) => e.stopPropagation()}>
                          <Form.Check aria-label="option 1" />
                          <div className="username_data">
                            <div className="icon_box square">
                              {/* <img src="/images/profile.png" alt="user-icon" /> */}
                              <GoPackage />
                            </div>
                            <div className="dashboard_side-bar-footer_usernames">
                              <span>{item?.loadId || "N/A"}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          {item?.customer?.name || item?.customer?.username}
                        </td>
                        <td>
                          <div className="username_data">
                            <div className="icon_box">
                              {/* <img src="/images/profile.png" alt="user-icon" /> */}
                            </div>
                            <div className="dashboard_side-bar-footer_usernames">
                              <span>{item?.driver}</span>
                            </div>
                          </div>
                        </td>
                        <td>{item?.location || "N/A"}</td>
                        <td>{item?.dropOff?.location || 0}</td>
                        <td>${item?.rate}</td>
                        <td>
                          <img
                            src="/images/fileicon.png"
                            alt="icon"
                            className="file_icon"
                          />
                        </td>
                        <td>
                          {/* <div className="status pink">{item?.status}</div>{" "}
                        <div className="status pink">Schedule</div>{" "}
                        <div className="status warning">In Progress</div>{" "}
                        <div className="status success">Completed</div>{" "} */}
                          <div className={`status `}>
                            <span className={`${statusClasses[item?.status]}`}>
                              {item?.status}
                            </span>
                          </div>
                        </td>
                        <td className="dropdown_td">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              className="btn btn-light btn-sm calculator-list-dropdown"
                            >
                              <BsThreeDots />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <FiEdit /> Edit
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-1">
                                <FaCloudDownloadAlt /> Download
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
