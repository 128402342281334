// Pricing.jsx
import React from "react";
import PricingToggleButtons from "../components/PricingToggleButtons";
import { Box, Button, styled } from "@mui/material";
import check from "../assets/images/check.svg";

const StyledButton = styled(Button)(({ theme, customvariant }) => ({
  color: "var(--Global-Neutral-Grey-1000, #4F4D55)",
  fontFamily: "var(--Font-Poppins, Poppins)",
  fontSize: "0.875rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textTransform: "none",
  width: "100%",
  "&.MuiButton-root": {
    borderRadius: "6px",
    boxShadow: "0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)",
  },
  ...(customvariant === "standard" && {
    border: "1px solid var(--Global-Neutral-Grey-500, #E6E6E6)",
    background: "#E6E6E6",
    "&:hover": {
      background: "#D1D1D1",
    },
  }),
  ...(customvariant === "primary" && {
    background: theme.palette.primary.main,
    color: "#FFFFFF",
    border: "none",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  }),
}));

const CustomMuiButton = ({ variant = "standard", children, ...props }) => {
  return (
    <StyledButton variant="contained" customvariant={variant} {...props}>
      {children}
    </StyledButton>
  );
};

export default function Pricing() {
  return (
    <div className="pricing-container">
      <div className="button-container d-flex justify-content-center">
        <PricingToggleButtons />
      </div>
      <div className="price-wrapper">
        <div className="row row-with-gap">
          <div className="col-md-6 mb-md-4">
            <div className="pricing-card">
              <div className="d-flex align-items-center justify-content-between mb-20">
                <span className="card-title">Standard</span>
                <span className="card-badge invisible">Most Populer</span>
              </div>
              <div className="card-price-wrapper">
                <div className="mx-auto mb-2">
                  <span className="card-price me-1">$9.99</span>
                  <span className="card-price-period">/mo</span>
                </div>
                <div className="bottom-text">
                  Grow your business with the scalable <br /> starter solution
                </div>
              </div>
              <Box className="mb-40">
                <CustomMuiButton variant="standard">
                  Active Plan
                </CustomMuiButton>
              </Box>
              <div className="features">
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">100GB user storage</span>
                </div>
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">
                    Custom metrics & timeframes
                  </span>
                </div>
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">
                    Google Sheets integration
                  </span>
                </div>
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">
                    Live chat and email support
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="pricing-card">
              <div className="d-flex align-items-center justify-content-between mb-20">
                <span className="card-title">Premium</span>
                <span className="card-badge">Most Populer</span>
              </div>
              <div className="card-price-wrapper">
                <div className="mx-auto mb-2">
                  <span className="card-price me-1">$20.00</span>
                  <span className="card-price-period">/mo</span>
                </div>
                <div className="bottom-text">
                  Grow your business with the scalable <br /> starter solution
                </div>
              </div>
              <Box className="mb-40">
                <CustomMuiButton variant="primary">Get started</CustomMuiButton>
              </Box>
              <div className="features">
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">100GB user storage</span>
                </div>
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">
                    Custom metrics & timeframes
                  </span>
                </div>
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">
                    Google Sheets integration
                  </span>
                </div>
                <div className="feature d-flex align-items-center gap-2">
                  <span className="icon-container">
                    <img src={check} alt="" className="icon" />
                  </span>
                  <span className="feature-text">
                    Live chat and email support
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
