import tableIcon from "../assets/images/table-icon.svg";
import stopIcon from "../assets/images/stop-icon.svg";
import boxIcon from "../assets/images/box.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import tableEdit from "../assets/images/table-edit.svg";
import tableDelete from "../assets/images/table-delete.svg";
import DeleteLoadModal from "./Modals/DeleteLoadModal";
import DeleteLoadModalTable from "./Modals/DeleteLoadModalTable";
import EditLoadModalTable from "./Modals/EditLoadModalTable";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import EditLoadModal from "./Modals/EditLoadModal";
import EditNewLoadModalTable from "./Modals/EditNewLoadModalTable";

export default function TableComponent({ loadStop, params }) {
  console.log("🚀 ~ TableComponent ~ loadStop:", loadStop?.results);
  const [deleteModalShow, deleteSetModalShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [editNewModalShow, editSetNewModalShow] = useState(false);

  const handleDeleteModalOpen = (id) => {
    setDeleteId(id);
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
    setDeleteId("");
  };

  const [editModalShow, editSetModalShow] = useState(false);

  const handleEditModalOpen = () => {
    editSetModalShow(true);
  };

  const handleEditModalClose = () => {
    editSetModalShow(false);
  };

  const handleNewEditModalOpen = () => {
    editSetNewModalShow(true);
  };

  const handleNewEditModalClose = () => {
    editSetNewModalShow(false);
  };
  return (
    <div className="table-wrapper">
      <div
        className="container-fluid"
        style={{ padding: 0, overflowX: "auto" }}
      >
        <table>
          <thead>
            <tr>
              <th>
                <Form.Check aria-label="option 1" />
              </th>
              <th style={{ width: "140px" }}>
                <div className="stop-item">
                  <strong>STOP</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>
              <th>
                <div>
                  <strong>TYPE</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>
              <th style={{ width: "270px" }}>
                <div>
                  <strong>ADDRESS</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>
              <th>
                <strong>APPOINTMENT WINDOW</strong>
              </th>
              <th>
                <div>
                  <strong>DISTANCE</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>
              <th>
                <div>
                  <strong>IN-PROGRESS TIME</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>
              <th>
                <strong>COMPLETED TIME</strong>
              </th>
              <th>
                <div>
                  <strong>STATUS</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>
              <th className="text-end">
                <strong>ACTIONS</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>
                <Form.Check aria-label="option 1" />
              </td>
              <td>
                <div className="stop-item">
                  <div className="icon">
                    <img src={stopIcon} alt="" className="stop-icon" />
                  </div>
                  <span>1</span>
                </div>
              </td>
              <td>
                <div>
                  <span>PICKUP</span>
                </div>
              </td>
              <td>
                <div className="address-col">
                  <strong>a</strong>
                  <p>
                    {" "}
                    8282 West South Carolina Avenue Fenwick Island, Delaware
                  </p>
                </div>
              </td>
              <td>
                <div>
                  <span>07/03/2024, 14:29</span>
                </div>
              </td>
              <td>
                <div>
                  <span>--</span>
                </div>
              </td>
              <td>
                <div>
                  <span>07/03/2024, 22:03</span>
                </div>
              </td>
              <td>
                <div>
                  <span>07/03/2024, 22:12</span>
                </div>
              </td>
              <td>
                <div className="status">
                  <span>Complete</span>
                </div>
              </td>
              <td>
                <div className="table-dropdown">
                  <DropdownButton id="dropdown-basic-button" title="...">
                    <Dropdown.Item href="">
                      <div
                        className="single-dropdown"
                        onClick={handleNewEditModalOpen}
                      >
                        <EditNewLoadModalTable
                          params={params}
                          editNewModalShow={editNewModalShow}
                          handleNewEditModalClose={handleNewEditModalClose}
                        />
                      </div>
                      {/* <EditLoadModal
                        editModalShow={editModalShow}
                        handleEditModalClose={handleEditModalClose}
                      /> */}
                      {/* <EditNewLoadModalTable
                          params={params}
                          editNewModalShow={editNewModalShow}
                          handleNewEditModalClose={handleNewEditModalClose}
                        /> 
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div
                        className="single-dropdown"
                        onClick={handleDeleteModalOpen}
                      >
                        <img src={tableDelete} alt="icon" /> Delete
                      </div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </td>
            </tr> */}
              {/*  <tr>
              <td>
                <Form.Check aria-label="option 1" />{" "}
              </td>
              <td>
                <div className="stop-item">
                  <div className="box-icon">
                    <img src={boxIcon} alt="" />
                  </div>
                  <span>1</span>
                </div>
              </td>
              <td>
                <div>
                  <span>DELIVERY</span>
                </div>
              </td>
              <td>
                <div className="address-col">
                  <strong>b</strong>
                  <p>4892 West Kellogg Boulevard Saint Paul, Minnesota</p>
                </div>
              </td>
              <td>
                <div>
                  <span>07/03/2024, 14:29</span>
                </div>
              </td>
              <td>
                <div>
                  <span>1,220 mi 18 hours 44 mins</span>
                </div>
              </td>
              <td>
                <div>
                  <span>07/03/2024, 22:03</span>
                </div>
              </td>
              <td>
                <div>
                  <span>07/03/2024, 22:12</span>
                </div>
              </td>
              <td>
                <div className="status">
                  <span>Complete</span>
                </div>
              </td>
              <td>
                <div className="table-dropdown">
                  <DropdownButton id="dropdown-basic-button" title="...">
                    <Dropdown.Item href="">
                      <div
                        className="single-dropdown"
                        onClick={handleNewEditModalOpen}
                      >
                        <EditNewLoadModalTable
                          params={params}
                          editNewModalShow={editNewModalShow}
                          handleNewEditModalClose={handleNewEditModalClose}
                        />
                      </div>
                      {/* <EditLoadModal
                        editModalShow={editModalShow}
                        handleEditModalClose={handleEditModalClose}
                      /> 

                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div
                        className="single-dropdown"
                        onClick={handleDeleteModalOpen}
                      >
                        <img src={tableDelete} alt="icon" /> Delete
                      </div>
                      <DeleteLoadModal
                        handleDeleteModalClose={handleDeleteModalClose}
                        deleteModalShow={deleteModalShow}
                      />
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </td>
            </tr>*/}

            {loadStop &&
              loadStop?.results &&
              loadStop?.results?.map((item, i) => {
                return (
                  <tr key={item?.id}>
                    <td>
                      <Form.Check aria-label="option 1" />{" "}
                    </td>
                    <td>
                      <div className="stop-item">
                        <div className="box-icon">
                          <img src={boxIcon} alt="" />
                        </div>
                        <span>{i + 1}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item?.type || "N/A"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <strong>{item?.location || "N/A"}</strong>
                        <p>{item?.address || "N/A"}</p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {item?.appointmentWindow
                            ? `${new Date(
                              item.appointmentWindow.start
                            ).toLocaleString()} - ${new Date(
                              item.appointmentWindow.end
                            ).toLocaleString()}`
                            : "N/A"}
                        </span>{" "}
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item?.distance || "N/A"}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item?.inProgressTime || "N/A"}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item?.completedTime || "N/A"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span>{item?.status || "N/A"}</span>
                      </div>
                    </td>
                    <td>
                      <div className="table-dropdown">
                        <DropdownButton id="dropdown-basic-button" title="...">
                          <Dropdown.Item href="">
                            {/* <EditLoadModalTable
                              params={params}
                              editBody={item}
                              editModalShow={editModalShow}
                              handleEditModalClose={handleEditModalClose}
                            /> */}
                            <div
                              className="single-dropdown"
                              onClick={handleNewEditModalOpen}
                            >
                              <EditNewLoadModalTable
                                params={params}
                                editNewModalShow={editNewModalShow}
                                handleNewEditModalClose={handleNewEditModalClose}
                              />
                            </div>
                            <EditNewLoadModalTable
                              params={params}
                              editNewModalShow={editNewModalShow}
                              handleNewEditModalClose={handleNewEditModalClose}
                            />
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className="single-dropdown"
                              onClick={() => handleDeleteModalOpen(item.id)}
                            >
                              <img src={tableDelete} alt="icon" /> Delete
                            </div>
                            {deleteModalShow && deleteId && (
                              <DeleteLoadModal
                                deleteId={deleteId}
                                params={params}
                                handleDeleteModalClose={handleDeleteModalClose}
                                deleteModalShow={deleteModalShow}
                              />
                            )}
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
