// ** Reducers Imports
import auth from "./pagesSlices/authSlice";
import model from "./layoutSlices/modelSlice";
import user from "./pagesSlices/userSlice";
import load from "./pagesSlices/loadSlice.js";
import loadStop from "./pagesSlices/loadStopSlice.js";
import fleet from "./pagesSlices/fleetSlice.js";
import workspace from "./pagesSlices/workspaceSlice.js";
import crudExampleSlice from "./pagesSlices/crudExampleSlice.js";
import userDoc from "./pagesSlices/userDocSlice.js";
import fleetDoc from "./pagesSlices/fleetDocSlice.js";
import fleetExpense from "./pagesSlices/fleetExpenseSlice.js";
import driverEmp from "./pagesSlices/driverEmpSlice.js";
import error from "./errors/handleErrorsAndPayloads";
import { combineReducers } from "@reduxjs/toolkit";
import drivers from "./pagesSlices/driverSlice.js";

const rootReducer = combineReducers({
  user,
  error,
  auth,
  model,
  load,
  loadStop,
  fleet,
  workspace,
  crudExampleSlice,
  userDoc,
  fleetDoc,
  fleetExpense,
  driverEmp,
  drivers,
});

export default rootReducer;
