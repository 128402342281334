import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    modalTitleClass: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '1.25rem',
      fontWeight: 600,
      color: '#002846',
    },
    formTitleClass: {
      color: 'var(--Primary-1100, #002846)',
      fontFamily: 'var(--Font-Poppins, Poppins)',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#0A8FEF',
    },
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        sizeSmall: {
          '&.MuiRadio-sizeSmall': {
            padding: '0px 9px',
          },
          '& svg': {
            width: '16px',
            height: '16px',
          },
          color: '#DFDFE2',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#002846',
          fontFamily: 'Poppins',
          fontSize: '0.75rem',
          fontWeight: '500',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'var(--Primary-1100, #002846)',
          fontFamily: 'var(--Font-Poppins, Poppins)',
          fontSize: '0.75rem',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          marginBottom: '8px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            padding: '13.5px 16px',
            height: '50px',
            '& input.MuiOutlinedInput-input': {
              padding: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#DFDFE2',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#DFDFE2',
              borderWidth: '1px',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '50px',
          borderRadius: '8px',
          color: 'var(--Grey-500, #AEB2BC)',
          fontFamily: 'var(--Font-Poppins, Poppins)',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DFDFE2',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DFDFE2',
            borderWidth: '1px',
          },
          '& svg': {
            color: '#7F8493',
          },
          '& .MuiSelect-select': {
            padding: '13px 16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            lineHeight: '1.25rem',
            fontFamily: 'Inter, sans-serif',
            fontSize: '0.875rem',
            fontWeight: 475,
            letterSpacing: '-0.00313rem',
            padding: '8px 12px',
            minWidth: 'fit-content',
          },
        },
      },
    },
    customButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            lineHeight: '1.25rem',
            fontFamily: 'Inter, sans-serif',
            fontSize: '0.875rem',
            fontWeight: 475,
            letterSpacing: '-0.00313rem',
            padding: '8px 12px',
            minWidth: 'fit-content',
          },
        },
      },
    },
  },
})
