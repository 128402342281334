import React, { useEffect, useState } from "react";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInUserAsyncThunk } from "../redux/pagesSlices/userSlice";
import { useSignIn } from "@clerk/clerk-react";
import { Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import logo from "../assets/images/forms-images/image 1.png";

const LoginPage = () => {
  const { loadings } = useSelector((state) => state.user);
  const [isShowPass, setIsShowPass] = useState(false);
  const { signIn, setActive } = useSignIn();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Retrieve email from localStorage
  const paramEmail = localStorage.getItem("loginEmail");
  const [initialEmail, setInitialEmail] = useState("");

  useEffect(() => {
    try {
      // Check if there's an email in localStorage
      if (paramEmail) {
        setInitialEmail(paramEmail);
      }
    } catch (error) {
      console.error("Error parsing email from localStorage:", error);
    }
  }, [paramEmail]);

  // Define Yup validation schema
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .max(50),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
      .max(50),
    remember: Yup.boolean(),
  });

  // Initial form values
  const initialValues = {
    email: initialEmail,
    password: "",
    remember: true, // Default checked
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const { email, password, remember } = values;

    dispatch(
      signInUserAsyncThunk({
        data: { email, password },
        signIn,
        setActive,
        callBack: async () => {
          // Remove email from localStorage after successful sign-in
          localStorage.removeItem("loginEmail");
          navigate("/home");
        },
        remember,
      })
    ).finally(() => {
      setSubmitting(false);
    });
    localStorage.removeItem("loginEmail");
  };

  return (
    <div className="bg-image fixed-page">
      <div className="container">
        <div className="form-wrapper">
          <div className="form-logo mb-4">
            <Link to={"/"}>
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="heading-wrapper">
            <h2>Welcome ReEnvoy</h2>
            <p>Enter your password to continue to ReEnvoyWeb</p>
          </div>

          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="login-fields">
                  {/* Email Field */}
                  <div className="input-fields">
                    <label htmlFor="email">Enter your email</label>
                    <div className="input-wrapper">
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="form-control"
                      />
                    </div>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>

                  {/* Password Field */}
                  <div className="input-fields with-icon">
                    <label htmlFor="password">Password</label>
                    <div className="input-wrapper">
                      <Field
                        type={isShowPass ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder=".........."
                        className="form-control"
                      />
                      <button
                        className="btn btn-link p-0"
                        type="button"
                        onClick={() => setIsShowPass(!isShowPass)}
                      >
                        {isShowPass ? (
                          <FiEye className="input-icon" />
                        ) : (
                          <FiEyeOff className="input-icon" />
                        )}
                      </button>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                {/* Remember Me and Forgot Password */}
                <div className="checkbox-section">
                  <div className="remember">
                    <div className="checkbox-wrapper">
                      <input type="checkbox" id="remember" name="remember" />
                    </div>
                    <label htmlFor="remember">Remember me</label>
                  </div>
                  <div className="forgot">
                    <Link to="/forget">Forgot Password?</Link>
                  </div>
                </div>

                {/* Submit Button and Sign Up Link */}
                <div className="login-btn">
                  <div className="new-signup">
                    <p>
                      Don't have an account?{" "}
                      <Link to="/sign-up">
                        Sign up <FaArrowRight />
                      </Link>
                    </p>
                  </div>
                  <button
                    className="btn-style border-0"
                    type="submit"
                    disabled={isSubmitting || loadings.signInUserAsyncThunk}
                  >
                    {isSubmitting || loadings.signInUserAsyncThunk ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Login"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
