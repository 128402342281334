import React from "react";
import { Link } from "react-router-dom";
import qrCode from "../assets/images/forms-images/Frame.svg";
import iphone from "../assets/images/forms-images/pngwing.com (1).png";
import google from "../assets/images/forms-images/pngwing.com 1.png";
import logo from "../assets/images/forms-images/image 1.png";
const ReEnovy = () => {
  return (
    <>
      <div
        className="bg-image fixed-page colored"
        style={{ minHeight: "100vh" }}
      >
        <div className="container">
          <div className="form-wrapper text-center">
            <img
              src={logo}
              alt="logo"
              style={{ maxWidth: 230, width: "100%", marginBottom: 36 }}
            />
            <div className="heading-wrapper">
              <h2 style={{ fontWeight: 500 }}>Welcome to ReEnvoy! </h2>
            </div>

            <div className="verify-title">
              <p>Your workspace is all set now. Let’s explore!</p>
            </div>

            <div className="donepage-btn mb-0">
              <Link className="btn-style" to={"/home"}>
                Explore ReEnvoy
              </Link>
            </div>
            {/* <div className="qr-code">
              <img src={qrCode} alt="QR Code" />
            </div>

            <div className="enovy-btn">
              <button>
                <img src={iphone} />
              </button>
              <button>
                <img src={google} />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReEnovy;
