const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV === "development";
export const app_mode = localhost
  ? "localhost"
  : development
    ? "development"
    : "production";

export const appPath =
  process.env.REACT_APP_BasePath ??
  (localhost
    ? "http://localhost:3333/reenovy/api/"
    : development
      ? "http://192.168.18.66:3333/reenovy/api/"
      : "https://xyc.com/reenovy/api/"
  );

export const APIurls = {
  // auth
  login: "auth/login",
  refreshTokens: "auth/refresh-tokens",
  register: "auth/register",
  logout: "auth/logout",
  authenticate: "auth/authenticate",

  users: "users",
  workSpaces: "workspace",
  invite: "invite",
  loads: "load",
  loadStops: "loadstop",
  fleets: "fleet",
  deleteMultipleFleets: "fleet/deleteMultipleFleets",

  userDoc: "userDoc",
  drivers: "driver",
  driverDoc: "driverDoc",
  fleetDoc: "fleetDoc",
  expense: "expense",
  
  driverEmp: "driverEmployement",
};
