import { Link, useLocation } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";


export default function Sidebar( {sideBarOpen, sidebarOpenHandler}) {
  const location = useLocation();

  return (
    <div className={`sidebar-wrapper admin-sidebar ${sideBarOpen ? 'active' : ''}`}>
      <div className="sidebar-list">
        <ul>
          <li>
            <Link
              to={"/admin/company-profile"}
              className={
                location.pathname === "/admin/company-profile" ? "active" : ""
              }
            >
              Company
            </Link>
          </li>
          {/* <li><Link to={'/admin/integration'} >Integrations</Link></li> */}
          <li>
            <Link
              to={"/admin/compliance"}
              className={
                location.pathname === "/admin/compliance" ? "active" : ""
              }
            >
              Documents
            </Link>
          </li>
          {/* <li><Link to={'/admin/billing'}>Billing</Link></li> */}
          <li>
            <Link
              to={"/admin/teams"}
              className={location.pathname === "/admin/teams" ? "active" : ""}
            >
              Teams
            </Link>
          </li>
          <li>
            <Link
              to={"/admin/invitations"}
              className={
                location.pathname === "/admin/invitations"
                  ? "active"
                  : ""
              }
            >
              Invitations
            </Link>
          </li>
        </ul>
      </div>
      <div className="open-arrow">
        <MdKeyboardArrowRight onClick={sidebarOpenHandler}/>
      </div>
    </div>
  );
}

