import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";

export const createFleetAsyncThunk = createAsyncThunk(
  "fleet/createFleetAsyncThunk",
  catchAsync(async ({ data, callBack, activeTab }, { dispatch, getState }) => {
    console.log("🚀 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.createFleet(data);
    await dispatch(getFleetsAsyncThunk({ fleetType: activeTab }));
    console.log("🚀 ~ catchAsync ~ response:", response.data);
    // if (response.status === 201) {
    //   toast.success(
    //     `${data.fleetType === "TRUCK" ? "Truck" : "Tailor"} added Successfully!`
    //   );
    // }
    if (callBack) callBack();
    return response?.data;
  })
);

export const getFleetsAsyncThunk = createAsyncThunk(
  "fleet/getFleetsAsyncThunk",
  catchAsync(async (params) => {
    console.log("🚀 ~ catchAsync ~ params:", params);
    const response = await ApiRequests.getFleets(params);
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const getFleetAsyncThunk = createAsyncThunk(
  "fleet/getFleetAsyncThunk",
  catchAsync(async ({ id }) => {
    console.log("🚀 ~ catchAsync ~ id:", id);
    const response = await ApiRequests.getFleet(id);
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const updateFleetAsyncThunk = createAsyncThunk(
  "fleet/updateFleetAsyncThunk",
  catchAsync(async ({ id, data, activeTab, callBack }, { dispatch }) => {
    console.log("🚀 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.updateFleet({ id, data });
    console.log("🚀 ~ catchAsync ~ response:", response);
    if (response.status === 200) {
      toast.success("Fleet updated Successfully!");
    }

    if (callBack) callBack();
    await dispatch(
      getFleetAsyncThunk({
        id,
        populate: true,
      })
    );
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const deleteFleetAsyncThunk = createAsyncThunk(
  "fleet/deleteFleetsAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    const response = await ApiRequests.deleteFleet(data);
    if (response.status == 204) {
      toast.success("Delete Fleet Successfully!");
    }
    // await dispatch(getFleetsAsyncThunk({ fleetType: activeTab }))
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);
export const deleteMultipleFleetsAsyncThunk = createAsyncThunk(
  "fleet/deleteMultipleFleetsAsyncThunk",
  async ({ fleetIds, activeTab }, { dispatch }) => {
    try {
      const response = await ApiRequests.deleteMultipleFleets(fleetIds);
      console.log(
        ":rocket: ~ deleteMultipleFleetsAsyncThunk ~ response:",
        response
      );

      await dispatch(
        getFleetsAsyncThunk({
          page: 1,
          fleetType: activeTab,
        })
      );
      console.log("Fleets successfully deleted and refreshed.");

      return response.data;
    } catch (error) {
      console.error("Error deleting multiple fleets:", error);
      throw error;
    }
  }
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  fleet: {
    results: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
  },
  fleetDocuments: {
    results: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
  },
  fleetById: {},
  fleetDocumentById: {},

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //

      .addCase(getFleetsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.fleet = {
            ...action.payload,
            results: state?.results?.concat(action?.payload?.results),
          };
        } else {
          state.fleet = action.payload;
        }
      })

      .addCase(getFleetAsyncThunk.pending, (state, action) => {
        state.fleetById = {};
      })
      .addCase(getFleetAsyncThunk.fulfilled, (state, action) => {
        state.fleetById = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors fleeting and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createFleetAsyncThunk,
            getFleetsAsyncThunk,
            getFleetAsyncThunk,
            updateFleetAsyncThunk,
            deleteFleetAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setSearchValue, setCategoryValue, setOrderValue } =
  fleetSlice.actions;

export default fleetSlice.reducer;
