import React, { useState, useEffect } from "react";
import sortIcon from "../assets/images/table-icon.svg";
import LoadingData from "../components/loadingdata.jsx";
import NoDataFound from "../components/nodatafound.jsx";

import ResendInviteModal from "../components/Modals/ResendInviteModal.js";
import { useDispatch, useSelector } from "react-redux";
import { getInvitationsAsyncThunk } from "../redux/pagesSlices/workspaceSlice.js";
import { parseSortBy } from "../helpers/parse.js";

export default function Invitations() {
  const { activeOrg, invites, loadings, errors } = useSelector(
    (state) => state.workspace
  );

  const [resendModalShow, setResendModalShow] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [columnSortOrder, setColumnSortOrder] = useState([
    { name: "Email", isSortable: true, order: "asc", key: "email" },
    { name: "Role", isSortable: true, key: "role" },
    { name: "Status", isSortable: true, key: "status" },
    { name: "Date", isSortable: true, key: "createdAt" },
    { name: "Action", isSortable: false, key: "" },
  ]);

  const dispatch = useDispatch();

  // Debounce mechanism to prevent excessive API calls
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const params = { populate: "userId", ...parseSortBy(columnSortOrder) };
      dispatch(getInvitationsAsyncThunk(params));
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [dispatch, activeOrg, columnSortOrder]);

  const handleResendModalOpen = (email, role) => {
    setEmail(email);
    setRole(role);
    setResendModalShow(true);
  };

  const handleResendModalClose = () => {
    setResendModalShow(false);
    setEmail("");
    setRole("");
    // Refetch users after deletion to refresh the list
    dispatch(getInvitationsAsyncThunk());
  };

  return (
    <div className="teams-page-wrapper">
      <div className="teampage-topbar-wrapper">
        <div className="bar-heading">
          <strong>Invitations</strong>
        </div>
      </div>

      <div className="table-wrapper">
        {loadings?.getInvitationsAsyncThunk && <LoadingData />}
        {errors?.getInvitationsAsyncThunk && (
          <div className="p-3 text-danger">
            Something went wrong while getting invitations!
          </div>
        )}
        {!loadings?.getInvitationsAsyncThunk &&
          !errors?.getInvitationsAsyncThunk && (
            <>
              {invites && invites?.results && invites?.results?.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      {columnSortOrder.map((item, index) => (
                        <th
                          onClick={() => {
                            if (!item.isSortable) return;
                            setColumnSortOrder((prev) => {
                              const newColumns = prev.map((col) => {
                                if (col.key === item.key) {
                                  return {
                                    ...col,
                                    order: col.order === "asc" ? "desc" : "asc",
                                  };
                                }
                                return {
                                  ...col,
                                  order: undefined,
                                };
                              });
                              return newColumns;
                            });
                          }}
                        >
                          <div>
                            <strong>{item.name}</strong>
                            {item.isSortable && (
                              <img src={sortIcon} alt="Sort Icon" />
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {invites?.results?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div>
                            <span>{item.email || "N/A"}</span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>{item.role || "N/A"}</span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>{item.status || "N/A"}</span>
                          </div>
                        </td>

                        <td>
                          <div>
                            <span>
                              {item.createdAt
                                ? new Date(item.createdAt).toLocaleDateString()
                                : "N/A"}
                            </span>
                          </div>
                        </td>
                        <td>
                          {(item.status === "pending" ||
                            item.status === "rejected") && (
                            <div>
                              <span
                                className="remove-action"
                                onClick={() =>
                                  handleResendModalOpen(item.email, item.role)
                                }
                              >
                                Resend
                              </span>
                              {resendModalShow && (
                                <ResendInviteModal
                                  resendModalShow={resendModalShow}
                                  handleResendModalClose={
                                    handleResendModalClose
                                  }
                                  email={email}
                                  role={role}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound nodataTitle="No invitations found." />
              )}
            </>
          )}
      </div>
    </div>
  );
}
