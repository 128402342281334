import React, { useState } from "react";
import logo from "../assets/images/image 1.png";
import apple from "../assets/images/ic_outline-apple.png";
import google from "../assets/images/Group.png";
import { FaArrowRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useSession, useSignIn, useSignUp } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { signInWithGoogleAsyncThunk } from "../redux/pagesSlices/userSlice";
import { Spinner } from "react-bootstrap";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const { signUp, setActive } = useSignUp();
  const [email, setEmail] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const { userId } = useAuth();
  const { signIn } = useSignIn();
  const dispatch = useDispatch();

  const handleEmailSet = () => {
    localStorage.setItem("loginEmail", email);
    navigate("/login");
  };
  const handleSignInWithEmail = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    try {
      const signInAttempt = await signIn.create({ identifier: email });

      const emailFactor = signInAttempt.supportedFirstFactors.find(
        (factor) => factor.strategy === "email_link"
      );
      const emailAddressId = emailFactor?.emailAddressId;

      if (emailAddressId) {
        await signInAttempt.prepareFirstFactor({
          strategy: "email_link",
          emailAddressId: emailAddressId,
          redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/home`,
        });

        toast.success("Check your email for a magic link to sign in!");
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else if (error.errors) {
        console.log(error.errors);
        const messages = error.errors.map((error) => error.message).join(", ");
        toast.error(messages);
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const handleContinueWithGoogle = async () => {
    handleSignIn("oauth_google");
  };
  async function handleSignIn(strategy) {
    if (!signIn || !signUp) return null;

    const userExistsButNeedsToSignIn =
      signUp.verifications.externalAccount.status === "transferable" &&
      signUp.verifications.externalAccount.error?.code ===
        "external_account_exists";

    if (userExistsButNeedsToSignIn) {
      const res = await signIn.create({ transfer: true });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
      }
    }

    const userNeedsToBeCreated =
      signIn.firstFactorVerification.status === "transferable";

    if (userNeedsToBeCreated) {
      const res = await signUp.create({
        transfer: true,
      });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
        navigate("/home");
      }
    } else {
      signInWith(strategy);
    }
  }

  const signInWith = (strategy) => {
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: "/oauth-callback",
      redirectUrlComplete: "/home",
    });
  };

  return (
    <>
      <div className="bg-image fixed-page">
        <div className="form-wrapper">
          <div className="form-logo">
            <Link to={"/"}>
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="heading-wrapper">
            <h2>Welcome ReEnvoy</h2>
            <p>Log in now to continue to ReEnvoyWeb.</p>
          </div>

          <form onSubmit={handleSignInWithEmail}>
            <div className="login-fields">
              <div className="input-fields">
                <label>Enter your email</label>
                <div className="input-wrapper">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="login-btn">
              <button
                className="btn-style border-0 w-100"
                onClick={handleEmailSet}
              >
                Continue
              </button>
            </div>
            <div className="login-btn">
              <button
                className="btn-style border-0 w-100"
                type="submit"
                disabled={loginLoading}
              >
                {loginLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Get Magic link"
                )}
              </button>
            </div>
            <div className="signup-link">
              <p>
                Don't have an account?{" "}
                <Link to="/sign-up">
                  Sign up <FaArrowRight />
                </Link>
              </p>
            </div>
          </form>

          <div className="signup-fields">
            <div className="field-wrapper">
              <div className="field-logo">
                <img src={apple} alt="email" />
              </div>
              <p>Continue with apple</p>
            </div>

            <div className="field-wrapper" onClick={handleContinueWithGoogle}>
              <div className="field-logo">
                <img src={google} alt="email" />
              </div>
              <p>Continue with Google</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
