import { GoPackage } from "react-icons/go";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { HiDotsVertical } from "react-icons/hi";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function LoadGridview({ data }) {
  const statusClasses = {
    Schedule: "pink",
    "In Progress": "warning",
    Completed: "success",
  };

  return (
    <div className="loadgridview">
      <div className="container-fluid">
        <div className="grid-view_cards">
          <div className="row">
            {data && data.length > 0
              ? data?.map((item, i) => (
                <div className="col-xl-4 col-lg-4 mb-3" key={i}>
                  <div className="grid-cards">
                    <div className="card-header">
                      <div className="username_data">
                        <div className="icon_box">
                          <GoPackage />
                        </div>
                        <strong className="d-flex align-items-center gap-1">
                          LOAD: <p>{item.loadId}</p>
                        </strong>
                      </div>

                      <div className="dropdown-section">
                        <div className="status">
                          <span className={`${statusClasses[item?.status]}`}>
                            {item.status}
                          </span>
                        </div>
                        <div className="dropdown_main-div">
                          <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                              <HiDotsVertical />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                <FiEdit /> Download
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                <FiEdit /> Another Action
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="name_portion">
                        {/* <strong>DRIVER:</strong> */}
                        <div className="flex-box">
                        <div className="username_body">
                          <div className="icon_box">
                            <img src="images/person.png" alt="driver" />
                          </div>
                          <div className="profile_details">
                            <h6>Maxwell Johnas</h6>
                          <p>{item.driver}</p>
                          </div>
                        </div>
                        <div className="price_portion">
                          {/* <strong>Rate:</strong> */}
                          <p>${item.rate}</p>
                        </div>
                        </div>

                        {/* <div className="py-3">
                          <strong>PARTNER COMPANY:</strong>
                          <p>{item.partnerCompany}</p>
                        </div> */}

                        <div className="map-portion">
                          {/* Dynamically set Google Maps embed URL using pickup location */}
                          <iframe
                            src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d27239.607735418533!2d${item.pickup?.longitude}!3d${item.pickup?.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1727775994336!5m2!1sen!2s`}
                            width="100%"
                            height="150"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>

                          {/* <Link to={`/load-details/${item?.id}`}>
                        <div className="grid_button">
                          <button>View Detail</button>
                        </div>
                      </Link> */}

                    </div>
                  </div>
                </div>
                  </div>
          ))
              : null}
        </div>
      </div>
    </div>
    </div >
  );
}
