import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import tableDelete from "../../assets/images/table-delete.svg";
import edit from "../../assets/images/edit-icon.svg";
import tableEdit from "../../assets/images/table-edit.svg";
import { useDispatch, useSelector } from "react-redux";
import { updateLoadStopAsyncThunk } from "../../redux/pagesSlices/loadStopSlice";
import { Spinner } from "react-bootstrap";
import moment from "moment";

const initialState = {
    type: "PICKUP",
    location: "",
    address: "",
    pickupNumber: "",
    phone: "",
    notes: "",
};

function MyVerticallyCenteredModal(props) {
    const { loadings } = useSelector((state) => state.loadStop);
    const [loadStop, setLoadStop] = useState(
        props.editBody ? props.editBody : initialState
    );
    const [appointmentWindow, setAppointmentWindow] = useState(
        props.editBody
            ? {
                start: moment(props.editBody.appointmentWindow.start).format(
                    "YYYY-MM-DD"
                ),
                end: moment(props.editBody.appointmentWindow.end).format(
                    "YYYY-MM-DD"
                ),
            }
            : {
                start: "",
                end: "",
            }
    );
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoadStop((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            ...loadStop,
            Load: props.params.id,
            appointmentWindow,
        };
        dispatch(
            updateLoadStopAsyncThunk({
                id: props.editBody.id,
                Load: props.params.id,
                data,
                callBack: () => {
                    setLoadStop(initialState);
                    setAppointmentWindow({
                        start: "",
                        end: "",
                    });
                    props.onHide();
                },
            })
        );
    };
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="edit-load-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit stop 1</Modal.Title>
            </Modal.Header>
            <div className="edit-load-modal-body">
                <div className="container">
                    {/* <div className="radio-items">
            <div className="single-item">
              <input
                type="radio"
                value="PICKUP"
                name="type"
                checked={loadStop.type === "PICKUP"}
                onChange={handleInputChange}
                id="pickup"
              />
              <label htmlFor="pickup">Status</label>
            </div>
            <div className="single-item">
              <input
                type="radio"
                value="DELIVERY"
                checked={loadStop.type === "DELIVERY"}
                name="type"
                onChange={handleInputChange}
                id="dropoff"
              />
              <label htmlFor="dropoff">Drop Off</label>
            </div>
          </div> */}
                    <div className="form-section mt-0">
                        <div className="row">
                            <div className="col">
                                <div className="single-field">
                                    <label htmlFor="">Status</label>
                                    <select
                                        id=""
                                        name="location"
                                        value={loadStop.location}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Completed</option>
                                        <option value="Hasimara">Hasimara</option>
                                        <option value="Princess_Anne">Princess Anne</option>
                                        <option value="Baao">Baao</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="stop-details-section mt-0">

                            <div className="appointment-items my-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="single-detail-field">
                                            <label htmlFor="">Appointment Window Start</label>
                                            <input
                                                type="date"
                                                required
                                                value={appointmentWindow.start}
                                                onChange={(e) =>
                                                    setAppointmentWindow({
                                                        ...appointmentWindow,
                                                        start: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="appointment-items">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="single-detail-field">
                                            <label htmlFor="">Appointment Window End</label>
                                            <input
                                                type="date"
                                                required
                                                value={appointmentWindow.end}
                                                onChange={(e) =>
                                                    setAppointmentWindow({
                                                        ...appointmentWindow,
                                                        end: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="update-btn">
                                <button onClick={handleSubmit}>
                                    {loadings?.updateLoadStopAsyncThunk ? (
                                        <Spinner animation="border" role="status" size="sm" />
                                    ) : (
                                        "Update"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default function EditNewLoadModalTable({ params, editBody }) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <div className="single-dropdown" onClick={() => setModalShow(true)}>
                <img src={tableEdit} alt="icon" /> Edit
            </div>
            {modalShow && (
                <MyVerticallyCenteredModal
                    show={modalShow}
                    params={params}
                    editBody={editBody}
                    onHide={() => setModalShow(false)}
                />
            )}
        </>
    );
}
