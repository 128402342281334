import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

export const createFleetDocAsyncThunk = createAsyncThunk(
  "fleetDoc/createFleetDocAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    console.log("data", data);
    const response = await ApiRequests.addFleetDocuments(data);
    await dispatch(getFleetDocsAsyncThunk()); // Refresh documents after adding new one
    if (callBack) callBack(); // Call callback if provided
    return response?.data;
  })
);

export const getFleetDocsAsyncThunk = createAsyncThunk(
  "fleetDoc/getFleetDocsAsyncThunk",
  catchAsync(async ({ params, callBack }, { getState }) => {
    const response = await ApiRequests.getFleetDocs(params);
    console.log("response.data", response.data);
    if (callBack) callBack();
    return response?.data;
  })
);

export const getFleetDocAsyncThunk = createAsyncThunk(
  "fleetDoc/getFleetDocAsyncThunk",
  catchAsync(async ({ id }) => {
    const response = await ApiRequests.getFleetDoc(id);
    return response?.data;
  })
);

export const updateFleetDocAsyncThunk = createAsyncThunk(
  "fleetDoc/updateFleetDocAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    const response = await ApiRequests.updateFleetDoc(data);
    if (response.status == 200) {
      toast.success("Document Updated Successfully!");
    }
    await dispatch(getFleetDocsAsyncThunk());
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteFleetDocAsyncThunk = createAsyncThunk(
  "fleetDoc/deleteFleetDocAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch }) => {
    const response = await ApiRequests.deleteFleetDoc(id);
    if (response.status == 204) {
      toast.success("Document Deleted Successfully!");
    }
    await dispatch(getFleetDocsAsyncThunk());
    if (callBack) callBack();
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  fleetDocs: {
    results: [], // Ensure results array is correctly initialized
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
  fleetDocuments: [],
  fleetDocById: {},
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const fleetDocSlice = createSlice({
  name: "fleetDoc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling fulfilled state for getFleetDocsAsyncThunk
      // .addCase(getFleetDocsAsyncThunk.fulfilled, (state, action) => {
      //   if (action.meta.arg?.params?.page > 1) {
      //     // For paginated results, concatenate with existing results
      //     state.fleetDocs = {
      //       ...state.fleetDocs,
      //       ...action.payload,
      //       results: state.fleetDocs.results.concat(
      //         action.payload?.results || []
      //       ),
      //     };
      //   } else {
      //     // For the first page, replace the current results
      //     state.fleetDocs = action.payload;
      //   }
      // })
      .addCase(getFleetDocsAsyncThunk.fulfilled, (state, action) => {
        state.fleetDocuments = action.payload;
      })
      .addCase(getFleetDocAsyncThunk.fulfilled, (state, action) => {
        state.fleetDocById = action.payload;
      })
      // Manage loading and error states using addMatcher
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([
            createFleetDocAsyncThunk,
            getFleetDocsAsyncThunk,
            getFleetDocAsyncThunk,
            updateFleetDocAsyncThunk,
            deleteFleetDocAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default fleetDocSlice.reducer;
