import React, { useEffect, useState } from "react";
import tick from "../assets/images/forms-images/Check circle.png";
import dark from "../assets/images/forms-images/Check circle (1).png";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUserAsyncThunk } from "../redux/pagesSlices/userSlice";
import { Spinner } from "react-bootstrap";
import { useSignUp, useAuth } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string(),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  confirmPassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  isTermsAccepted: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("Terms acceptance is required"),
});
const CreateAccount = () => {
  const dispatch = useDispatch();
  const { signUp, setActive } = useSignUp();
  const { loadings } = useSelector((state) => state.user);
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const sessionEmail = JSON.parse(sessionStorage.getItem("email"));
  console.log("sessionEmail :>> ", sessionEmail);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (values.password !== values.confirmPassword) {
      return toast.error("Passwords do not match");
    }
    dispatch(
      createUserAsyncThunk({
        data: values,
        signUp,
        navigate,
        setActive,
        callBack: () => {
          resetForm();
        },
      })
    ).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <>
      <div className="bg-image fixed-page">
        <div className="container">
          <div className="form-wrapper" style={{ maxWidth: 640 }}>
            <div className="heading-wrapper">
              <h2>Create an account</h2>
              <p>Get started with ReEnvoy. </p>
            </div>
            <div className="main-flex">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  // phone: "",
                  email: sessionEmail ? sessionEmail : "",
                  password: "",
                  confirmPassword: "",
                  isTermsAccepted: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, setFieldValue, values, errors }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-fields">
                          <div className="input-title">
                            <p>First Name</p>
                          </div>
                          <div className="input-wrapper">
                            <Field
                              type="text"
                              name="firstName"
                              placeholder="Name"
                            />
                          </div>
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-fields">
                          <div className="input-title">
                            <p> Last Name</p>
                          </div>
                          <div className="input-wrapper">
                            <Field
                              type="text"
                              name="lastName"
                              placeholder="Name"
                            />
                          </div>
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-fields">
                          <div className="input-title">
                            <p> Enter Your Email</p>
                          </div>
                          <div className="input-wrapper">
                            <Field
                              type="text"
                              name="email"
                              placeholder="info@reenvoy.com"
                              disabled={!!sessionEmail} // Disable if sessionEmail exists
                            />
                          </div>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-fields phone_number_input">
                          <div className="input-title">
                            <p> Phone</p>
                          </div>
                          <div className="input-wrapper">
                            {/* <input
                          type="tel"
                          placeholder="+1 2314 - 5614 - 112"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                        /> */}
                            <PhoneInput
                              country={"us"}
                              // value={phone}
                              // onChange={handleInputNumberChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-fields">
                          <div className="input-title">
                            <p>Password</p>
                          </div>
                          <div className="input-wrapper-1">
                            <Field
                              type={isShowPass ? "text" : "password"}
                              name="password"
                              placeholder=".........."
                            />

                            <button
                              type="button"
                              className="btn btn-link p-0"
                              onClick={() => setIsShowPass(!isShowPass)}
                            >
                              {isShowPass ? <FiEye /> : <FiEyeOff />}
                            </button>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-fields">
                          <div className="input-title">
                            <p>Confirm Password</p>
                          </div>
                          <div className="input-wrapper-1">
                            <Field
                              type={isShowConfirmPass ? "text" : "password"}
                              name="confirmPassword"
                              placeholder=".........."
                            />
                            <button
                              type="button"
                              className="btn btn-link p-0"
                              onClick={() =>
                                setIsShowConfirmPass(!isShowConfirmPass)
                              }
                            >
                              {isShowConfirmPass ? <FiEye /> : <FiEyeOff />}
                            </button>
                          </div>
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="chracter">
                      <img
                        src={values.password.length >= 8 ? tick : dark}
                        alt="Tick"
                      />
                      <p>Must be at least 8 characters</p>
                    </div>

                    <div className="chracter">
                      <img
                        src={/[@$!%*?&]/.test(values.password) ? tick : dark}
                        alt="Tick"
                      />
                      <p>Must contain 1 special character</p>
                    </div>

                    <div className="privacy-sec">
                      <Field
                        type={"checkbox"}
                        checked={values.isTermsAccepted}
                        onChange={() =>
                          setFieldValue(
                            "isTermsAccepted",
                            !values.isTermsAccepted
                          )
                        }
                      />

                      <p htmlFor="ddd">
                        By creating an account you agree to the{" "}
                        <a href="#"> Terms of Service </a> and{" "}
                        <a href="#"> Privacy Policy</a>
                      </p>
                    </div>
                    <ErrorMessage
                      name="isTermsAccepted"
                      component="div"
                      className="error"
                    />

                    <div className="login-btn">
                      <div className="new-signup">
                        <p>
                          Already have an account?{" "}
                          <Link to="/login">Login </Link>
                        </p>
                      </div>
                      <button
                        className="btn-style border-0"
                        type="submit"
                        disabled={isSubmitting || loadings.createUserAsyncThunk}
                      >
                        {isSubmitting || loadings.createUserAsyncThunk ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : (
                          "Create account"
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
