import React, { useState } from "react";
import download from "../assets/images/download.svg";
import deleteIcon from "../assets/images/delete.svg";
import { IoEyeOutline } from "react-icons/io5";
import editIcon from "../assets/images/pencil.svg";
import TailorDetailsForm from "./TailorDetailsForm";
import TailorExpenses from "./TailorExpenses";
import addDocument from "../assets/images/add-document.svg";
import AddTruckExpenseModal from "./Modals/AddTruckExpenseModal";
import EditTruckDetailsModal from "./Modals/EditTruckDetailsModal";
import DeleteLoadModal from "./Modals/DeleteLoadModal";
import AddDocumentModal from "./Modals/AddDocumentModal";

const TailorTabsSection = () => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("Details");
  const [editTruckModalShow, setTruckModalShow] = useState(false);
  const [deleteModalShow, deleteSetModalShow] = useState(false);

  const [openAddDocumentModal,setAddDocumentModalShow] = useState(false)

  const handleCloseAddDocumentModal = () => setAddDocumentModalShow(false)
  const handleOpenAddDocumentModal = () => setAddDocumentModalShow(true)

  const handleTruckModaOpen = () => {
    setTruckModalShow(true);
  };

  const handleTruckModalClose = () => {
    setTruckModalShow(false);
  };

  const [addtruckExpenseShow, setAddTruckExpenseShow] = useState(false);

  const handleAddTruckModalShow = () => setAddTruckExpenseShow(true);

  const handleAddTruckModalClose = () => setAddTruckExpenseShow(false);

  const handleDeleteModalOpen = () => {
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
  };



  return (
    <div className="custom-tabs-container">
      {/* Tabs Header */}
      <div className="custom-tabs-header flex-column-reverse flex-md-row" >
        <div className="custom-tabs">
          <div
            className={`custom-tab-item ${
              activeTab === "Details" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Details")}
          >
            Details
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Documents" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Documents")}
          >
            Documents
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Expenses" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Expenses")}
          >
            Expenses
          </div>
        </div>
        <div
          className={`${
            activeTab === "Expenses" ? "btn-show" : "btn-hide"
          } add-document-btn`}
        >
          <button onClick={handleAddTruckModalShow}>
            <img src={addDocument} alt="icon" />
            <span>Add Tailor Expense</span>
          </button>
          <AddTruckExpenseModal
            addtruckExpenseShow={addtruckExpenseShow}
            handleAddTruckModalClose={handleAddTruckModalClose}
          />
        </div>
        <div
          className={`${
            activeTab === "Documents" ? "btn-show" : "btn-hide"
          } add-document-btn`}
        >
          <button onClick={handleOpenAddDocumentModal}>
            <img src={addDocument} alt="icon" />
            <span>Add Document</span>
          </button>
          <AddDocumentModal
            openAddDocumentModal={openAddDocumentModal}
            handleCloseAddDocumentModal={handleCloseAddDocumentModal}
          />
        </div>
      </div>

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {activeTab === "Details" && (
          <div className="custom-tab-pane">
            <TailorDetailsForm />
          </div>
        )}

        {activeTab === "Documents" && (
          <div className="custom-tab-pane">
            <div className="truck-docs-section">
              <div className="container-fluid">
                <div className="documents-table" style={{ overflowX: "auto" }}>
                  <table className="truck-documents-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ minWidth: "300px" }}>
                          <div className="single-list-item">
                            <div className="item-info">
                              <span>DL</span>
                              <div className="info">
                                <strong>Driver License</strong>
                                <p>Driver License</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style={{ minWidth: "250px" }}>
                          <div className="content">
                            <strong>Updated</strong>
                            <strong>
                              <span className="yellow-text">Missing</span>
                            </strong>
                          </div>
                        </td>
                        <td style={{ minWidth: "120px" }}>
                          <div className="content">
                            <strong>Expiration</strong>
                            <strong>
                              <span className="yellow-text">Missing</span>
                            </strong>
                          </div>
                        </td>
                        <td style={{ minWidth: "250px" }}>
                          <div className="item-actions">
                            <div className="single-action">
                              <button onClick={handleTruckModaOpen}>
                                <img src={editIcon} alt="icon" />
                              </button>
                              <EditTruckDetailsModal
                                editTruckModalShow={editTruckModalShow}
                                handleTruckModalClose={handleTruckModalClose}
                              />
                            </div>
                            <div className="single-action">
                              <button>
                                <IoEyeOutline color="#9499A5" />
                              </button>
                            </div>
                            <div className="single-action">
                              <button>
                                <img src={download} alt="icon" />
                              </button>
                            </div>
                            <div className="single-action">
                              <button onClick={handleDeleteModalOpen}>
                                <img src={deleteIcon} alt="icon" />
                              </button>
                              <DeleteLoadModal
                                handleDeleteModalClose={handleDeleteModalClose}
                                deleteModalShow={deleteModalShow}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="single-list-item">
                            <div className="item-info">
                              <span>DL</span>
                              <div className="info">
                                <strong>Driver License</strong>
                                <p>Driver License</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="content">
                            <strong>Updated</strong>
                            <strong className="description">
                              <span className="yellow-text"></span>
                              4/24/2024, 1:23 AM by Abdullahi mohamed
                            </strong>
                          </div>
                        </td>
                        <td>
                          <div className="content">
                            <strong>Expiration</strong>
                            <strong className="description">
                              <span className="yellow-text"></span>
                              1/30/2025
                            </strong>
                          </div>
                        </td>
                        <td>
                        <div className="item-actions">
                            <div className="single-action">
                              <button onClick={handleTruckModaOpen}>
                                <img src={editIcon} alt="icon" />
                              </button>
                              <EditTruckDetailsModal
                                editTruckModalShow={editTruckModalShow}
                                handleTruckModalClose={handleTruckModalClose}
                              />
                            </div>
                            <div className="single-action">
                              <button>
                                <IoEyeOutline color="#9499A5" />
                              </button>
                            </div>
                            <div className="single-action">
                              <button>
                                <img src={download} alt="icon" />
                              </button>
                            </div>
                            <div className="single-action">
                              <button onClick={handleDeleteModalOpen}>
                                <img src={deleteIcon} alt="icon" />
                              </button>
                              <DeleteLoadModal
                                handleDeleteModalClose={handleDeleteModalClose}
                                deleteModalShow={deleteModalShow}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="single-list-item">
                            <div className="item-info">
                              <span>DL</span>
                              <div className="info">
                                <strong>Driver License</strong>
                                <p>Driver License</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="content">
                            <strong>Updated</strong>
                            <strong>
                              <span className="yellow-text">Missing</span>
                            </strong>
                          </div>
                        </td>
                        <td>
                          <div className="content">
                            <strong>Expiration</strong>
                            <strong>
                              <span className="yellow-text">Missing</span>
                            </strong>
                          </div>
                        </td>
                        <td>
                        <div className="item-actions">
                            <div className="single-action">
                              <button onClick={handleTruckModaOpen}>
                                <img src={editIcon} alt="icon" />
                              </button>
                              <EditTruckDetailsModal
                                editTruckModalShow={editTruckModalShow}
                                handleTruckModalClose={handleTruckModalClose}
                              />
                            </div>
                            <div className="single-action">
                              <button>
                                <IoEyeOutline color="#9499A5" />
                              </button>
                            </div>
                            <div className="single-action">
                              <button>
                                <img src={download} alt="icon" />
                              </button>
                            </div>
                            <div className="single-action">
                              <button onClick={handleDeleteModalOpen}>
                                <img src={deleteIcon} alt="icon" />
                              </button>
                              <DeleteLoadModal
                                handleDeleteModalClose={handleDeleteModalClose}
                                deleteModalShow={deleteModalShow}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === "Expenses" && (
          <div className="custom-tab-pane">
            <TailorExpenses />
          </div>
        )}
      </div>
    </div>
  );
};

export default TailorTabsSection;
