import { useState } from "react";
import pencilEdit from "../assets/images/pencil-edit.svg";
import EditFleetDetailsModal from "./Modals/EditFleetDetailsModal";
import useUserHasRole from "../hooks/useHasRole.js";
import { useUser } from "@clerk/clerk-react";
import { ROLES } from "../constant/roles.js";

export default function TruckDetailsSection({ fleetData }) {
  console.log("🚀 ~ TruckDetailsSection ~ fleetData:", fleetData);
  const [showFleetDetailsModal, setFleetDetailsModal] = useState(false);

  const handleShowFleetModal = () => setFleetDetailsModal(true);
  const handleHideFleetModal = () => setFleetDetailsModal(false);

  const { user } = useUser();

  const isAdminOrOwner = useUserHasRole(user, [ROLES.ADMIN, ROLES.OWNER]);
  console.log("🚀 ~ TruckDetailsSection ~ isAdminOrOwner:", isAdminOrOwner);

  return (
    <div className="truck-details-section">
      <div className="container">
        <div className="truck-details-wrapper">
          <div className="details-content-wrapper">
            <div className="details-header">
              <strong>Details</strong>
              {isAdminOrOwner && (
                <button onClick={handleShowFleetModal}>
                  <img src={pencilEdit} alt="icon" />
                </button>
              )}
              <EditFleetDetailsModal
                showFleetDetailsModal={showFleetDetailsModal}
                handleHideFleetModal={handleHideFleetModal}
                fleetData={fleetData}
              />
            </div>
            <div className="details-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="single-detail">
                    <span>Type</span>
                    <strong>
                      {fleetData?.isOwner
                        ? "Owner-operated Truck"
                        : "Company Truck"}
                    </strong>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-detail">
                    <span>Make</span>
                    <strong>{fleetData?.fleetMake || "N/A"}</strong>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="single-detail">
                    <span>Model</span>
                    <strong>{fleetData?.fleetModel || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-detail">
                    <span>License number</span>
                    <strong>{fleetData?.licenseNumber || "N/A"}</strong>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="single-detail">
                    <span>VIN</span>
                    <strong>{fleetData?.odometer || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-detail">
                    <span>Assigned Driver</span>
                    <strong>
                      {(fleetData?.users && fleetData?.users[0]?.email) ||
                        "N/A"}
                    </strong>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="single-detail">
                    <span>Truck Number</span>
                    <strong>{fleetData?.fleetNumber || "N/A"}</strong>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="single-detail">
                    <span>Year</span>
                    <strong>{fleetData?.modelYear || "N/A"}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
