import crossIcon from "../assets/images/cross-blue.svg";
import deleteIcon from "../assets/images/delete-blue.svg";
export default function flFleetSelectionTab({
  selectedCount,
  onDelete,
  setSelectedRows,
}) {
  return (
    <div className="fleet-selection-tab-wrapper">
      <div className="container-fluid">
        {selectedCount > 0 && (
          <div className="fleet-selection-tab">
            <div className="selected-count">
              <div className="icon">
                <button onClick={() => setSelectedRows([])}>
                  <img src={crossIcon} alt="icon" />
                </button>
              </div>
              <strong>{`${selectedCount} selected`}</strong>
            </div>
            <div className="delete-action">
              <div className="icon">
                <button onClick={onDelete} disabled={selectedCount === 0}>
                  <img src={deleteIcon} alt="icon" />
                  <strong>DELETE</strong>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
