import arrowBack from "../assets/images/arrow-back.svg";
import trash from "../assets/images/trash.svg";
import edit from "../assets/images/edit-icon.svg";
import DeleteLoadModal from "./Modals/DeleteLoadModal";
import TruckModal from "./Modals/MarkTruckModal";
import EditLoadModal from "./Modals/EditLoadModal";
import EditNewLoadModal from "./Modals/EditNewLoadModal"
import { Link } from "react-router-dom";
import { useState } from "react";

export default function LoadPageTopBar({ loadById, params }) {
  const [deleteModalShow, deleteSetModalShow] = useState(false);
  const [editModalShow, editSetModalShow] = useState(false);
  const [editNewModalShow, editSetNewModalShow] = useState(false);

  const handleDeleteModalOpen = () => {
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
  };

  const handleEditModalOpen = () => {
    editSetModalShow(true);
  };

  const handleEditModalClose = () => {
    editSetModalShow(false);
  };

  const handleNewEditModalOpen = () => {
    editSetNewModalShow(true);
  };

  const handleNewEditModalClose = () => {
    editSetNewModalShow(false);
  };

  return (
    <div className="load-top-bar-wrapper">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-4">
            <div className="bar-navigation">
              <div className="back-icon">
                <Link to="/load">
                  <img src={arrowBack} alt="arrow back" />
                </Link>
              </div>
              <div className="page-name">
                <strong>Load Name {loadById?.loadId}</strong>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="buttons-wrapper">
              <div className="single-btn">
                <TruckModal />
              </div>
              <div className="single-btn red-bg-btn">
                <button onClick={handleDeleteModalOpen}>
                  <img src={trash} alt="icon" />
                  <span>Delete Load</span>
                </button>
              </div>

              <div className="single-btn">
                <EditLoadModal
                  params={params}
                  editModalShow={editModalShow}
                  handleEditModalClose={handleEditModalClose}
                />
                <button onClick={handleEditModalOpen}>
                  <img src={edit} alt="icon" />
                  <span>Edit Load</span>
                </button>
              </div>
              <div className="single-btn">
                <EditNewLoadModal
                  params={params}
                  editNewModalShow={editNewModalShow}
                  handleNewEditModalClose={handleNewEditModalClose}
                />
                <button onClick={handleNewEditModalOpen}>
                  <img src={edit} alt="icon" />
                  <span>Edit New Load</span>
                </button>
              </div>
              <DeleteLoadModal
                handleDeleteModalClose={handleDeleteModalClose}
                deleteModalShow={deleteModalShow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
