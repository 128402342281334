import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createUserDocAsyncThunk,
  getUserDocsAsyncThunk,
} from "../redux/pagesSlices/userDocSlice";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Button as ButtonMui } from "@mui/material";

import { Close } from "@mui/icons-material";
import { Button } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";

// Yup validation schema
const validationSchema = Yup.object({
  documentType: Yup.string().required("Document Type is required"),
  documentDesc: Yup.string()
    .required("Document Description is required")
    .max(191),
  status: Yup.string().required("Status is required"),
});

const initialDocumentData = {
  documentDesc: "",
  documentType: "",
  status: "",
};

const CompanyDocumentsEditModel = ({ inviteModalShow, handleModalClose }) => {
  const { activeOrg } = useSelector((state) => state.workspace);
  const [base64URL, setBase64URL] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const loadings = useSelector((state) => state.userDoc?.loadings); // Assuming you track loading in Redux

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG, JPEG, PDF, and MS Word files are allowed.");
        return;
      }

      // Convert to Base64 if valid
      const base64 = await convertToBase64(file);
      setBase64URL(base64);
      setSelectedFile(file);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemoveFile = () => {
    setBase64URL("");
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleAddDocument = (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("documentDesc", values.documentDesc);
    formData.append("documentType", values.documentType);
    formData.append("status", values.status);
    formData.append("referenceId", activeOrg);
    formData.append("referenceType", "USER");

    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    dispatch(
      createUserDocAsyncThunk({
        data: formData,
        callBack: () => {
          handleRemoveFile();
          handleModalClose();
          const params = {
            referenceType: "USER",
            referenceId: activeOrg,
            page: 1,
          };
          dispatch(getUserDocsAsyncThunk({ params }));
        },
      })
    ).finally(() => setSubmitting(false));
  };

  return (
    <Modal
      open={inviteModalShow}
      onClose={handleModalClose}
      aria-labelledby="add-document-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "90%",
            sm: "80%",
            md: 537,
          },
          maxWidth: 537,
          bgcolor: "#fff",
          p: 3,
          borderRadius: "16px",
          border: "1px solid #dfdfe2",
          boxShadow: "0px 15px 32px 0px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography variant="h6">Add Document</Typography>
          <IconButton aria-label="close" onClick={handleModalClose}>
            <Close />
          </IconButton>
        </Box>

        <Formik
          initialValues={initialDocumentData}
          validationSchema={validationSchema}
          onSubmit={handleAddDocument}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            touched,
            errors,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ mt: 2 }}>
                <FormLabel>Document Type</FormLabel>
                <Select
                  fullWidth
                  name="documentType"
                  value={values.documentType}
                  onChange={handleChange}
                  error={touched.documentType && Boolean(errors.documentType)}
                  placeholder="Select Document Type"
                >
                  <MenuItem value="UCR">UCR</MenuItem>
                  <MenuItem value="IFTA_LICENSE">IFTA LICENSE</MenuItem>
                  <MenuItem value="HVUT_FORM_2290">HVUT FORM 2290</MenuItem>
                  <MenuItem value="CONSORTIUM_ENROLLMENT_CERTIFICATE">
                    CONSORTIUM ENROLLMENT CERTIFICATE
                  </MenuItem>
                  <MenuItem value="NOTICE_OF_ASSIGNMENT">
                    NOTICE OF ASSIGNMENT
                  </MenuItem>
                  <MenuItem value="AUTHORITY_BIENNIAL_UPDATES">
                    AUTHORITY BIENNIAL UPDATES
                  </MenuItem>
                </Select>
                {touched.documentType && errors.documentType && (
                  <Typography color="error">{errors.documentType}</Typography>
                )}
              </Box>

              <Box>
                <FormLabel>Document Description</FormLabel>
                <TextField
                  required
                  fullWidth
                  name="documentDesc"
                  value={values.documentDesc}
                  onChange={handleChange}
                  placeholder="Type here ..."
                  error={touched.documentDesc && Boolean(errors.documentDesc)}
                />
                {touched.documentDesc && errors.documentDesc && (
                  <Typography color="error">{errors.documentDesc}</Typography>
                )}
              </Box>

              <Box sx={{ mt: 2 }}>
                <FormLabel>Status</FormLabel>
                <Select
                  fullWidth
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  error={touched.status && Boolean(errors.status)}
                >
                  <MenuItem value="missing">missing</MenuItem>
                  <MenuItem value="updated">updated</MenuItem>
                </Select>
                {touched.status && errors.status && (
                  <Typography color="error">{errors.status}</Typography>
                )}
              </Box>

              <Box sx={{ mt: 2 }}>
                <ButtonMui
                  variant="outlined"
                  startIcon={<FiUpload />}
                  onClick={() => fileInputRef.current.click()}
                >
                  Upload
                </ButtonMui>
                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  accept=".png, .jpeg, .jpg, .pdf, .doc, .docx"
                  onChange={handleFileInputChange}
                />
                {selectedFile && (
                  <ButtonMui
                    variant="text"
                    onClick={handleRemoveFile}
                    sx={{ ml: 2 }}
                  >
                    Remove
                  </ButtonMui>
                )}
              </Box>

              <Box sx={{ mt: 4 }}>
                <ButtonMui
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || loadings.createUserDocAsyncThunk}
                >
                  {isSubmitting || loadings.createUserDocAsyncThunk ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    <span>Add</span>
                  )}
                </ButtonMui>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CompanyDocumentsEditModel;
