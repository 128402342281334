import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

export const createUserDocAsyncThunk = createAsyncThunk(
  "userDoc/createUserDocAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    const response = await ApiRequests.createUserDocument(data);
    if (response.status == 201 || response.status == 200) {
      toast.success("Create Document Successfully!");
    }
    await dispatch(getUserDocsAsyncThunk()); // Refresh documents after adding new one
    if (callBack) callBack(); // Call callback if provided
    return response?.data;
  })
);

export const getUserDocsAsyncThunk = createAsyncThunk(
  "userDoc/getUserDocsAsyncThunk",
  catchAsync(async ({ params, callBack }, { getState }) => {
    const response = await ApiRequests.getUserDocuments(params);
    if (callBack) callBack(); // Call callback if provided
    return response?.data;
  })
);

export const getUserDocByIdAsyncThunk = createAsyncThunk(
  "userDoc/getUserDocByIdAsyncThunk",
  catchAsync(async ({ id }) => {
    const response = await ApiRequests.getUserDocument(id);
    return response?.data;
  })
);

export const updateUserDocAsyncThunk = createAsyncThunk(
  "userDoc/updateUserDocAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    const response = await ApiRequests.updateUserDocument(data);
    if (response.status == 200) {
      toast.success("Update Document Successfully!");
    }
    await dispatch(getUserDocsAsyncThunk());
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteUserDocAsyncThunk = createAsyncThunk(
  "userDoc/deleteUserDocAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch }) => {
    const response = await ApiRequests.deleteUserDocument(id);
    if (response.status == 204) {
      toast.success("Delete Document Successfully!");
    }
    await dispatch(getUserDocsAsyncThunk());
    if (callBack) callBack();
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  userDocs: {
    results: [], // Ensure results array is correctly initialized
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
  userDocuments: [],
  userDocById: {},
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const userDocSlice = createSlice({
  name: "userDoc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling fulfilled state for getUserDocsAsyncThunk
      // .addCase(getUserDocsAsyncThunk.fulfilled, (state, action) => {
      //   if (action.meta.arg?.params?.page > 1) {
      //     // For paginated results, concatenate with existing results
      //     state.userDocs = {
      //       ...state.userDocs,
      //       ...action.payload,
      //       results: state.userDocs.results.concat(
      //         action.payload?.results || []
      //       ),
      //     };
      //   } else {
      //     // For the first page, replace the current results
      //     state.userDocs = action.payload;
      //   }
      // })
      .addCase(getUserDocsAsyncThunk.fulfilled, (state, action) => {
        state.userDocuments = action.payload;
      })
      .addCase(getUserDocByIdAsyncThunk.fulfilled, (state, action) => {
        state.userDocById = action.payload;
      })
      // Manage loading and error states using addMatcher
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([
            createUserDocAsyncThunk,
            getUserDocsAsyncThunk,
            getUserDocByIdAsyncThunk,
            updateUserDocAsyncThunk,
            deleteUserDocAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default userDocSlice.reducer;
