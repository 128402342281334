import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { IoMdAdd } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { ROLES } from "../constant/roles";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { sendInvitationAsyncThunk } from "../redux/pagesSlices/workspaceSlice";
import { Spinner } from "react-bootstrap";
import { LeaderboardTwoTone } from "@mui/icons-material";

const initialFields = [{ email: "", role: ROLES.ADMIN }];

const InviteTeam = () => {
  const [fields, setFields] = useState(initialFields);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleAddField = () => {
    setFields([...fields, { email: "", role: ROLES.ADMIN }]);
  };

  const handleSendInvites = async () => {
    const validFields = fields.filter((field) => field.email.trim() !== "");

    if (validFields.length === 0) {
      toast.error("Please enter a valid email address");
      return;
    }
    setIsLoading(true);
    try {
      const promises = validFields.map((field) => {
        return dispatch(
          sendInvitationAsyncThunk({
            data: field,
          })
        );
      });

      const responses = await Promise.all(promises);

      const failedFields = validFields.filter((_, index) => {
        return responses[index].meta.requestStatus !== "fulfilled";
      });

      setFields(failedFields);

      if (failedFields.length > 0) {
        // toast.error("Some invitations failed to send.");
        failedFields.forEach((field) => {
          console.error("Failed to send invite for:", field.email);
        });
      } else {
        // toast.success("All invitations sent successfully!");
        navigate("/reenovy");
      }
    } catch (error) {
      // toast.error("An error occurred while sending invites.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="bg-image fixed-page">
        <div className="container">
          <div className="form-wrapper">
            <div className="heading-wrapper">
              <h2>Invite team</h2>
            </div>

            <div className="main-drop">
              {fields?.map((field, index) => (
                <div className="dropdown-fields" key={index}>
                  <div className="dropdown-input">
                    <input
                      type="email"
                      placeholder="example@reenvoy.com"
                      value={field.email}
                      onChange={(e) => {
                        const newFields = fields?.map((field, idx) =>
                          idx === index
                            ? { ...field, email: e.target.value }
                            : field
                        );
                        setFields(newFields);
                      }}
                    />
                  </div>
                  <div className="dropdown">
                    <DropdownButton
                      align="end"
                      title={toTitleCase(field.role)}
                      id={`dropdown-menu-align-end-${index}`}
                      onSelect={(role) => {
                        const newFields = fields?.map((field, idx) =>
                          idx === index ? { ...field, role } : field
                        );
                        setFields(newFields);
                      }}
                    >
                      <Dropdown.Item eventKey={ROLES.ADMIN}>
                        Admin
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={ROLES.OWNER}>
                        Owner
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={ROLES.USER}>USER</Dropdown.Item>
                      <Dropdown.Item eventKey={ROLES.DRIVER}>
                        Driver
                      </Dropdown.Item>
                      <Dropdown.Item eventKey={ROLES.DISPATCH}>
                        Dispatch
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              ))}
              <div className="add-btn">
                <IoMdAdd />
                <button type="button" onClick={handleAddField}>
                  Add another
                </button>
              </div>
            </div>

            <div className="notnow-btn">
              <div className="btn-flex">
                <p>
                  <Link to="/home" className="btn-text">
                    Not Now
                  </Link>
                </p>
              </div>
              <button disabled={isLoading} onClick={handleSendInvites}>
                {isLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Send Invites"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteTeam;
