import React, { useEffect, useState } from "react";
import tabs from "../assets/images/forms-images/tabs.png";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownItem } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DriversPageTopBar from "../components/DriversPageTopbar";
import StepperComponents from "../components/StepperComponents";
import { ErrorMessage, Field } from "formik";
import { toast } from "react-toastify";
import { State } from "country-state-city"; // Import the State module
import { useNavigate } from "react-router-dom";
import { getFleetsAsyncThunk } from "../redux/pagesSlices/fleetSlice";
import { useDispatch, useSelector } from "react-redux";

const AddDriverProfile = ({
  handleNext,
  validateForm,
  handleSubmit,
  values,
  setFieldValue,
}) => {
  const [states, setStates] = useState("");
  const navigate = useNavigate();
  const data = useSelector((state) => state?.fleet?.fleet);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        getFleetsAsyncThunk({
          fleetType: "TRUCK",
        })
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    const usStates = State.getStatesOfCountry("US");
    setStates(usStates);
  }, []);

  return (
    <>
      <div className="main-driver">
        <div className="container">
          <div className="profile-tabs">
            {/* <img src={tabs} alt='Links Tabs' /> */}
            {/* <StepperComponents /> */}
          </div>
          <div className="add-driver-form">
            <div className="row">
              <div className="col-md-6">
                <div className="modal-field">
                  <label>First Name *</label>
                  <Field type="text" name="firstName" placeholder="Max" />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Last Name *</label>
                  <Field type="text" name="lastName" placeholder="Max" />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="modal-field">
                  <label>City</label>
                  <Field type="text" name="city" placeholder="Max" />
                  <ErrorMessage name="city" component="div" className="error" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-field">
                  <label>State</label>
                  <Field as="select" name="state" className="bg-white">
                    <option value="">Select</option>
                    {Object.entries(states).length > 0 ? (
                      Object.entries(states)?.map(([key, value]) => (
                        <option key={key} value={value.isoCode}>
                          {value.name}
                        </option>
                      ))
                    ) : (
                      <option value="">No states available</option>
                    )}
                  </Field>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Phone Number *</label>
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        placeholder="Enter phone number"
                        maxLength="10"
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Zip Code</label>
                  <Field
                    type="text"
                    name="zipCode"
                    placeholder="Enter Zip code"
                  />
                  <ErrorMessage
                    name="zipCode"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Email</label>
                  <Field
                    type="email"
                    name="email"
                    placeholder="example@reenvoy.com"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Assign Truck</label>
                  <Field as="select" className="bg-white" name="fleetId">
                    <option value="">Select</option>
                    {data?.results?.map((item) => (
                      <option key={item.id} value={item?.id}>
                        {item?.fleetMake}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fleetId"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Date of birth</label>
                  <Field
                    type="date"
                    name="dateOfBirth"
                    placeholder="mm/dd/yyyy"
                  />
                  <ErrorMessage
                    name="dateOfBirth"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Date of hire</label>
                  <Field type="date" name="hireDate" placeholder="mm/dd/yyyy" />
                  <ErrorMessage
                    name="hireDate"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="modal-field">
                  <label>License number</label>
                  <Field
                    type="text"
                    name="licenseNumber"
                    placeholder="XXXXXXXXXX"
                  />
                  <ErrorMessage
                    name="licenseNumber"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="modal-field">
                  <label>Issuing states for license</label>
                  <Field
                    as="select"
                    placeholder="Select"
                    name="issuingState"
                    className="form-control form-input"
                  >
                    <option value="">Select</option>
                    {Object.entries(states).length > 0 ? (
                      Object.entries(states)?.map(([key, value]) => (
                        <option key={key} value={value.isoCode}>
                          {value.name}
                        </option>
                      ))
                    ) : (
                      <option value="">No states available</option>
                    )}
                  </Field>
                  <ErrorMessage
                    name="issuingState"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="driver-profile-btn">
            <Button
              className="link-btn light-bg"
              onClick={() => navigate("/drivers")}
            >
              Cancel
            </Button>
            <Button className="add-btn" type="button" onClick={handleSubmit}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDriverProfile;
