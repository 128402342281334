import { useSignUp } from "@clerk/clerk-react";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const NewVerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const { email } = useParams();
  const { signUp } = useSignUp();

  const handleResendVerification = async () => {
    setLoading(true);
    try {
      const signUpAttempt = await signUp.prepareEmailAddressVerification({
        strategy: "email_link",
        redirectUrl: `${process.env.REACT_APP_FRONTEND_URL}/verified`,
      });
      console.log("signUpAttempt", signUpAttempt);

      if (signUpAttempt.status === "missing_requirements") {
        toast.success("Verification link sent successfully!");
      }
    } catch (error) {
      console.error("Error sending verification link:", error);
      toast.error("An error occurred while sending the verification link.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="bg-image fixed-page ">
        <div className="container">
          <div className="form-wrapper" style={{ maxWidth: "400px" }}>
            <div className="heading-wrapper">
              <h2 style={{fontWeight:400}}>Verify Email</h2>
            </div>

            <div className="verify-title">
              <p>
                We sent a verification link to <br></br>
                <a href={`mailto:${email}`}> {email} </a>
              </p>
            </div>

            <div className="verify-btn-1">
              <p
                className={`${
                  loading ? "d-flex" : "d-inline"
                }  align-items-center justify-content-center`}
              >
                Don’t received the email?{" "}
                {loading ? (
                  <Spinner size="sm" className="ms-2" />
                ) : (
                  <a
                    className="#" 
                    onClick={handleResendVerification}
                    style={{padding:'0 0 0 0', cursor:'pointer'}}
                  >
                    Click to resend
                  </a>
                )}
              </p>
            </div>

            <div className="back-btn">
              <Link to="/login">
                {" "}
                <FaArrowLeft /> Back to Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewVerifyEmail;
