import React, { useState } from "react";
import TableComponent from "./TableComponent";
import addDocument from "../assets/images/add-document.svg";
import DocumentsContent from "./DocumentsContent";
import DetailsCard from "./DetailsCard";
import tableIcon from "../assets/images/table-icon.svg";
import tripSummaryIcon from "../assets/images/trip-summary.svg";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  FormLabel,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import Logs from "./Logs";
import HomeWrapper from "../Pages/HomeWrapper";
import EmptyDocuments from "./EmptyDocuments";
import { Close } from "@mui/icons-material";
import { FiUpload } from "react-icons/fi";

const TabsSection = ({ item, loadStop, params }) => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("Details");
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);

  const handleOpenAddDocumentModal = () => setOpenAddDocumentModal(true);
  const handleCloseAddDocumentModal = () => setOpenAddDocumentModal(false);

  return (
    <div className="custom-tabs-container">
      {/* Tabs Header */}
      <div className="custom-tabs-header">
        <div className="custom-tabs">
          <div
            className={`custom-tab-item ${
              activeTab === "Details" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Details")}
          >
            Details
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Documents" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Documents")}
          >
            Documents
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Trip Summary" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Trip Summary")}
          >
            Trip Summary
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Invoicing" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Invoicing")}
          >
            Invoicing
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Log" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Log")}
          >
            Log
          </div>
        </div>
        <div
          className={`${
            activeTab === "Documents" ? "btn-show" : "btn-hide"
          } add-document-btn`}
        >
          <button onClick={handleOpenAddDocumentModal}>
            <img src={addDocument} alt="icon" />
            <span>Add Document</span>
          </button>
          <Modal
            open={openAddDocumentModal}
            onClose={handleCloseAddDocumentModal}
            aria-labelledby="add-document-modal"
            className="add-new-expense"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: {
                  xs: "90%",
                  sm: "80%",
                  md: 537,
                },
                maxWidth: 537,
                bgcolor: "#fff",
                p: 3,
                borderRadius: "16px",
                border: "1px solid #dfdfe2",
                boxShadow: "0px 15px 32px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <Typography variant="modalTitleClass" id="simple-modal-title" className="model-title">
                  Add Document
                </Typography>

                <IconButton
                  aria-label="close"
                  onClick={handleCloseAddDocumentModal}
                  sx={{
                    "&.MuiIconButton-root": {
                      padding: "3px",
                      borderRadius: "0%",
                      fill: "#68687A",
                    },
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormLabel htmlFor="document-name" className="form-label">Document Name</FormLabel>
                  <TextField
                    required
                    id="document-name"
                    fullWidth
                    placeholder="Type here ..."
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel id="document-type-label" className="form-label">
                    Document Type
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      labelId="document-type-label"
                      id="document-type-select"
                      // value={documentType}
                      // onChange={handleDocumentTypeChange}
                      placeholder="Select Document Type"
                    >
                      <MenuItem value="invoice">Invoice</MenuItem>
                      <MenuItem value="receipt">Receipt</MenuItem>
                      <MenuItem value="contract">Contract</MenuItem>
                      {/* Add more document types as needed */}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <FormLabel htmlFor="attach-document" className="form-label">Attach Document</FormLabel>
              <Box sx={{ mt: 2, mb: 4 }}>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<FiUpload />}
                  sx={{
                    "&.MuiButton-root": {
                      padding: "10px 20px",
                      color: "var(--Primary-P300, #0065EC)",
                      textAlign: "center",
                      fontFamily: "var(--Font-Poppins, Poppins)",
                      fontSize: "0.75rem",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    },
                  }}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
                <Button variant="text" disabled sx={{ ml: 2 }}>
                  Remove
                </Button>
              </Box>
              <Button variant="contained">Add</Button>
            </Box>
          </Modal>
        </div>
      </div>

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {activeTab === "Details" && (
          <div className="custom-tab-pane">
            <DetailsCard item={item} />
            <TableComponent loadStop={loadStop} params={params} />
          </div>
        )}

        {activeTab === "Documents" && (
          <div className="custom-tab-pane">
            <EmptyDocuments />
            <DocumentsContent />
          </div>
        )}

        {activeTab === "Trip Summary" && (
          <div className="custom-tab-pane">
            <div className="container-fluid" style={{ padding: "0" }}>
              <DetailsCard />
              <TableComponent loadStop={loadStop} params={params} />
              {/* <div className="stop-summary-content">
                <strong>Stop Summary</strong>
              </div>
              <div className="table-wrapper" style={{ overflowX: "auto" }}>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "172px" }}>
                        <div>
                          <strong>LEG</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>ORIGIN</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>
                      <th style={{ width: "270px" }}>
                        <div>
                          <strong>DESTINATION</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>ESTIMATED MILES</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>

                      <th>
                        <div>
                          <strong>ACTUAL MILES</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>DURATION DESTINATION</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>

                      <th>
                        <div>
                          <strong>TOTAL DURATION</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>
                      <th>
                        <div>
                          <strong>FUEL USED</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <span>Deadhead</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>07/03/2024, 22:03</span>
                        </div>
                      </td>
                      <td>
                        <div className="address-col">
                          <span>a</span>
                          <p>
                            {" "}
                            8282 West South Carolina Avenue Fenwick
                            Island, Delaware
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>14</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>14</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>4 Hr,64 Min</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>4 Hr,64 Min</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>3 Gallons</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="trip-summary">
                <div className="title">
                  <strong>Trip Summary</strong>
                </div>
                <div className="table-wrapper" style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "420px", textAlign: "center" }}>
                          <div style={{ justifyContent: "center" }}>
                            <strong>START</strong>
                            <img src={tableIcon} alt="icon" />
                          </div>
                        </th>
                        <th style={{ width: "420px" }}>
                          <div style={{ justifyContent: "center" }}>
                            <strong>STOP</strong>
                            <img src={tableIcon} alt="icon" />
                          </div>
                        </th>
                        <th style={{ width: "200px" }}>
                          <div>
                            <strong>DRIVING DURATION</strong>
                            <img src={tableIcon} alt="icon" />
                          </div>
                        </th>
                        <th style={{ width: "200px" }}>
                          <div>
                            <strong>MILES</strong>
                            <img src={tableIcon} alt="icon" />
                          </div>
                        </th>
                        <th style={{ width: "200px" }}>
                          <div>
                            <strong>FUEL USED</strong>
                            <img src={tableIcon} alt="icon" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="start-row">
                            <div className="summary-icon">
                              <img src={tripSummaryIcon} alt="icon" />
                            </div>
                            <div className="content">
                              <strong>KROGER</strong>
                              <p>Florence, Kentucky </p>
                              <span>9/12/2023, 1:19 PM</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="start-row">
                            <div className="summary-icon">
                              <img src={tripSummaryIcon} alt="icon" />
                            </div>
                            <div className="content">
                              <strong>KROGER</strong>
                              <p>Florence, Kentucky </p>
                              <span>9/12/2023, 1:19 PM</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>00:10:13</span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>3.92</span>
                            <p>Avg.23 MPH</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>0.58 Gal</span>
                            <p>6.76 MPG</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> */}
            </div>
          </div>
        )}

        {activeTab === "Invoicing" && (
          <div className="custom-tab-pane">
            <HomeWrapper />
          </div>
        )}

        {activeTab === "Log" && <Logs></Logs>}
      </div>
    </div>
  );
};

export default TabsSection;
