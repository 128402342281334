import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

export const addDriverEmpAsyncThunk = createAsyncThunk(
  "fleetDoc/addDriverEmpAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    console.log("data", data);
    const response = await ApiRequests.addDriverEmp(data);
    // if (response.status == 201) {
    //   toast.success("Employemnt Created Successfully!");
    // }
    if (callBack) callBack();
    return response?.data;
  })
);

export const getDriverEmpsAsyncThunk = createAsyncThunk(
  "fleetDoc/getDriverEmpsAsyncThunk",
  catchAsync(async ({ params, callBack }, { getState }) => {
    const response = await ApiRequests.getDriverEmps(params);
    if (callBack) callBack();
    return response?.data;
  })
);

export const getDriverEmpAsyncThunk = createAsyncThunk(
  "fleetDoc/getDriverEmpAsyncThunk",
  catchAsync(async ({ id }) => {
    const response = await ApiRequests.getDriverEmp(id);
    return response?.data;
  })
);

export const updateDriverEmpAsyncThunk = createAsyncThunk(
  "fleetDoc/updateDriverEmpAsyncThunk",
  catchAsync(async ({ id, data, driverId, callBack }, { dispatch }) => {
    const response = await ApiRequests.updateDriverEmp(id, data);
    if (response.status == 200) {
      toast.success("Employemnt Updated Successfully!");
    }
    await dispatch(getDriverEmpAsyncThunk({ id: driverId }));
    if (callBack) callBack();
    return response?.data;
  })
);

export const deleteDriverEmpAsyncThunk = createAsyncThunk(
  "fleetDoc/deleteDriverEmpAsyncThunk",
  catchAsync(async ({ id, callBack }, { dispatch }) => {
    const response = await ApiRequests.deleteDriverEmp(id);
    if (response.status == 204) {
      toast.success("Employemnt Deleted Successfully!");
    }
    await dispatch(getDriverEmpsAsyncThunk());
    if (callBack) callBack();
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //
  DriverEmps: {
    results: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
  driverEmpById: {},
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const driverEmpSlice = createSlice({
  name: "driverEmp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handling fulfilled state for getFleetDocsAsyncThunk

      .addCase(getDriverEmpsAsyncThunk.fulfilled, (state, action) => {
        if (action.meta.arg?.params?.page > 1) {
          // For paginated results, concatenate with existing results
          state.DriverEmps = {
            ...state.DriverEmps,
            ...action.payload,
            results: state.DriverEmps.results.concat(
              action.payload?.results || []
            ),
          };
        } else {
          // For the first page, replace the current results
          state.DriverEmps = action.payload;
        }
      })
      .addCase(getDriverEmpAsyncThunk.pending, (state, action) => {
        state.driverEmpById = {};
      })
      .addCase(getDriverEmpAsyncThunk.fulfilled, (state, action) => {
        state.driverEmpById = action.payload;
      })
      // Manage loading and error states using addMatcher
      .addMatcher(
        isAnyOf(
          ...reduxToolKitCaseBuilder([
            addDriverEmpAsyncThunk,
            getDriverEmpsAsyncThunk,
            getDriverEmpAsyncThunk,
            updateDriverEmpAsyncThunk,
            deleteDriverEmpAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default driverEmpSlice.reducer;
