import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material'
import UsePaginationv2 from './UsePaginationv2';

const rows = [
  {
    status: 'Active',
    date: '9/12/2023, 8:33 AM',
    name: 'Godax Awale',
    address: '123 Main St',
  },
  {
    status: 'Inactive',
    date: '9/12/2023, 9:15 AM',
    name: 'Jane Doe',
    address: '456 Elm St',
  },
  {
    status: 'Pending',
    date: '9/13/2023, 10:30 AM',
    name: 'John Smith',
    address: '789 Oak Ave',
  },
  {
    status: 'Active',
    date: '9/14/2023, 11:45 AM',
    name: 'Emily Johnson',
    address: '101 Pine Rd',
  },
  {
    status: 'Inactive',
    date: '9/15/2023, 1:00 PM',
    name: 'Michael Brown',
    address: '202 Maple Ln',
  },
  {
    status: 'Active',
    date: '9/16/2023, 2:15 PM',
    name: 'Sarah Davis',
    address: '303 Cedar Blvd',
  },
  {
    status: 'Pending',
    date: '9/17/2023, 3:30 PM',
    name: 'David Wilson',
    address: '404 Birch St',
  },
  {
    status: 'Inactive',
    date: '9/18/2023, 4:45 PM',
    name: 'Lisa Anderson',
    address: '505 Walnut Ave',
  },
  {
    status: 'Active',
    date: '9/19/2023, 6:00 PM',
    name: 'Robert Taylor',
    address: '606 Chestnut Rd',
  },
  {
    status: 'Pending',
    date: '9/20/2023, 7:15 PM',
    name: 'Jennifer Martinez',
    address: '707 Spruce Ln',
  },
  {
    status: 'Inactive',
    date: '9/21/2023, 8:30 PM',
    name: 'William Thompson',
    address: '808 Fir Blvd',
  },
  {
    status: 'Active',
    date: '9/22/2023, 9:45 PM',
    name: 'Karen Garcia',
    address: '909 Ash St',
  },
  {
    status: 'Pending',
    date: '9/23/2023, 11:00 PM',
    name: 'Thomas Rodriguez',
    address: '1010 Elm Ave',
  },
  {
    status: 'Inactive',
    date: '9/24/2023, 12:15 AM',
    name: 'Nancy Lee',
    address: '1111 Oak Rd',
  },
  {
    status: 'Active',
    date: '9/25/2023, 1:30 AM',
    name: 'Daniel Harris',
    address: '1212 Pine Ln',
  },
  {
    status: 'Pending',
    date: '9/26/2023, 2:45 AM',
    name: 'Susan Clark',
    address: '1313 Maple Blvd',
  },
  {
    status: 'Inactive',
    date: '9/27/2023, 4:00 AM',
    name: 'Joseph Lewis',
    address: '1414 Cedar St',
  },
  // Add more rows as needed
]

export default function Logs() {
  const [currentPage, setCurrentPage] = useState(1)

  const itemsPerPage = 10
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = rows.slice(indexOfFirstItem, indexOfLastItem)

  return (
    <div className='pb-4 px-2' style={{paddingTop:"32px"}}>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table>
          <TableBody
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              width: '100%',
              background: '#fff',
              '&:last-child td, &:last-child th': { border: 0 },
              '& tr': {
                border: '1px solid var(--Cipta-30, #EEEEF0)',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: { xs: 'flex-start', sm: 'center' },
              },
              '& tr:last-child': {
                marginBottom: 1,
              },
              '& td': {
                color: 'var(--Cipta-90, #4B4B61)',
                fontFamily: 'var(--Font-Poppins, Poppins)',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                width: { xs: '100%', sm: '25%' },
                padding: { xs: '8px 16px', sm: '16px' },
              },
            }}
          >
            {currentItems?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UsePaginationv2
        className='mt-4'
        count={Math.ceil(rows.length / itemsPerPage)}
        onChange={(event, page) => setCurrentPage(page)}
      />
    </div>
  )
}
