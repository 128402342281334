import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import tableDelete from "../../assets/images/table-delete.svg";
import trash from "../../assets/images/trash.svg";
import cancel from "../../assets/images/cancel-right.svg";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="truck-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="truck-modal-body">
        <div className="title">
          <strong>Mark Truck Ordered Not Used</strong>
        </div>
        <div className="description-field">
          <label htmlFor="">Truck Not Used Change</label>
          <input type="number" />
        </div>
        <div className="update-btn">
          <button>Update</button>
        </div>
      </div>
    </Modal>
  );
}

export default function TruckModal({ src, text }) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <button onClick={() => setModalShow(true)}>
        <img src={cancel} alt="icon" />
        <span>Truck Ordered Not Used</span>
      </button>
      {/* <div className="single-dropdown" onClick={() => handleShowModal("tableDropdown")}>
        <img src={tableDelete} alt="icon" /> Delete
      </div> */}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
