import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { MdOutlineSplitscreen } from "react-icons/md";
import { MdOutlineWindow } from "react-icons/md";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import addSquare from "../assets/images/forms-images/add-square-04.svg";

export default function Topbar({ activeViewHandler, activeView }) {
  return (
    <div className="topbar">
      <div className="list-header">
        <div className="container-fluid">
          <div className="list_main_flexbox">
            <div className="text-wrapper">
              <h6>Loads</h6>
            </div>
            <div className="list_views">
              {/* <div className="icons_flex">
                <button
                  onClick={() => activeViewHandler("list")}
                  className={`${activeView === "list" ? "active" : ""}`}
                >
                  <MdOutlineSplitscreen />
                </button>
                <div className="line">|</div>
                <button
                  onClick={() => activeViewHandler("grid")}
                  className={`${activeView === "grid" ? "active" : ""}`}
                >
                  <MdOutlineWindow />
                </button>
              </div> */}
              <Link to={"/create-load"} className="btn-style">
                {" "}
                <img src={addSquare} /> Create Load
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
