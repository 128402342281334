import { Outlet } from "react-router-dom";
import useUserHasRole from "../hooks/useHasRole";
import { useUser } from "@clerk/clerk-react";
import Unauthorized from "./Unauthorized";

const RestrictedRoute = ({ allowedRoles, children }) => {
  const { user } = useUser();
  const hasAccess = useUserHasRole(user, allowedRoles);

  return hasAccess ? children || <Outlet /> : <Unauthorized />;
};

export default RestrictedRoute;
