import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";

export const createLoadAsyncThunk = createAsyncThunk(
  "load/createLoadAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    console.log("🚀 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.createLoad(data);
    await dispatch(getLoadsAsyncThunk({ populate: "customer" }));
    console.log("🚀 ~ catchAsync ~ response:", response.data);
    if (callBack) callBack();
    return response?.data;
  })
);

export const getLoadsAsyncThunk = createAsyncThunk(
  "load/getLoadsAsyncThunk",
  catchAsync(async (params) => {
    console.log("🚀 ~ catchAsync ~ params:", params);
    const response = await ApiRequests.getLoads(params);
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const getLoadAsyncThunk = createAsyncThunk(
  "load/getLoadAsyncThunk",
  catchAsync(async (id) => {
    console.log("🚀 ~ catchAsync ~ id:", id);
    const response = await ApiRequests.getLoad(id);
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const updateLoadAsyncThunk = createAsyncThunk(
  "load/updateLoadsAsyncThunk",
  catchAsync(async ({ id, data, callBack }, { dispatch }) => {
    console.log("🚀 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.updateLoad({ id, data });
    console.log("🚀 ~ catchAsync ~ response:", response);
    await dispatch(getLoadsAsyncThunk({ email: id }));
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const deleteLoadAsyncThunk = createAsyncThunk(
  "load/deleteLoadsAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch }) => {
    const response = await ApiRequests.deleteLoad(data);
    await dispatch(getLoadsAsyncThunk());
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  load: {
    results: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
  },
  loadById: {},

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const loadSlice = createSlice({
  name: "load",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //

      .addCase(getLoadsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.load = {
            ...action.payload,
            load: state?.load?.results.concat(action?.payload?.results),
          };
        } else {
          state.load = action.payload;
        }
      })

      .addCase(getLoadAsyncThunk.fulfilled, (state, action) => {
        state.loadById = action.payload;
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createLoadAsyncThunk,
            getLoadsAsyncThunk,
            getLoadAsyncThunk,
            updateLoadAsyncThunk,
            deleteLoadAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  loadSlice.actions;

export default loadSlice.reducer;
