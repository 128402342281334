import React, { useEffect, useState } from "react";
import delicon from "../assets/images/delete.svg";
import downloadicon from "../assets/images/download-icon.svg";
import pencilicon from "../assets/images/pencil.svg";
import pencilIcon from "../assets/images/pencil-edit.svg";
import EditDriverProfileModal from "../components/Modals/EditDriverProfileModal";
import moment from "moment"; // for date formatting
import { State } from "country-state-city"; // Import the State module
import { useDispatch, useSelector } from "react-redux";
import { getDriverLinceseAsyncThunk } from "../redux/pagesSlices/driverSlice";
import EditDriverLicenseModal from "../components/Modals/EditDriverLicenseModal";
import DeleteDriverLicenseModal from "../components/Modals/DeleteDriverLicenseModal";
import { saveAs } from "file-saver";
import { getFileNameFromUrl } from "../helpers/file";

export default function DriverLicenseform({ user }) {
  const { loadings, driverLicense } = useSelector((state) => state.drivers);
  const [showEditDriverProfile, setShowEditDiverProfile] = useState(false);
  const [stateDetails, setStateDetails] = useState({});
  const [issuingStateDetails, setIssuingStateDetails] = useState({});
  const [editDriverLicenseModalShow, setEditDriverLicenseModalShow] =
    useState(false);
  const [deleteModalShow, deleteSetModalShow] = useState(false);
  const dispatch = useDispatch();
  const states = State.getStatesOfCountry("US");
  const handleShowEditProfileModal = () => setShowEditDiverProfile(true);
  const handleHideEditProfileModal = () => setShowEditDiverProfile(false);

  useEffect(() => {
    dispatch(
      getDriverLinceseAsyncThunk({
        driverId: user.user_id,
      })
    );
  }, []);

  const handleDriverLicenseModalOpen = () => {
    setEditDriverLicenseModalShow(true);
  };
  const handleDriverLicenseModalClose = () => {
    setEditDriverLicenseModalShow(false);
  };

  const handleDeleteModalOpen = () => {
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
  };

  useEffect(() => {
    if (user && user?.state) {
      setStateDetails(states.find((state) => state.isoCode === user?.state));
    }
    if (user && user?.issuingState) {
      setIssuingStateDetails(
        states.find((state) => state.isoCode === user?.issuingState)
      );
    }
  }, [user]);

  const downloadFilesSequentially = async () => {
    const files = driverLicense?.files?.map(
      (file) => `${process.env.REACT_APP_CLOUDFLARE_URL}${file}`
    );

    for (const fullUrl of files) {
      const link = document.createElement("a");
      link.href = fullUrl;
      link.download = getFileNameFromUrl(fullUrl); // Extracts the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Adding a delay between each download
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay
    }
  };

  return (
    <div className="driver-detail-tab">
      <div className="container">
        <div className="form-wrapper li-form">
          <h1 className="topic-text">Driver License</h1>

          <div className="dl-div">
            <div className="dl-update-div">
              <div className="dl-text">DL</div>
              <div className="double-text">
                <h1>Driver License</h1>
                <span>Driver License</span>
              </div>
            </div>
            <div className="missing-update-div">
              <div className="missing-text-1">
                <h1>Updated</h1>
                <span>
                  {driverLicense?.updatedAt
                    ? moment(driverLicense?.updatedAt).format("DD MMM, YYYY")
                    : "Missing"}
                </span>
              </div>
              <div className="missing-text-1">
                <h1>Expiration</h1>
                <span>
                  {driverLicense?.expiryDate
                    ? moment(driverLicense?.expiryDate).format("DD MMM, YYYY")
                    : "Missing"}
                </span>
              </div>
            </div>

            <div className="dl-expiring-div">
              <button
                className="btn btn-link text-decoration-none text-dark p-0"
                onClick={handleDriverLicenseModalOpen}
              >
                <img src={pencilicon} alt="Edit" className="main-icons" />
              </button>
              {editDriverLicenseModalShow && (
                <EditDriverLicenseModal
                  license={driverLicense}
                  driverId={user.user_id}
                  editDriverLicenseModalShow={editDriverLicenseModalShow}
                  handleDriverLicenseModalClose={handleDriverLicenseModalClose}
                />
              )}
              {/* <img src={downloadicon} alt="Download" className="main-icons" /> */}
              {driverLicense?.files && driverLicense?.files.length > 0 && (
                <div className="single-action">
                  <button
                    className="btn btn-link text-decoration-none text-dark p-0"
                    onClick={downloadFilesSequentially}
                  >
                    <img src={downloadicon} alt="icon" className="main-icons" />
                  </button>
                </div>
              )}
              <button
                className="btn btn-link text-decoration-none text-dark p-0"
                onClick={handleDeleteModalOpen}
              >
                <img src={delicon} alt="Delete" className="main-icons" />
              </button>
              {driverLicense && deleteModalShow && (
                <DeleteDriverLicenseModal
                  driverId={user.user_id}
                  handleDeleteModalClose={handleDeleteModalClose}
                  deleteModalShow={deleteModalShow}
                />
              )}
            </div>
          </div>

          <div className="driver-profile-info-wrapper">
            <div className="profile-title-head">
              <h1 className="topic-text-2">Profile</h1>
              <button className="icon" onClick={handleShowEditProfileModal}>
                <img src={pencilIcon} alt="Edit Profile" />
              </button>
              {showEditDriverProfile && (
                <EditDriverProfileModal
                  showEditDriverProfile={showEditDriverProfile}
                  handleHideEditProfileModal={handleHideEditProfileModal}
                  user={user}
                />
              )}
            </div>
            <div className="profile-details-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Full Name</span>
                    <strong>
                      {user.displayName || `${user.firstName} ${user.lastName}`}
                    </strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Phone Number</span>
                    <strong>{user.phone || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Email</span>
                    <strong>{user.email || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Assign Truck</span>
                    <strong>
                      {(user?.fleets?.length > 0 &&
                        user?.fleets[0]?.fleetNumber) ||
                        "N/A"}
                    </strong>{" "}
                    {/* Replace with actual data if available */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>City</span>
                    <strong>{user.city || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>State</span>
                    <strong>{stateDetails?.name || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Zip Code</span>
                    <strong>{user.zipCode || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Address</span>
                    <strong>{user.address || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Date of Birth</span>
                    <strong>
                      {user.dateOfBirth
                        ? moment(user.dateOfBirth).format("DD MMM, YYYY")
                        : "N/A"}
                    </strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Date of Hire</span>
                    <strong>
                      {user.hireDate
                        ? moment(user.hireDate).format("DD MMM, YYYY")
                        : "N/A"}
                    </strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>License Number</span>
                    <strong>{user.licenseNumber || "N/A"}</strong>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="single-item">
                    <span>Issuing State for License</span>
                    <strong>{issuingStateDetails?.name || "N/A"}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
