import React from 'react'
import { Link } from 'react-router-dom';
import Titlebar from '../components/adminPanelComponents/Titlebar'
import Sidebar from '../components/adminPanelComponents/Sidebar'
import tableIcon from "../assets/images/table-icon.svg";


const CompanyMissingPage = () => {
  return (
   <>
   
   
   <Titlebar />
            <div className="row">
                <div className="col-12 col-md-2 p-0">
                    <Sidebar />
                </div>

                <div className="col-12 col-md-10 p-0">
                    <div className="integration-topbar">

                        <div className="integration-link">
                            <Link to={'/compliance'} >Company Documents</Link>
                            <Link to={'/dashboard'}>Compliance Documentation Dashboard</Link>
                        </div>
                    </div>

                    <div className="bg-color">
                            <div className="items-list">
                                <ul>
                                    <li>Company</li>
                                    <li>Driver</li>
                                    <li>Truck</li>
                                </ul>
                            </div>

                            <div className="integration-topbar">

<div className="integration-link">
    <Link to={'/dashboard'}>Expired Documents</Link>
    <Link to={'/expired-soon'}>Expired Soon</Link>
    <Link to={'/missing'}>Missing</Link>
</div>
</div>

<div className="integration-topbar">
<div className="integration-link">
    <p>5  Documents Expired</p>
</div>
</div>




                            <div className="table-wrapper">

                                <table>
                                    <thead>
                                        <tr>


                                            <th>
                                                <div>
                                                    <strong>DOCUMENT TITLE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>


                                            <th>
                                                <div>
                                                    <strong>UPDATED DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>

                                            <th>
                                                <div>
                                                    <strong>EXPIRATION DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>

                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>

                                        </tr>


                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>

                                        </tr>

                                 

                               


                                    </tbody>
                                </table>

                            </div>
                      
                    </div>




                </div>
            </div>

   
   </>
  )
}

export default CompanyMissingPage
