import axios from "axios";
import { APIurls, appPath } from "../constants";

const apiService = axios.create();
apiService.defaults.baseURL = appPath;
// apiService.defaults.timeout = 35000;

// Add a variable to track whether a token refresh is in progress
let isRefreshing = false;

// Create an array to store the requests that are waiting for a new token
let refreshSubscribers = [];

// Function to refresh the token
async function refreshAccessToken() {
  try {
    const refreshToken = localStorage.getItem("app-refresh-token");
    const response = await apiService.post(APIurls.refreshTokens, {
      refreshToken,
    });

    const newAccessToken = response.data;
    console.log("🚀 ~ refreshAccessToken ~ newAccessToken:", newAccessToken)
    const token = newAccessToken?.access?.token;
    const refreshTokenStr = newAccessToken?.refresh?.token;

    // Update tokens in localStorage
    localStorage.setItem("app-access-token", token);
    localStorage.setItem("app-refresh-token", refreshTokenStr);

    // Notify subscribers with the new token
    refreshSubscribers.forEach((cb) => cb(newAccessToken));
    refreshSubscribers = [];
  } catch (error) {
    isRefreshing = false;
    // Handle token refresh failure
    localStorage.clear();
    window.location.href = `/sign-up`;
    refreshSubscribers.forEach((cb) => cb(null));
    refreshSubscribers = [];
  } finally {
    isRefreshing = false;
  }
}


// Intercept requests and add the access token to the headers
apiService.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("app-access-token");
    const activeOrg = localStorage.getItem("active_org");
    const clerkActiveOrg = localStorage.getItem("clerk_active_org");
    const isFormData = config.data instanceof FormData;

    config.headers = {
      Accept: "application/json, text/plain, */*",
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-control-allow-headers": "*",
      "access-control-allow-methods": "*",
      "access-control-allow-origin": "*",
      "active-org": activeOrg || clerkActiveOrg,
    };
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercept responses and handle 401 errors
apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.__isRetryRequest
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        // await refreshAccessToken();
      }

      return new Promise((resolve, reject) => {
        refreshSubscribers.push((newAccessToken) => {
          if (newAccessToken) {
            const token = newAccessToken?.access?.token;
            if (token) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(apiService(originalRequest));
            } else {
              reject(error);
            }
          } else {
            reject(error);
          }
        });
      });
    }

    return Promise.reject(error);
  }
);


export default apiService;
