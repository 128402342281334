import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar as MuiAvatar,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { ReactComponent as EditIcon } from "../assets/images/pencil.svg";
import { ReactComponent as DownloadIcon } from "../assets/images/download-icon.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete.svg";

import { styled } from "@mui/material/styles";
import EditDetailModal from "./Modals/EditDetailModal";
import DeleteLoadModal from "./Modals/DeleteLoadModal";
import { getUserDocsAsyncThunk } from "../redux/pagesSlices/userDocSlice";
import { useDispatch, useSelector } from "react-redux";

import Nodatafound from "../components/nodatafound";
import Loadingdata from "../components/loadingdata";
import { getFileNameFromUrl } from "../helpers/file";
import { saveAs } from "file-saver";
import { getFirstTwoUpperCase } from "../helpers/parse";
import { defaultUserDocuments } from "../constant/documents";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "& .MuiTableCell-root": {
    padding: "12px",
    [theme.breakpoints.up("sm")]: {
      padding: "24px",
    },
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({}));
const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: "50px",
  height: "50px",
  marginRight: "16px",
  color: theme.palette.primary.main,
  backgroundColor: "#F5F8FC",
}));
const Title = styled(Typography)(({ theme }) => ({
  color: "var(--Cipta-100, #0F0F2C)",
  fontFamily: "var(--Font-Poppins, Poppins)",
  fontSize: theme.breakpoints.up("sm") ? "0.875rem" : "0.75rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  marginBottom: "6px",
}));
const Description = styled(Typography)(({ theme }) => ({
  color: "var(--Cipta-80, #68687A)",
  fontFamily: "var(--Font-Poppins, Poppins)",
  fontSize: "0.75rem",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
}));

export default function CompanyDocuments() {
  const dispatch = useDispatch();
  const { activeOrg } = useSelector((state) => state.workspace);
  const { userDocuments } = useSelector((state) => state.userDoc);
  const loading = useSelector(
    (state) => state.userDoc?.loadings?.getUserDocsAsyncThunk
  ); // Assuming your Redux slice tracks loading state
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documents, setDocuments] = useState(null);
  // Fetch documents on component mount
  // useEffect(() => {
  //   dispatch(getUserDocsAsyncThunk({ params: { page: 1, limit: 10 } }));
  // }, [dispatch, activeOrg, deleteModalShow, editModalShow]);

  const fetchDocuments = async () => {
    const params = {
      referenceType: "USER",
      referenceId: activeOrg,
    };
    dispatch(getUserDocsAsyncThunk({ params }));
  };

  useEffect(() => {
    // if (activeOrg) {
    fetchDocuments();
    // }
  }, [dispatch, activeOrg]);

  useEffect(() => {
    if (userDocuments?.length > 0 || defaultUserDocuments?.length > 0) {
      const docsWithData = defaultUserDocuments?.map((doc) => {
        const docData = userDocuments.find(
          (data) => data.documentType === doc.documentType
        );
        return {
          ...doc,
          documentDesc: docData?.documentDesc || null,
          createdAt: docData?.createdAt || null,
          updatedAt: docData?.updatedAt || null,
          expiryDate: docData?.expiryDate || null,
          file: docData?.file || null,
          id: docData?.id || null,
        };
      });
      setDocuments(docsWithData);
    }
  }, [userDocuments, defaultUserDocuments]);

  // Open the edit modal and set the selected document data
  const handleEditModalOpen = (doc) => {
    setSelectedDocument(doc);
    setEditModalShow(true);
  };

  // Close the edit modal
  const handleEditModalClose = () => {
    setEditModalShow(false);
    setSelectedDocument(null); // Clear the selected document
  };

  const handleDeleteModalOpen = (doc) => {
    setSelectedDocument(doc);
    setDeleteModalShow(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalShow(false);
    setSelectedDocument(null);
  };

  // Show loading state
  if (loading) {
    return <Loadingdata />;
  }

  // Show "No Data Found" if documents.results is empty or undefined
  if (!documents?.length === 0) {
    return <Nodatafound nodataTitle="No Documents Found" />;
  }

  return (
    <Table>
      <TableBody>
        {documents?.map((doc, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell
              sx={{ maxWidth: "40px", overflowWrap: "break-word" }}
            >
              <Box display="flex" alignItems="center">
                <div className="userDoc-icon">
                  {getFirstTwoUpperCase(doc.documentType)}
                </div>
                <Box>
                  <Title variant="subtitle1">{doc.documentType}</Title>
                  <Description variant="body2">{doc.documentDesc}</Description>
                </Box>
              </Box>
            </StyledTableCell>
            <StyledTableCell sx={{ width: "20%" }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Title variant="subtitle1">Updated</Title>
                <Description variant="body2">
                  {doc.updatedAt
                    ? new Date(doc.updatedAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "Missing"}
                </Description>
              </Box>
            </StyledTableCell>

            <StyledTableCell sx={{ width: "20%" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Title variant="subtitle1">Expiration</Title>
                <Description variant="body2">
                  {doc.expiryDate
                    ? new Date(doc.expiryDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "Missing"}
                </Description>
              </Box>
            </StyledTableCell>

            <StyledTableCell sx={{ width: "20%" }}>
              <IconButton
                sx={{ padding: "15px" }}
                onClick={() => handleEditModalOpen(doc)}
              >
                <EditIcon width={14} height={14} />
              </IconButton>
              {doc.file && (
                <IconButton
                  sx={{ padding: "12px" }}
                  onClick={() =>
                    saveAs(
                      process.env.REACT_APP_CLOUDFLARE_URL + doc.file,
                      getFileNameFromUrl(
                        process.env.REACT_APP_CLOUDFLARE_URL + doc.file
                      )
                    )
                  }
                >
                  <DownloadIcon width={20} height={20} />
                </IconButton>
              )}
              <IconButton
                sx={{ padding: "12px" }}
                onClick={() => handleDeleteModalOpen(doc)}
              >
                <DeleteIcon width={20} height={20} />
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>

      {/* Edit Modal */}
      <EditDetailModal
        editModalShow={editModalShow}
        handleEditModalClose={handleEditModalClose}
        document={selectedDocument}
      />

      {/* Delete Modal */}
      <DeleteLoadModal
        deleteModalShow={deleteModalShow}
        handleDeleteModalClose={handleDeleteModalClose}
        document={selectedDocument}
      />
    </Table>
  );
}
