import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import fileImg from "../../assets/images/forms-images/material-symbols_upload.svg";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import {
  getDriverLinceseAsyncThunk,
  updateDriverLinceseAsyncThunk,
} from "../../redux/pagesSlices/driverSlice";

export default function EditDriverLicenseModal({
  driverId,
  license,
  editDriverLicenseModalShow,
  handleDriverLicenseModalClose,
}) {
  const dispatch = useDispatch();
  const { loadings } = useSelector((state) => state?.drivers);
  const fileInputRef = useRef(null); // Reference for the file input
  const [expiryDate, setExpiryDate] = useState("");

  useEffect(() => {
    if (license) {
      let expiryDate = moment(license?.expiryDate).format("YYYY-MM-DD");
      setExpiryDate(expiryDate);
    }
  }, [license]);

  const validationSchema = Yup.object({
    expiryDate: Yup.string()
      .test(
        "is-valid-date",
        "Expiration date is invalid or cannot be in the past",
        (value) =>
          moment(value, "YYYY-MM-DD", true).isValid() &&
          moment(value).isSameOrAfter(moment())
      )
      .required("Expiration date is required"),
    files: Yup.mixed()
      .nullable()
      .test(
        "fileFormat",
        "Unsupported file format. Only JPEG, PNG, and PDF files are allowed.",
        (files) => {
          if (!files) return true; // No file selected is valid
          return Array.from(files).every((file) =>
            ["image/jpeg", "image/png", "application/pdf"].includes(file.type)
          );
        }
      )
      .test(
        "fileCount",
        "You can only upload a maximum of 2 files",
        (files) => !files || files.length <= 2
      ),
  });

  const formik = useFormik({
    initialValues: {
      expiryDate: expiryDate || "",
      files: null,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("expiryDate", values.expiryDate);

      if (values.files && values.files.length > 0) {
        Array.from(values.files).forEach((file) => {
          formData.append("files", file);
        });
      }

      dispatch(
        updateDriverLinceseAsyncThunk({
          id: driverId,
          data: formData,
          callBack: () => {
            formik.resetForm();
            handleDriverLicenseModalClose();
            handleRemoveFile();
            dispatch(
              getDriverLinceseAsyncThunk({
                driverId,
              })
            );
          },
        })
      );
    },
  });

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    formik.setFieldValue("files", files);
  };

  const handleRemoveFile = () => {
    formik.setFieldValue("files", null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Modal
      show={editDriverLicenseModalShow}
      onHide={handleDriverLicenseModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-truck-btn"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit License</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-field">
            <label>Expiration Date</label>
            <input
              type="date"
              name="expiryDate"
              value={formik.values.expiryDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.expiryDate && formik.errors.expiryDate ? (
              <div className="error">{formik.errors.expiryDate}</div>
            ) : null}
          </div>

          <div className="attach-docs">
            <label>Attach Files</label>
            <div className="select-file">
              <div
                className="selectfile-border"
                onClick={() => fileInputRef.current.click()}
              >
                <img src={fileImg} alt="Upload Icon" />
                <p>Select new file</p>
              </div>
              <input
                type="file"
                id="attach-document"
                multiple
                ref={fileInputRef}
                onChange={handleFileInputChange}
                style={{ position: "absolute", left: "-9999px" }}
              />
              {formik.values.files && formik.values.files.length > 0 && (
                <div className="remove-text" onClick={handleRemoveFile}>
                  <p>Remove</p>
                </div>
              )}
            </div>
            {formik.errors.files ? (
              <div className="error mb-3">{formik.errors.files}</div>
            ) : null}
          </div>

          <Button
            type="submit"
            disabled={
              loadings?.updateDriverLinceseAsyncThunk ||
              !formik.isValid ||
              !formik.dirty
            }
            className="btn-style"
          >
            {loadings?.updateDriverLinceseAsyncThunk ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              "Update"
            )}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
