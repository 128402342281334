import { useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateFleetAsyncThunk } from "../../redux/pagesSlices/fleetSlice";
import { getDriversAsyncThunk } from "../../redux/pagesSlices/driverSlice";

export default function EditFleetDetailsModal({
  showFleetDetailsModal,
  handleHideFleetModal,
  fleetData,
}) {
  console.log("🚀 ~ fleetData:", fleetData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDriversAsyncThunk());
  }, []);
  const getAllDrivers = useSelector((s) => s?.drivers?.drivers?.results);

  // Validation schema with Yup
  const validationSchema = Yup.object({
    // licenseNumber: Yup.string().required("License Number is required"),
    // odometer: Yup.string().required("VIN is required"),
    // assignDriver: Yup.string().required("Driver assignment is required"),

    fleetNumber: Yup.string()
      .matches(/^[0-9]+$/, "Fleet Number must be numeric")
      .required("Fleet Number is required")
      .max(32),
    modelYear: Yup.number("Model Year must be a number")
      .min(1800, "Model Year must be at least 1800")
      .max(new Date().getFullYear(), "Model Year cannot be in the future")
      .required("Model Year is required"),
    fleetMake: Yup.string()
      .required("Fleet Make is required")
      .min(1, "Fleet Make cannot be empty")
      .max(32, "Fleet Make cannot exceed 32 characters"),
    fleetModel: Yup.string()
      .matches(/^[0-9]+$/, "Fleet Model must be numeric")
      .required("Fleet Model is required")
      .max(32),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      fleetNumber: fleetData?.fleetNumber || "",
      modelYear: fleetData?.modelYear || "",
      fleetMake: fleetData?.fleetMake || "",
      fleetModel: fleetData?.fleetModel || "",
      licenseNumber: fleetData?.licenseNumber || "",
      odometer: fleetData?.odometer || "",
      users: fleetData?.users?.[0]?.user_id || "", // Set to first driver if available
    },
    enableReinitialize: true, // Update form values when fleetData changes
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const {
        id,
        userId,
        users,
        createdAt,
        updatedAt,
        drivers,
        isOwner,
        ...allowedData
      } = fleetData;

      const updatedData = {
        ...allowedData,
        fleetNumber: values.fleetNumber,
        modelYear: values.modelYear,
        fleetMake: values.fleetMake,
        fleetModel: values.fleetModel,
        licenseNumber: values.licenseNumber,
        odometer: values.odometer,
        users: [values.users],
      };

      dispatch(
        updateFleetAsyncThunk({ id: fleetData.id, data: updatedData })
      ).then(() => {
        handleHideFleetModal();
      });
    },
  });

  return (
    <Modal
      centered
      className="add-truck-btn"
      show={showFleetDetailsModal}
      onHide={handleHideFleetModal}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Truck Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <div className="add-truck-stepper-form-wrapper">
            <div className="form-wrapper">
              <div className="form-fields">
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-field">
                      <label>Truck Number *</label>
                      <input
                        type="text"
                        name="fleetNumber"
                        value={formik.values.fleetNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Truck Number"
                      />
                      {formik.touched.fleetNumber &&
                      formik.errors.fleetNumber ? (
                        <div className="error">{formik.errors.fleetNumber}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-field">
                      <label>Year *</label>
                      <input
                        type="text"
                        name="modelYear"
                        value={formik.values.modelYear}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Year"
                      />
                      {formik.touched.modelYear && formik.errors.modelYear ? (
                        <div className="error">{formik.errors.modelYear}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-field">
                      <label>Make *</label>
                      <select
                        name="fleetMake"
                        value={formik.values.fleetMake}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select</option>
                        <option value="Freightliner">Freightliner</option>
                        <option value="Kenworth">Kenworth</option>
                        {/* Add other options as needed */}
                      </select>
                      {formik.touched.fleetMake && formik.errors.fleetMake ? (
                        <div className="error">{formik.errors.fleetMake}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-field">
                      <label>Model *</label>
                      <input
                        type="text"
                        name="fleetModel"
                        value={formik.values.fleetModel}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="License Number"
                      />
                      {formik.touched.fleetModel && formik.errors.fleetModel ? (
                        <div className="error">{formik.errors.fleetModel}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-field">
                      <label>License Number</label>
                      <input
                        type="text"
                        name="licenseNumber"
                        value={formik.values.licenseNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="License Number"
                      />
                      {formik.touched.licenseNumber &&
                      formik.errors.licenseNumber ? (
                        <div className="error">
                          {formik.errors.licenseNumber}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-field">
                      <label>VIN</label>
                      <input
                        type="text"
                        name="odometer"
                        value={formik.values.odometer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="VIN"
                      />
                      {formik.touched.odometer && formik.errors.odometer ? (
                        <div className="error">{formik.errors.odometer}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="single-field">
                      <label>Assign Driver</label>
                      <select
                        name="users"
                        value={formik.values.users}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select</option>
                        {getAllDrivers &&
                          getAllDrivers.map((driver) => (
                            <option key={driver.user_id} value={driver.user_id}>
                              {driver.email}
                            </option>
                          ))}
                      </select>
                      {formik.touched.users && formik.errors.users ? (
                        <div className="error">{formik.errors.users}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-buttons skip-section">
                <div className="single-btn">
                  <button
                    type="submit"
                    className="bg-blue"
                    disabled={formik.isSubmitting || !formik.dirty}
                  >
                    {formik.isSubmitting ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
