import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import file from "../../assets/images/forms-images/material-symbols_upload.svg";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { getFleetDocAsyncThunk } from "../../redux/pagesSlices/fleetDocSlice";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { FiUpload } from "react-icons/fi";

export default function EditTruckDetailsModal({
  editId,
  fleetData,
  editTruckModalShow,
  handleTruckModalClose,
}) {
  const dispatch = useDispatch();
  const { fleetDocById } = useSelector((state) => state?.fleetDoc);
  const [expiryDate, setExpiryDate] = useState("");
  const [base64URL, setBase64URL] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null); // Reference for the file input

  const fetchDocument = async (editId) => {
    dispatch(getFleetDocAsyncThunk({ id: editId }));
  };

  useEffect(() => {
    if (editId !== "") {
      fetchDocument(editId);
    }
  }, [editId]);

  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        // if (img.width >= 400 && img.height >= 400) {
        resolve(true);
        // } else {
        // reject(
        //   new Error("Image dimensions should be at least 400px by 400px")
        // );
        // }
      };
      img.onerror = reject;
    });
  };
  // Function to convert file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "application/pdf",
        "application/msword",
      ];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG and JPEG files are allowed.");
        return;
      }

      // // Validate file size (10MB = 10 * 1024 * 1024 bytes)
      // const maxSizeInBytes = 10 * 1024 * 1024;
      // if (file.size > maxSizeInBytes) {
      //   toast.error("File size should not exceed 10MB.");
      //   return;
      // }

      // Validate image dimensions
      try {
        await checkImageDimensions(file);
      } catch (dimensionError) {
        toast.error(dimensionError.message);
        return;
      }

      // If all validations pass, convert to Base64
      try {
        const base64 = await convertToBase64(file);
        setBase64URL(base64);
        setSelectedFile(file);
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }
  };

  const handleRemoveFile = () => {
    // setBase64URL("");
    // setSelectedFile(null);
    // if (fileInputRef.current) {
    //   fileInputRef.current.value = ""; // Reset file input value
    // }
  };
  console.log("selectedFile", selectedFile);

  const handleUpdate = () => {
    var formData = new FormData();

    // Append other form data
    formData.append("expiryDate", expiryDate);
    formData.append("fleetId", fleetData.id);

    if (selectedFile) {
      formData.append("file", selectedFile);
    } else {
      formData = { expiryDate, fleetId: fleetData.id };
    }
    console.log("formData", formData);

    // dispatch(
    //   updateFleetDocAsyncThunk({
    //     id: editId,
    //     data: formData,
    //     callBack: () => {
    //       handleRemoveFile();
    //       handleTruckModalClose();
    //     },
    //   })
    // );
  };

  return (
    <Modal
      show={editTruckModalShow}
      onHide={handleTruckModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="add-truck-btn"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Details </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-field">
          <label>Expiration Date</label>
          <input
            type="date"
            placeholder="max"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </div>

        <div className="attach-docs">
          <label>Attach Document</label>
          <Box sx={{ mt: 2, mb: 4 }}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<FiUpload />}
              sx={{
                "&.MuiButton-root": {
                  padding: "10px 20px",
                  color: "var(--Primary-P300, #0065EC)",
                  textAlign: "center",
                  fontFamily: "var(--Font-Poppins, Poppins)",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                },
              }}
              onClick={() => document.getElementById("attach-document").click()}
            >
              Upload
            </Button>
            <input
              type="file"
              id="attach-document"
              hidden
              onChange={handleFileInputChange}
              ref={fileInputRef}
            />
            <Button variant="text" onClick={handleRemoveFile} sx={{ ml: 2 }}>
              Remove
            </Button>
          </Box>
          {/* <div className="select-file">
            <div
              className="selectfile-border"
              onClick={() => document.getElementById("attach-document").click()}
            >
              <img src={file} alt="Upload Icon" />
              <p>Select new file</p>
            </div>
            <input
              type="file"
              id="attach-document"
              hidden
              onChange={handleFileInputChange}
              ref={fileInputRef}
            />
            <div className="remove-text" onClick={handleRemoveFile}>
              <p>Remove</p>
            </div>
          </div> */}
        </div>

        <Button className="btn-style" onClick={handleUpdate}>
          Update
        </Button>
      </Modal.Body>
    </Modal>
  );
}
