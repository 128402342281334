import { useDispatch, useSelector } from "react-redux";
import DriversDetailTabs from "../components/DriversDetailTabs";
import DriversDetailTopbar from "../components/DriversDetailTopbar";
import DriversDetailTopbarNew from "../components/DriversDetailTopbarNew";
import DriversPageTopBar from "../components/DriversPageTopbar";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getUserAsyncThunk } from "../redux/pagesSlices/userSlice";
import { Spinner } from "react-bootstrap";

export default function DriversDetailPage() {
  const dispatch = useDispatch();
  const { user, loadings } = useSelector((state) => state.user);

  const { driverId } = useParams();

  useEffect(() => {
    const params = { include: ["documents", "fleets"] };
    dispatch(getUserAsyncThunk({ driverId, params }));
    // dispatch(getLoadAsyncThunk(params?.id));
  }, [driverId]);

  return (
    <>
      <DriversDetailTopbar user={user} />
      <DriversDetailTabs user={user}
              getUserLoading={loadings.getUserAsyncThunk}
 />
    </>
  );
}
