import emptyDocs from "../assets/images/document-icon.svg";
import addDocIcon from "../assets/images/add-document.svg";

export default function EmptyDocuments() {
  return (
    <div className="empty-docs-wrapper">
      <div className="container">
        <div className="inner-wrapper">
        <div className="doc-icon">
          <img src={emptyDocs} alt="doc icon" />
        </div>
        <div className="content">
          <strong>You don’t have any documents</strong>
          <p>Add a document now to your load.</p>
        </div>
        <div className="add-btn">
          <button>
            <div className="icon">
              <img src={addDocIcon} alt="icon" />
            </div>
            <span>Add Document</span>
          </button>
        </div>
        </div>
        
      </div>
    </div>
  );
}
