import React, { useEffect, useState } from "react";
import delieveryIcon from "../assets/images/delivery-icon.svg";
import { FaAngleDown } from "react-icons/fa6";
import { useUser } from "@clerk/clerk-react";
import { useDispatch, useSelector } from "react-redux";
import { switchWorkspaceAsyncThunk } from "../redux/pagesSlices/workspaceSlice";
import tick from "../assets/images/tick.svg";
import plusIcon from "../assets/images/plus.svg";
import { useNavigate } from "react-router-dom";
import { getFirstTwoUpperCase } from "../helpers/parse";

const CustomDropdown = () => {
  const [organizations, setOrganizations] = useState([]);
  const { activeOrg } = useSelector((state) => state.workspace);

  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setOrganizations(
        user.organizationMemberships.map((org) => org.organization)
      );
    }
  }, [user]);

  const activeWorkspace = localStorage.getItem("active_org");
  const dispatch = useDispatch();

  // State to manage dropdown visibility
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the dropdown open/close
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Handle selecting an option from the dropdown
  const handleOptionSelect = (orgId) => {
    dispatch(
      switchWorkspaceAsyncThunk({
        org: orgId,
        callBack: () => {
          setIsOpen(false);
        },
      })
    );
  };

  // Check if there are available workspaces
  const hasWorkspaces = organizations?.length > 0;

  return (
    <div className="custom-dropdown">
      {/* Dropdown Header */}
      <div className="custom-dropdown-header" onClick={toggleDropdown}>
        <span className="custom-dropdown-icon">
          <img src={delieveryIcon} alt="icon" />
        </span>
        <span className="custom-dropdown-title">
          {organizations?.find(
            (membership) => membership.id === activeWorkspace
          )?.name || "Select workspace"}
        </span>
        <span className={`custom-dropdown-arrow ${isOpen ? "open" : ""}`}>
          <FaAngleDown />
        </span>
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <div className="custom-dropdown-menu">
          <div className="dropdown-title">
            <span>SWITCH WORKSPACES</span>
          </div>
          {hasWorkspaces ? (
            organizations.map((organization, i) => (
              <div
                key={i}
                className="custom-dropdown-option"
                onClick={() => handleOptionSelect(organization.id)}
              >
                <div className="name-section">
                  {organization?.publicMetadata?.companyLogo ? (
                    <div className="workspace-avatar">
                      <img
                        src={
                          process.env.REACT_APP_CLOUDFLARE_URL +
                          organization?.publicMetadata?.companyLogo
                        }
                        alt="avatar"
                        // onError={(e) => {
                        //   e.target.onerror = null;
                        // }}
                      />
                    </div>
                  ) : (
                    <div className="icon">
                      {getFirstTwoUpperCase(organization.name)}
                    </div>
                  )}
                  <div className="workspace-name">
                    <strong>{organization.name}</strong>
                    <span>
                      Free Forever . {organization.membersCount} members
                    </span>
                  </div>
                </div>
                {organization.id === activeOrg && (
                  <div className="tick-icon">
                    <img src={tick} alt="icon" />
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="custom-dropdown-option">No Workspace Available</div>
          )}
          <button
            onClick={() => {
              navigate("/workSpace");
              setIsOpen(false);
            }}
            className="add-workspace-option"
          >
            <img src={plusIcon} alt="icon" />
            <span>New Workspace</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
