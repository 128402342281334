import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  Modal,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'

export default function CreateInvoiceModal(props) {
  return (
    <Modal
      open={props.createInvoiceModalOpen}
      onClose={props.handleCloseCreateInvoiceModal}
      aria-labelledby='create-invoice-modal-title'
      className='create-invoice-modal'
    >
      <Box
        className='create-invoice-modal-content'
                sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: '90%',
            sm: '80%',
            md: 519,
          },
          maxWidth: 519,
          bgcolor: '#fff',
          p: 3,
          borderRadius: '16px',
          border: '1px solid #dfdfe2',
          boxShadow: '0px 15px 32px 0px rgba(0, 0, 0, 0.10)',
          overflowY: 'auto',
          minHeight: '90vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3,
          }}
        >
          <Typography variant='modalTitleClass' id='create-invoice-modal-title'>
            New Invoice For Load #A5474736
          </Typography>
          <IconButton
            aria-label='close'
            onClick={props.handleCloseCreateInvoiceModal}
            sx={{
              '&.MuiIconButton-root': {
                padding: '3px',
                borderRadius: '0%',
                fill: '#68687A',
              },
            }}
          >
            <Close />
          </IconButton>
        </Box>
        {/* Form */}
        <Box component='form' className='modal-form'>
          <Box className='mb-4'>
            <Typography variant='formTitleClass'>
              Invoice Information
            </Typography>
            <Box>
              <FormLabel htmlFor='invoice-number'>Amount</FormLabel>
              <TextField
                id='invoice-number'
                type='number'
                placeholder='Type here'
                fullWidth
              />
            </Box>
          </Box>

          <Box>
            <Typography variant='formTitleClass'>
              Billing Information
            </Typography>
            <Box className='mb-3'>
              <FormLabel htmlFor='customer-name'>Bill-To Name</FormLabel>
              <TextField
                id='customer-name'
                type='text'
                placeholder='Faizan Mustafa'
                fullWidth
              />
            </Box>

            <Box className='mb-3'>
              <FormLabel htmlFor='customer-address'>Bill-To Address</FormLabel>
              <TextField
                id='customer-address'
                type='text'
                placeholder='Enter address'
                fullWidth
              />
            </Box>

            <Box className='mb-3'>
              <FormLabel htmlFor='customer-address-2'>
                Bill-To Address 2
              </FormLabel>
              <TextField
                id='customer-address-2'
                type='text'
                placeholder='Enter additional address'
                fullWidth
              />
            </Box>

            <Box className='mb-3'>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <FormLabel htmlFor='customer-city'>City</FormLabel>
                  <TextField
                    id='customer-city'
                    type='text'
                    placeholder='Enter city'
                    fullWidth
                  />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <FormLabel htmlFor='customer-state'>State</FormLabel>
                  <TextField
                    id='customer-state'
                    type='text'
                    placeholder='Enter state'
                    fullWidth
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <Button variant='contained' type='submit'>
              Create
            </Button>
          </Box>
        </Box>
        {/* Form */}
      </Box>
    </Modal>
  )
}
