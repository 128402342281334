import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

export default function AddTruckExpenseForm({
  isSubmitting,
  handlePrevious,
  setFieldValue,
  submitForm,
  setExpenseValidationSchema,
  skipSchema,
  stepThreeValidationSchema,
  handleExpenseToggle,
  isExpenseActive,
}) {
  const handleSkip = async () => {
    await setExpenseValidationSchema(skipSchema);
    setFieldValue("expenses", {
      amount: "",
      insurance: "",
      description: "",
      variableExpense: "",
      category: "Lease",
    });

    // Bypass Formik validation by submitting directly
    submitForm();
  };

  const handleAdd = async () => {
    setExpenseValidationSchema(stepThreeValidationSchema);

    submitForm();
  };

  return (
    <div className="add-truck-stepper-form-wrapper">
      <div className="container">
        <div className="form-wrapper">
          <div className="form-header">
            <div className="toggle-btn">
              <span>Lease</span>
              <div
                className={`toggle ${isExpenseActive ? "active" : ""}`}
                onClick={handleExpenseToggle}
              >
                <div className="toggle-circle"></div>
              </div>
              <span>Own</span>
            </div>
          </div>
          <div className="form-fields">
            <div className="row">
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="amount">Monthly Rate</label>
                  <Field
                    type="text"
                    name="expenses.amount"
                    placeholder="$ Rate"
                  />
                  <ErrorMessage
                    name="expenses.amount"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="insurance">Insurance</label>
                  <Field
                    type="text"
                    name="expenses.insurance"
                    placeholder="$ Rate"
                  />
                  <ErrorMessage
                    name="expenses.insurance"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="description">Registration</label>
                  <Field
                    type="text"
                    name="expenses.description"
                    placeholder="$ Rate"
                  />
                  <ErrorMessage
                    name="expenses.description"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-field">
                  <label htmlFor="variableExpense">Variable Expense</label>
                  <Field
                    type="text"
                    name="expenses.variableExpense"
                    placeholder="Enter Variable Expense"
                  />
                  <ErrorMessage
                    name="expenses.variableExpense"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-buttons skip-section">
            <div className="skip-btn">
              <button
                type="button"
                disabled={isSubmitting}
                onClick={handleSkip}
              >
                Skip for now
              </button>
            </div>
            <div className="control-btns">
              <div className="single-btn">
                <button disabled={isSubmitting} onClick={handlePrevious}>
                  Back
                </button>
              </div>
              <div className="single-btn">
                <button
                  className="bg-blue"
                  type="button"
                  onClick={handleAdd}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
