import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";

import Sidebar from "../../components/adminPanelComponents/Sidebar";

export default function AdminHome() {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const sidebarOpenHandler = () => {
    setSideBarOpen(true);
  };
  const sidebarCloseHandler = () => {
    setSideBarOpen(false);
  };
  return (
    <div className="admin-page">
      {/* <Titlebar /> */}
      <div className="main-header">
        <h2 className="mb-0">Admin</h2>
      </div>
      <div className="admin-dashboard-wrapper-main">
        <Sidebar sideBarOpen={sideBarOpen} sidebarOpenHandler={sidebarOpenHandler} />
        <div className="admin-dashboard-content-main">
          <main>
            <Outlet />
          </main>
        </div>
      </div>
      <div
        id="overLay"
        className={`${sideBarOpen ? "active" : ""}`}
        onClick={sidebarCloseHandler}
      ></div>
    </div>
  );
}
