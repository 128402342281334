import React, { useState } from "react";
import TableComponent from "./TableComponent";
import DocumentsContent from "./DocumentsContent";
import DetailsCard from "./DetailsCard";
import tableIcon from "../assets/images/table-icon.svg";
import tripSummaryIcon from "../assets/images/trip-summary.svg";
import Logs from "./Logs";
import HomeWrapper from "../Pages/HomeWrapper";
import filterIcon from "../assets/images/filter-icon.svg";
import plusIcon from "../assets/images/plus-gray.svg";
import { Form } from "react-bootstrap";
import actionBtn from "../assets/images/action-btn.svg";
import UsePagination from "./UsePagination";
import UsePaginationv2 from "./UsePaginationv2";
import Dropdown from "react-bootstrap/Dropdown";

const TailorTabsSection = () => {
  // State to manage active tab
  const [activeTab, setActiveTab] = useState("Tailor");
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);

  const handleOpenAddDocumentModal = () => setOpenAddDocumentModal(true);
  const handleCloseAddDocumentModal = () => setOpenAddDocumentModal(false);

  return (
    <div className="custom-tabs-container">
      {/* Tabs Header */}
      <div className="custom-tabs-header flex-column-reverse flex-md-row">
        <div className="custom-tabs">
          <div
            className={`custom-tab-item ${
              activeTab === "TAILOR" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("TAILOR")}
          >
            TAILOR
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Trailor" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Trailor")}
          >
            Trailor
          </div>
        </div>

        <div className="tab-buttons">
          <div className="filter-btn">
            <button>
              <div className="icon">
                <img src={filterIcon} alt="filter-icon" />
              </div>
              <strong>Filter</strong>
            </button>
          </div>
          <div className="add-buttons">
            <Dropdown id="dropdown-basic">
              <Dropdown.Toggle>
                <div className="single-add-btn">
                  <strong>Tailor Number</strong>
                  <div className="icon">
                    <img src={plusIcon} alt="icon" />
                  </div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <div className="dropdown-content">
                  <div className="input-section">
                    <label htmlFor="">Tailor #</label>
                    <input type="number" name="truckNumber" placeholder="124" />
                  </div>
                  <div className="done-button">
                    <button>Done</button>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {/* <div className="single-add-btn">
              <strong>Tailor Number</strong>
              <div className="icon">
                <img src={plusIcon} alt="icon" />
              </div>
            </div> */}
            <div className="single-add-btn">
              <strong>Model</strong>
              <div className="icon">
                <img src={plusIcon} alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {activeTab === "TAILOR" && (
          <div className="custom-tab-pane">
            <div
              className="table-wrapper"
              style={{ marginTop: "32px", overflowX: "auto" }}
            >
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </th>
                    <th>
                      <div>
                        <strong>TAILOR MAKE</strong>
                        <img src={tableIcon} alt="icon" />
                      </div>
                    </th>
                    <th>
                      <div>
                        <strong>MODEL YEAR</strong>
                        <img src={tableIcon} alt="icon" />
                      </div>
                    </th>

                    <th>
                      <div>
                        <strong>TAILOR MODEL</strong>
                        <img src={tableIcon} alt="icon" />
                      </div>
                    </th>
                    <th>
                      <div>
                        <strong>ODOMETER</strong>
                        <img src={tableIcon} alt="icon" />
                      </div>
                    </th>

                    <th>
                      <div>
                        <strong>TAILOR NUMBER</strong>
                        <img src={tableIcon} alt="icon" />
                      </div>
                    </th>
                    <th>
                      <div>
                        <strong>LICENSE NUMBER</strong>
                        <img src={tableIcon} alt="icon" />
                      </div>
                    </th>
                    <th>
                      <div>
                        <strong>STATUS</strong>
                        <img src={tableIcon} alt="icon" />
                      </div>
                    </th>
                    <th>
                      <div>
                        <strong>ACTION</strong>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </td>
                    <td>
                      <div>
                        <span>2018</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>Freight linter</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <span>S256565</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>390000</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>102</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>262274262724</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span>Active</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button>
                          <img
                            src={actionBtn}
                            alt="icon"
                            style={{
                              width: "18px",
                              height: "10px",
                              marginLeft: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </td>
                    <td>
                      <div>
                        <span>2018</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>Freight linter</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <span>S256565</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>390000</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>102</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>262274262724</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span className="pink">Deactivate</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button>
                          <img
                            src={actionBtn}
                            alt="icon"
                            style={{
                              width: "18px",
                              height: "10px",
                              marginLeft: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </td>
                    <td>
                      <div>
                        <span>2018</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>Freight linter</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <span>S256565</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>390000</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>102</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>262274262724</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span className="pink">Deactivate</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button>
                          <img
                            src={actionBtn}
                            alt="icon"
                            style={{
                              width: "18px",
                              height: "10px",
                              marginLeft: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </td>
                    <td>
                      <div>
                        <span>2018</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>Freight linter</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <span>S256565</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>390000</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>102</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>262274262724</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span className="pink">Deactivate</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button>
                          <img
                            src={actionBtn}
                            alt="icon"
                            style={{
                              width: "18px",
                              height: "10px",
                              marginLeft: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </td>
                    <td>
                      <div>
                        <span>2018</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>Freight linter</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <span>S256565</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>390000</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>102</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>262274262724</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span className="pink">Deactivate</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button>
                          <img
                            src={actionBtn}
                            alt="icon"
                            style={{
                              width: "18px",
                              height: "10px",
                              marginLeft: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </td>
                    <td>
                      <div>
                        <span>2018</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>Freight linter</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <span>S256565</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>390000</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>102</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>262274262724</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span className="pink">Deactivate</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button>
                          <img
                            src={actionBtn}
                            alt="icon"
                            style={{
                              width: "18px",
                              height: "10px",
                              marginLeft: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </td>
                    <td>
                      <div>
                        <span>2018</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>Freight linter</span>
                      </div>
                    </td>
                    <td>
                      <div className="address-col">
                        <span>S256565</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>390000</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>102</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>262274262724</span>
                      </div>
                    </td>
                    <td>
                      <div className="status">
                        <span className="pink">Deactivate</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button>
                          <img
                            src={actionBtn}
                            alt="icon"
                            style={{
                              width: "18px",
                              height: "10px",
                              marginLeft: "20px",
                            }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <UsePaginationv2 />
          </div>
        )}

        {activeTab === "Trailor" && <div className="custom-tab-pane"></div>}
      </div>
    </div>
  );
};

export default TailorTabsSection;
