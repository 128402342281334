import comingSoon from "../assets/images/coming-soon.png"

export default function ComingSoon() {
  return (
    <div className="welcome-section">
      <h1>
        Coming <span>Soon! </span> 👋
      </h1>
      <div className="banner" >
      {/* <img src={comingSoon} alt="banner" width={250} height={250}/> */}
      </div>
        
    </div>
  );
}
