import React, { useState } from "react";
import searchIcon from "../assets/images/search-icon.svg";
import inviteMember from "../assets/images/invite-member-icon.svg";
import InviteTeamModal from "./Modals/InviteTeamModal";

export default function TeamPageTopbar({
  searchTerm,
  setSearchTerm,
  onInviteMember,
}) {
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const handleModaShow = () => {
    setInviteModalShow(true);
  };
  const handleModaClose = () => {
    setInviteModalShow(false);
  };

  return (
    <div className="teampage-topbar-wrapper">
      <div className="bar-heading">
        <strong>Teams</strong>
      </div>
      <div className="searchbar-btn">
        <div className="search-bar">
          <img src={searchIcon} alt="Search Icon" />
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="invite-member-btn">
          <button onClick={handleModaShow}>
            <img src={inviteMember} alt="icon" />
            <span>Invite Member</span>
          </button>
          <InviteTeamModal
            inviteModalShow={inviteModalShow}
            handleModaClose={handleModaClose}
          />
        </div>
      </div>
    </div>
  );
}
