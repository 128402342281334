import arrowBack from "../assets/images/arrow-back.svg";
import DeleteLoadModal from "./Modals/DeleteLoadModal";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

export default function DriversDetailPageTopBar({ user, fleetType }) {
  const location = useLocation();
  const [deleteModalShow, deleteSetModalShow] = useState(false);

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
  };

  return (
    <div className="load-top-bar-wrapper">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-4">
            <div className="bar-navigation">
              <div className="back-icon">
                <Link
                  to={`${
                    location.pathname === "/add-fleet" ? "/fleet" : "/drivers"
                  } `}
                >
                  <img src={arrowBack} alt="arrow back" />
                </Link>
              </div>
              <div className="page-name">
                <strong>
                  {location.pathname === "/add-driver"
                    ? "Add Driver"
                    : location.pathname === "/add-fleet"
                    ? `Add ${fleetType === "TRUCK" ? "Truck" : "Trailer"}`
                    : user?.displayName
                    ? user.displayName
                    : ""}
                </strong>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="buttons-wrapper">
              <div
                className={`single-button ${
                  location.pathname === "/add-driver" || "/add-fleet"
                    ? "d-none"
                    : ""
                }`}
              >
                <button className="danger">
                  <>Deactivate Driver</>
                </button>
              </div>

              <DeleteLoadModal
                handleDeleteModalClose={handleDeleteModalClose}
                deleteModalShow={deleteModalShow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
