import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Tablebox from "../components/LoadListView";
import TabsSection from "../components/TabsSection";
import TableComponent from "../components/TableComponent";
import LoadPageTopBar from "../components/LoadPageTopBar";
import DetailsCard from "../components/DetailsCard";
import { useDispatch, useSelector } from "react-redux";
import { getLoadAsyncThunk } from "../redux/pagesSlices/loadSlice";
import { useParams } from "react-router-dom";
import { getLoadStopsAsyncThunk } from "../redux/pagesSlices/loadStopSlice";

export default function LoadDetails() {
  const d = useDispatch();

  const params = useParams();
  // console.log("🚀 ~ LoadDetails ~ params:", params);
  useEffect(() => {
    d(getLoadStopsAsyncThunk({ loadId: params?.id }));
    d(getLoadAsyncThunk(params?.id));
  }, [params?.id]);

  const loadById = useSelector((state) => state?.load?.loadById);
  // console.log("🚀 ~ LoadDetails ~ loadById:", loadById);

  const loadStop = useSelector((state) => state?.loadStop?.loadStop);
  // console.log("🚀 ~ LoadDetails ~ loadStop:", loadStop);

  return (
    <div className="load-details-page">
      <LoadPageTopBar loadById={loadById} params={params} />
      {/* <DetailsCard /> */}
      <TabsSection item={loadById} loadStop={loadStop} params={params} />
    </div>
  );
}
