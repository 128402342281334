import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { getContentsAsyncThunk } from "./contentSlice";

export const createLoadStopAsyncThunk = createAsyncThunk(
  "loadStop/createLoadStopAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    console.log("🚀 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.createLoadStop(data);
    await dispatch(getLoadStopsAsyncThunk());
    console.log("🚀 ~ catchAsync ~ response:", response.data);
    if (callBack) callBack();
    return response?.data;
  })
);

export const getLoadStopsAsyncThunk = createAsyncThunk(
  "loadStop/getLoadStopsAsyncThunk",
  catchAsync(async (params) => {
    console.log("🚀 ~ catchAsync ~ params:", params);
    const response = await ApiRequests.getLoadStops(params);
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const getLoadStopAsyncThunk = createAsyncThunk(
  "loadStop/getLoadStopAsyncThunk",
  catchAsync(async (id) => {
    console.log("🚀 ~ catchAsync ~ id:", id);
    const response = await ApiRequests.getLoadStop(id);
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

// export const updateLoadStopAsyncThunk = createAsyncThunk(
//   "loadStop/updateLoadStopsAsyncThunk",
//   catchAsync(async ({ data, callBack }, { dispatch }) => {
//     const response = await ApiRequests.updateLoadStop(data);
//     await dispatch(getLoadStopsAsyncThunk({ email: data?.email }))
//     console.log("The get Response is:", response?.data)
//     return response?.data;
//   })
// );

export const updateLoadStopAsyncThunk = createAsyncThunk(
  "loadStop/updateLoadStopsAsyncThunk",
  catchAsync(async ({ id, Load, data, callBack }, { dispatch }) => {
    console.log("🚀 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.updateLoadStop({ id, data });
    console.log("🚀 ~ catchAsync ~ response:", response);
    await dispatch(getLoadStopsAsyncThunk({ Load }));
    if (callBack) callBack();
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const deleteLoadStopAsyncThunk = createAsyncThunk(
  "loadStop/deleteLoadStopsAsyncThunk",
  catchAsync(async ({ id, callBack, Load }, { dispatch }) => {
    const response = await ApiRequests.deleteLoadStop(id);
    await dispatch(getLoadStopsAsyncThunk({ Load }));
    if (callBack) callBack();
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  loadStop: {
    results: [],
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 1,
  },
  loadStopById: {},

  // manager states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
};

const loadStopSlice = createSlice({
  name: "loadStop",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //

      .addCase(getLoadStopsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.loadStop = {
            ...action.payload,
            results: state?.results?.concat(action?.payload?.results),
          };
        } else {
          state.loadStop = action.payload;
        }
      })

      .addCase(getLoadStopAsyncThunk.fulfilled, (state, action) => {
        state.loadStopById = action.payload;
      })

      // .addCase(deleteLoadStop.fulfilled, (state, action) => {
      //   // console.log("response>>", action.payload);
      // })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asyncthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createLoadStopAsyncThunk,
            getLoadStopsAsyncThunk,
            getLoadStopAsyncThunk,
            updateLoadStopAsyncThunk,
            deleteLoadStopAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export const { setLoading, setSearchValue, setCategoryValue, setOrderValue } =
  loadStopSlice.actions;

export default loadStopSlice.reducer;
