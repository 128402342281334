import React from "react";
import doneLogo from "../assets/images/forms-images/icon-park_success.png";
import { Link } from "react-router-dom";

const AllDone = () => {
  return (
    <>
      <div className="bg-image fixed-page">
        <div className="container">
          <div className="form-wrapper" style={{ maxWidth: 440 }}>
            <div className="done-logo">
              <img src={doneLogo} alt="Done Logo" />
            </div>
            <div className="heading-wrapper">
              <h2 style={{ fontWeight: 500 }}>All done!</h2>
              <p>Your password has been reset.</p>
            </div>
            <div className="donepage-btn">
              <Link className="btn-style" to={"/home"}>
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllDone;
