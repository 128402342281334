import React from "react";
import TableComponent from "./TableComponent";
import addDocument from "../assets/images/add-document.svg";
import DocumentsContent from "./DocumentsContent";
import DetailsCard from "./DetailsCard";
import tableIcon from "../assets/images/table-icon.svg";
import tripSummaryIcon from "../assets/images/trip-summary.svg";
import { Modal, Box, TextField, Button } from "@mui/material";
import Logs from "./Logs";
import HomeWrapper from "../Pages/HomeWrapper";
import filterIcon from "../assets/images/filter-icon.svg";
import plusIcon from "../assets/images/plus-gray.svg";
import { Form } from "react-bootstrap";
import actionBtn from "../assets/images/action-btn.svg";
import UsePagination from "./UsePagination";
import UsePaginationv2 from "./UsePaginationv2";
import { Link, useNavigate } from "react-router-dom";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import Nodatafound from "./nodatafound";
import Loadingdata from "./loadingdata";

const FleetTabs = ({
  data,
  isLoading, // Add loading prop
  totalPages,
  currentPage,
  handlePageChange,
  selectedRows,
  setColumns,
  setSelectedRows,
  activeTab,
  setActiveTab,
  fleetNumber,
  setFleetNumber,
  fleetMake,
  setFleetMake,
  handleFilterClick,
  columns,
  setFilterOption,
  filterOption,
}) => {
  const navigate = useNavigate();

  // Handle checkbox selection
  const handleSelect = (itemId) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId));
    } else {
      setSelectedRows([...selectedRows, itemId]);
    }
  };

  const isSelected = (itemId) => selectedRows.includes(itemId);

  return (
    <div className="custom-tabs-container">
      {/* Tabs Header */}
      <div className="custom-tabs-header">
        <div className="custom-tabs">
          <div
            className={`custom-tab-item ${
              activeTab === "TRUCK" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("TRUCK")}
          >
            Truck
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "TAILOR" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("TAILOR")}
          >
            Trailer
          </div>
          <div
            className={`custom-tab-item ${
              activeTab === "Deactivated" ? "custom-active-tab" : ""
            }`}
            onClick={() => setActiveTab("Deactivated")}
          >
            Deactivated
          </div>
        </div>
        <div className="tab-buttons">
          <div className="filter-btn">
            <button onClick={() => setFilterOption(!filterOption)}>
              <div className="icon">
                <img src={filterIcon} alt="filter-icon" />
              </div>
              <strong>Filter</strong>
            </button>
          </div>
          <div className="add-buttons">
            <Dropdown id="dropdown-basic">
              <Dropdown.Toggle>
                <div className="single-add-btn">
                  <strong>
                    {activeTab === "TRUCK" ? "Truck" : "Trailer"} Number
                  </strong>
                  <div className="icon">
                    <img src={plusIcon} alt="icon" />
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form onSubmit={handleFilterClick} className="dropdown-content">
                  <div className="input-section">
                    <label htmlFor="">
                      {activeTab === "TRUCK" ? "Truck" : "Trailer"} #
                    </label>
                    <input
                      type="number"
                      name="truckNumber"
                      placeholder="124"
                      value={fleetNumber}
                      onChange={(e) => setFleetNumber(e.target.value)}
                    />
                  </div>
                  <div className="done-button">
                    <button>Done</button>
                  </div>
                </form>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown id="dropdown-basic">
              <Dropdown.Toggle>
                <div className="single-add-btn">
                  <strong>Make</strong>
                  <div className="icon">
                    <img src={plusIcon} alt="icon" />
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <form onSubmit={handleFilterClick} className="dropdown-content">
                  <div className="input-section">
                    <label htmlFor="">
                      {activeTab === "TRUCK" ? "Truck" : "Trailer"} Make
                    </label>
                    <input
                      type="text"
                      name="f"
                      placeholder="124"
                      value={fleetMake}
                      onChange={(e) => setFleetMake(e.target.value)}
                    />
                  </div>
                  <div className="done-button">
                    <button>Done</button>
                  </div>
                </form>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {isLoading ? (
          <Loadingdata />
        ) : data?.length === 0 ? (
          <Nodatafound nodataTitle="No Data Found" />
        ) : (
          <div className="custom-tab-pane">
            <div
              className="table-wrapper"
              style={{ marginTop: "32px", overflowX: "auto" }}
            >
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "30px" }}>
                      <Form.Check aria-label="option 1" />
                    </th>
                    {columns.map((item, index) => (
                      <th
                        onClick={() => {
                          if (!item.isSortable) return;
                          setColumns((prev) => {
                            const newColumns = prev.map((col) => {
                              if (col.key === item.key) {
                                return {
                                  ...col,
                                  order: col.order === "asc" ? "desc" : "asc",
                                };
                              }
                              return {
                                ...col,
                                order: undefined,
                              };
                            });
                            return newColumns;
                          });
                        }}
                      >
                        <div>
                          <strong>{item.name}</strong>
                          <img src={tableIcon} alt="icon" />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => (
                    <tr
                      key={item?.id}
                      onClick={() => navigate(`/fleet-details/${item?.id}`)}
                    >
                      <td
                        style={{ width: "30px" }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Form.Check
                          aria-label={`Select truck ${item.id}`}
                          checked={isSelected(item.id)}
                          onChange={() => handleSelect(item.id)}
                        />
                      </td>
                      <td>
                        <div>
                          <span>{item?.fleetNumber || "N/A"}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{item?.modelYear || "N/A"}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{item?.fleetMake || "N/A"}</span>
                        </div>
                      </td>
                      <td>
                        <div className="address-col">
                          <span>{item?.fleetModel || "N/A"}</span>
                        </div>
                      </td>
                      {/* <td>
                        <div>
                          <span>{item?.odometer || "N/A"}</span>
                        </div>
                      </td> */}
                      <td>
                        <div>
                          <span>{item?.licenseNumber || "N/A"}</span>
                        </div>
                      </td>
                      {/* <td>
                        <div className="status">
                          <span
                            className={
                              item?.status.toLowerCase() === "active"
                                ? "success"
                                : "pink"
                            }
                          >
                            {item?.status || "N/A"}
                          </span>
                        </div>
                      </td> */}
                      <td>
                        <div>
                          <span>
                            {item?.users && item?.users?.length > 0 ? (
                              <div
                                key={item?.users[0]?.id}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  marginBottom: "8px",
                                }}
                              >
                                <img
                                  src={item?.users[0]?.picture}
                                  alt={item?.users[0]?.username}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <span>{item?.users[0]?.username}</span>
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </span>
                        </div>
                      </td>

                      {/* <td>
                        <div>
                          <button
                            onClick={() =>
                              navigate(`/fleet-details/${item?.id}`)
                            }
                          >
                            <img
                              src={actionBtn}
                              alt="icon"
                              style={{
                                width: "18px",
                                height: "10px",
                                marginLeft: "20px",
                              }}
                            />
                          </button>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {totalPages > 1 && (
              <UsePagination
                currentPage={currentPage}
                count={totalPages}
                onChange={handlePageChange}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FleetTabs;
