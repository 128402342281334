import React, { useState, useEffect } from "react";
import LoadListView from "./LoadListView";
import addDocument from "../assets/images/add-document.svg";
import LoadGridview from "./LoadGridview";
import TopTabsComponent from "./TopTabsComponent";

import { useSelector, useDispatch } from "react-redux";
import { getLoadsAsyncThunk } from "../redux/pagesSlices/loadSlice";
import Loadingdata from "../components/loadingdata"; // Assuming you have a loading component
import NoDataFound from "./nodatafound";
import UsePagination from "./UsePagination";

export default function Loadstab({ activeView }) {
  const [activeTab, setActiveTab] = useState("History");
  const { activeOrg } = useSelector((state) => state.workspace);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // New: Keep track of the current page
  const [customername, setCustomerName] = useState("");
  const [driver, setDriverName] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const data = useSelector((state) => state.load?.load);
  console.log("🚀 ~ Loadstab ~ data:", data);
  // let {
  //   limit,
  //   page,
  //   results,
  //   totalPages,
  //   totalResults
  //   } = data;
  const dispatch = useDispatch();

  const fetchData = async (currentPage) => {
    setIsLoading(true);
    const data = {
      page: currentPage,
      driver: driver || null,
      status: status || null,
      location: location || null,
      customername: customername || null,
    };
    await dispatch(
      getLoadsAsyncThunk(data, {
        page: currentPage, // Pass the current page to the data request
        populate: "customer",
      })
    );
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage); // Fetch data when the component mounts or page changes
  }, [dispatch, currentPage, activeOrg]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the state and this triggers a new fetch with the new page
  };

  const handleFilterClick = (e) => {
    e.preventDefault();
    fetchData(currentPage);
  };

  // Main loading indicator
  if (isLoading) {
    return (
      <div
        className="loading-container"
        style={{ height: "calc(100vh - 157px)" }}
      >
        <Loadingdata title="Loading..." />
      </div>
    );
  }

  if (data?.results?.length === 0) {
    return (
      <div className="loading-container">
        <NoDataFound nodataTitle="No Data Found" />
      </div>
    );
  }

  return (
    <div className="custom-tabs-container loadstab">
      {/* Tabs Header */}
      <TopTabsComponent
        status={status}
        setStatus={setStatus}
        location={location}
        setLocation={setLocation}
        driver={driver}
        setDriverName={setDriverName}
        customername={customername}
        setCustomerName={setCustomerName}
        handleFilterClick={handleFilterClick}
      />

      {/* Content Section for each tab */}
      <div className="custom-tab-content">
        {activeView === "grid" ? (
          <div className="custom-tab-pane">
            <LoadGridview data={data?.results} />
          </div>
        ) : (
          <div className="custom-tab-pane">
            <LoadListView data={data?.results} />
          </div>
        )}
        <UsePagination
          currentPage={data?.page}
          count={data?.totalPages}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}
