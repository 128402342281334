import React, { useEffect } from "react";
import {
  AuthenticateWithRedirectCallback,
  useAuth,
  useSignUp,
} from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

export default function OAuthCallback() {
  const { isLoaded, user } = useAuth();
  const { signUp } = useSignUp();
  const navigate = useNavigate();

  useEffect(() => {
    // if (isLoaded && user) {
    const invitationToken = sessionStorage.getItem("__clerk_ticket");

    if (invitationToken) {
      acceptInvitation(invitationToken);
    }
    // }
  }, []);

  const acceptInvitation = async (invitationToken) => {
    try {
      // Attach the invitation token (ticket) to accept the organization invitation
      const res = await signUp.create({
        strategy: "ticket",
        ticket: invitationToken,
      });

      if (res.status === "complete") {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
    }
  };

  return (
    <AuthenticateWithRedirectCallback signUpFallbackRedirectUrl={"/home"} />
  );
}
