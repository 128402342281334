import React from "react";
import { Link, useParams } from "react-router-dom";
import qrCode from "../assets/images/forms-images/Frame.svg";
import iphone from "../assets/images/forms-images/pngwing.com (1).png";
import google from "../assets/images/forms-images/pngwing.com 1.png";

const Welcome = () => {
  const { workspaceName } = useParams();
  return (
    <>
      <div
        className="bg-image fixed-page colored"
        style={{ minHeight: "100vh" }}
      >
        <div className="container">
          <div className="form-wrapper" style={{ maxWidth: 720 }}>
            <div className="heading-wrapper">
              <h2 style={{ fontWeight: 500 }}>
                Welcome to {workspaceName} Workspace!{" "}
              </h2>
            </div>

            <div className="verify-title">
              <p>
                You have successfully joined the new workspace. Start exploring
                ReEnvoy now!{" "}
              </p>
            </div>

            <div className="donepage-btn">
              <Link className="btn-style" to={"/home"}>
                Get Started
              </Link>
            </div>

            {/* <div className="reenovy-app-title">
              <p>
                Welcome to the <span>ReEnvoy team!</span> Get the ReEnvoy App!
              </p>
            </div>

            <div className="qr-code">
              <img src={qrCode} alt="QR Code" />
            </div>

            <div className="enovy-btn">
              <button>
                <img src={iphone} />
              </button>
              <button>
                <img src={google} />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
