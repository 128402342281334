import Tablebox from "../components/LoadListView";
import Loods from "../components/Loods";

export default function HomeWrapper() {
  return (
    <>
      <Loods />
    </>
  );
}
