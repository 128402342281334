import { Modal, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import WorkSpace from "../WorkSpace";
import { FiUpload } from "react-icons/fi";
import companyLogo from "../../assets/images/forms-images/Ellipse 3780.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { State } from "country-state-city"; // Import the State module
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { toast } from "react-toastify";
import { updateWorkspaceAsyncThunk } from "../../redux/pagesSlices/workspaceSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Company Name is required").max(50),
  mcNumber: Yup.string()
    .matches(/^[0-9]+$/, "MC Number must be numeric")
    .required("MC Number is required")
    .max(50),
  dotNumber: Yup.string()
    .matches(/^[0-9]+$/, "DOT Number must be numeric")
    .required("DOT Number is required")
    .max(10),
  city: Yup.string().max(50).required("City is required"),
  state: Yup.string().max(50).required("State is required"),
});

export default function EditCompanyProfileModal({
  workspace,
  showCompanyModal,
  handleHideCompanyModal,
}) {
  const { loadings } = useSelector((state) => state.workspace);
  const [base64URL, setBase64URL] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [states, setStates] = useState("");
  const dispatch = useDispatch();
  const fileInputRef = useRef(null); // Reference for the file input
  const { user } = useUser();

  useEffect(() => {
    const usStates = State.getStatesOfCountry("US");
    setStates(usStates);
  }, []);

  const handleHide = () => {
    handleHideCompanyModal();
  };
  // Function to convert file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Function to check image dimensions
  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        resolve(true);
      };
      img.onerror = reject;
    });
  };

  const handleFileInputChange = async (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      const allowedTypes = ["image/png", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG and JPEG files are allowed.");
        return;
      }

      // Validate file size (10MB = 10 * 1024 * 1024 bytes)
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        toast.error("File size should not exceed 10MB.");
        return;
      }

      // Validate image dimensions
      try {
        await checkImageDimensions(file);
      } catch (dimensionError) {
        toast.error("Image dimensions should be at least 400px by 400px.");
        return;
      }

      // If all validations pass, convert to Base64
      try {
        const base64 = await convertToBase64(file);
        setBase64URL(base64);
        setSelectedFile(file);
        setFieldValue("companyLogo", file); // Set file in Formik's state
      } catch (error) {
        console.error("Error converting file to Base64:", error);
      }
    }
  };

  const handleRemoveFile = (setFieldValue) => {
    setBase64URL("");
    setSelectedFile(null);
    setFieldValue("companyLogo", null); // Clear file in Formik's state
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input value
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formDataWithFile = new FormData();

    // Append other form data
    formDataWithFile.append("name", values.name);
    formDataWithFile.append("mcNumber", values.mcNumber);
    formDataWithFile.append("dotNumber", values.dotNumber);
    if (values.city) {
      formDataWithFile.append("city", values.city);
    }
    if (values.state) {
      formDataWithFile.append("state", values.state);
    }

    // Append the image file (if selected)
    if (selectedFile) {
      formDataWithFile.append("companyLogo", selectedFile);
    }

    dispatch(
      updateWorkspaceAsyncThunk({
        id: workspace?.workSpaceId,
        data: formDataWithFile,
        callBack: async () => {
          resetForm();
          handleRemoveFile(() => {});
          await user.reload();
          handleHide();
        },
      })
    );
  };

  return (
    <Modal
      show={showCompanyModal}
      onHide={handleHide}
      size="lg"
      centered
      className="edit-company-profile-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Company Details </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: workspace?.name,
            mcNumber: workspace?.mcNumber,
            dotNumber: workspace?.dotNumber,
            city: workspace?.city,
            state: workspace?.state,
            companyLogo: null,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty, setFieldValue }) => (
            <Form className="form-wrapper">
              <div className="edit-company-modal-body">
                <div className="spacing-sec">
                  <div className="form-wrapper">
                    <div className="company-sec">
                      <div className="upload-sec">
                        <div className="title">
                          <p>Company Logo</p>
                        </div>
                        <div className="flex-wrapper">
                          <div className="upload-border">
                            <input
                              type="file"
                              id="file-upload"
                              className="file-upload-input"
                              onChange={(e) =>
                                handleFileInputChange(e, setFieldValue)
                              }
                              ref={fileInputRef}
                            />
                            <label
                              htmlFor="file-upload"
                              className="upload-label"
                            >
                              <FiUpload />
                              <p>Upload Image</p>
                            </label>
                          </div>
                          <p>
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => handleRemoveFile(setFieldValue)}
                            >
                              Remove
                            </button>
                          </p>
                        </div>
                        <div className="extensions">
                          <p>
                            *png, *jpeg files up to 10MB at least 400px by 400px
                          </p>
                        </div>
                      </div>
                      <div className="Logo-sec">
                        <div className="company-logo">
                          <img
                            src={
                              base64URL
                                ? base64URL
                                : workspace?.companyLogo
                                ? process.env.REACT_APP_CLOUDFLARE_URL +
                                  workspace?.companyLogo
                                : companyLogo
                            }
                            alt="Company Logo"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="login-fields">
                      <div className="input-fields">
                        <label className="workspace" htmlFor="name">
                          Company Name
                        </label>
                        <div className="input-wrapper">
                          <Field
                            className="workspace"
                            type="text"
                            placeholder="Enter your name"
                            name="name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 ">
                          <div className="input-fields">
                            <label className="workspace" htmlFor="mcNumber">
                              MC Number
                            </label>
                            <div className="input-wrapper">
                              <Field
                                className="workspace"
                                type="text"
                                placeholder="xxxxxxx"
                                name="mcNumber"
                              />
                              <ErrorMessage
                                name="mcNumber"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-fields">
                            <label className="workspace" htmlFor="dotNumber">
                              DOT Number
                            </label>
                            <div className="input-wrapper">
                              <Field
                                className="workspace"
                                type="text"
                                placeholder="xxxxxxx"
                                name="dotNumber"
                              />
                              <ErrorMessage
                                name="dotNumber"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-fields">
                            <label className="workspace" htmlFor="city">
                              City (Optional)
                            </label>
                            <div className="input-wrapper">
                              <Field
                                className="workspace"
                                type="text"
                                placeholder="City"
                                name="city"
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-fields">
                            <label className="workspace" htmlFor="state">
                              State (Optional)
                            </label>
                            <div className="input-wrapper">
                              <Field
                                as="select"
                                placeholder="Select"
                                name="state"
                              >
                                <option value="">Select</option>
                                {Object.entries(states)?.length > 0 ? (
                                  Object.entries(states)?.map(
                                    ([key, value]) => (
                                      <option key={key} value={value.isoCode}>
                                        {value.name}
                                      </option>
                                    )
                                  )
                                ) : (
                                  <option value="">No states available</option>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="document-btn" style={{ marginTop: "20px" }}>
                <div className="driver-profile-btn">
                  <Button
                    type="submit"
                    disabled={!dirty || loadings?.updateWorkspaceAsyncThunk}
                    className="add-btn"
                  >
                    {loadings?.updateWorkspaceAsyncThunk ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
