import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { Button as ButtonMui } from "@mui/material";

import file from "../../assets/images/forms-images/material-symbols_upload.svg";
import {
  getUserDocsAsyncThunk,
  updateUserDocAsyncThunk,
} from "../../redux/pagesSlices/userDocSlice";
import {
  Box,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import { FiUpload } from "react-icons/fi";
import moment from "moment";

// Yup validation schema
const validationSchema = Yup.object({
  documentType: Yup.string().required("Document Type is required"),
  documentDesc: Yup.string().max(191),
  expiryDate: Yup.string().required("Expiration Date is required"),
  file: Yup.mixed().nullable(),
});

const EditDetailModal = ({ editModalShow, handleEditModalClose, document }) => {
  const dispatch = useDispatch();
  const { activeOrg } = useSelector((state) => state.workspace);
  const [base64URL, setBase64URL] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const loading = useSelector(
    (state) => state.userDoc.loadings?.updateUserDocAsyncThunk
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("documentType", values.documentType);
    formData.append("documentDesc", values.documentDesc);
    formData.append("expiryDate", new Date(values.expiryDate).toISOString());
    formData.append("referenceId", activeOrg);
    formData.append("referenceType", "USER");

    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    dispatch(
      updateUserDocAsyncThunk({
        data: formData,
        callBack: () => {
          handleRemoveFile();
          const params = {
            referenceType: "USER",
            referenceId: activeOrg,
          };
          dispatch(getUserDocsAsyncThunk({ params }));
        },
      })
    ).finally(() => {
      setSubmitting(false);
      handleEditModalClose();
    });
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(file.type)) {
        toast.error("Only PNG, JPEG, PDF, and MS Word files are allowed.");
        return;
      }

      const base64 = await convertToBase64(file);
      setBase64URL(base64);
      setSelectedFile(file);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemoveFile = () => {
    setBase64URL("");
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <Modal
        show={editModalShow}
        onHide={handleEditModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              documentType: document?.documentType || "",
              documentDesc: document?.documentDesc || "",
              expiryDate:
                moment(document?.expiryDate).format("YYYY-MM-DD") || "",
              file: null,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box sx={{ mt: 2 }}>
                  <FormLabel>Expiration Date</FormLabel>
                  <TextField
                    fullWidth
                    type="date"
                    name="expiryDate"
                    value={values.expiryDate}
                    onChange={handleChange}
                    error={touched.expiryDate && Boolean(errors.expiryDate)}
                  />
                  {touched.expiryDate && errors.expiryDate && (
                    <Typography color="error">{errors.expiryDate}</Typography>
                  )}
                </Box>

                <FormLabel sx={{ mt: 2 }}>Attach Document</FormLabel>
                <Box style={{ marginBottom: "15px" }}>
                  <ButtonMui
                    variant="outlined"
                    startIcon={<FiUpload />}
                    onClick={() => fileInputRef.current.click()}
                  >
                    Select new file
                  </ButtonMui>
                  <input
                    type="file"
                    hidden
                    ref={fileInputRef}
                    accept=".png, .jpeg, .jpg, .pdf, .doc, .docx"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setFieldValue("file", file);
                      handleFileInputChange(e);
                    }}
                  />
                  {selectedFile && (
                    <ButtonMui
                      variant="text"
                      onClick={() => {
                        handleRemoveFile();
                        setFieldValue("file", null);
                      }}
                      sx={{ ml: 2 }}
                    >
                      Remove
                    </ButtonMui>
                  )}
                </Box>
                {touched.file && errors.file && (
                  <Typography color="error">{errors.file}</Typography>
                )}
                <Button
                  className="btn-style"
                  type="submit"
                  disabled={loading || !dirty}
                >
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditDetailModal;
