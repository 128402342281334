import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Tablebox from "../components/LoadListView";
import TabsSection from "../components/TabsSection";
import TableComponent from "../components/TableComponent";
import Topbar from "../components/Topbar";
import Loadstab from "../components/Loadstabs";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

export default function LoadPage() {
  const [activeView, setActiveView] = useState("list");

  const activeViewHandler = (tab) => {
    setActiveView(tab);
  };

  return (
    <div className="home-page">
      <Topbar activeViewHandler={activeViewHandler} activeView={activeView} />
      <Loadstab activeView={activeView} />
      {/* <Tablebox /> */}
      {/* <TabsSection />
            <TableComponent /> */}
    </div>
  );
}
