import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  addFleetExpenseAsyncThunk,
  getFleetExpensesAsyncThunk,
} from "../../redux/pagesSlices/fleetExpenseSlice";
import { toast } from "react-toastify";

// Yup validation schema
const validationSchema = Yup.object({
  category: Yup.string().required("Category is required"),
  amount: Yup.string()
    .matches(/^[0-9]+$/, "Expense Amount must be numeric")
    .required("Expense Amount is required")
    .max(32),
  date: Yup.date().required("Expense Date is required"),
  description: Yup.string().required("Description is required"),
});

const initialExpenseData = {
  category: "",
  amount: "",
  date: "",
  description: "",
};

const categories = [
  "Fuel",
  "Insurance",
  "ELD",
  "Trailer",
  "Registration",
  "Repairs",
  "Other",
];

export default function AddTruckExpenseModal({
  fleetData,
  addtruckExpenseShow,
  handleAddTruckModalClose,
}) {
  const { loadings } = useSelector((state) => state?.fleetExpense);
  const dispatch = useDispatch();

  const handleAddExpense = (values, { resetForm }) => {
    dispatch(
      addFleetExpenseAsyncThunk({
        data: {
          ...values,
          fleetId: fleetData.id,
        },
        callBack: () => {
          toast.success("Expense Added Successfully!");
          handleAddTruckModalClose();
          const params = {
            fleetId: fleetData?.id,
          };
          dispatch(getFleetExpensesAsyncThunk({ params }));
          resetForm();
        },
      })
    );
  };

  return (
    <Modal
      show={addtruckExpenseShow}
      onHide={handleAddTruckModalClose}
      className="add-truck-btn"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Add {fleetData?.fleetType === "TRUCK" ? "Truck" : "Trailer"} Expense
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialExpenseData}
          validationSchema={validationSchema}
          onSubmit={handleAddExpense}
        >
          {({ values }) => (
            <Form>
              <div className="modal-field">
                <label>Category</label>
                <Field as="select" name="category" className="bg-white">
                  <option value="">Select</option>
                  {categories?.map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="category"
                  component="div"
                  className="error"
                />
              </div>

              <div className="modal-field">
                <label>Expense Amount $</label>
                <Field type="text" name="amount" placeholder="Notes" />
                <ErrorMessage name="amount" component="div" className="error" />
              </div>

              <div className="modal-field">
                <label>Expense Date</label>
                <Field type="date" name="date" placeholder="max" />
                <ErrorMessage name="date" component="div" className="error" />
              </div>
              <div className="modal-field">
                <label>Description</label>
                <Field type="text" name="description" placeholder="Notes" />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="error"
                />
              </div>
              <Button
                type="submit"
                disabled={loadings?.addFleetExpenseAsyncThunk}
                className="btn-style"
              >
                {loadings?.addFleetExpenseAsyncThunk ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Add"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
