import { useState } from "react";
import Sidebar from "../components/adminPanelComponents/Sidebar";
import Titlebar from "../components/adminPanelComponents/Titlebar";

export default function AdminPanel() {

    const [sideBarOpen, setSideBarOpen] = useState(true);
    const sidebarOpenHandler = () => {
        setSideBarOpen(true)
    }
    const sidebarCloseHandler = () => {
        setSideBarOpen(false)
    }
    return (
        <>
            <div>
                <Titlebar />
                <Sidebar sideBarOpen={sideBarOpen} />
            </div>
            <div id="overLay" className={`${sideBarOpen ? 'active' : ''}`} onClick={sidebarCloseHandler}></div>
        </>
    )
}