import React, { useEffect } from "react";
import { FiUpload } from "react-icons/fi";
import Button from "react-bootstrap/Button";
import { ErrorMessage } from "formik";
import { defaultFleetDocuments } from "../constant/documents";

const AddTruckDocument = ({
  handlePrevious,
  handleNext,
  values,
  setFieldValue,
  handleSubmit,
  errors,
}) => {
  const initialDocuments = defaultFleetDocuments?.map(
    (item) => item.documentName
  );

  // // Initialize documents if not already set
  // useEffect(() => {
  //   if (!values.documents || values.documents.length === 0) {
  //     const initialValues = initialDocuments.map((doc) => ({
  //       name: doc,
  //       expirationDate: "",
  //       file: null,
  //     }));
  //     setFieldValue("documents", initialValues);
  //   }
  // }, [initialDocuments, setFieldValue, values.documents]);

  // const handleInputChange = (index, field, value) => {
  //   const updatedDocuments = [...values.documents];
  //   if (!updatedDocuments[index]) {
  //     // Ensure object exists at the index with default fields
  //     updatedDocuments[index] = {
  //       name: initialDocuments[index],
  //       expirationDate: "",
  //       file: null,
  //     };
  //   }
  //   updatedDocuments[index][field] = value;
  //   setFieldValue("documents", updatedDocuments);
  // };

  // const handleFileChange = (index, file) => {
  //   handleInputChange(index, "file", file); // Safely set the file field
  // };

  const handleInputChange = (index, field, value) => {
    const updatedDocuments = [...values.documents];
    if (!updatedDocuments[index]) {
      updatedDocuments[index] = { name: initialDocuments[index] };
    }
    updatedDocuments[index][field] = value;
    setFieldValue("documents", updatedDocuments);
  };

  const handleFileChange = (index, file) => {
    handleInputChange(index, "file", file);
  };

  const handleSkip = () => {
    setFieldValue("documents", []);
    handleNext();
  };

  return (
    <div className="main-driver">
      <div className="container">
        <div className="driver-table-wrapper">
          <div
            className="container-fluid"
            style={{ padding: 0, overflowX: "auto" }}
          >
            <table>
              <thead>
                <tr>
                  <th>
                    <strong>Driver Documents</strong>
                  </th>
                  <th>
                    <strong>Expiration Date</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {initialDocuments.map((document, index) => (
                  <tr key={index}>
                    <td>
                      <div>
                        <span>{document}</span>
                      </div>
                    </td>
                    <td>
                      <div className="modal-field">
                        <input
                          type="date"
                          value={values?.documents?.[index]?.expirationDate}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "expirationDate",
                              e.target.value
                            )
                          }
                          placeholder="Max"
                        />
                      </div>
                    </td>
                    <td>
                      <label
                        htmlFor={`upload-${index}`}
                        className="upload-border"
                      >
                        <input
                          type="file"
                          accept="image/*,application/pdf"
                          onChange={(e) =>
                            handleFileChange(index, e.target.files[0])
                          }
                          style={{ display: "none" }}
                          id={`upload-${index}`}
                        />
                        <FiUpload />
                        <p>
                          {values?.documents?.[index]?.file
                            ? values?.documents?.[index]?.file.name
                            : "Upload"}
                        </p>
                      </label>
                    </td>
                    {errors.documents &&
                      (errors?.documents?.[index]?.file ||
                        errors?.documents?.[index]?.expirationDate) && (
                        <p className="error">
                          {errors?.documents?.[index]?.file ||
                            errors?.documents?.[index]?.expirationDate}
                        </p>
                      )}
                  </tr>
                ))}
                {errors.documents && typeof errors.documents === "string" && (
                  <ErrorMessage
                    component={"p"}
                    name="documents"
                    className="error"
                  />
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="document-btn">
          <p onClick={handleSkip}>Skip for now</p>
          <div className="driver-profile-btn">
            <Button className="link-btn light-bg" onClick={handlePrevious}>
              Back
            </Button>
            <Button type="button" onClick={handleSubmit} className="add-btn">
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTruckDocument;
