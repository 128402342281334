import Form from "react-bootstrap/Form";
import tableIcon from "../assets/images/table-icon.svg";
import trash from "../assets/images/forms-images/trash-03.svg";
import pencilIcon from "../assets/images/pencil-edit.svg";
import deleteGray from "../assets/images/delete-gray.svg";
import statsChart from "../assets/images/stats-chart.svg";
import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMultipleExpensesAsyncThunk,
  getFleetExpensesAsyncThunk,
} from "../redux/pagesSlices/fleetExpenseSlice";
import UsePagination from "./UsePagination";
import moment from "moment";
import EditTruckExpenseModal from "./Modals/EditTruckExpenseModal";
import Loadingdata from "./loadingdata";
import NoDataFound from "./nodatafound";
import DeleteFleetExpenseModal from "./Modals/DeleteFleetExpenseModal";
import FleetSelectionTab from "../components/FleetSelectionTab";

import { Pie } from "react-chartjs-2";
import { parseSortBy } from "../helpers/parse";

export default function FleetExpense({ fleetData }) {
  const { fleetExpenses, categoriesCalculation, loadings } = useSelector(
    (state) => state?.fleetExpense
  );
  console.log("🚀 ~ FleetExpense ~ fleetExpenses:", fleetExpenses);
  const [currentPage, setCurrentPage] = useState(1);
  const [expenseData, setExpenseData] = useState({});
  const [delId, setDelId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [editTruckExpenseShow, setEditTruckExpenseShow] = useState(false);
  const [deleteModalShow, deleteSetModalShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [period, setPeriod] = useState("");
  const [columnSortOrder, setColumnSortOrder] = useState([
    { name: "DATE", isSortable: true, order: "asc", key: "date" },
    { name: "CATEGORY", isSortable: true, key: "category" },
    { name: "DESCRIPTION/NOTES", isSortable: true, key: "description" },
    { name: "TOTAL", isSortable: true, key: "amount" },
    { name: "Action", isSortable: false, key: "" },
  ]);

  console.log("selectedRows", selectedRows);
  const dispatch = useDispatch();

  const handleEditTruckModalShow = (data) => {
    setExpenseData(data);
    setEditTruckExpenseShow(true);
  };

  const handleEditTruckModalClose = () => {
    setExpenseData({});
    setEditTruckExpenseShow(false);
  };
  const fetchData = async (currentPage) => {
    const params = {
      fleetId: fleetData?.id,
      page: currentPage,
      ...parseSortBy(columnSortOrder),
    };
    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    if (period) {
      params.period = period;
    }
    if (editTruckExpenseShow === false) {
      dispatch(getFleetExpensesAsyncThunk({ params }));
    }
  };

  useLayoutEffect(() => {
    fetchData(currentPage);
  }, [
    dispatch,
    currentPage,
    editTruckExpenseShow,
    columnSortOrder,
    deleteModalShow,
  ]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDeleteModalOpen = (delId) => {
    setDelId(delId);
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    setDelId("");
    deleteSetModalShow(false);
  };

  const handleClickFilter = () => {
    fetchData(currentPage);
  };
  const handleSelect = (itemId) => {
    if (selectedRows.includes(itemId)) {
      setSelectedRows(selectedRows.filter((id) => id !== itemId));
    } else {
      setSelectedRows([...selectedRows, itemId]);
    }
  };

  const isSelected = (itemId) => selectedRows.includes(itemId);

  const handleDelete = () => {
    console.log("Delete Rows: ", selectedRows);
    if (selectedRows.length == 0) {
      console.warn("No fleets selected for deletion.");
      return;
    }
    dispatch(
      deleteMultipleExpensesAsyncThunk({
        expenseIds: selectedRows,
        fleetId: fleetData?.id,
      })
    )
      .unwrap()
      .then(() => {
        console.log("Delete operation successful.");
        setSelectedRows([]);
      })
      .catch((error) => {
        console.error("Delete operation failed:", error);
      });
  };

  const colors = [
    "#0065EC",
    "#B658F5",
    "#FFC300",
    "#FF69B4",
    "#B22222",
    "#1FCC7D",
    "#F28F17",
  ];
  const mappedData = Object.entries(categoriesCalculation).map(
    ([category, amount], index) => ({
      category,
      amount,
      color: colors[index % colors.length], // Loop through colors
    })
  );

  const totalAmount = Object.values(categoriesCalculation)?.reduce(
    (acc, amount) => acc + amount,
    0
  );

  // Prepare data for the pie chart
  const data = {
    labels: mappedData.map((item) => item.category),
    datasets: [
      {
        data: mappedData.map((item) => item.amount),
        backgroundColor: mappedData.map((item) => item.color),
        borderColor: "rgba(255, 255, 255, 0.5)",
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="fleet-expense-section-wrapper">
      <div className="container-fluid">
        <div className="fleet-expense-section">
          <div className="row">
            <div className="col-md-4">
              {loadings?.getFleetExpensesAsyncThunk ? (
                <Loadingdata />
              ) : mappedData?.length === 0 ? (
                <NoDataFound nodataTitle="No Data Found" />
              ) : (
                <div className="stats-section">
                  <div className="title">
                    <strong>Expense Summary</strong>
                  </div>
                  <div className="stats-chart">
                    <Pie
                      data={data}
                      options={{ plugins: { legend: { display: false } } }}
                    />
                  </div>
                  <div className="stats-description-section">
                    {mappedData?.map(({ category, amount, color }) => (
                      <div key={category} className="single-item">
                        <div className="color-section">
                          <div
                            style={{ backgroundColor: color }}
                            className={`color`}
                          ></div>
                          <span>{category}</span>
                        </div>
                        <div className="amount">
                          <span>${amount.toLocaleString()}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="total-amount">
                    <strong>${totalAmount}</strong>
                    <span>TOTAL</span>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-8">
              <div className="details-section">
                <div className="filter-section">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="single-field">
                        <label>Starting Date</label>
                        <input
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-field">
                        <label>Ending Date</label>
                        <input
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="single-field">
                        <label>Time Period</label>
                        <select
                          name=""
                          value={period}
                          onChange={(e) => setPeriod(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="lastWeek">Past Week</option>
                          <option value="lastMonth">Past Month</option>
                          <option value="lastQuarter">Past Quarter</option>
                          <option value="lastYear">Past Year</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-btn">
                  <button onClick={handleClickFilter}>Filter Results</button>
                </div>
                <div className="truck-expense-section">
                  <div className="title">
                    <strong>
                      {fleetData?.fleetType === "TRUCK" ? "Truck" : "Trailer"}{" "}
                      Expense History
                    </strong>
                  </div>
                  <FleetSelectionTab
                    selectedCount={selectedRows.length}
                    onDelete={handleDelete}
                    setSelectedRows={setSelectedRows}
                  />
                  {loadings?.getFleetExpensesAsyncThunk ? (
                    <Loadingdata />
                  ) : fleetExpenses?.results?.length === 0 ? (
                    <NoDataFound nodataTitle="No Data Found" />
                  ) : (
                    <div className="table-wrapper overflow-x-auto">
                      <div className="container-fluid" style={{ padding: 0 }}>
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "30px" }}>
                                <Form.Check aria-label="option 1" />
                              </th>
                              {columnSortOrder.map((item, index) => (
                                <th
                                  onClick={() => {
                                    if (!item.isSortable) return;
                                    setColumnSortOrder((prev) => {
                                      const newColumns = prev.map((col) => {
                                        if (col.key === item.key) {
                                          return {
                                            ...col,
                                            order:
                                              col.order === "asc"
                                                ? "desc"
                                                : "asc",
                                          };
                                        }
                                        return {
                                          ...col,
                                          order: undefined,
                                        };
                                      });
                                      return newColumns;
                                    });
                                  }}
                                >
                                  <div>
                                    <strong>{item.name}</strong>
                                    <img src={tableIcon} alt="icon" />
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {fleetExpenses?.results?.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <Form.Check
                                      aria-label={`Select truck ${item.id}`}
                                      checked={isSelected(item.id)}
                                      onChange={() => handleSelect(item.id)}
                                    />
                                  </td>
                                  <td>
                                    <div className="stop-item">
                                      <span>
                                        {item?.date !== null
                                          ? moment(item?.date).format(
                                              "D/M/YYYY"
                                            )
                                          : moment(item?.createdAt).format(
                                              "D/M/YYYY"
                                            )}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <span>{item.category || "N/A"}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="address-col">
                                      <span>{item.description || "N/A"}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <span>{item.amount || "N/A"}</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="action-buttons">
                                      <div className="trash-icon">
                                        <button
                                          style={{
                                            boder: "none",
                                            background: "transparent",
                                          }}
                                          onClick={() =>
                                            handleEditTruckModalShow(item)
                                          }
                                        >
                                          <img
                                            src={pencilIcon}
                                            alt="Trash"
                                            style={{ marginLeft: "15px" }}
                                          />
                                        </button>
                                        <EditTruckExpenseModal
                                          fleetType={fleetData?.fleetType}
                                          expenseData={expenseData}
                                          editTruckExpenseShow={
                                            editTruckExpenseShow
                                          }
                                          handleEditTruckModalClose={
                                            handleEditTruckModalClose
                                          }
                                        />
                                      </div>
                                      <div className="single-action">
                                        <button
                                          onClick={() =>
                                            handleDeleteModalOpen(item.id)
                                          }
                                        >
                                          <img src={deleteGray} alt="icon" />
                                        </button>
                                        <DeleteFleetExpenseModal
                                          delId={delId}
                                          handleDeleteModalClose={
                                            handleDeleteModalClose
                                          }
                                          deleteModalShow={deleteModalShow}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                {fleetExpenses?.totalPages > 1 && (
                  <UsePagination
                    currentPage={fleetExpenses?.page}
                    count={fleetExpenses?.totalPages}
                    onChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
