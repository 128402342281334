import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { Provider } from 'react-redux'
import { store } from './redux/store'
// import Models from './components/modals/index.jsx'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundary from './ErrorBoundary.js'
import { ClerkProvider } from '@clerk/clerk-react'
import { theme } from './muitheme'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ClerkProvider
          frontendApi={process.env.REACT_APP_CLERK_FRONTEND_API}
          publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
        >
          <Provider store={store}>
            <BrowserRouter>
              <ErrorBoundary>
                <App />
                <ToastContainer
                  position="bottom-right"
                />
                {/* <Models /> */}
              </ErrorBoundary>
            </BrowserRouter>
          </Provider>
        </ClerkProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
)

// Report web vitals (optional)
reportWebVitals()
