import Close from '@mui/icons-material/Close'
import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import React from 'react'
import { Typography } from '@mui/material'

export default function AddExpensesModal(props) {
  return (
    <Modal
      className='add-new-expense'
      open={props.newExpense}
      onClose={props.handleCloseNewExpense}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: {
            xs: '90%',
            sm: '80%',
            md: 537,
          },
          maxWidth: 537,
          bgcolor: '#fff',
          p: 3,
          borderRadius: '16px',
          border: '1px solid #dfdfe2',
          boxShadow: '0px 15px 32px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3,
          }}
        >
          <Typography variant='modalTitleClass' id='simple-modal-title' className='model-title'>
            Invoice Changes/Expenses
          </Typography>

          <IconButton
            aria-label='close'
            onClick={props.handleCloseNewExpense}
            sx={{
              '&.MuiIconButton-root': {
                padding: '3px',
                borderRadius: '0%',
                fill: '#68687A',
              },
            }}
          >
            <Close />
          </IconButton>
        </Box>

        <Box
          component='form'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
          noValidate
          autoComplete='off'
        >
          <Box className="radio-btns">
            <RadioGroup row aria-label='expense-type' name='expense-type'>
              <FormControlLabel
                value='charge'
                control={<Radio size='small' />}
                label='Charge'
              />
              <FormControlLabel
                value='credit'
                control={<Radio size='small' />}
                label='Credit'
              />
            </RadioGroup>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mb: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormLabel htmlFor='description' className='form-label'>Description</FormLabel>
              <TextField
                required
                id='description'
                fullWidth
                placeholder='Type here ...'
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormLabel htmlFor='amount' className='form-label'>
                Amount
              </FormLabel>
              <TextField
                required
                id='amount'
                type='number'
                className='input-field'
                placeholder='$ 00'
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <Button variant='contained' color='primary'>
              Add
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
