import { Modal, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getDriversAsyncThunk,
  updateDriverAsyncThunk,
} from "../../redux/pagesSlices/driverSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { State } from "country-state-city"; // Import the State module
import * as Yup from "yup";
import moment from "moment";
import { getFleetsAsyncThunk } from "../../redux/pagesSlices/fleetSlice";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const validatePhoneNumber = (phoneNumber) => {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber);
  return parsedNumber ? parsedNumber.isValid() : false;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  zipCode: Yup.string(),
  phone: Yup.string().matches(
    /^(\d{10})$/,
    "Phone number must be exactly 10 digits"
  ),
  email: Yup.string().email("Please enter a valid email"),
  fleetId: Yup.string(),
  dateOfBirth: Yup.date(),
  hireDate: Yup.date(),
  licenseNumber: Yup.number(),
  issuingState: Yup.string(),
});

export default function EditDriverProfileModal({
  showEditDriverProfile,
  handleHideEditProfileModal,
  user,
}) {
  const { loadings } = useSelector((state) => state?.drivers);
  const data = useSelector((state) => state?.fleet?.fleet);
  const [states, setStates] = useState("");

  const fetchData = async () => {
    dispatch(
      getFleetsAsyncThunk({
        fleetType: "TRUCK",
      })
    );
  };

  useEffect(() => {
    fetchData();
    const usStates = State.getStatesOfCountry("US");
    setStates(usStates);
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log("values", values);
    dispatch(
      updateDriverAsyncThunk({
        id: user?.user_id,
        data: {
          ...values,
          displayName: values.firstName + " " + values.lastName,
        },
        callBack: () => {
          handleHideEditProfileModal();
        },
      })
    );
  };

  return (
    <Modal
      show={showEditDriverProfile}
      onHide={handleHideEditProfileModal}
      size="lg"
      centered
      className="edit-driver-profile-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Driver Profile </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            firstName: user?.firstName || "",
            lastName: user?.lastName || "",
            city: user?.city || "",
            state: user?.state || "",
            zipCode: user?.zipCode || "",
            phone: user?.phone || "",
            email: user?.email || "",
            fleetId: user?.fleets[0]?.id || "",
            dateOfBirth: moment(user?.dateOfBirth).format("YYYY-MM-DD") || "",
            hireDate: moment(user?.hireDate).format("YYYY-MM-DD") || "",
            licenseNumber: user?.licenseNumber || "",
            issuingState: user?.issuingState || "",
          }}
          className="row g-3"
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty }) => (
            <Form>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label
                    htmlFor="firstName"
                    className="form-label label-heading"
                  >
                    First Name
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="Max"
                    className="form-control form-input"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="lastName"
                    className="form-label label-heading"
                  >
                    Last Name
                  </label>
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="Max"
                    className="form-control form-input"
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="firstName"
                    className="form-label label-heading add-label-2"
                  >
                    City
                  </label>
                  <Field
                    type="text"
                    name="city"
                    placeholder="Enter City"
                    className="form-control form-input"
                  />
                  <ErrorMessage name="city" component="div" className="error" />
                </div>
                <div className="col-md-6 ">
                  <label
                    htmlFor="lastName"
                    className="form-label label-heading add-label-2"
                  >
                    State
                  </label>
                  <Field
                    as="select"
                    placeholder="Select"
                    name="state"
                    className="form-control form-input"
                  >
                    <option value="">Select</option>
                    {Object.entries(states).length > 0 ? (
                      Object.entries(states)?.map(([key, value]) => (
                        <option key={key} value={value.isoCode}>
                          {value.name}
                        </option>
                      ))
                    ) : (
                      <option value="">No states available</option>
                    )}
                  </Field>
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="firstName"
                    className="form-label label-heading"
                  >
                    Phone Number
                  </label>
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <input
                        className="form-control form-input"
                        {...field}
                        type="text"
                        placeholder="Enter phone number"
                        maxLength="10"
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="lastName"
                    className="form-label label-heading"
                  >
                    Zip Code
                  </label>
                  <Field
                    type="text"
                    className="form-control form-input"
                    placeholder="Max"
                    name="zipCode"
                  />
                  <ErrorMessage
                    name="zipCode"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="firstName"
                    className="form-label label-heading add-label-2"
                  >
                    Email
                  </label>
                  <Field
                    type="text"
                    disabled
                    className="form-control form-input"
                    placeholder="example@reenvoy.com"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6 ">
                  <label
                    htmlFor="lastName"
                    className="form-label label-heading add-label-2"
                  >
                    Assign Truck
                  </label>
                  <Field
                    as="select"
                    placeholder="Select"
                    name="fleetId"
                    className="form-control form-input"
                  >
                    <option value="">Select</option>

                    {data?.results?.map((item) => (
                      <option key={item.id} value={item?.id}>
                        {item?.fleetMake}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fleetId"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="firstName"
                    className="form-label label-heading add-label-2"
                  >
                    Date of birth
                  </label>
                  <Field
                    type="date"
                    className="form-control form-input"
                    placeholder="mm/dd/YYYY"
                    name="dateOfBirth"
                  />
                  <ErrorMessage
                    name="dateOfBirth"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6 ">
                  <label
                    htmlFor="lastName"
                    className="form-label label-heading add-label-2"
                  >
                    Date of hire
                  </label>
                  <Field
                    type="date"
                    className="form-control form-input"
                    placeholder="mm/dd/YYYY"
                    name="hireDate"
                  />
                  <ErrorMessage
                    name="hireDate"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="col-md-6 ">
                  <label
                    htmlFor="firstName"
                    className="form-label label-heading add-label-2"
                  >
                    License Number
                  </label>
                  <Field
                    type="text"
                    className="form-control form-input"
                    placeholder="xxxxxxxxxxx"
                    name="licenseNumber"
                  />
                  <ErrorMessage
                    name="licenseNumber"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="col-md-6 ">
                  <label
                    htmlFor="lastName"
                    className="form-label label-heading add-label-2"
                  >
                    Issuing states for license
                  </label>
                  <Field
                    as="select"
                    placeholder="Select"
                    name="issuingState"
                    className="form-control form-input"
                  >
                    <option value="">Select</option>
                    {Object.entries(states).length > 0 ? (
                      Object.entries(states)?.map(([key, value]) => (
                        <option key={key} value={value.isoCode}>
                          {value.name}
                        </option>
                      ))
                    ) : (
                      <option value="">No states available</option>
                    )}
                  </Field>
                  <ErrorMessage
                    name="issuingState"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="document-btn mt-3">
                  <div className="driver-profile-btn">
                    <Button
                      type="submit"
                      className="add-btn"
                      disabled={loadings?.updateDriverAsyncThunk || !dirty}
                    >
                      {loadings?.updateDriverAsyncThunk ? (
                        <Spinner animation="border" role="status" size="sm" />
                      ) : (
                        "Update"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
