import React from 'react'
import { Link } from 'react-router-dom';
import Titlebar from '../components/adminPanelComponents/Titlebar'
import Sidebar from '../components/adminPanelComponents/Sidebar'

const ComplianceDashboard = () => {
    return (
        <>

            <Titlebar />
            <div className="row">
                <div className="col-12 col-md-2 p-0">
                    <Sidebar />
                </div>

                <div className="col-12 col-md-10 p-0">
                    <div className="integration-topbar">

                        <div className="integration-link">
                            <Link to={'/compliance'} >Company Documents</Link>
                            <Link to={'/dashboard'}>Compliance Documentation Dashboard</Link>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default ComplianceDashboard
