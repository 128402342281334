import React, { useState } from "react";
import refresh from "../assets/images/forms-images/arrow-refresh-05.svg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import viewIcon from "../assets/images/view.svg";
import download from "../assets/images/download.svg";
import deleteIcon from "../assets/images/delete.svg";
import { IoEyeOutline } from "react-icons/io5";
import edit from "../assets/images/forms-images/Edit_Pencil_02.svg";
import tableIcon from "../assets/images/table-icon.svg";

const complianceDocuments = ({
    activeDashboardHandler,
    activeDashboardTab,
    subActiveab,
    subActiveabHandler

}) => {
    return (
        <div className="docucments-content-wrapper">
            <div className=" container-fluid p-0">
                <div className="bg-color">
                    <div className="items-list">
                        <ul>
                            <li>
                                <Link
                                    to={""}
                                    onClick={() => activeDashboardHandler("Company")}
                                    className={` ${activeDashboardTab === "Company" ? "active" : ""
                                        }`}
                                >
                                    Company
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={""}
                                    onClick={() => activeDashboardHandler("Driver")}
                                    className={` ${activeDashboardTab === "Driver" ? "active" : ""
                                        }`}
                                >
                                    Driver
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={""}
                                    onClick={() => activeDashboardHandler("Truck")}
                                    className={` ${activeDashboardTab === "Truck" ? "active" : ""
                                        }`}
                                >
                                    Truck
                                </Link>
                            </li>
                        </ul>
                    </div>

                    {activeDashboardTab === "Company" && (
                        <>
                            <div className="integration-topbar">
                                <div className="integration-link">
                                    <Link className="active">Expired Documents</Link>
                                    <Link>Expired Soon</Link>
                                    <Link>Missing</Link>
                                </div>
                            </div>
                            <div className="integration-topbar">
                                <div className="integration-link">
                                    <p>7 Documents Expired</p>
                                </div>
                            </div>
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div>
                                                    <strong>DOCUMENT TITLE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>

                                            <th>
                                                <div>
                                                    <strong>UPDATED DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>

                                            <th>
                                                <div>
                                                    <strong>EXPIRATION DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    {activeDashboardTab === "Driver" && (
                        <>
                            <div className="integration-topbar">
                                <div className="integration-link">
                                    <Link
                                        onClick={() => subActiveabHandler("Company")}
                                        className={` ${subActiveab === "Company" ? "active" : ""
                                            }`}
                                    >
                                        Expired Documents
                                    </Link>
                                    <Link
                                        onClick={() => subActiveabHandler("expire")}
                                        className={` ${subActiveab === "expire" ? "active" : ""
                                            }`}
                                    >
                                        Expired Soon
                                    </Link>
                                    <Link
                                        onClick={() => subActiveabHandler("missing")}
                                        className={` ${subActiveab === "missing" ? "active" : ""
                                            }`}
                                    >
                                        Missing
                                    </Link>
                                </div>
                            </div>
                            <div className="integration-topbar">
                                <div className="integration-link">
                                    <p>7 Documents Expired</p>
                                </div>
                            </div>
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div>
                                                    <strong>Driver</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    <strong>DOCUMENT TITLE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>

                                            <th>
                                                <div>
                                                    <strong>UPDATED DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>

                                            <th>
                                                <div>
                                                    <strong>EXPIRATION DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                    {activeDashboardTab === "Truck" && (
                        <>
                            <div className="integration-topbar">
                                <div className="integration-link">
                                    <Link>Expired Documents</Link>
                                    <Link>Expired Soon</Link>
                                    <Link>Missing</Link>
                                </div>
                            </div>
                            <div className="integration-topbar">
                                <div className="integration-link">
                                    <p>7 Documents Expired</p>
                                </div>
                            </div>
                            <div className="table-wrapper">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div>
                                                    <strong>Truck</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>
                                            <th>
                                                <div>
                                                    <strong>DOCUMENT TITLE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>

                                            <th>
                                                <div>
                                                    <strong>UPDATED DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>

                                            <th>
                                                <div>
                                                    <strong>EXPIRATION DATE</strong>
                                                    <img src={tableIcon} alt="icon" />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <span>IFTA License</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2023</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div>
                                                    <span>12/31/2024</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default complianceDocuments
