import boxBlue from "../assets/images/box-blue.svg";
import truckBlue from "../assets/images/truck-blue.svg";
import usersBlue from "../assets/images/users-blue.svg";

export default function DetailsCard({ item }) {
  return (
    <div className="details-cards-wrapper">
      <div className="container-fluid">
        <div className="upper-cards">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="single-detail-card">
                <div className="card-content">
                  <div className="detail-title">
                    <div className="icon">
                      <img src={truckBlue} alt="icon" />
                    </div>
                    <div className="title">
                      <strong>DRIVER</strong>
                    </div>
                  </div>
                  <div className="description">
                    <p>#890-2017 Freightiner Cascadia</p>
                  </div>
                  <div className="heading">
                    <strong>{item?.driver}</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3">
              <div className="single-detail-card">
                <div className="card-content">
                  <div className="detail-title">
                    <div className="icon">
                      <img src={boxBlue} alt="icon" />
                    </div>
                    <div className="title">
                      <strong>DISPATCHER</strong>
                    </div>
                  </div>
                  <div className="description">
                    <p>08/15/2023</p>
                  </div>
                  <div className="heading">
                    <strong>Mohamma Ahmed</strong>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-6 col-lg-4 mb-3">
              <div className="single-detail-card">
                <div className="card-content">
                  <div className="detail-title">
                    <div className="icon">
                      <img src={usersBlue} alt="icon" />
                    </div>
                    <div className="title">
                      <strong>PARTNER COMPANY CONTACT</strong>
                    </div>
                  </div>
                  <div className="description">
                    <p>(480) 293-4322</p>
                  </div>
                  <div className="heading">
                    <strong>Joseph Smith</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-cards">
          <div className="row">
            <div className="col-md-4 col-lg-2 mb-3">
              <div className="single-detail-card">
                <div className="title">
                  <strong>BROKER/COMPANY</strong>
                </div>
                <div className="info">
                  <span>TQL</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 mb-3">
              <div className="single-detail-card">
                <div className="title">
                  <strong>LOAD</strong>
                </div>
                <div className="info">
                  <span>{item?.loadType || "N/A"}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 mb-3">
              <div className="single-detail-card">
                <div className="title">
                  <strong>WEIGHT (LBS)</strong>
                </div>
                <div className="info">
                  <span>{item?.weight || "N/A"}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 mb-3">
              <div className="single-detail-card">
                <div className="title">
                  <strong>CONFIRMED RATE</strong>
                </div>
                <div className="info">
                  <span>$2,000.00</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 mb-3">
              <div className="single-detail-card">
                <div className="title">
                  <strong>TOTAL MILES</strong>
                </div>
                <div className="info">
                  <span>1220.41</span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-2 mb-3">
              <div className="single-detail-card">
                <div className="title">
                  <strong>RATE PER MILE</strong>
                </div>
                <div className="info">
                  <span>${item?.rate}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
