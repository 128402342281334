import viewIcon from "../assets/images/view.svg";
import download from "../assets/images/download.svg";
import deleteIcon from "../assets/images/delete.svg";
import { IoEyeOutline } from "react-icons/io5";

export default function DocumentsContent() {
  return (
    <div className="docucments-content-wrapper container-fluid">
      <div className="documents-table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="single-list-item">
                  <div className="item-info">
                    <span>IL</span>
                    <div className="info">
                      <strong>Rate Confirmation</strong>
                      <p>3628392324-43-343</p>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="item-actions">
                  <div className="single-action">
                    <button>
                      <IoEyeOutline color="#9499A5" />
                    </button>
                  </div>
                  <div className="single-action">
                    <button>
                      <img src={download} alt="icon" />
                    </button>
                  </div>
                  <div className="single-action">
                    <button>
                      <img src={deleteIcon} alt="icon" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="single-list-item">
                  <div className="item-info">
                    <span>IL</span>
                    <div className="info">
                      <strong>Rate Confirmation</strong>
                      <p>3628392324-43-343</p>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="item-actions">
                  <div className="single-action">
                    <button>
                      <IoEyeOutline color="#9499A5" />
                    </button>
                  </div>
                  <div className="single-action">
                    <button>
                      <img src={download} alt="icon" />
                    </button>
                  </div>
                  <div className="single-action">
                    <button>
                      <img src={deleteIcon} alt="icon" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="single-list-item">
                  <div className="item-info">
                    <span>IL</span>
                    <div className="info">
                      <strong>Rate Confirmation</strong>
                      <p>3628392324-43-343</p>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="item-actions">
                  <div className="single-action">
                    <button>
                      <IoEyeOutline color="#9499A5" />
                    </button>
                  </div>
                  <div className="single-action">
                    <button>
                      <img src={download} alt="icon" />
                    </button>
                  </div>
                  <div className="single-action">
                    <button>
                      <img src={deleteIcon} alt="icon" />
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
