import arrowBack from "../assets/images/arrow-back.svg";
import trash from "../assets/images/trash.svg";
import cancel from "../assets/images/cancel-right.svg";
import edit from "../assets/images/edit-icon.svg";
import DeleteLoadModal from "./Modals/DeleteLoadModal";
import TruckModal from "./Modals/MarkTruckModal";
import EditLoadModal from "./Modals/EditLoadModal";
import downloadGray from "../assets/images/download-gray.svg";
import addDocument from "../assets/images/add-document.svg";
import { Link } from "react-router-dom";
import { act, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { createFleetAsyncThunk } from "../redux/pagesSlices/fleetSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getDriversAsyncThunk } from "../redux/pagesSlices/driverSlice";

export default function FleetPageTopBar({ data, activeTab }) {
  const { activeOrg } = useSelector((state) => state.workspace);
  const { loadings } = useSelector((state) => state.fleet);
  const { drivers } = useSelector((state) => state.drivers);
  const [show, setShow] = useState(false);
  const [driverEmail, setDriverEmail] = useState(null);
  const [deleteModalShow, deleteSetModalShow] = useState(false);
  const [editModalShow, editSetModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      status: true,
    };
    dispatch(getDriversAsyncThunk(params));
  }, [dispatch, activeOrg, currentPage]);

  const handleDeleteModalOpen = () => {
    deleteSetModalShow(true);
  };

  const handleDeleteModalClose = () => {
    deleteSetModalShow(false);
  };

  const handleEditModalOpen = () => {
    editSetModalShow(true);
  };

  const handleEditModalClose = () => {
    editSetModalShow(false);
  };
  // Modal control
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currentYear = new Date().getFullYear();

  // Formik and Yup validation schema
  const validationSchema = Yup.object().shape({
    fleetType: Yup.string().required("Fleet Type is required").max(32),

    modelYear: Yup.number("Model Year must be a number")
      .min(1800, "Model Year must be at least 1800")
      .max(currentYear, `Model Year cannot be later than ${currentYear}`)
      .required("Model Year is required"),
    fleetMake: Yup.string().required("Fleet Make is required").max(32),
    fleetModel: Yup.string()
      .matches(/^[0-9]+$/, "fleetModel must be numeric")
      .required("fleetModel is required")
      .max(32),
    odometer: Yup.string()
      .matches(/^[0-9]+$/, "Odometer must be numeric")
      .required("Odometer is required")
      .max(32),
    fleetNumber: Yup.string()
      .matches(/^[0-9]+$/, "Fleet Number must be numeric")
      .required("Fleet Number is required")
      .max(32),
    licenseNumber: Yup.string()
      .matches(/^[0-9]+$/, "License Number must be numeric")
      .required("License Number is required")
      .max(32),
    status: Yup.string().required("Status is required").max(32),
    users: Yup.array().optional(),
  });

  const handleCreateFleet = (values, { setSubmitting, resetForm }) => {
    dispatch(
      createFleetAsyncThunk({
        data: values,
        activeTab: values.fleetType,
        callBack: () => {
          handleClose();
          resetForm(); // Reset form after submission
          setDriverEmail(null);
        },
      })
    ).finally(() => {
      setSubmitting(false); // Stop the submission spinner
    });
  };

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const downloadJsonFile = () => {
    setIsLoadingDownload(true);

    // Mocking download delay
    setTimeout(() => {
      const fileData = JSON.stringify(data, null, 2);
      const blob = new Blob([fileData], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "fleetData.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoadingDownload(false);
    }, 1000); // 1-second delay to simulate loading
  };

  return (
    <div className="load-top-bar-wrapper">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-4 col-4">
            <div className="bar-navigation">
              <div className="back-icon">
                <Link to="/home">
                  <img src={arrowBack} alt="arrow back" />
                </Link>
              </div>
              <div className="page-name">
                <strong>Fleet</strong>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-8">
            <div className="buttons-wrapper">
              {(activeTab === "TRUCK" || activeTab === "TAILOR") && (
                <>
                  <div className="single-button">
                    <button
                      onClick={downloadJsonFile}
                      disabled={isLoadingDownload || data?.length == 0}
                    >
                      {isLoadingDownload ? (
                        <span>Loading...</span>
                      ) : (
                        <>
                          <span>Download</span>
                          <div className="icon">
                            <img src={downloadGray} alt="icon" />
                          </div>
                        </>
                      )}
                    </button>
                  </div>

                  {/* <div className="single-button">
                <button onClick={handleShow} className="add-btn">
                <div className="icon">
                <img src={addDocument} alt="icon" />
                </div>
                <span>Add</span>
                </button>
                </div> */}
                  <div className="single-button">
                    <Link
                      to={`/add-fleet?tab=${activeTab}`}
                      className="add-btn"
                    >
                      <div className="icon">
                        <img src={addDocument} alt="icon" />
                      </div>
                      <span>Add</span>
                    </Link>
                  </div>
                </>
              )}

              {/* Modal for Adding Truck */}
              <Modal show={show} onHide={handleClose} className="add-truck-btn">
                <Modal.Header closeButton>
                  <Modal.Title>Add New Fleet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Truck Form using Formik */}
                  <Formik
                    initialValues={{
                      fleetType: "TAILOR",
                      // modelYear: 0,
                      fleetMake: "",
                      fleetModel: "",
                      // odometer: 0,
                      // fleetNumber: 0,
                      licenseNumber: "",
                      status: "ACTIVE",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleCreateFleet}
                  >
                    {({ isSubmitting, setFieldValue, values }) => (
                      <Form>
                        <div className="modal-field">
                          <label>Fleet Type</label>
                          <Dropdown className="dropdown-1">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {values.fleetType || "Select fleet type"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {["TRUCK", "TAILOR"].map((fleetType) => (
                                <Dropdown.Item
                                  key={fleetType}
                                  onClick={() =>
                                    setFieldValue("fleetType", fleetType)
                                  }
                                >
                                  {fleetType}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="fleetType"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="modal-field">
                          <label>Model Year</label>
                          <Field
                            type="string"
                            name="modelYear"
                            placeholder="Year"
                          />
                          <ErrorMessage
                            name="modelYear"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="modal-field">
                          <label>Fleet Make</label>
                          <Dropdown className="dropdown-1">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {values.fleetMake || "Select"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {["Freightliner", "Kenworth", "Volvo"].map(
                                (make) => (
                                  <Dropdown.Item
                                    key={make}
                                    onClick={() =>
                                      setFieldValue("fleetMake", make)
                                    }
                                  >
                                    {make}
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="fleetMake"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="modal-field">
                          <label>Fleet Model</label>
                          <Field
                            type="text"
                            name="fleetModel"
                            placeholder="fleetModel"
                          />
                          <ErrorMessage
                            name="fleetModel"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="modal-field">
                          <label>Odometer</label>
                          <Field
                            type="text"
                            name="odometer"
                            placeholder="odometer"
                          />
                          <ErrorMessage
                            name="odometer"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="modal-field">
                          <label>Fleet Number</label>
                          <Field
                            type="text"
                            name="fleetNumber"
                            placeholder="Fleet Number"
                          />
                          <ErrorMessage
                            name="fleetNumber"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="modal-field">
                          <label>License Number</label>
                          <Field
                            type="text"
                            name="licenseNumber"
                            placeholder="License Number"
                          />
                          <ErrorMessage
                            name="licenseNumber"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div className="modal-field">
                          <label>Assign Driver</label>
                          <Dropdown className="dropdown-1">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {driverEmail || "Select Status"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {drivers?.results?.map((item) => (
                                <Dropdown.Item
                                  key={item.user_id}
                                  onClick={() => {
                                    setFieldValue("users", [item.user_id]);
                                    setDriverEmail(item.email);
                                  }}
                                >
                                  {item.email}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="modal-field">
                          <label>Status</label>
                          <Dropdown className="dropdown-1">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {values.status || "Select Status"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {["ACTIVE", "DEACTIVE"].map((status) => (
                                <Dropdown.Item
                                  key={status}
                                  onClick={() =>
                                    setFieldValue("status", status)
                                  }
                                >
                                  {status}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>

                        <Button
                          className="btn-style"
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "21px",
                          }}
                          disabled={
                            isSubmitting || loadings.createFleetAsyncThunk
                          }
                          type="submit"
                        >
                          {isSubmitting || loadings.createFleetAsyncThunk ? (
                            <Spinner
                              animation="border"
                              role="status"
                              size="sm"
                            />
                          ) : (
                            "Add"
                          )}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>

              <DeleteLoadModal
                handleDeleteModalClose={handleDeleteModalClose}
                deleteModalShow={deleteModalShow}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
