import React from 'react'
import Titlebar from '../components/adminPanelComponents/Titlebar'
import Sidebar from '../components/adminPanelComponents/Sidebar'
import Form from "react-bootstrap/Form";
import tableIcon from "../assets/images/table-icon.svg";
import stopIcon from "../assets/images/stop-icon.svg";
import boxIcon from "../assets/images/box.svg";
import refresh from '../assets/images/forms-images/arrow-refresh-05.svg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ConnectedTrucks = () => {
  return (
   <>
    <Titlebar/>
      <div className="row"> 
        <div className="col-12 col-md-2 p-0">
            <Sidebar/>
        </div>

        <div className="col-12 col-md-10 p-0">
          <div className="integration-topbar">
           
              <div className="integration-link">
                <Link to={'/integration'} > Connected Drivers</Link>
                <Link to={'/connected-trucks'}> Connected Trucks</Link>
              </div>
           
            <div className="topbar-butn">
              <Button> <img src={refresh}alt='Refresh-icon'/> Click Here To Link Your Motive Account</Button>
            </div>
          </div>


        <div className="table-wrapper">
          
        <table>
          <thead>
            <tr>
             
            
              <th>
                <div>
                  <strong>TRUCK NUMBER</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>
              

              <th>
                <div>
                  <strong>Model Year</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>

              <th>
                <div>
                  <strong>Make</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>

              <th>
                <div>
                  <strong>Model</strong>
                  <img src={tableIcon} alt="icon" />
                </div>
              </th>

            </tr>
          </thead>


          <tbody>

            <tr>
              <td>
                <div>
                  <span>102</span>
                </div>
              </td>

              <td>
                <div>
                  <span>2014</span>
                </div>
              </td>

              <td>
                <div>
                  <span>2011</span>
                </div>
              </td>
             
              <td>
                <div>
                  <span>S256565</span>
                </div>
              </td>
            </tr>

      
            <tr>
              <td>
                <div>
                  <span>102</span>
                </div>
              </td>

              <td>
                <div>
                  <span>2014</span>
                </div>
              </td>

              <td>
                <div>
                  <span>2011</span>
                </div>
              </td>
             
              <td>
                <div>
                  <span>S256565</span>
                </div>
              </td>
            </tr>


            <tr>
              <td>
                <div>
                  <span>102</span>
                </div>
              </td>

              <td>
                <div>
                  <span>2014</span>
                </div>
              </td>

              <td>
                <div>
                  <span>2011</span>
                </div>
              </td>
             
              <td>
                <div>
                  <span>S256565</span>
                </div>
              </td>
            </tr>

          
          </tbody>
        </table>
     
    </div>
        </div>
      </div>
   
   </>
  )
}

export default ConnectedTrucks
