import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import home from "../assets/images/home.svg";
import loads from "../assets/images/loads.svg";
import activity from "../assets/images/activity.svg";
import bars from "../assets/images/bars.svg";
import user from "../assets/images/user-avatar.svg";
import profile from "../assets/images/user.svg";
import rocket from "../assets/images/rocket.svg";
import truck from "../assets/images/truck.svg";
import search from "../assets/images/search.svg";
import CustomDropdown from "./NavbarDropdown";
import ProfileDropdown from "./ProfileDropdown";
import ProfileSetting from "../Pages/ProfileSetting";
import { useAuth, useUser } from "@clerk/clerk-react";
import driverIcon from "../assets/images/driver-icon.svg";
import { IoMdMenu } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import useUserHasRole from "../hooks/useHasRole";
import { ROLES } from "../constant/roles";
import { useSelector } from "react-redux";

const defaultNavItems = [
  { path: "/home", label: "Home", icon: home },
  { path: "/activity", label: "Activity", icon: activity },
  { path: "/load", label: "Loads", icon: loads },
  { path: "/fleet", label: "Fleet", icon: truck },
  { path: "/finance", label: "Finance", icon: bars },
  { path: "/drivers", label: "Drivers", icon: driverIcon },
];

const adminNavItems = [
  { path: "/home", label: "Home", icon: home },
  { path: "/activity", label: "Activity", icon: activity },
  { path: "/load", label: "Loads", icon: loads },
  { path: "/fleet", label: "Fleet", icon: truck },
  { path: "/finance", label: "Finance", icon: bars },
  { path: "/drivers", label: "Drivers", icon: driverIcon },
  { path: "/admin/company-profile", label: "Admin", icon: profile },
];

const Navbar = () => {
  const { activeOrg } = useSelector((state) => state.workspace);
  const [headerMenu, setHeaderMenu] = useState(false);
  const location = useLocation(); // Get current route
  const { user } = useUser();
  const [navItems, setNavItems] = useState(defaultNavItems);
  const adminAccess = useUserHasRole(user, [ROLES.OWNER, ROLES.ADMIN]);

  useEffect(() => {
    if (adminAccess) {
      setNavItems(adminNavItems);
    } else {
      setNavItems(defaultNavItems);
    }
  }, [adminAccess, activeOrg]);

  const pathsToHideNavbar = [
    "/new-verify",
    "/verified",
    "/workSpace",
    "/inviteTeam",
    "/reenovy",
    "/workspace-invite",
    "/welcome",
  ];
  const isMatch = pathsToHideNavbar.some((prefix) =>
    location.pathname.startsWith(prefix)
  );

  const handleMenuShow = () => {
    setHeaderMenu(true);
  };
  const handleMenuClose = () => {
    setHeaderMenu(false);
  };

  return (
    <div className={`navbar-wrapper ${!user || isMatch ? "d-none" : ""}`}>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-2 col-lg-4 col-md-4 col-4 align-self-center">
            <div className="navbar-header d-flex align-items-center justify-content-between d-lg-block">
              <div className="logo-wrapper">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </div>

              {/* <div className="dropdown-wrapper">
            <CustomDropdown />
          </div> */}
            </div>
          </div>
          <div className="col-xl-7 col-lg-1 col-md-1 col-1 align-self-center">
            <div className={`navbar-items ${headerMenu ? "active" : ""}`}>
              <IoClose
                className="d-block  d-md-block d-xl-none"
                style={{
                  width: 24,
                  height: 24,
                  position: "absolute",
                  top: 20,
                  right: 20,
                }}
                onClick={handleMenuClose}
              />
              <ul>
                {navItems?.map((item) => (
                  <li
                    key={item.path}
                    className={location.pathname === item.path ? "active" : ""}
                  >
                    <Link to={item.path}>
                      <img src={item.icon} alt={item.label} />
                      <span>{item.label}</span>
                    </Link>
                  </li>
                ))}
                <div className="dropdown-wrapper d-flex d-lg-none mt-3 justify-content-center">
                  <CustomDropdown />
                </div>
              </ul>
            </div>
          </div>
          <div className="col-xl-3 col-lg-7 col-md-7 col-7 align-self-center">
            <div className="search-profile-wrapper">
              <IoMdMenu
                style={{ cursor: "pointer", width: 22, height: 22 }}
                className="d-block d-md-block d-lg-block d-xl-none"
                onClick={handleMenuShow}
              />
              <div className="dropdown-wrapper d-none d-lg-block">
                <CustomDropdown />
              </div>
              {/* <div className="search-bar">
            <div className="icon">
              <img src={search} alt="icon" />
            </div>
            <div className="input">
              <input
                type="text"
                name="search"
                id="search"
                placeholder="Search"
              />
            </div>
          </div> */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
