import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../../service/ApiRequests";
import {
  catchAsync,
  detectError,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "../../helpers/detectError";
import { toast } from "react-toastify";

export const switchWorkspaceAsyncThunk = createAsyncThunk(
  "workspace/switchWorkspaceAsyncThunk",
  catchAsync(async ({ org, callBack }) => {
    localStorage.setItem("active_org", org);

    if (callBack) callBack();
    return;
  })
);
export const getWorkspaceAsyncThunk = createAsyncThunk(
  "workspace/getWorkspaceAsyncThunk",
  catchAsync(async ({ id }) => {
    console.log("🚀 ~ catchAsync ~ id:", id);
    const response = await ApiRequests.getWorkSpace(id);
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const updateWorkspaceAsyncThunk = createAsyncThunk(
  "workspace/updateWorkspaceAsyncThunk",
  catchAsync(async ({ id, data, activeTab, callBack }, { dispatch }) => {
    console.log("🚀 ~ catchAsync ~ data:", data);
    const response = await ApiRequests.updateWorkspace({ id, data });
    console.log("🚀 ~ catchAsync ~ response:", response);
    if (response.status === 200) {
      toast.success("Workspace updated Successfully!");
    }
    if (callBack) callBack();

    await dispatch(
      getWorkspaceAsyncThunk({
        id,
      })
    );
    console.log("The get Response is:", response?.data);
    return response?.data;
  })
);

export const createWorkspaceAsyncThunk = createAsyncThunk(
  "workspace/createWorkspaceAsyncThunk",
  catchAsync(async ({ data, callBack }, { dispatch, getState }) => {
    const state = getState();
    const response = await ApiRequests.createWorkSpace(data);

    if (response.status === 201) {
      toast.success("Workspace Created Successfully!", { autoClose: true });
      localStorage.setItem("active_org", response.data.id);
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const sendInvitationAsyncThunk = createAsyncThunk(
  "workspace/sendInvitationAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const response = await ApiRequests.inviteMember(data);
    if (response.status === 201) {
      toast.success("Invitation Sent!");
    }
    if (callBack) callBack();
    return response?.data;
  })
);

export const rejectInvitationAsyncThunk = createAsyncThunk(
  "workspace/rejectInvitationAsyncThunk",
  catchAsync(async ({ data, callBack }) => {
    const response = await ApiRequests.rejectInvitation(data);

    if (response.status === 201) {
      toast.success("Invitation declined!");
    }

    if (callBack) callBack();
    return response?.data;
  })
);

export const removeUserFromWorkspaceAsyncThunk = createAsyncThunk(
  "workspace/removeUserFromWorkspaceAsyncThunk",
  catchAsync(async ({ id, callBack }) => {
    const response = await ApiRequests.deleteWorkSpaceUser(id);
    console.log("response", response);
    if (response.status === 201) {
      toast.success("Invitation declined!");
    }

    if (callBack) callBack();
    return response?.data;
  })
);

export const getInvitationsAsyncThunk = createAsyncThunk(
  "user/getInvitationsAsyncThunk",
  catchAsync(async (params, _) => {
    const response = await ApiRequests.getInvitations(params);
    return response?.data;
  })
);

///////////////////////////////////////////////////

const initialState = {
  //news states
  workspaces: {
    page: 0,
    users: [],
    totalPages: 1,
  },
  workspacesCount: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  listings: {
    page: 0,
    results: [],
    totalPages: 1,
  },
  invites: {
    page: 0,
    invites: [],
    totalPages: 1,
  },
  errors: {},
  loadings: {},
  workspace: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
  activeOrg: "",
  search: null,
  categoryId: null,
  categories: [],
  order: "asce",
  userIds: [],
};

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    setSearchValue(state, action) {
      state.search = action.payload;
    },
    setCategoryValue(state, action) {
      state.categoryId = action.payload;
    },
    setOrderValue(state, action) {
      state.order = action.payload;
    },
    storeUserIds: (state, action) => {
      state.userIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(switchWorkspaceAsyncThunk.fulfilled, (state, action) => {
        state.activeOrg = localStorage.getItem("active_org");
      })
      .addCase(getInvitationsAsyncThunk.pending, (state, action) => {
        if (action.meta?.arg?.page <= 1 || !action.meta?.arg?.page) {
          state.invites = {
            page: 0,
            results: [],
            totalPages: 1,
          };
        }
      })
      .addCase(getInvitationsAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.page > 1) {
          state.invites = {
            ...action.payload,
            users: state?.invites?.results.concat(action?.payload?.results),
          };
        } else {
          state.invites = action.payload;
        }
      })
      .addCase(getWorkspaceAsyncThunk.pending, (state, action) => {
        state.workspace = {};
      })
      .addCase(getWorkspaceAsyncThunk.fulfilled, (state, action) => {
        state.workspace = action.payload;
      })
      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            createWorkspaceAsyncThunk,
            switchWorkspaceAsyncThunk,
            sendInvitationAsyncThunk,
            rejectInvitationAsyncThunk,
            removeUserFromWorkspaceAsyncThunk,
            getInvitationsAsyncThunk,
            getWorkspaceAsyncThunk,
            updateWorkspaceAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default workspaceSlice.reducer;
export const {
  setLoading,
  setSearchValue,
  setCategoryValue,
  setOrderValue,
  storeUserIds,
} = workspaceSlice.actions;
