import React from "react";
import Modal from "react-bootstrap/Modal";
import trash from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendInvitationAsyncThunk } from "../../redux/pagesSlices/workspaceSlice";
import { Spinner } from "react-bootstrap";

function ResendInviteModal({
  resendModalShow,
  handleResendModalClose,
  email,
  role,
}) {
  const { loadings } = useSelector((state) => state.workspace);
  console.log("🚀 ~ email:", email);
  const dispatch = useDispatch();

  const handleResend = () => {
    dispatch(
      sendInvitationAsyncThunk({
        data: { email, role },
        callBack: () => {
          handleResendModalClose();
        },
      })
    );
  };

  return (
    <Modal
      show={resendModalShow}
      onHide={handleResendModalClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-load-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="delete-modal-body">
        <div className="title">
          <strong>Are you sure you want to resend the invitation?</strong>
        </div>
        <button
        className="btn btn-primary w-100 mt-4"
          onClick={handleResend}
          disabled={loadings.sendInvitationAsyncThunk}
        >
          {loadings?.sendInvitationAsyncThunk ? (
            <Spinner animation="border" color="white" role="status" size="sm" />
          ) : (
            <>
              {/* <img src={trash} alt="Delete Icon" /> */}
              <span>Resend</span>
            </>
          )}
        </button>
      </div>
    </Modal>
  );
}

export default ResendInviteModal;
