import React from "react";
import newload from "../assets/images/forms-images/arrow-turn-backward.svg";
import cancel from "../assets/images/forms-images/cancel-01.svg";
import { Link } from "react-router-dom";

const CreateLoadTopbar = () => {
  return (
    <div className="basic-topbar">
      <div className="row d-flex align-utems-center justify-content-between">
        <div className="col-auto col-md-2">
          <Link to="/load">
            <div className="new-create">
              <img src={newload} alt="New Create Load" />
              <p>Create load</p>
            </div>
          </Link>
        </div>
        {/* <div className="col-auto col-md-1">
          <Link to="/load">
            <div className="cancel-img">
              <img src={cancel} alt="Cancel-Icon" />
            </div>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default CreateLoadTopbar;
