import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import plus from "../../assets/images/forms-images/plus.svg";
import { ROLES } from "../../constant/roles";
import { toast } from "react-toastify";
import { sendInvitationAsyncThunk } from "../../redux/pagesSlices/workspaceSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DropdownButton, Spinner } from "react-bootstrap";
import { useAuth, useUser } from "@clerk/clerk-react";

const initialFields = [{ email: "", role: ROLES.ADMIN }];

const InviteTeamModal = ({ inviteModalShow, handleModaClose }) => {
  const [fields, setFields] = useState(initialFields);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useAuth();
  const { user } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Function to add a new email and dropdown pair
  const handleAddField = () => {
    setFields([...fields, { email: "", role: ROLES.ADMIN }]);
  };

  const handleSendInvites = async () => {
    const validFields = fields.filter((field) => field.email.trim() !== "");
    if (validFields.length === 0) {
      toast.error("Please enter a valid email address");
      return;
    }
    setIsLoading(true);
    try {
      const promises = validFields?.map((field) => {
        return dispatch(
          sendInvitationAsyncThunk({
            data: {
              ...field,
              // userId,
              userName: user?.fullName,
            },
          })
        );
      });

      const responses = await Promise.all(promises);

      const failedFields = validFields.filter((_, index) => {
        return responses[index].meta.requestStatus !== "fulfilled";
      });

      setFields(failedFields);

      if (failedFields.length > 0) {
        toast.error("Some invitations failed to send.");
        failedFields.forEach((field) => {
          console.error("Failed to send invite for:", field.email);
        });
      } else {
        // toast.success("All invitations sent successfully!");
        handleModaClose();
        setFields(initialFields);
      }
    } catch (error) {
      toast.error("An error occurred while sending invites.");
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  console.log("isLoading", isLoading);

  return (
    <>
      <Modal
        show={inviteModalShow}
        onHide={handleModaClose}
        className="invite-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="container">
            <div className="invite-heading">
              <strong>Invite Team</strong>
            </div>

            {fields?.map((field, index) => (
              <div className="envite-dropdwn" key={index}>
                <input
                  type="email"
                  placeholder="example@reenvoy.com"
                  value={field.email}
                  onChange={(e) => {
                    let newFields = [...fields];
                    newFields[index].email = e.target.value;
                    setFields(newFields);
                  }}
                />
                <DropdownButton
                  align="end"
                  title={toTitleCase(field.role)}
                  id={`dropdown-menu-align-end-${index}`}
                  onSelect={(role) => {
                    let newFields = [...fields];
                    newFields[index].role = role;
                    setFields(newFields);
                  }}
                >
                  <Dropdown.Item eventKey={ROLES.ADMIN}>Admin</Dropdown.Item>
                  <Dropdown.Item eventKey={ROLES.OWNER}>Owner</Dropdown.Item>
                  <Dropdown.Item eventKey={ROLES.USER}>User</Dropdown.Item>
                  <Dropdown.Item eventKey={ROLES.DRIVER}>Driver</Dropdown.Item>
                  <Dropdown.Item eventKey={ROLES.DISPATCH}>
                    Dispatch
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            ))}

            <div className="add-sec">
              <button onClick={handleAddField}>
                <img src={plus} /> Add another
              </button>
            </div>

            <div className="send-btn">
              <button disabled={isLoading} onClick={handleSendInvites}>
                {isLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Send Invites"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InviteTeamModal;
