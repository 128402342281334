import React from "react";
import doneLogo from "../assets/images/forms-images/icon-park_success.png";
import { useNavigate } from "react-router-dom";

const EmailVerified = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-image fixed-page" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="form-wrapper">
            <div className="done-logo">
              <img src={doneLogo} alt="Done Logo" />
            </div>
            <div className="heading-wrapper">
              <h2>Email Verified</h2>
            </div>
            <div className="title-text">
              <p style={{ textAlign: "center" }}>
                Your email has been successfully verified.<br></br> Click below
                to get started.{" "}
              </p>
            </div>
            <div className="donepage-btn">
              <button className="py-0" onClick={() => navigate("/home")}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerified;
