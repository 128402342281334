import arrowBack from "../assets/images/arrow-back.svg";
import addDocument from "../assets/images/add-document.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateFleetAsyncThunk } from "../redux/pagesSlices/fleetSlice";
import { Modal, Spinner } from "react-bootstrap";
import { useState } from "react";
import { useAuth, useUser } from "@clerk/clerk-react";

export default function FleetDetailsTopBar({ fleetData }) {
  const { loadings } = useSelector((state) => state.fleet);
  const [isShow, setIsShow] = useState(false);
  console.log("🚀 ~ FleetDetailsTopBar ~ fleetData:", fleetData);
  const dispatch = useDispatch();

  const { user } = useUser();
  console.log("🚀 ~ FleetDetailsTopBar ~ user:", user?.organizationMemberships);
  const userRole = user?.organizationMemberships[0]?.role; // assuming single org for simplicity
  console.log("🚀 ~ DriverRoute ~ userRole:", userRole);

  const handleClose = () => {
    setIsShow(false);
  };
  const handleOpen = () => {
    setIsShow(true);
  };
  const handleStatusToggle = () => {
    const newStatus = fleetData.status == "ACTIVE" ? "DEACTIVE" : "ACTIVE";

    // Dispatch the status update
    dispatch(
      updateFleetAsyncThunk({
        id: fleetData?.id,
        data: { status: newStatus },
        callBack: () => {
          handleClose();
        },
      })
    );
  };

  return (
    <>
      <Modal
        show={isShow}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="fleet-status-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="status-modal-body">
          <div className="title">
            <strong>
              Are you sure you want to{" "}
              {fleetData?.status === "ACTIVE" ? "deactivate" : "reactivate"}{" "}
              this Vehicle?
            </strong>
          </div>
          <div className="single-button">
            {/* {user} */}
            <button
              className={fleetData?.status === "ACTIVE" ? "danger" : "primary"}
              disabled={loadings.updateFleetAsyncThunk}
              onClick={handleStatusToggle}
            >
              {loadings.updateFleetAsyncThunk ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                <>
                  <span>
                    {fleetData?.status === "ACTIVE"
                      ? "Deactivate"
                      : "Reactivate"}
                  </span>
                </>
              )}
            </button>
          </div>
        </div>
      </Modal>
      <div className="load-top-bar-wrapper">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div className="bar-navigation">
                <div className="back-icon">
                  <Link to="/fleet">
                    <img src={arrowBack} alt="arrow back" />
                  </Link>
                </div>
                <div className="page-name">
                  <strong>
                    {`${
                      fleetData?.fleetType === "TRUCK" ? "TRUCK" : "Trailer"
                    } # ${fleetData?.fleetNumber || ""}`}
                  </strong>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="buttons-wrapper">
                <div className="single-button">
                  {userRole !== "org:driver" && (
                    <button
                      className={` ${
                        fleetData?.status === "ACTIVE" ? "danger" : "add-btn"
                      } text-light`}
                      onClick={handleOpen}
                    >
                      {fleetData?.status === "ACTIVE"
                        ? "Deactivate"
                        : "Reactivate"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
