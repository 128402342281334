import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import Loadingdata from "./loadingdata"; // Assuming you have a loading spinner

const AuthRoute = ({ children }) => {
  const { isSignedIn, isLoaded } = useAuth();

  // Only show loading state until authentication status is fully resolved
  if (!isLoaded) {
    return <Loadingdata />;
  }

  // Redirect authenticated users to the home page
  if (isSignedIn) {
    return <Navigate to="/home" replace />;
  }

  // Render the child routes (like login, signup) if the user is not authenticated
  return children;
};

export default AuthRoute;
