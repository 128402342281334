import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FiEye, FiUpload } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { updateUserAsyncThunk } from "../redux/pagesSlices/userSlice";
import { Spinner } from "react-bootstrap";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Typography } from "@mui/material";

const initialState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  // password: "",
  // confirmPassword: "",
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string(),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required"),
  phone: Yup.string(),
  profileImage: Yup.mixed()
    .nullable()
    .test(
      "fileFormat",
      "Unsupported file format. Only JPEG, and PNG files are allowed.",
      (files) => {
        if (!files) return true; // No file selected is valid
        return Array.from(files).every((file) =>
          ["image/jpeg", "image/png"].includes(file.type)
        );
      }
    ),
});

export default function ProfileSetting({ show, handleClose }) {
  const { loadings } = useSelector((state) => state.user);
  const { user, isLoaded } = useUser();
  const dispatch = useDispatch();
  const [base64URL, setBase64URL] = useState("");
  const fileInputRef = useRef(null);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (values, { setSubmitting, resetForm, errors }) => {
    // if (formData.phone && !validatePhoneNumber(formData.phone)) {
    //   return toast.error(
    //     "Phone number must be a valid international number in the format +123456789."
    //   );
    // }
    // if (formData.password !== formData.confirmPassword) {
    //   return toast.error("Passwords do not match");
    // }

    const formDataWithFile = new FormData();

    formDataWithFile.append("firstName", values.firstName);
    formDataWithFile.append("lastName", values.lastName);
    formDataWithFile.append("phone", values.phone);
    // formDataWithFile.append("password", formData.password);
    if (values.profileImage) {
      formDataWithFile.append("profileImage", values.profileImage);
    }

    dispatch(
      updateUserAsyncThunk({
        id: user.id,
        data: formDataWithFile,
        callBack: async () => {
          await user.reload();
          handleClose();
          setBase64URL("");
        },
      })
    );
  };

  return (
    <>
      <div className="profile-setting-modal">
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="profile-setting-modal"
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="bg-image" style={{ backgroundImage: "none" }}>
              {isLoaded ? (
                <Formik
                  initialValues={{
                    firstName: user?.firstName || "",
                    lastName: user?.lastName || "",
                    phone: user?.phoneNumbers[0]?.phoneNumber || "",
                    email: user?.primaryEmailAddress?.emailAddress || "",
                    profileImage: null,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    errors,
                    dirty,
                  }) => (
                    <Form className="form-wrapper bg-white">
                      <div className="company-sec mt-0">
                        <div className="upload-sec">
                          <div className="heading-sec">
                            <strong>Profile Settings</strong>
                          </div>
                          <div className="title">
                            <span>Profile Picture</span>
                          </div>

                          <div className="flex-wrapper">
                            <div className="upload-border">
                              <input
                                type="file"
                                id="file-upload"
                                className="file-upload-input"
                                accept=".png, .jpeg"
                                onChange={async (e) => {
                                  const file = e.target.files[0];
                                  try {
                                    const base64 = await convertToBase64(file);
                                    setBase64URL(base64);
                                    setFieldValue("profileImage", file);
                                  } catch (error) {
                                    console.error(
                                      "Error converting file to Base64:",
                                      error
                                    );
                                  }
                                }}
                                ref={fileInputRef}
                              />
                              <label
                                htmlFor="file-upload"
                                className="upload-label"
                              >
                                <FiUpload />
                                <p>Upload Image</p>
                              </label>
                            </div>
                            <p>
                              <button
                                type="button"
                                className="btn btn-link"
                                onClick={() => {
                                  setFieldValue("profileImage", null);
                                  setBase64URL("");
                                }}
                              >
                                Remove
                              </button>
                            </p>
                          </div>

                          <div className="extensions">
                            <p>
                              *png,*jpeg files up to 10MB at least 400px by
                              400px
                            </p>
                          </div>
                        </div>

                        <div className="Logo-sec">
                          <div className="company-logo">
                            <img
                              src={base64URL ? base64URL : user?.imageUrl}
                              alt="Company Logo"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="login-fields">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="input-fields">
                              <label>First Name</label>
                              <div className="company-wrapper">
                                <Field
                                  type="text"
                                  name="firstName"
                                  placeholder="First Name"
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="input-fields">
                              <label>Last Name</label>
                              <div className="company-wrapper">
                                <Field
                                  type="text"
                                  name="lastName"
                                  placeholder="Last Name"
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="input-fields">
                              <label>Enter your email</label>
                              <div className="company-wrapper">
                                <Field
                                  type="text"
                                  name="email"
                                  disabled
                                  placeholder="Your Email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="input-fields">
                              <label>Phone Number</label>
                              <div className="company-wrapper">
                                <PhoneInput
                                  country="US"
                                  onlyCountries={["us"]}
                                  disableCountryCode
                                  disableDropdown
                                  placeholder="Enter phone number"
                                  value={values.phone}
                                  onChange={(value) =>
                                    setFieldValue("phone", value)
                                  }
                                  inputProps={{
                                    name: "phone",
                                    autoFocus: true,
                                  }}
                                  error={touched.phone && Boolean(errors.phone)}
                                />
                                {touched.phone && errors.phone && (
                                  <Typography color="error">
                                    {errors.phone}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*
                     <div className="row">
                      
                     <div className="col-12 col-md-6">
                        <div className="input-fields with-icon">
                          <label>Password</label>
                          <div className="input-wrapper">
                            <input
                              type={isShowPass ? "text" : "password"}
                              placeholder=".........."
                              name="password"
                              value={formData.password}
                              onChange={handleInputChange}
                            />
                            <button
                              className="btn btn-link p-0"
                              type="button"
                              onClick={() => setIsShowPass(!isShowPass)}
                            >
                              {isShowPass ? (
                                <FiEye className="input-icon" />
                              ) : (
                                <FiEyeOff className="input-icon" />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="input-fields with-icon">
                          <label>Password</label>
                          <div className="input-wrapper">
                            <input
                              type={isShowConfirmPass ? "text" : "password"}
                              placeholder=".........."
                              name="confirmPassword"
                              value={formData.confirmPassword}
                              onChange={handleInputChange}
                            />
                            <button
                              className="btn btn-link p-0"
                              type="button"
                              onClick={() =>
                                setIsShowConfirmPass(!isShowConfirmPass)
                              }
                            >
                              {isShowConfirmPass ? (
                                <FiEye className="input-icon" />
                              ) : (
                                <FiEyeOff className="input-icon" />
                              )}
                            </button>
                          </div>
                        </div>
                      </div> 
                      
                      </div> 
                      */}
                      </div>
                      <Button
                        type="submit"
                        disabled={loadings?.updateUserAsyncThunk || !dirty}
                      >
                        {loadings?.updateUserAsyncThunk ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : (
                          "Save Changes"
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Spinner size="lg" />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
