import React, { useEffect, useLayoutEffect, useState } from "react";
import companyLogo from "../assets/images/forms-images/Ellipse 3780.png";
import { FiEyeOff } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import Sidebar from "../components/adminPanelComponents/Sidebar";
import Titlebar from "../components/adminPanelComponents/Titlebar";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import WorkSpace from "../components/WorkSpace";
import pencil from "../assets/images/pencil-edit.svg";
import companyIcon from "../assets/images/company-icon.svg";
import EditCompanyProfileModal from "../components/Modals/CompanyProfileEditModal";
import { getWorkspaceAsyncThunk } from "../redux/pagesSlices/workspaceSlice";
import { useDispatch, useSelector } from "react-redux";
import { State } from "country-state-city"; // Import the State module
import { getFirstTwoUpperCase } from "../helpers/parse";
import Loadingdata from "../components/loadingdata";
import NoDataFound from "../components/nodatafound";

const CompanyProfile = () => {
  const { activeOrg, workspace, loadings } = useSelector(
    (state) => state.workspace
  );
  const [stateDetails, setStateDetails] = useState({});
  const dispatch = useDispatch();
  const states = State.getStatesOfCountry("US");

  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const handleShowCompanyModal = () => setShowCompanyModal(true);
  const handleHideCompanyModal = () => setShowCompanyModal(false);

  useLayoutEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getWorkspaceAsyncThunk({
          id: activeOrg,
        })
      );
    };

    fetchData();
  }, [dispatch, activeOrg]);

  useEffect(() => {
    if (workspace && workspace?.state) {
      setStateDetails(
        states.find((state) => state.isoCode === workspace?.state)
      );
    }
  }, [workspace]);

  return (
    <>
      <div className="company-page-wrapper">
        <div className="company-page-topbar">
          <span>Company</span>
          <button className="icon" onClick={handleShowCompanyModal}>
            <img src={pencil} alt="icon" />
          </button>
          <EditCompanyProfileModal
            workspace={workspace}
            showCompanyModal={showCompanyModal}
            handleHideCompanyModal={handleHideCompanyModal}
          />
        </div>
        {loadings?.getWorkspaceAsyncThunk ? (
          <Loadingdata />
        ) : !workspace ? (
          <NoDataFound nodataTitle="No WorkSpace Found" />
        ) : (
          <div className="company-page-content">
            <div className="container">
              <div className="content-wrapper">
                <div className="content-section">
                  <div className="icon-title">
                    {workspace?.companyLogo ? (
                      <div className="workspace-avatar">
                        <img
                          src={
                            process.env.REACT_APP_CLOUDFLARE_URL +
                            workspace?.companyLogo
                          }
                          alt="icon"
                        />
                      </div>
                    ) : (
                      <div className="icon">
                        {getFirstTwoUpperCase(workspace?.name)}
                      </div>
                    )}

                    <strong>{workspace?.name}</strong>
                  </div>
                  <div className="info-section">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="single-item">
                          <span>MIC Number</span>
                          <strong>{workspace?.mcNumber}</strong>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-item">
                          <span>DOT Number</span>
                          <strong>{workspace?.dotNumber}</strong>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-item">
                          <span>City</span>
                          <strong>{workspace?.city}</strong>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-item">
                          <span>State</span>
                          <strong>{stateDetails?.name}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <WorkSpace /> */}
    </>
  );
};

export default CompanyProfile;
