import { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { updateFleetAsyncThunk } from "../redux/pagesSlices/fleetSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
const currentYear = new Date().getFullYear();

// Initial form data and validation schema
const validationSchema = Yup.object().shape({
  modelYear: Yup.number("Model Year must be a number")
    .min(1800, "Model Year must be at least 1800")
    .max(currentYear, `Model Year cannot be later than ${currentYear}`)
    .required("Model Year is required"),
  fleetMake: Yup.string().required("Fleet Make is required").max(32),
  fleetModel: Yup.string()
    .matches(/^[0-9]+$/, "fleetModel must be numeric")
    .required("fleetModel is required")
    .max(32),
  odometer: Yup.string()
    .matches(/^[0-9]+$/, "Odometer must be numeric")
    .required("Odometer is required")
    .max(32),
  fleetNumber: Yup.string()
    .matches(/^[0-9]+$/, "Fleet Number must be numeric")
    .required("Fleet Number is required")
    .max(32),
  licenseNumber: Yup.string()
    .matches(/^[0-9]+$/, "License Number must be numeric")
    .required("License Number is required")
    .max(32),
  // status: Yup.string().required("Status is required").max(32),
});

export default function TruckDetailsForm({ fleetData }) {
  const { loadings } = useSelector((state) => state?.fleet);
  const dispatch = useDispatch();

  // Formik form submit handler
  const handleSubmit = (values, { setSubmitting }) => {
    const data = {
      modelYear: values.modelYear,
      fleetMake: values.fleetMake,
      fleetModel: values.fleetModel,
      odometer: values.odometer,
      fleetNumber: values.fleetNumber,
      licenseNumber: values.licenseNumber,
      // status: values.status,
    };

    dispatch(
      updateFleetAsyncThunk({
        id: fleetData.id,
        data,
        activeTab: fleetData?.fleetType == "TRUCK" ? "TRUCK" : "TAILOR",
      })
    ).finally(() => setSubmitting(false)); // Stop loading spinner after submission
  };

  return (
    <div className="truck-details-form">
      <div className="container-fluid">
        <div className="truck-details-form-inner-wrapper">
          <div className="form-wrapper">
            <Formik
              initialValues={{
                modelYear: fleetData?.modelYear || 0,
                fleetMake: fleetData?.fleetMake || "",
                fleetModel: fleetData?.fleetModel || "",
                odometer: fleetData?.odometer || 0,
                fleetNumber: fleetData?.fleetNumber || 0,
                licenseNumber: fleetData?.licenseNumber || "",
                // status: fleetData?.status || "ACTIVE",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true} // To update form data when fleetData changes
            >
              {({ isSubmitting, setFieldValue, values, dirty }) => (
                <Form>
                  <div className="form-fiels-wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="single-field">
                          <label>Model Year</label>
                          <Field
                            type="text"
                            name="modelYear"
                            placeholder="Enter Model Year"
                          />
                          <ErrorMessage
                            name="modelYear"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="single-field">
                          <label>
                            {fleetData?.fleetType === "TRUCK"
                              ? "Truck"
                              : "Trailer"}{" "}
                            Make
                          </label>
                          <Dropdown className="dropdown-1">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {values.fleetMake || "Select"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {["Freightliner", "Kenworth", "Volvo"].map(
                                (make) => (
                                  <Dropdown.Item
                                    key={make}
                                    onClick={() =>
                                      setFieldValue("fleetMake", make)
                                    }
                                  >
                                    {make}
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="fleetMake"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="single-field">
                          <label>License Number</label>
                          <Field
                            type="text"
                            name="licenseNumber"
                            placeholder={`Enter ${
                              fleetData?.fleetType === "TRUCK"
                                ? "Truck"
                                : "Trailer"
                            } License Number`}
                          />
                          <ErrorMessage
                            name="licenseNumber"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="single-field">
                          <label>
                            {fleetData?.fleetType === "TRUCK"
                              ? "Truck"
                              : "Trailer"}{" "}
                            Model
                          </label>
                          <Field
                            type="text"
                            name="fleetModel"
                            placeholder="fleetModel"
                          />
                          <ErrorMessage
                            name="fleetModel"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="single-field">
                          <label>Odometer</label>
                          <Field
                            type="text"
                            name="odometer"
                            placeholder="Enter Odometer"
                          />
                          <ErrorMessage
                            name="odometer"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="single-field">
                          <label>Fleet Number</label>
                          <Field
                            type="text"
                            name="fleetNumber"
                            placeholder={`Enter ${
                              fleetData?.fleetType === "TRUCK"
                                ? "Truck"
                                : "Trailer"
                            } Number`}
                          />
                          <ErrorMessage
                            name="fleetNumber"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-12">
                        <div className="single-field">
                          <label>Status</label>
                          <Dropdown className="dropdown-1">
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              {values.status || "Select Status"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {["ACTIVE", "DEACTIVE"].map((status) => (
                                <Dropdown.Item
                                  key={status}
                                  onClick={() =>
                                    setFieldValue("status", status)
                                  }
                                >
                                  {status}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="error"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="save-button">
                    <button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !dirty ||
                        loadings?.updateFleetAsyncThunk
                      }
                    >
                      {isSubmitting || loadings?.updateFleetAsyncThunk ? (
                        <Spinner animation="border" role="status" size="sm" />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
