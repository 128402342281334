// PricingToggleButtons.jsx
import React, { useState } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

export default function PricingToggleButtons() {
  const [alignment, setAlignment] = useState('monthly')

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment)
    }
  }

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleChange}
      sx={{
        padding: '4px',
        borderRadius: '8px',
        backgroundColor: 'var(--Global-Neutral-Grey-200, #F8F8F8)',
        '& .MuiToggleButton-root': {
          border: 'none',
          borderRadius: '6px',
          lineHeight: '1.25rem',
          letterSpacing: '-0.00125rem',
          fontStyle: 'normal',
          fontSize: '0.875rem',
          fontFamily: 'Inter, sans-serif',
          textTransform: 'none',
          '&.Mui-selected': {
            background: 'var(--Grey-White, #FFF)',
            boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)',
            color: 'var(--Global-Neutral-Grey-1300, #0A090B)',
            fontWeight: 550,
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.00)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.00)',
          },
        },
      }}
    >
      <ToggleButton
        disableRipple
        sx={{
          '&.MuiToggleButton-root': {
            borderRadius: '6px',
            padding: '6px 12px',
            color: 'var(--Global-Neutral-Grey-1000, #4F4D55)',
            fontWeight: 475,
          },
          '&.Mui-selected': {
            background: 'var(--Grey-White, #FFF)',
            boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)',
            color: 'var(--Global-Neutral-Grey-1300, #0A090B)',
            fontWeight: 550,
          },
        }}
        value='monthly'
      >
        Monthly
      </ToggleButton>
      <ToggleButton
        disableRipple
        sx={{
          '&.MuiToggleButton-root': {
            borderRadius: '6px',
            padding: '6px 12px',
            color: 'var(--Global-Neutral-Grey-1000, #4F4D55)',
            fontWeight: 475,
          },
          '&.Mui-selected': {
            background: 'var(--Grey-White, #FFF)',
            boxShadow: '0px 1.5px 4px -1px rgba(10, 9, 11, 0.07)',
            color: 'var(--Global-Neutral-Grey-1300, #0A090B)',
            fontWeight: 550,
          },
        }}
        value='annually'
      >
        Annually (25% OFF)
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
