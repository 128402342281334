export const parseSortBy = (sortBy = []) => {
  if (!Array.isArray(sortBy) || !sortBy.length) return {};
  if (!sortBy.some((k) => !!k.order)) return {};
  return {
    sortBy: sortBy
      .filter((k) => !!k.order)
      .map((item) => `${item.key}:${item.order}`)
      .join(","),
  };
};

export const getFirstTwoUpperCase = (str) => {
  if (str) {
    if (str.length < 2) {
      return str.toUpperCase();
    }
    return str.substring(0, 2).toUpperCase();
  }
  return "";
};
