import { useUser } from "@clerk/clerk-react";
import React, { useEffect } from "react";

const HomeWelcome = () => {
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, []);

  return (
    <>
      <div className="welcome-section">
        <h1>
          Hi, Welcome to <span>ReEnvoy! </span> 👋
        </h1>
      </div>
    </>
  );
};

export default HomeWelcome;
