import React from "react";
import Modal from "react-bootstrap/Modal";
import trash from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { removeUserFromWorkspaceAsyncThunk } from "../../redux/pagesSlices/workspaceSlice";
import { Spinner } from "react-bootstrap";

function DeleteUserModal({
  deleteModalShow,
  handleDeleteModalClose,
  deleteId,
}) {
  const { loadings } = useSelector((state) => state.workspace);
  console.log("🚀 ~ deleteId:", deleteId);
  const dispatch = useDispatch();

  const handleDeleteUser = () => {
    dispatch(
      removeUserFromWorkspaceAsyncThunk({
        id: deleteId,
        callBack: () => {
          handleDeleteModalClose();
        },
      })
    );
  };

  return (
    <Modal
      show={deleteModalShow}
      onHide={handleDeleteModalClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-load-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="delete-modal-body">
        <div className="title">
          <strong>Are you sure you want to delete this user?</strong>
        </div>
        <div className="delete-btn">
          <button
            onClick={handleDeleteUser}
            disabled={loadings.removeUserFromWorkspaceAsyncThunk}
          >
            {loadings?.removeUserFromWorkspaceAsyncThunk ? (
              <Spinner animation="border" color="white" role="status" size="sm" />
            ) : (
              <>
                <img src={trash} alt="Delete Icon" />
                <span>Delete User</span>
              </>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteUserModal;
